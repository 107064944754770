import { createContext } from 'react';

import type { KeyboardNavigationContextValue } from '../lib/types';

export const defaultKeyboardNavigationContextValue: KeyboardNavigationContextValue =
  {
    registerElements: () => undefined,
    elementRefs: {
      current: {
        input: null,
        categories: [],
        results: [],
      },
    },
  };

export const KeyboardNavigationContext =
  createContext<KeyboardNavigationContextValue>(
    defaultKeyboardNavigationContextValue,
  );

KeyboardNavigationContext.displayName = 'KeyboardNavigationContext';
