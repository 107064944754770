import { useTranslation } from 'react-i18next';

import { Icon } from '~/components';
import { cn } from '~/lib/cn';
import type { IconType } from '~/modules/link-farm/components/LinkList/LinkItemIcons';
import { ICON_BY_TYPE } from '~/modules/link-farm/components/LinkList/LinkItemIcons';

export type ItemIconProps = {
  icon: string | IconType;
  isExternalLink: boolean;
};

export function ItemIcon({ icon, isExternalLink }: ItemIconProps) {
  const { t } = useTranslation('wmgone');

  return (
    <div className="relative">
      <img
        src={typeof icon === 'string' ? icon : ICON_BY_TYPE[icon]}
        className={cn(
          'size-2xl rounded-full transition delay-150 duration-300',
          isExternalLink && 'group-hover:opacity-none group-focus:opacity-none',
        )}
        alt={t('globalSearch.icon', { icon })}
      />
      {isExternalLink ? (
        <div
          aria-hidden
          className="absolute top-0 flex size-2xl items-center justify-center rounded-full opacity-none transition duration-150 bg-emphasis-strong group-hover:opacity-full group-focus:opacity-full"
        >
          <Icon
            type="external-link"
            size="lg"
            variant="regular"
            role="presentation"
            aria-hidden
            aria-label="external-url-icon"
            className="text-white"
          />
        </div>
      ) : null}
    </div>
  );
}

ItemIcon.displayName = 'ItemIcon';
