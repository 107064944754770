import type { Role } from '../api/lib/schemas';

export interface RoleInfo {
  name: string;
  description: string;
}

export const RoleMap: Record<Role, RoleInfo> = {
  LABEL_ADMIN: {
    name: 'labelAdmin.name',
    description: 'labelAdmin.description',
  },
  RELEASE_COORDINATOR: {
    name: 'releaseCoordinator.name',
    description: 'releaseCoordinator.description',
  },
  VIDEO_COORDINATOR: {
    name: 'videoCoordinator.name',
    description: 'videoCoordinator.description',
  },
  OPS_ADMIN: {
    name: 'opsAdmin.name',
    description: 'opsAdmin.description',
  },
};
