import { useQuery } from '@tanstack/react-query';

import { getAssetByFamilyId } from '../lib/tracks';

interface TrackDetailsQueryProps {
  familyId?: string;
}

export const useTrackDetailsQuery = ({ familyId }: TrackDetailsQueryProps) => {
  return useQuery({
    queryKey: ['asset-by-family-id', familyId],
    queryFn: () => {
      return getAssetByFamilyId({ familyId: familyId! });
    },
    enabled: !!familyId,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
};
