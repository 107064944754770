import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { DEFAULT_PAGE_SIZE } from '~/modules/settings/constants';

export const usePagination = (perPage: number = DEFAULT_PAGE_SIZE) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get('page');
  const [pageNumber, setPageNumber] = useState(
    pageParam && +pageParam ? +pageParam : 1,
  );

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    if (pageNumber > 1) {
      params.set('page', `${pageNumber}`);
    } else {
      params.delete('page');
    }

    setSearchParams(params);
  }, [pageNumber, setSearchParams]);

  const skip = useMemo(() => (pageNumber - 1) * perPage, [pageNumber, perPage]);
  const take = perPage;

  return {
    page: pageNumber,
    setPageNumber,
    perPage,
    skip,
    take,
  };
};

export const usePaginationMessage = (
  page: number,
  perPage: number,
  total: number,
  entity: string,
) => {
  const { t } = useTranslation('settings');

  const translatedEntity = t(entity);

  const start = useMemo(() => (page - 1) * perPage + 1, [page, perPage]);
  const end = useMemo(
    () => Math.min(page * perPage, total),
    [page, perPage, total],
  );

  const message = t('pagination.label', {
    start,
    end,
    total,
    entity: translatedEntity,
  });

  return message;
};
