import type { ColDef } from '../../components/DataGrid/types';
import { dateFormatter, listFormatter, zeroFormatter } from './renderers';

export const columnDefs: ColDef[] = [
  {
    field: 'hashKey',
    headerName: 'ID',
    flex: 1,
    sortable: true,
    hide: true,
  },
  {
    field: 'artist',
    headerName: 'Artist',
    width: 300,
    sortable: true,
    hide: false,
  },
  {
    field: 'cleanTitle',
    headerName: 'Clean Title',
    width: 300,
    sortable: true,
    hide: false,
  },
  {
    field: 'trackTitleCleaned',
    headerName: 'Track Title Cleaned',
    width: 120,
    sortable: true,
    hide: true,
  },
  {
    field: 'mediaCode',
    headerName: 'Media Code',
    width: 120,
    sortable: true,
    hide: true,
  },
  {
    field: 'latestProductTs',
    headerName: 'Update Date',
    width: 120,
    sortable: true,
    hide: true,
    valueFormatter: dateFormatter,
  },
  {
    field: 'isrcList',
    headerName: 'ISRC List',
    flex: 1,
    valueFormatter: listFormatter,
    hide: true,
  },
  {
    field: 'gpidList',
    headerName: 'GPID List',
    flex: 1,
    valueFormatter: listFormatter,
    hide: true,
  },
  {
    field: 'majorLabelList',
    headerName: 'Major Label List',
    flex: 1,
    valueFormatter: listFormatter,
    hide: true,
  },
  {
    field: 'presentationLabelList',
    headerName: 'Presentation Label List',
    flex: 1,
    valueFormatter: listFormatter,
    hide: true,
  },
  {
    field: 'repertoireLabelList',
    headerName: 'Repetoire Label List',
    flex: 1,
    valueFormatter: listFormatter,
    hide: true,
  },
  {
    field: 'deliveryTerritoryList',
    headerName: 'Delivery Territory List',
    flex: 1,
    valueFormatter: listFormatter,
    hide: true,
  },
  {
    field: 'trackRightsTerritoryList',
    headerName: 'Track Rights Territory List',
    flex: 1,
    valueFormatter: listFormatter,
    hide: true,
  },
  {
    field: 'shortTitleList',
    headerName: 'Short Title List',
    flex: 1,
    valueFormatter: listFormatter,
    hide: true,
  },
  {
    field: 'majorGenreDescList',
    headerName: 'Major Genre List',
    flex: 1,
    valueFormatter: listFormatter,
    hide: true,
  },
  {
    field: 'rollUpTitleList',
    headerName: 'Roll Up Title List',
    flex: 1,
    valueFormatter: listFormatter,
    hide: true,
  },
  {
    field: 'wtdStreams',
    headerName: 'WTD Streams',
    flex: 1,
    valueFormatter: zeroFormatter,
    hide: true,
    sortable: true,
  },
  {
    field: 'mtdStreams',
    headerName: 'MTD Streams',
    flex: 1,
    valueFormatter: zeroFormatter,
    hide: true,
    sortable: true,
  },
  {
    field: 'ytdStreams',
    headerName: 'YTD Streams',
    flex: 1,
    valueFormatter: zeroFormatter,
    hide: true,
    sortable: true,
  },
  {
    field: 'immersiveCnt',
    headerName: 'Immersive',
    width: 120,
    valueFormatter: zeroFormatter,
    hide: false,
    sortable: true,
  },
  {
    field: 'spedUpCnt',
    headerName: 'Sped Up',
    width: 120,
    valueFormatter: zeroFormatter,
    hide: false,
    sortable: true,
  },
  {
    field: 'slowedDownCnt',
    headerName: 'Slowed Down',
    width: 120,
    valueFormatter: zeroFormatter,
    hide: false,
    sortable: true,
  },
  {
    field: 'acousticCnt',
    headerName: 'Acoustic',
    width: 120,
    valueFormatter: zeroFormatter,
    hide: false,
    sortable: true,
  },
  {
    field: 'instrumentalCnt',
    headerName: 'Instrumental',
    width: 120,
    valueFormatter: zeroFormatter,
    hide: false,
    sortable: true,
  },
  {
    field: 'remixCnt',
    headerName: 'Remix',
    width: 120,
    valueFormatter: zeroFormatter,
    hide: false,
    sortable: true,
  },
  {
    field: 'remasterCnt',
    headerName: 'Remaster',
    width: 120,
    valueFormatter: zeroFormatter,
    hide: false,
    sortable: true,
  },
  {
    field: 'liveCnt',
    headerName: 'Live',
    width: 120,
    valueFormatter: zeroFormatter,
    hide: false,
    sortable: true,
  },
  {
    field: 'exclusiveCnt',
    headerName: 'Exclusive',
    width: 120,
    valueFormatter: zeroFormatter,
    hide: false,
    sortable: true,
  },
  {
    field: 'amendedCnt',
    headerName: 'Amended',
    width: 120,
    valueFormatter: zeroFormatter,
    hide: false,
    sortable: true,
  },
  {
    field: 'pianoCnt',
    headerName: 'Piano',
    width: 120,
    valueFormatter: zeroFormatter,
    hide: false,
    sortable: true,
  },
  {
    field: 'stringsCnt',
    headerName: 'Strings',
    width: 120,
    valueFormatter: zeroFormatter,
    hide: false,
    sortable: true,
  },
  {
    field: 'demoCnt',
    headerName: 'Demo',
    width: 120,
    valueFormatter: zeroFormatter,
    hide: false,
    sortable: true,
  },
  {
    field: 'featuredCnt',
    headerName: 'Featured',
    width: 120,
    valueFormatter: zeroFormatter,
    hide: false,
    sortable: true,
  },
];
