import { Navigate, Route, Routes } from 'react-router-dom';

import { ErrorPage } from '~/components/ErrorPage';
import { env } from '~/lib/env';
import { useCanAccessView, VIEWS } from '~settings/hooks/useCanAccessView';

import { TabsPagesLayout } from './components';
import { LabelRouter } from './modules/label/router';
import { ListLabelGroupPage } from './modules/label-group/pages/ListLabelGroupPage';
import { UserRouter } from './modules/user/router';

/**
 * Top-level router combining module-level routers
 */
const Router = () => {
  const hasPermissionsToViewLabelGroups = useCanAccessView(
    VIEWS.LABEL_GROUPS_LIST,
  );

  const labelGroupsEnabled =
    env('VITE_CONTAINER_SETTINGS_LABEL_GROUPS_ENABLED', false) === 'true';

  return (
    <Routes>
      <Route element={<TabsPagesLayout />}>
        <Route index element={<Navigate replace to="users" />} />
        {hasPermissionsToViewLabelGroups && labelGroupsEnabled ? (
          <Route path="label-groups" element={<ListLabelGroupPage />} />
        ) : null}
      </Route>

      <Route path="users/*" element={<UserRouter />} />
      <Route path="labels/*" element={<LabelRouter />} />

      <Route path="*" element={<ErrorPage type="notFoundPage" />} />
    </Routes>
  );
};
Router.displayName = 'SettingsRouter';

export { Router };
