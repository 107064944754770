import { env } from '~/lib/env';
import type { User } from '~/modules/auth';
import type { UserFeature } from '~/modules/auth/lib/bff-client';
import type { HasPermissionFunction } from '~/modules/remotes/types';

export enum NavigationPermissionKeys {
  RELEASE_CLINIC = 'RELEASE_CLINIC',
  VIDEO_MANAGEMENT = 'VIDEO_MANAGEMENT',
  REPORTS = 'REPORTS',
  CREATE_RELEASE = 'CREATE_RELEASE',
  COOP_SETTINGS = 'COOP_SETTINGS',
  SETTINGS = 'SETTINGS',
}

export const hasSettingsEnabled = (features: UserFeature[] = []) => {
  const viteContainerSettingsEnabled =
    env('VITE_CONTAINER_SETTINGS_ENABLED', false) === 'true';

  return (
    viteContainerSettingsEnabled &&
    features.some(
      (feature) =>
        feature.enabled &&
        feature.systemPermission === 'VIEW_NEW_PERMISSIONS_UI',
    )
  );
};

export const NAVIGATION_PERMISSIONS: Record<
  NavigationPermissionKeys,
  <Context extends Record<string, unknown>>(
    hasPermission: HasPermissionFunction,
    context?: Context,
  ) => boolean
> = {
  [NavigationPermissionKeys.RELEASE_CLINIC]: (hasPermission) =>
    hasPermission('RELEASE_CLINIC.VIEW_INTEGRATION'),

  [NavigationPermissionKeys.VIDEO_MANAGEMENT]: (hasPermission) => {
    return hasPermission('VIDEO_CLAIMING.VIEW');
  },

  [NavigationPermissionKeys.REPORTS]: (hasPermission) =>
    hasPermission('REPORT.VIEW'),
  [NavigationPermissionKeys.CREATE_RELEASE]: (hasPermission) =>
    hasPermission('RELEASE.CREATE'),
  [NavigationPermissionKeys.COOP_SETTINGS]: (hasPermission) =>
    hasPermission('PLATFORM.SETTINGS'),

  [NavigationPermissionKeys.SETTINGS]: (_, context) => {
    const {
      user: { features },
    } = (context as Record<'user', User>) || { user: { features: [] } };

    return hasSettingsEnabled(features);
  },
};
