import { cn } from '~/lib/cn';

export interface CardProps {
  className?: string;
  children: React.ReactNode | React.ReactNode[];
}

const Card = ({ className, children }: CardProps): JSX.Element => {
  return (
    <div
      className={cn('px-md py-lg bg-level-01', className)}
      data-testid="card"
    >
      <div className="flex h-full flex-col justify-between gap-lg">
        {children}
      </div>
    </div>
  );
};

Card.displayName = 'Card';

export { Card };
