import type {
  ExportCSVPayload,
  ExportCSVResponse,
  FetchFiltersRefinersPayload,
  PageQuery,
  RefinerResponse,
} from '../components/DataGrid/types';
import { snakeToCamel } from '../components/DataGrid/utils';
import { URL_PREFIX } from '../constants/api';
import type {
  DerivativeRow,
  DerivativeRowKey,
  DerivativeRowKeyWithPrefix,
  PageColl,
} from '../types';
import type { DerivativePageDataResponse } from '../types/derivatives';
import { derivativeMockData } from './derivativeMock';
import { APIError } from './errors';

const ARRAY_FIELDS: (keyof DerivativeRow)[] = [
  'isrcList',
  'gpidList',
  'majorLabelList',
  'presentationLabelList',
  'repertoireLabelList',
  'deliveryTerritoryList',
  'trackRightsTerritoryList',
  'shortTitleList',
  'majorGenreDescList',
  'rollUpTitleList',
];

const mapPageData = (
  data: Partial<DerivativeRow>[],
): Partial<DerivativeRow>[] => {
  return data.map((item) => {
    const mapped: { [key: string]: unknown } = {};
    Object.entries(item).forEach(([key, value]) => {
      const camelKey = snakeToCamel(key.toLowerCase()) as keyof DerivativeRow;
      if (ARRAY_FIELDS.includes(camelKey) && typeof value === 'string') {
        try {
          mapped[camelKey] = JSON.parse(value);
        } catch {
          mapped[camelKey] = [];
        }
      } else {
        mapped[camelKey] = value;
      }
    });
    return mapped as Partial<DerivativeRow>;
  });
};

export const getDerivativePageData = async (
  query: PageQuery<DerivativeRowKey, DerivativeRowKeyWithPrefix>,
): Promise<PageColl> => {
  try {
    const defaultLimit = 100;
    const limit = query.limit ?? defaultLimit;

    // const url = `${URL_PREFIX}/api/v1/derivative`;
    // const body = JSON.stringify({
    //   limit,
    //   select: query.select?.map(camelToSnake),
    //   order: query.order?.map(camelToSnake),
    //   filter: query.filter,
    //   offset: query.offset,
    // });

    // const response = await fetch(url, {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body,
    // });

    // if (!response.ok) {
    //   throw new APIError('Failed to fetch derivative data', response.status);
    // }

    const json = derivativeMockData as DerivativePageDataResponse; // (await response.json()) as DerivativePageDataResponse;

    return {
      data: mapPageData(json.data),
      total: json.total,
      nextPageToken:
        json.data.length === limit
          ? `offset=${(query.offset ?? 0) + limit}`
          : '',
    };
  } catch (error) {
    console.warn('Error fetching derivative page data:', error);
    throw error;
  }
};

export const exportDerivativeCSV = async (
  gridData: ExportCSVPayload,
): Promise<ExportCSVResponse> => {
  try {
    const url = `${URL_PREFIX}/api/v1/derivative/export`;
    const body = JSON.stringify(gridData);

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    });

    if (!response.ok) {
      throw new APIError('Failed to export CSV', response.status);
    }

    return await response.json();
  } catch (error) {
    console.warn('Error exporting CSV:', error);
    throw error;
  }
};

export const getDerivativeFiltersRefiners = async (
  payload: FetchFiltersRefinersPayload,
): Promise<RefinerResponse> => {
  try {
    const url = `${URL_PREFIX}/api/v1/dashboard/refiners`;
    const body = JSON.stringify(payload);

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    });

    if (!response.ok) {
      throw new APIError('Failed to fetch refiners', response.status);
    }

    return await response.json();
  } catch (error) {
    console.warn('Error fetching refiners:', error);
    throw error;
  }
};
