import type { FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Condition } from '~/modules/global-search/Condition';

type RequestAccessLinkProps = {
  requestAccessUrl?: string;
  appName?: string;
  onClick?(e: MouseEvent<HTMLAnchorElement>): void;
};

export const RequestAccessLink: FC<RequestAccessLinkProps> = ({
  requestAccessUrl,
  appName,
  onClick,
}) => {
  const { t } = useTranslation('wmgone');

  return (
    <Condition visible={Boolean(requestAccessUrl)}>
      <a
        aria-roledescription="button"
        aria-label={t('globalSearch.requestAccess', { name: appName })}
        className="text-body-sm underline transition-colors duration-100 hover:text-hover"
        href={requestAccessUrl}
        target="_blank"
        rel="noopener noreferrer"
        onClick={onClick}
      >
        {t('links.item.requestAccess')}
      </a>
    </Condition>
  );
};

RequestAccessLink.displayName = 'RequestAccessLink';
