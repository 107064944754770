import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { Button } from '~/components/Button';
import { InternalChips } from '~/components/FilterChip/InternalChip';
import { env } from '~/lib/env';
import { FilterDrawer } from '~/modules/settings/components/Filter';
import { LABEL_TYPES } from '~/modules/settings/constants/Filter';
import { useFilters } from '~/modules/settings/hooks/useFilters';
import { useSearch } from '~/modules/settings/hooks/useSearch';
import { useSort } from '~/modules/settings/hooks/useSort';
import type { LabelListItem } from '~settings/api/lib/schemas';
import { useLabelList } from '~settings/api/queries/labelList';
import { ListControls } from '~settings/components/ListControls';
import { Table } from '~settings/components/Table';
import { SETTINGS_ROUTES } from '~settings/config/routes';
import { usePagination } from '~settings/hooks/usePagination';

import { LabelsFormFilter } from '../../components/LabelFilter/LabelsFormFilter';
import { useLabelsColumns } from './hooks/useLabelsColumns';

const emptyLabels: LabelListItem[] = [];

export const ListLabelPage = () => {
  const manageLabelEnabled =
    env('VITE_CONTAINER_SETTINGS_MANAGE_LABEL', false) === 'true';

  const { page, setPageNumber, perPage, skip, take } = usePagination();
  const { filterData, drawerOpen, closeDrawer, openDrawer } = useFilters();
  const { sorting, setSorting, sortParams } = useSort();
  const { searchTerm } = useSearch();
  const { isLoading, isFetching, isError, labelList } = useLabelList({
    skip,
    take,
    ...sortParams,
    ...filterData,
    ...(searchTerm && { search: searchTerm }),
  });
  const { t } = useTranslation('settings');
  const columns = useLabelsColumns();

  const navigate = useNavigate();
  const onClick = (row: LabelListItem) => {
    navigate(SETTINGS_ROUTES.LABEL_DETAIL(row.id));
  };

  useEffect(() => {
    setPageNumber(1);
  }, [searchTerm, setPageNumber]);

  if (isLoading) {
    return <section className="py-md">Loading...</section>;
  }

  if (isError) {
    // eslint-disable-next-line i18next/no-literal-string
    return <section className="py-md">Something went wrong.</section>;
  }

  return (
    <>
      <ListControls
        placeholder={t('searchLabels')}
        page={page}
        total={labelList?.total ?? 0}
        perPage={perPage}
        entity="labels"
        onFilterClick={openDrawer}
        internalFilter={
          <InternalChips options={LABEL_TYPES} path="labelType" />
        }
        setPageNumber={setPageNumber}
        toolbarRightAddon={
          manageLabelEnabled ? (
            <Button
              asChild
              variant="primary"
              className="shrink-0 self-center"
              size="large"
            >
              <Link to={SETTINGS_ROUTES.LABEL_CREATE}>
                {t('label.addLink')}
              </Link>
            </Button>
          ) : null
        }
      />
      <Table
        data={labelList?.data ?? emptyLabels}
        columns={columns}
        onRowClick={onClick}
        sort={{
          sorting,
          onSortingChange: setSorting,
        }}
        loading={isFetching}
      />
      <FilterDrawer open={drawerOpen} onOpenChange={closeDrawer}>
        <LabelsFormFilter open={drawerOpen} onApply={closeDrawer} />
      </FilterDrawer>
    </>
  );
};
ListLabelPage.displayName = 'ListLabelPage';
