import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { Icon } from '~/components';
import {
  type FixesHistValue,
  LegendColor,
  LegendName,
} from '~/modules/label-perf/types/label-perf';

import { TooltipContent } from './ChartTooltip';
import { ErrorDisplay } from './ErrorDisplay';

export interface ChartItem extends FixesHistValue {
  __original: Omit<FixesHistValue, 'name'>;
}

export interface ChartProps {
  className?: string;
  data: FixesHistValue[];
  isLoading: boolean;
  isError: boolean;
}

const Chart = ({
  className,
  data,
  isLoading,
  isError,
}: ChartProps): JSX.Element => {
  const { t } = useTranslation('label-perf');

  const ref = useRef<HTMLDivElement>(null);
  const [chartData, setChartData] = useState<ChartItem[]>([]);
  const [width, setWidth] = useState<number>(500);
  const [zoom, setZoom] = useState<number>(1);

  // Update width on resize
  useEffect(() => {
    if (!ref.current) {
      return;
    }
    const dim = ref.current.getBoundingClientRect();
    setWidth(dim.width);

    const onResize = (): void => {
      if (!ref.current) {
        return;
      }
      const newDim = ref.current.getBoundingClientRect();
      setWidth(newDim.width);
    };

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  // Add zoom effect
  useEffect(() => {
    const onWheel = (e: WheelEvent): void => {
      // Hold CTRL key to zoom
      if (!e.ctrlKey) {
        return;
      }

      e.preventDefault(); // Prevent page scroll

      // Zoom in/out from 100% to max 1%
      setZoom((z) => Math.max(0.01, Math.min(1, z + e.deltaY / 2500)));
    };

    ref.current?.addEventListener('wheel', onWheel);
    // No need to remove event listener since the component will be unmounted
  }, []);

  useEffect(() => {
    setChartData(
      data.map((item) => {
        const total = item.needFixes + item.inReview + item.fixed;
        const rounded = (num: number): number => Math.round(num * 100) / 100;
        return {
          name: item.name,
          needFixes: total ? rounded((item.needFixes / total) * 100) : 0,
          inReview: total ? rounded((item.inReview / total) * 100) : 0,
          fixed: total ? rounded((item.fixed / total) * 100) : 0,
          __original: {
            needFixes: item.needFixes,
            inReview: item.inReview,
            fixed: item.fixed,
          },
        };
      }),
    );
  }, [data]);

  return (
    <div data-testid="chart-container" className={className} ref={ref}>
      {data.length === 0 && (
        <div className="absolute inset-0 flex h-full items-center justify-center">
          {isLoading ? (
            <div className="mb-2xl ml-2xl text-body-xl font-bold">
              <p>
                <Icon
                  type="spinner"
                  variant="solid"
                  size="lg"
                  className="fa-spin"
                  data-testid="loader"
                />
              </p>
            </div>
          ) : null}
          {isError ? (
            <ErrorDisplay
              title={t('error.failedLoading')}
              description={t('error.refresh')}
              className="z-10 h-full"
            />
          ) : null}
        </div>
      )}
      <LineChart
        data-testid="chart-svg"
        data={chartData}
        height={280}
        width={width}
      >
        <CartesianGrid stroke="#ffffff1f" />
        <XAxis
          axisLine={false}
          dataKey="name"
          fontSize={10}
          strokeWidth={1}
          tickLine={false}
          tick={{ fill: '#fff', dy: 14 }}
        />
        <YAxis
          allowDataOverflow={true}
          axisLine={false}
          domain={[0, Math.floor(100 * zoom)]}
          fontSize={10}
          strokeWidth={1}
          tickFormatter={(v) => `${v}%`}
          tickLine={false}
          tick={{ fill: '#fff', dx: -14 }}
        />
        <Tooltip
          content={<TooltipContent />}
          labelClassName="text-sm font-semibold"
          wrapperClassName="text-xs"
        />
        <Line
          dataKey="needFixes"
          name={LegendName.NeedsReview}
          stroke={LegendColor.NeedsReview}
          strokeDasharray="5 5"
          strokeWidth={2}
          type="monotone"
          unit="%"
          dot={false}
        />
        <Line
          dataKey="inReview"
          name={LegendName.InReview}
          stroke={LegendColor.InReview}
          strokeDasharray="5 5"
          strokeWidth={2}
          type="monotone"
          unit="%"
          dot={false}
        />
        <Line
          dataKey="fixed"
          name={LegendName.FixedTracks}
          stroke={LegendColor.FixedTracks}
          strokeDasharray="5 5"
          strokeWidth={2}
          type="monotone"
          unit="%"
          dot={false}
        />
      </LineChart>
    </div>
  );
};

Chart.displayName = 'Chart';

export { Chart };
