import { differenceInDays } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { Tag } from '~/components';
import { cn } from '~/lib/cn';

interface ReleaseTagProps {
  releaseDateString?: string;
}

type ReleaseStatus = 'pre-release' | 'new-release' | '';

const getReleaseStatus = (dateString?: string): ReleaseStatus => {
  if (!dateString) {
    return 'pre-release';
  }

  const releaseDate = new Date(dateString);
  releaseDate.setUTCHours(0, 0, 0, 0);

  if (isNaN(releaseDate.getTime())) {
    return '';
  }

  const today = new Date();

  if (releaseDate > today) {
    return 'pre-release';
  }

  const diffDays = differenceInDays(today, releaseDate);

  return diffDays <= 14 ? 'new-release' : '';
};

export function ReleaseTag({ releaseDateString }: ReleaseTagProps) {
  const { t } = useTranslation('roster');
  const releaseStatus = getReleaseStatus(releaseDateString);
  if (!releaseStatus) {
    return null;
  }

  return (
    <Tag
      size="sm"
      className={cn(
        'font-normal text-strong-on-light',
        releaseStatus === 'pre-release'
          ? 'bg-primary-hover'
          : 'bg-tertiary-default hover:bg-tertiary-hover',
      )}
    >
      {releaseStatus === 'pre-release'
        ? t('trackList.labels.preRelease')
        : t('trackList.labels.newRelease')}
    </Tag>
  );
}

ReleaseTag.displayName = 'ReleaseTag';
