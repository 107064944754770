import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { ArtistDataTable } from '~/modules/artist-roster/components/ArtistDataTable';
import { ArtistSearchBar } from '~/modules/artist-roster/components/ArtistSearchBar/ArtistSearchBar';
import { getArtists } from '~/modules/artist-roster/lib/artists';
import { useAnalytics } from '~/modules/monitoring/amplitude';
import { PageButtons } from '~/modules/pagination/PageButtons';

import { useArtistColumns } from '../hooks/useArtistColumns';
import { usePaginatedQuery } from '../hooks/usePaginatedQuery';
import { SEARCH_PARAM_TYPE } from '../lib/constants';
import { getLabels } from '../lib/labels';
import { type LabelData } from '../lib/types';
import { ArtistExport } from './ArtistExport/ArtistExport';
import { ArtistAppliedFilterList } from './ArtistFilter/ArtistAppliedFilterList';
import { ArtistFilterButton } from './ArtistFilter/ArtistFilterButton';
import type { ArtistFiltersType } from './ArtistFilter/ArtistFiltersType';
import { DEFAULT_ARTIST_FILTERS } from './ArtistFilter/ArtistFiltersType';
import { DEFAULT_PAGE_SIZE } from './constants';
import { Header } from './Header';

export function ArtistTable() {
  const { t } = useTranslation('roster');
  const [searchParams, setSearchParams] = useSearchParams();
  const { trackAnalytics } = useAnalytics();
  const { columns } = useArtistColumns();
  const [showFilterPanel, setShowFilterPanel] = useState(false);
  const [filtersToApply, setFiltersToApply] = useState(DEFAULT_ARTIST_FILTERS);
  const ignoreLabelsChange = useRef(false);

  // Get the labels from the URL and convert them to an array of numbers (IDs).
  const [labels, setLabels] = useState<number[]>(
    searchParams
      .get(SEARCH_PARAM_TYPE.LABELS)
      ?.split(',')
      .map((label) => +label) || [],
  );

  useEffect(() => {
    trackAnalytics('rosterView');
  }, [trackAnalytics]);

  const [searchInputValue, setSearchInputValue] = useState(
    searchParams.get(SEARCH_PARAM_TYPE.SEARCH) || '',
  );

  const [search, setSearch] = useState(
    searchParams.get(SEARCH_PARAM_TYPE.SEARCH) || '',
  );

  const [isCrossover, setIsCrossover] = useState<boolean>(
    searchParams.get(SEARCH_PARAM_TYPE.CROSSOVER) === 'true',
  );

  const [filters, setFilters] = useState<ArtistFiltersType>(
    DEFAULT_ARTIST_FILTERS,
  );

  useEffect(() => {
    const urlLabels = searchParams.get(SEARCH_PARAM_TYPE.LABELS);
    if (urlLabels) {
      // Ignore labels change to prevent infinite loop.
      // No need to update search params, since we retreieved it from them.
      ignoreLabelsChange.current = true;
      setLabels(urlLabels.split(',').map((l) => +l));
    }
  }, [searchParams]);

  const {
    data,
    error,
    isFetching: isLoadingArtists,
    totalItems: totalArtists,
    pageNumber,
    setPageNumber,
    totalPages,
    onNext,
    onPrevious,
    pageStart,
    pageEnd,
  } = usePaginatedQuery({
    queryKey: ['artist-roster', search, filters.labels, filters.isCrossover],
    queryFn: ({ offset, limit }) =>
      getArtists({
        offset,
        limit,
        search,
        labels,
        isCrossover,
      }),
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const { data: labelData } = useQuery({
    queryKey: ['labels'],
    queryFn: () => getLabels({}),
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const onSearchInputClear = useCallback(() => {
    setSearchInputValue('');
    setSearch('');
  }, []);

  const onSubmitSearch = useCallback(() => {
    trackAnalytics('rosterArtistSearch', {
      roster_label_filters_count: filters.labels.length,
      roster_label_filters_applied: filters.labels.length > 0,
      roster_crossover_filter_applied: filters.isCrossover,
      search_term: searchInputValue,
    });
    setSearch(searchInputValue);
    setPageNumber(1);
  }, [
    trackAnalytics,
    filters.labels.length,
    filters.isCrossover,
    searchInputValue,
    setPageNumber,
  ]);

  useEffect(() => {
    if (ignoreLabelsChange.current) {
      ignoreLabelsChange.current = false;
      return;
    }

    const params = new URLSearchParams('');
    if (search) {
      params.set(SEARCH_PARAM_TYPE.SEARCH, search);
    }

    if (pageNumber > 1) {
      params.set('page', `${pageNumber}`);
    }

    if (labels) {
      if (labels.length > 0) {
        params.set('labels', labels.join(','));
      }

      if (isCrossover) {
        params.set(SEARCH_PARAM_TYPE.CROSSOVER, 'true');
      }

      if (labelData) {
        setFilters({
          labels: labelData.filter((label: LabelData) =>
            labels.includes(label.labelId),
          ),
          isCrossover,
        });
      }
    }

    setSearchParams(params);
  }, [search, pageNumber, labels, isCrossover, labelData, setSearchParams]);

  const onRemoveFilter = useCallback(
    (value: number) => {
      const newLabelFilters = filters.labels.filter(
        (labelFilterData) => labelFilterData.labelId !== value,
      );
      setFilters({ labels: newLabelFilters, isCrossover: filters.isCrossover });
      setLabels(newLabelFilters.map((label) => label.labelId));
      setIsCrossover(filters.isCrossover);
      setPageNumber(1);
    },
    [filters, setFilters, setLabels, setIsCrossover, setPageNumber],
  );

  const artistTableMeta = {
    filtersCount: filters.labels.length,
    filtersApplied: filters.labels.length > 0,
    crossoverFilterApplied: filters.isCrossover,
  };

  const onPaginationAmplitudeTrack = useCallback(
    (directionToNextPage: boolean) => {
      trackAnalytics('rosterTablePaginationClick', {
        roster_label_filters_count: filters.labels.length,
        roster_crossover_filter_applied: filters.isCrossover,
        roster_label_filters_applied: filters.labels.length > 0,
        direction: directionToNextPage ? 'next_page' : 'previous_page',
      });
    },
    [filters.isCrossover, filters.labels.length, trackAnalytics],
  );

  const onFilterClick = useCallback(() => {
    trackAnalytics('rosterFiltersClick', {
      roster_label_filters_count: filters.labels.length,
      roster_crossover_filter_applied: filters.isCrossover,
      roster_label_filters_applied: filters.labels.length > 0,
    });
  }, [filters.isCrossover, filters.labels.length, trackAnalytics]);

  const onFilterApplied = useCallback(
    (appliedFilters: ArtistFiltersType) => {
      setFilters(appliedFilters);
      setLabels(appliedFilters.labels.map((label) => label.labelId));
      setIsCrossover(appliedFilters.isCrossover);
      setSearch(searchInputValue);
      setPageNumber(1);
      trackAnalytics('rosterLabelFiltersApplied', {
        roster_label_filters_count: appliedFilters.labels.length,
        roster_crossover_filter_applied: appliedFilters.isCrossover,
        roster_label_filters_applied: appliedFilters.labels.length > 0,
      });
    },
    [searchInputValue, setPageNumber, trackAnalytics],
  );

  if (error) {
    // TODO Get proper UX for errors
    return (
      <div role="alert" className="text-error-default">
        {error.message}
      </div>
    );
  }

  return (
    <>
      <div className="sticky -top-xl z-10 -mx-xl -mt-xl bg-gradient bg-[100%,auto] bg-no-repeat px-xl pt-xl tracking-wide bg-level-01/full">
        <div className="flex items-end justify-between py-lg">
          <Header />
          {!isLoadingArtists && !!totalArtists ? (
            <ArtistExport
              search={search}
              isCrossOver={isCrossover}
              labels={filters.labels.map((label) => label.labelId)}
            />
          ) : null}
        </div>
        <div className="flex gap-md" role="search">
          <ArtistFilterButton
            selectedFilters={filters}
            onApplyFilters={onFilterApplied}
            showFilterPanel={showFilterPanel}
            setShowFilterPanel={setShowFilterPanel}
            filtersToApply={filtersToApply}
            setFiltersToApply={setFiltersToApply}
            onClick={onFilterClick}
            aria-label="Apply filters to the artist table"
          />
          <ArtistSearchBar
            inputValue={searchInputValue}
            placeholder="Search for an artist"
            onSubmit={onSubmitSearch}
            onChange={setSearchInputValue}
            onClear={onSearchInputClear}
            aria-label="Search artists by name"
          />
        </div>
        {filters.labels.length > 0 ? (
          <ArtistAppliedFilterList
            appliedFilters={filters.labels.map((label) => {
              const { labelId, name, updatedAt, updatedBy } = label;

              return {
                category: t('Label'),
                value: labelId,
                displayName: name,
                updatedAt,
                updatedBy,
              };
            })}
            onRemoveFilter={onRemoveFilter}
            setShowFilterPanel={setShowFilterPanel}
            setFiltersToApply={setFiltersToApply}
            originalFilters={DEFAULT_ARTIST_FILTERS}
            onApplyFilters={onFilterApplied}
          />
        ) : null}
        <div className="my-md inline-flex min-h-xl w-full items-center justify-between">
          <div className="flex gap-xs">
            {t('header.subtitle')}
            {!isLoadingArtists ? (
              <p className="text-subtle">({totalArtists})</p>
            ) : null}
          </div>

          {totalArtists > DEFAULT_PAGE_SIZE ? (
            <div id="right">
              <div className="inline-flex items-center">
                <span
                  className="text-nowrap pr-sm text-body-sm font-semibold text-disabled"
                  aria-live="polite"
                >
                  {t('header.pagination.label', {
                    start: pageStart,
                    end: pageEnd,
                    total: totalArtists,
                    type: t('header.pagination.type'),
                  })}
                </span>
                <PageButtons
                  currentPage={pageNumber}
                  totalPages={totalPages}
                  onNext={() => {
                    onNext();
                    onPaginationAmplitudeTrack(true);
                  }}
                  onPrevious={() => {
                    onPrevious();
                    onPaginationAmplitudeTrack(false);
                  }}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <ArtistDataTable
        columns={columns}
        meta={artistTableMeta}
        data={data?.items ?? []}
        isLoading={isLoadingArtists}
      />
    </>
  );
}

ArtistTable.displayName = 'ArtistTable';
