import { keepPreviousData, useQuery } from '@tanstack/react-query';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useMemo } from 'react';

import {
  Table,
  TableHead,
  TableHeader,
  TableRow,
} from '~/modules/artist-roster/components/Table';

import { getArtistTracksPerformance } from '../../lib/tracks';
import type { ArtistDetails, AssetFamily } from '../../lib/types';
import { TrackTableBody } from './TrackTableBody';
import { useTrackTableColumns } from './useTrackTableColumns';

export type TrackTableMeta = {
  artistName: string;
  filtersCount: number;
  filtersApplied: boolean;
  uaid: string;
};

interface TrackTableProps {
  data: AssetFamily[];
  meta: TrackTableMeta;
  artistData: ArtistDetails;
  isLoadingTracks: boolean;
  isFetchingTracks: boolean;
}

export function TrackTable({
  data,
  meta,
  artistData,
  isLoadingTracks,
  isFetchingTracks,
}: TrackTableProps) {
  const familyIds = useMemo(
    () => [...new Set(data?.map((track) => track.id))].sort(),
    [data],
  );

  const { data: performancesData, isLoading: isLoadingPerformance } = useQuery({
    queryKey: ['artist-tracks-performance', familyIds],
    queryFn: () => getArtistTracksPerformance({ familyIds }),
    enabled: !isLoadingTracks && !!familyIds,
    placeholderData: keepPreviousData,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const trackData = useMemo<AssetFamily[]>(() => {
    if (!performancesData) {
      return data;
    }

    return data.map((track) => {
      const performance = performancesData.get(track.id);
      if (!performance) {
        return track;
      }

      return {
        ...track,
        performance,
      };
    });
  }, [data, performancesData]);

  const columns = useTrackTableColumns({ range: 'TW' });
  const table = useReactTable({
    data: trackData,
    columns,
    meta,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Table
      aria-label="Artists roster list"
      className="group/table w-full text-left"
    >
      <TableHeader>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <TableHead
                  key={header.id}
                  className="text-body-sm font-normal first:pl-lg"
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </TableHead>
              );
            })}
          </TableRow>
        ))}
      </TableHeader>
      <TrackTableBody
        table={table}
        meta={meta}
        artistData={artistData}
        isFetchingTracks={isFetchingTracks}
        isLoadingPerformance={isLoadingPerformance}
      />
    </Table>
  );
}

TrackTable.displayName = 'TrackTable';
