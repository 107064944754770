import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Tag } from '~/components';
import { SETTINGS_ROUTES } from '~settings//config/routes';
import type { Label } from '~settings/api/lib/schemas';
import { UserRole } from '~settings/components/UserRole';
import { LabelName } from '~settings/modules/label/components/LabelName';

export const useUserLabelsColumns = () => {
  const { t } = useTranslation('settings');

  return useMemo(() => {
    const columnHelper = createColumnHelper<Label>();

    const labelsColumns = {
      name: columnHelper.accessor('name', {
        id: 'name',
        header: () => t('label.titles'),
        cell: (cell) => <LabelName name={cell.getValue()} />,
        enableSorting: true,
      }),
      roles: columnHelper.accessor('roles', {
        id: 'roles',
        header: () => t('role'),
        cell: (cell) => <UserRole roles={cell.getValue()} />,
        enableSorting: false,
      }),
      labelType: columnHelper.accessor('isParentLabel', {
        id: 'type',
        header: () => t('labelType'),
        cell: (cell) => t(cell.getValue() ? 'parentLabel' : 'sublabel'),
        enableSorting: true,
      }),
      parent: columnHelper.accessor('parent', {
        id: 'parentName',
        header: () => t('parentLabel'),
        cell: (cell) => {
          const parent = cell.getValue();

          if (parent) {
            return (
              <Tag asChild>
                <Link
                  to={SETTINGS_ROUTES.LABEL_DETAIL(parent.id)}
                  onClick={(e) => e.stopPropagation()}
                >
                  {parent.name}
                </Link>
              </Tag>
            );
          }

          return '-';
        },
        enableSorting: true,
      }),
    };

    return Object.values(labelsColumns);
  }, [t]);
};
