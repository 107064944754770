interface AreaGradientProps {
  lineColor: string;
  id: string;
  x1?: string;
  x2?: string;
  y1?: string;
  y2?: string;
}

function AreaGradient({
  lineColor,
  id,
  x1 = '0%',
  x2 = '0%',
  y1 = '0%',
  y2 = '100%',
}: AreaGradientProps) {
  return (
    <svg style={{ height: 0 }}>
      <defs>
        <linearGradient id={id} x1={x1} x2={x2} y1={y1} y2={y2}>
          <stop offset="0%" stopColor={lineColor} stopOpacity={100} />
          <stop offset="50%" stopColor={lineColor} stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  );
}

AreaGradient.displayName = 'AreaGradient';
export { AreaGradient };
