import type { HTMLAttributes } from 'react';
import { forwardRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, type LinkProps } from 'react-router-dom';

import { useAnalytics } from '~/modules/monitoring/amplitude';

import { useGlobalSearchContext } from '../../hooks/useGlobalSearchContext';
import type { LabelLinkItem } from '../../lib/types';
import { HighlightedText } from '../HighlightedText';
import { ItemIcon } from '../ItemIcon';

export type LabelItemProps = HTMLAttributes<LinkProps> & {
  item: LabelLinkItem;
};

export const LabelItem = forwardRef<HTMLAnchorElement, LabelItemProps>(
  ({ item }, ref) => {
    const { t } = useTranslation('wmgone');
    const { trackAnalytics } = useAnalytics();
    const { resetSearch } = useGlobalSearchContext();

    const handleLinkClick = useCallback(() => {
      trackAnalytics('linkFarmLinkClicked', {
        app_name: item.appName,
        page_name: item.category,
        url: item.url,
      });
      resetSearch();
    }, [item.appName, item.url, item.category, trackAnalytics, resetSearch]);

    const cleanUrl = item.url.replace(/^\/+/, '');

    return (
      <Link
        ref={ref}
        to={`/${cleanUrl}`}
        onClick={handleLinkClick}
        aria-label={t('globalSearch.open', { name: item.appName })}
        className="group mb-sm flex-1 rounded-xs border-px border-opacity-strong px-lg py-sm transition duration-150 border-transparent hover:bg-emphasis-strong hover:border-white focus-visible:ring"
      >
        <div className="flex flex-1 flex-row">
          <ItemIcon icon={item.icon} isExternalLink={false} />
          <div className="flex flex-1 flex-col items-baseline pl-lg">
            <HighlightedText className="font-semibold" text={item.actionText} />
            <p className="text-body-sm">{item.appName}</p>
          </div>
        </div>
      </Link>
    );
  },
);

LabelItem.displayName = 'LabelItem';
