import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, Tooltip } from '~/components';

type TimeRange = 'RTD' | 'TW' | 'TM' | 'TY';

interface TimeRangeLabelProps {
  timeRange: TimeRange;
}

export function TimeRangeLabel({ timeRange }: TimeRangeLabelProps) {
  const { t } = useTranslation('roster');
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  return (
    <div className="flex items-center gap-xs">
      <span className="text-body-xs font-semibold text-subtle">
        {timeRange}
      </span>

      <Tooltip
        content={
          <div className="flex flex-col gap-sm leading-5">
            <p>
              <span className="font-bold">{timeRange}</span>{' '}
              {t(`trackList.tooltips.${timeRange}`)}
            </p>
          </div>
        }
        delayDuration={0}
        sideOffset={16}
        side="top"
        align="end"
        triggerAsChild
        size="md"
        onOpenChange={setIsTooltipOpen}
      >
        <span className="flex">
          <Icon
            className="cursor-pointer"
            type="circle-info"
            variant={isTooltipOpen ? 'solid' : 'light'}
            size="sm"
          />
        </span>
      </Tooltip>
    </div>
  );
}
TimeRangeLabel.displayName = 'TimeRangeLabel';
