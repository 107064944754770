import { Icon } from '~/components';
import { cn } from '~/lib/cn';

export interface MenuItemProps {
  className?: string;
  title: string;
  disabled?: boolean;
  icon: string;
  iconOverlay?: () => JSX.Element;
  onClick: () => void;
  counter?: number;
}

const CheckedOverlay = (): JSX.Element => {
  return (
    <Icon
      className="text-body-sm text-success-default group-hover:text-success-hover"
      type="spin"
    />
  );
};

CheckedOverlay.displayName = 'CheckedOverlay';

const MenuItem = (props: MenuItemProps): JSX.Element => {
  return (
    <button
      className={cn(
        'group relative cursor-pointer rounded-xs border px-sm py-xs text-body-sm bg-level-03 border-emphasis-subtle text-white hover:bg-primary-default focus:outline-none',
        props.disabled &&
          'pointer-events-none cursor-not-allowed opacity-stronger',
        props.className,
      )}
      onClick={props.onClick}
    >
      <Icon
        className="mr-sm group-hover:text-white"
        size="sm"
        type={props.icon}
      />
      {props.iconOverlay ? (
        <div className="absolute left-md top-sm">{props.iconOverlay()}</div>
      ) : null}
      {props.title}
      {!!props.counter && (
        <span className="ml-px text-body-xs text-subtle group-hover:text-white">
          ({props.counter})
        </span>
      )}
    </button>
  );
};

MenuItem.displayName = 'MenuItem';

export { CheckedOverlay, MenuItem };
