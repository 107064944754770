import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { UserListItem } from '~settings/api/lib/schemas';
import { RelativeDate } from '~settings/components/RelativeDate';
import { UserRole } from '~settings/components/UserRole';
import { UserInfo } from '~settings/modules/user/components/UserInfo';

export const useUsersColumns = (labelId?: string) => {
  const { t } = useTranslation('settings');

  return useMemo(() => {
    const columnHelper = createColumnHelper<UserListItem>();

    const usersColumns = {
      name: columnHelper.accessor((row) => row.fullName, {
        id: 'fullName',
        header: () => t('name'),
        cell: (info) => (
          <UserInfo
            firstName={info.row.original.firstName}
            lastName={info.row.original.lastName}
            fullName={info.row.original.fullName}
          />
        ),
        enableSorting: true,
      }),
      email: columnHelper.accessor('email', {
        id: 'email',
        header: () => t('email'),
        cell: (cell) => cell.getValue(),
        enableSorting: true,
      }),
      role: columnHelper.accessor('labels', {
        id: 'role',
        header: () => t('role'),
        cell: (info) => {
          const labels = info.getValue();
          const roles = labels.find(({ id }) => id === labelId)?.roles ?? [];
          return <UserRole roles={roles} />;
        },
        enableSorting: false,
      }),
      lastLogin: columnHelper.accessor('lastLogin', {
        id: 'lastLogin',
        header: () => t('lastLogin'),
        cell: (info) => <RelativeDate data={info.getValue()} />,
        enableSorting: true,
      }),
    };

    return Object.values(usersColumns);
  }, [labelId, t]);
};
