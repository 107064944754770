import { Icon } from '~/components';
import type { ChartItem } from '~/modules/label-perf/types';
import { formatNumberSuffix } from '~/modules/label-perf/utils/format';

interface TooltipContentProps {
  active?: boolean;
  label?: string;
  payload?: {
    color: string;
    dataKey: string;
    name: string;
    unit?: string;
    value: number;
    payload: ChartItem;
  }[];
}

const isValidKey = <T extends object>(
  key: PropertyKey,
  obj: T,
): key is keyof T => key in obj;

const TooltipContent = ({
  active,
  label,
  payload,
}: TooltipContentProps): React.ReactNode => {
  if (!active || !payload) {
    return null;
  }

  return (
    <div
      data-testid="tooltip-content"
      className="rounded-sm px-md py-sm shadow-black bg-level-02"
    >
      <p className="mb-sm text-body-xs font-bold">{label}</p>
      <table className="text-body-xs">
        <tbody>
          {payload.map((item) => (
            <tr key={item.dataKey}>
              <td className="pr-sm text-body-xs font-light">
                <Icon
                  type="circle"
                  variant="solid"
                  size="sm"
                  style={{ color: item.color }}
                />
              </td>
              <td className="pr-md text-body-xs font-light">{item.name}</td>
              <td className="pr-sm text-body-xs font-bold">
                {item.value}
                {item.unit ?? ''}
              </td>
              <td className="text-body-xs font-extralight">
                (
                {formatNumberSuffix(
                  isValidKey(item.dataKey, item.payload.__original)
                    ? item.payload.__original[item.dataKey]
                    : 0,
                )}
                )
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

TooltipContent.displayName = 'TooltipContent';

export { TooltipContent };
