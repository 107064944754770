import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useFilters = () => {
  const [filterData, setFilterData] = useState({});
  const [drawerOpen, setDrawerOpen] = useState(false);

  const [searchParams] = useSearchParams();

  const closeDrawer = () => setDrawerOpen(false);
  const openDrawer = () => setDrawerOpen(true);
  useEffect(() => {
    setFilterData(Object.fromEntries(searchParams));
  }, [searchParams]);

  return { filterData, setFilterData, drawerOpen, closeDrawer, openDrawer };
};
