const UNITS = ['', 'K', 'M', 'B'];

/**
 * Based on the conversation here https://www.figma.com/design/Zw3MrTjcjB9hQUsGRwfQJz?node-id=1792-61696#1146477786
 * this formats the follower count by truncating it at the first decimal point
 * if the number is > 1000. We support up to 999.9B and that's more than enough
 * for now as there is no one on any social platform that currently even comes
 * clost to 1B followers:
 *  - https://en.wikipedia.org/wiki/List_of_most-followed_Instagram_accounts
 *  - https://en.wikipedia.org/wiki/List_of_most-followed_TikTok_accounts
 *  - https://en.wikipedia.org/wiki/List_of_most-subscribed_YouTube_channels
 *  - https://chartmasters.org/spotify-most-followed-artists/
 *
 * @param count The follower count.
 * @returns The formatted follower count.
 */
export function formatFollowerCount(count?: number | null) {
  // ---------------------------------------------------------------------------
  // !!! IMPORTANT !!!
  // There are other functions that call formatFollowerCount as a helper.
  // If anything changes in this function, make sure to double check everywhere
  // this is called to confirm there are no side effects.
  // ---------------------------------------------------------------------------

  // Can show either the actual count (including 0) or '--' if the count is null.
  // https://www.figma.com/design/Zw3MrTjcjB9hQUsGRwfQJz?node-id=1792-61696#1134212300
  if (count === undefined || count === null) {
    return '--';
  }

  if (count < 1000) {
    return `${count}`;
  }

  const magnitude = Math.floor(Math.log10(count) / 3);
  const scaled = count / Math.pow(1000, magnitude);
  const truncated = Math.trunc(scaled * 10) / 10;

  return `${truncated}${UNITS[magnitude]}`;
}

// Capitalizes the first letter of a string.
export function capitalize(str: string) {
  return str.charAt(0)?.toUpperCase() + str.slice(1);
}
