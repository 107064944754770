import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { LinkList } from '~/modules/link-farm/components/LinkList/LinkList.tsx';
import { LINK_FARM } from '~/modules/link-farm/linkFarm';
import type { LinkFarmCategory } from '~/modules/link-farm/types';
import { useAnalytics } from '~/modules/monitoring/amplitude';

import { PageTemplate } from './components/PageTemplate';

interface LinkPageProps {
  category: LinkFarmCategory;
}

export const LinkPage = ({ category }: LinkPageProps) => {
  const { t } = useTranslation('wmgone');
  const { flush: flushAnalytics, trackAnalytics } = useAnalytics();

  useEffect(() => {
    // Make sure to clean up analytics on unmount.
    return () => {
      flushAnalytics();
    };
  }, [flushAnalytics]);

  useEffect(() => {
    trackAnalytics('linkFarmPageView', {
      pageName: category,
    });
  }, [trackAnalytics, category]);

  const title = t(`links.${category}`);

  return (
    <PageTemplate>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <LinkList title={title} data={LINK_FARM[category || ''] || []} />
    </PageTemplate>
  );
};

LinkPage.displayName = 'LinkPage';
