import { useTranslation } from 'react-i18next';

import { Icon, Separator, Tag, Tooltip } from '~/components';
import { useIsExternalUser } from '~/hooks/useIsExternalUser';
import { RelativeDate } from '~/modules/settings/components/RelativeDate';
import { formatPhoneNumber } from '~/modules/settings/utils/formatters';
import type { UserDetail } from '~settings/api/lib/schemas';
import { ContentBox } from '~settings/components/ContentBox';
import { HeaderSmall } from '~settings/components/HeaderSmall';
import { PropertyList } from '~settings/components/PropertyList';

interface UserDetailsBlockProps {
  user: UserDetail;
}

export function UserDetailsBlock({ user }: UserDetailsBlockProps) {
  const { t } = useTranslation('settings');
  const isCurrentUserExternal = useIsExternalUser();

  const formattedPhoneNumber = formatPhoneNumber(user.phone);
  const isInternalUser = user.isInternal;

  return (
    <ContentBox>
      <HeaderSmall>{t('details')}</HeaderSmall>

      <PropertyList.Root>
        <PropertyList.Item>
          <PropertyList.Key>{t('email')}</PropertyList.Key>
          <PropertyList.Value>{user.email}</PropertyList.Value>
        </PropertyList.Item>
        {formattedPhoneNumber ? (
          <PropertyList.Item>
            <PropertyList.Key>{t('phone')}</PropertyList.Key>
            <PropertyList.Value>{formattedPhoneNumber}</PropertyList.Value>
          </PropertyList.Item>
        ) : null}
        <PropertyList.Item>
          <PropertyList.Key>{t('status')}</PropertyList.Key>
          <PropertyList.Value>
            {user.isActive ? t('active') : t('disabled')}
            <Tooltip
              content={t(user.isActive ? 'activeTooltip' : 'disabledTooltip')}
              side="top"
              delayDuration={0}
            >
              <Icon
                type="circle-info"
                variant="light"
                className="ml-sm text-subtlest"
                size="sm"
              />
            </Tooltip>
          </PropertyList.Value>
        </PropertyList.Item>
        {user.lastLogin ? (
          <PropertyList.Item>
            <PropertyList.Key>{t('lastLogin')}</PropertyList.Key>
            <PropertyList.Value>
              <RelativeDate data={user.lastLogin} />
            </PropertyList.Value>
          </PropertyList.Item>
        ) : null}
        {!isCurrentUserExternal && (
          <>
            <PropertyList.Item>
              <PropertyList.Key>{t('userType')}</PropertyList.Key>
              <PropertyList.Value>
                {isInternalUser ? t('internal') : t('external')}
              </PropertyList.Value>
            </PropertyList.Item>
            {isInternalUser && user.labelGroups?.length ? (
              <>
                <Separator className="border-t-px border-emphasis-subtle" />
                <div>
                  <HeaderSmall>{t('labelGroup.list')}</HeaderSmall>
                  <ul className="flex flex-wrap gap-x-xs gap-y-sm">
                    {user.labelGroups.map(({ id, name }) => (
                      <Tag key={id} variant="secondary">
                        {name}
                      </Tag>
                    ))}
                  </ul>
                </div>
              </>
            ) : null}
          </>
        )}
      </PropertyList.Root>
    </ContentBox>
  );
}
UserDetailsBlock.displayName = 'UserDetailsBlock';
