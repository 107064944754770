import { createInstance, Identify } from '@amplitude/analytics-browser';
import { useCallback, useContext, useEffect } from 'react';

import { useUser } from '~/modules/auth/hooks/useUser';
import { BrandContext } from '~/modules/branding';

// Specifically do not expose this externally so that callers have to go through
// the useAnalytics hook below to get the methods they need.
const instance = createInstance();

function initAmplitude(apiKey?: string) {
  if (!apiKey) {
    return;
  }

  instance.init(apiKey, { defaultTracking: true, instanceName: 'container' });

  return instance;
}

/**
 * Custom hook that exposes what we need from the Amplitude analytics service.
 *
 * Normally we'd put this under a `hooks` directory but we don't want to expose
 * the instance externally and we'd have to import it into the hook in the other
 * directory. So define everything in this file so that we can encapsulate the
 * instance and force clients to use the useAnalytics() hook to get the
 * functions they need.
 *
 * Also, we wrap some of the Amplitude functions and we want to make sure clients
 * don't bypass the wrapped functions so they should always use this hook.
 */
function useAnalytics() {
  const { email, name, isInternal, profile } = useUser();
  const brand = useContext(BrandContext);

  const trackAnalytics = useCallback(
    /**
     * Used to push analytics events to Amplitude. Using a wrapper function here
     * so that we can intercept all analytics events in one place and add/modify
     * the data as required (e.g. - to add user info like name, email, etc.).
     * @param eventName The name of the event to track.
     * @param eventProperties The data to track with the event.
     */
    (eventName: string, eventProperties?: Record<string, unknown>) => {
      // When first rendering the app, the user object won't yet be available.
      // Which means that on first render, we won't have the user's name or email.
      // So short circuit here so that we don't log an exra event with missing data.
      if (!name || !email) {
        return;
      }

      const commonProperties = {
        name,
        email,
        business_unit: profile?.businessUnit,
        job_family_group: profile?.jobFamilyGroup,
        user_description: profile?.description,
        team: profile?.team,
        label: profile?.label,
        job_title: profile?.title,
        country_code: profile?.countryCode,
        organization: profile?.organization,
        company: profile?.company,
        user_type: profile?.userType,
        department: profile?.department,
        is_external: !isInternal,
        product: brand.product,
      };

      instance.track(eventName, { ...eventProperties, ...commonProperties });
    },
    [brand.product, email, isInternal, name, profile],
  );

  return {
    trackAnalytics,
    flush: instance.flush,
  };
}

/**
 * Component that is used to set global Amplitude user properties when the
 * user first logs in. The idea here is that we access any information that
 * we need (e.g. - user name/email) when it becomes available and then set
 * that information on the Amplitude instance using the Identify API.
 *
 * Normally we'd put this component in its own file under /monitoring but we
 * don't want to expose the instance externally and we'd have to import it into
 * the other file. So define everything in this file so that we can encapsulate
 * the instance.
 */
function Analytics() {
  const { name, email, isInternal, profile } = useUser();
  const brand = useContext(BrandContext);

  useEffect(() => {
    // There is a render delay caused by fetching the data from Okta so at first
    // there won't be an email/name so just skip until we have them.
    if (!email || !name) {
      return;
    }

    // This will add information to the User Properites that are used to log all events:
    // https://amplitude.com/docs/sdks/analytics/browser/browser-sdk-2#user-properties
    const userInfo = new Identify();
    userInfo.set('email', email);
    userInfo.set('name', name);
    userInfo.set('is_external', !isInternal);
    userInfo.set('product', brand.product);

    if (profile) {
      userInfo.set('business_unit', profile.businessUnit);
      userInfo.set('job_family_group', profile.jobFamilyGroup);
      userInfo.set('team', profile.team);
      userInfo.set('label', profile.label);
      userInfo.set('job_title', profile.title);
      userInfo.set('country_code', profile.countryCode);
      userInfo.set('organization', profile.organization);
      userInfo.set('company', profile.company);
      userInfo.set('user_type', profile.userType);

      if (profile.description) {
        userInfo.set('user_description', profile.description);
      }

      if (profile.department) {
        userInfo.set('department', profile.department);
      }
    }

    instance.identify(userInfo);
  }, [brand.name, brand.product, email, isInternal, name, profile]);

  return null;
}

Analytics.displayName = 'Analytics';

// Specifically do not expose the instance as we want clients to use the hook
// or the Amplitude component to interact with the instance.
export { Analytics, initAmplitude, useAnalytics };
