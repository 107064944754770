import type { ForwardedRef, HTMLProps } from 'react';
import { forwardRef, useEffect, useState } from 'react';

import { cn } from '~/lib/cn';

import { Tag } from '../Tag';

type RadioGroupOption = {
  value: string;
  label: string;
};

export type RadioGroupProps = {
  options: RadioGroupOption[];
} & HTMLProps<HTMLInputElement>;

export const RadioGroup = forwardRef(
  (
    {
      options,
      name,
      className,
      value,
      disabled,
      ...inputProps
    }: RadioGroupProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    // Used only for styling, value is controlled by the input or RHF
    // This is so that the component can be used in controlled and uncontrolled forms
    const [valueForStyling, setValueForStyling] = useState<string>();
    useEffect(() => {
      setValueForStyling(value ? value.toString() : undefined);
    }, [value]);

    return (
      <fieldset className={cn('flex', 'flex-wrap', 'gap-sm', className)}>
        {options.map((option) => (
          <label key={option.value}>
            <Tag
              variant={
                valueForStyling === option.value ? 'active' : 'secondary'
              }
              className="cursor-pointer"
              disabled={disabled}
            >
              <input
                type="radio"
                name={name}
                {...inputProps}
                checked={value === option.value}
                value={option.value}
                disabled={disabled}
                onChange={(e) => {
                  inputProps.onChange?.(e);
                  setValueForStyling(option.value);
                }}
                className="sr-only"
                ref={ref}
              />
              {option.label}
            </Tag>
          </label>
        ))}
      </fieldset>
    );
  },
);

RadioGroup.displayName = 'RadioGroup';
