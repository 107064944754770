import * as RadixToggle from '@radix-ui/react-toggle';
import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';

import { cn } from '~/lib/cn';

export type ToggleProps = RadixToggle.ToggleProps;

export const Toggle = forwardRef(
  (
    { className, ...props }: ToggleProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    return (
      <RadixToggle.Root
        className={cn(
          'relative',
          'bg-emphasis-default',
          'rounded-full',
          'w-[3rem]',
          'h-lg',
          'p-xs',
          'transition-colors',
          'after:content-[""]',
          'after:rounded-full',
          'after:bg-default-inverse/full',
          'after:w-md',
          'after:h-md',
          'after:absolute',
          'after:inset-xs',
          'after:transition-transform',
          'aria-pressed:enabled:bg-primary-default',
          'aria-pressed:after:translate-x-lg',
          'disabled:bg-level-05',
          'disabled:cursor-not-allowed',
          'disabled:after:bg-level-01/full',
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);

Toggle.displayName = 'Toggle';
