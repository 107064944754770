import type { TrackItem, TrackResponse } from '../types/label-perf';

export const mapTrackResponse = (trackResponse: TrackResponse): TrackItem => {
  return {
    trackName: trackResponse.track_title,
    artistName: trackResponse.artist,
    isrc: trackResponse.isrc,
    revenue: trackResponse.revenue_value,
    revenueChange: trackResponse.revenue_trend,
    streams: trackResponse.streams_value,
    streamsChange: trackResponse.streams_trend,
  };
};
