import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMiniSearch } from 'react-minisearch';

import { Icon } from '~/components';
import { Modal } from '~/components/Modal';
import { Spinner } from '~/components/Spinner';

import { getLabels } from '../../../artist-roster/lib/labels';
import { LINK_FARM } from '../../linkFarm';
import { LinkItem, type LinkItemProps } from '../LinkList/LinkItem';
import { LABEL_ICON_MAPPINGS } from './constants';

interface LinkSearchProps {
  isOpen: boolean;
  onClose: () => void;
}

export const LinkSearch = ({ isOpen, onClose }: LinkSearchProps) => {
  const { t } = useTranslation('wmgone');
  const [query, setQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [labels, setLabels] = useState<LinkItemProps[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const resultsRef = useRef<(HTMLDivElement | null)[]>([]);

  const linkFarmItems: LinkItemProps[] = useMemo(() => {
    return Object.values(LINK_FARM)
      .flat()
      .map((item, index) => ({
        minisearchID: index,
        icon: item.icon,
        appName: item.appName,
        url: item.url,
        actionText: item.actionText,
        category: item.category,
        requestAccess: item.requestAccess,
        requireVpn: item.requireVpn,
      }));
  }, []);

  const { data: labelData } = useQuery({
    queryKey: ['labels'],
    queryFn: () => getLabels({}),
  });

  useEffect(() => {
    if (labelData) {
      setLabels(
        labelData.map((label, index) => ({
          minisearchID: linkFarmItems.length + 1 + index,
          icon: LABEL_ICON_MAPPINGS[label.name]?.icon || 'WARNER_BLACK',
          appName: label.name,
          url: '',
          labelId: label.labelId,
          actionText: label.name,
          category: 'label',
          requestAccess: 'false',
          requireVpn: false,
        })),
      );
    }
  }, [labelData, linkFarmItems.length]);

  const filteredLabels = useMemo(() => {
    if (!query) {
      return [...labels];
    }

    return labels.filter((item) =>
      item.appName.toLowerCase().includes(query.toLowerCase()),
    );
  }, [labels, query]);

  const { search, searchResults } = useMiniSearch(linkFarmItems, {
    fields: ['appName', 'actionText', 'category', 'description'],
    storeFields: [
      'appName',
      'actionText',
      'category',
      'url',
      'icon',
      'path',
      'requestAccess',
      'requireVpn',
    ],
    idField: 'minisearchID',
  });

  const combinedSearchResults = useMemo(
    () => [...(searchResults || []), ...filteredLabels],
    [searchResults, filteredLabels],
  );

  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      if (!query) {
        setIsLoading(false);
        return;
      }

      search(query, { fuzzy: 0.2, prefix: true });
      setIsLoading(false);
      setSelectedIndex(0);
    }, 300);

    return () => clearTimeout(timer);
  }, [query, linkFarmItems, search]);

  useEffect(() => {
    if (isOpen) {
      setQuery('');
      setSelectedIndex(0);
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  }, [isOpen]);

  useEffect(() => {
    const selectedItem = resultsRef.current[selectedIndex];
    if (selectedItem) {
      selectedItem.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [selectedIndex]);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (!combinedSearchResults) {
      return;
    }
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      setSelectedIndex((prevIndex) =>
        Math.min(prevIndex + 1, combinedSearchResults.length - 1),
      );
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    } else if (event.key === 'Enter' && combinedSearchResults[selectedIndex]) {
      const selectedItem = combinedSearchResults[selectedIndex];
      if (selectedItem.category === 'label' && selectedItem.labelId) {
        window.location.href = `/roster?labelId=${encodeURIComponent(selectedItem.labelId)}&labelName=${encodeURIComponent(selectedItem.appName)}`;
        window.open(selectedItem.url, '_blank');
      } else {
        window.open(selectedItem.url, '_blank');
      }
    } else if (event.key === 'Tab') {
      event.preventDefault();
      inputRef.current?.select();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="absolute top-[-40vh] flex max-h-[75vh] w-[50vw] max-w-[380px] flex-col items-center justify-center bg-transparent text-default">
        <div className="relative w-full">
          <div className="absolute inset-y-0 left-0 flex items-center pl-sm">
            <span className="flex size-[1.75rem] items-center justify-center">
              <Icon
                type="search"
                variant="solid"
                size="md"
                className="text-white"
                aria-hidden
              />
            </span>
          </div>
          <input
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            ref={inputRef}
            placeholder="Search by tool name"
            className={`w-full border-px border-opacity-subtle py-[0.75rem] pl-2xl pr-md text-body-sm italic bg-level-03 border-white placeholder:text-disabled ${
              query ? 'rounded-t-sm' : 'rounded-sm'
            }`}
            aria-controls="link-farm-dropdown"
            aria-haspopup="listbox"
            aria-label="Search tools"
            onKeyDown={handleKeyDown}
          />
        </div>
        {!!query && (
          <div className="w-full overflow-y-auto">
            <div
              role="listbox"
              id="link-farm-dropdown"
              aria-live="polite"
              className="z-50 flex w-full flex-col items-center justify-center gap-md rounded-b-sm border-px border-opacity-strong px-md py-[1.25rem] bg-level-03 border-white"
            >
              {combinedSearchResults?.length ? (
                combinedSearchResults.map((item, index) => (
                  <LinkItem
                    key={item.actionText + index}
                    ref={(el) => (resultsRef.current[index] = el)}
                    {...item}
                    className={`${index === selectedIndex ? 'border-opacity-stronger bg-default-inverse/strong border-white' : ''}`}
                    tabIndex={-1}
                    role="option"
                    aria-selected={index === selectedIndex}
                  />
                ))
              ) : isLoading ? (
                <div className="flex w-full items-center justify-center px-md py-xs bg-transparent">
                  <div className="flex w-lg items-center justify-center">
                    <Spinner />
                  </div>
                </div>
              ) : (
                <div className="flex w-full items-center justify-center px-md py-[0.75rem] bg-transparent">
                  {t('links.search.noResults')}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};
LinkSearch.displayName = 'LinkSearch';
