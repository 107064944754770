import type React from 'react';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '~/components';
import { Avatar } from '~/components/Avatar';
import { Button } from '~/components/Button';
import { Popover } from '~/components/Popover';
import { Tooltip } from '~/components/Tooltip';
import { SIDEBAR_NAV_ITEMS } from '~/lib/nav.ts';
import { useUser } from '~/modules/auth/hooks/useUser.ts';
import { BrandContext } from '~/modules/branding';
import { BrandLogo } from '~/modules/branding/components/BrandLogo';
import { useShowNav } from '~/modules/navigation';
import { useSelected } from '~/modules/navigation/components/PrimaryLayout/hooks/useSelected.ts';
import {
  Sidebar,
  SidebarMenuItem,
} from '~/modules/navigation/components/Sidebar';
import { SidebarPopoverContent } from '~/modules/navigation/components/Sidebar/parts/SidebarPopoverContent';
import { dataTestIds } from '~/test-utils/dataTestIds.ts';

export function Nav(): React.ReactNode {
  const { t } = useTranslation('navigation', {
    keyPrefix: 'sidebar.items',
  });
  const { showNav } = useShowNav();
  const brand = useContext(BrandContext);
  const { hasPermission, ...user } = useUser();
  const { name } = user;

  const permittedItems = brand.name
    ? SIDEBAR_NAV_ITEMS.filter(
        ({ permission, brands }) =>
          brand.name &&
          brands?.includes(brand.name) &&
          (!permission || permission(hasPermission, { user })),
      )
    : [];
  const { selected, refToBlur } = useSelected({ items: permittedItems });

  // States
  const [expanded, setExpanded] = useState(false);
  const toggleExpand = () => setExpanded((prev) => !prev);

  if (showNav === false) {
    return null;
  }

  return (
    <Sidebar>
      <Button variant="tertiary" onClick={toggleExpand}>
        <div
          className={`absolute left-md top-lg flex size-2xl items-center justify-center transition-opacity duration-300 ${expanded ? '' : 'group-hover:opacity-none'}`}
        >
          <BrandLogo />
        </div>
        <Tooltip
          content={t(expanded ? 'collapse' : 'expand', {
            keyPrefix: 'sidebar',
          })}
          delayDuration={0}
          enabled
          side="right"
          sideOffset={8}
          triggerAsChild
          size="sm"
        >
          <div
            className={`absolute top-lg flex size-2xl items-center justify-center rounded-full transition-all duration-300 hover:bg-emphasis-strong group-hover:opacity-full ${expanded ? 'left-[9rem]' : 'left-md opacity-none'}`}
          >
            <Icon
              type={expanded ? 'chevron-left' : 'chevron-right'}
              variant="solid"
              size="lg"
            />
          </div>
        </Tooltip>
      </Button>
      <div className="mt-[4.5rem] flex h-full flex-col gap-lg px-md pt-sm">
        {permittedItems.map(
          ({ to, icon, iconVariant, label, title }, index) => {
            const isActive = selected === index;
            const _title =
              title === 'dashboard' ? 'Dashboard' : title && t(title);

            return (
              <SidebarMenuItem
                key={label}
                to={to}
                icon={icon}
                iconVariant={iconVariant}
                title={_title}
                variant={isActive ? 'active' : 'default'}
                aria-current={isActive ? 'page' : undefined}
                onClick={(e) => {
                  refToBlur.current = e.currentTarget;
                }}
                expanded={expanded}
              >
                {t(label)}
              </SidebarMenuItem>
            );
          },
        )}
      </div>
      <div data-testid={dataTestIds.USER_PROFILE_ICON}>
        <Popover
          content={(onItemClick) => (
            <SidebarPopoverContent name={name} onItemClick={onItemClick} />
          )}
        >
          <div className="px-md pb-lg">
            <Avatar name={name} data-testid="avatar-icon" />
          </div>
        </Popover>
      </div>
    </Sidebar>
  );
}

Nav.displayName = 'Nav';
