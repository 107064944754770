import { type FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useKeyboardCategoryHelper } from '../../hooks/useKeyboardCategoryHelper';
import type { LinkItem } from '../../lib/types';
import { ResultSection } from '../ResultSection';
import { ShowAllCategoryResultsButton } from '../ShowAllCategoryResultsButton/ShowAllCategoryResultsButton';
import { ToolItem } from '../ToolItem';

export type ToolsListProps = {
  items: LinkItem[];
};

const CATEGORY_KEY = 'tools';

export const ToolsList: FC<ToolsListProps> = ({ items }) => {
  const { t } = useTranslation('wmgone');
  const { registerElement } = useKeyboardCategoryHelper({
    category: CATEGORY_KEY,
    items,
  });

  if (!items.length) {
    return null;
  }

  return (
    <ResultSection
      iconType="browser"
      title={t('globalSearch.result.tools.title')}
    >
      {items.map((item, index) => (
        <ToolItem
          ref={(element) => registerElement({ element, index })}
          key={item.id}
          item={item}
        />
      ))}
      <ShowAllCategoryResultsButton
        ref={(element) => registerElement({ element, index: items.length })}
        category={CATEGORY_KEY}
      />
    </ResultSection>
  );
};

ToolsList.displayName = 'ToolsList';
