import type { HTMLProps, ReactNode } from 'react';

import { cn } from '~/lib/cn';

interface LabelProps extends HTMLProps<HTMLLabelElement> {
  children: ReactNode;
  disabled?: boolean;
  asSpan?: boolean;
}

const Label = ({
  children,
  disabled = false,
  className,
  asSpan,
  ...props
}: LabelProps) => {
  const Component = asSpan ? 'span' : 'label';
  return (
    <Component
      {...props}
      className={cn(
        'text-body-sm',
        disabled && 'text-disabled',
        !disabled && 'text-default',
        className,
      )}
    >
      {children}
    </Component>
  );
};

Label.displayName = 'Label';

export { Label };
