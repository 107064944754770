import '../../../styles/label-perf-override.css';

import { Icon } from '~/components';
import { cn } from '~/lib/cn';
import type { Direction } from '~/modules/label-perf/types/label-perf';

export interface TrendProps {
  className?: string;
  children: string | number;
  direction: Direction;
  variant?: 'default' | 'table';
}

const Trend = ({
  className,
  children,
  direction,
  variant = 'default',
}: TrendProps): JSX.Element => {
  const containerClasses =
    variant === 'table'
      ? 'inline-block'
      : cn(
          'mr-sm inline-block rounded-xs px-sm text-body-md font-semibold backdrop-blur-md',
          direction === 'up' && 'bg-success shadow-green',
          direction === 'down' && 'bg-error shadow-red',
          direction === 'none' && 'bg-primary-default',
        );

  const valueClasses =
    variant === 'table'
      ? cn(
          'ml-xs text-body-sm font-bold',
          direction === 'up'
            ? 'text-success-default'
            : direction === 'down'
              ? 'text-error-default'
              : 'text-primary-default',
        )
      : 'ml-xs text-black text-[18px] font-bold';

  const iconClasses =
    variant === 'table'
      ? direction === 'up'
        ? 'text-success-default'
        : direction === 'down'
          ? 'text-error-default'
          : 'text-primary-default'
      : 'text-black';

  return (
    <div className={cn(containerClasses, className)} data-testid="trend">
      <div className="flex items-center">
        {direction === 'up' && (
          <Icon
            type="caret-up"
            variant="solid"
            size="sm"
            className={iconClasses}
          />
        )}
        {direction === 'down' && (
          <Icon
            type="caret-down"
            variant="solid"
            size="sm"
            className={iconClasses}
          />
        )}
        {direction === 'none' && (
          <Icon
            type="diamond"
            variant="solid"
            size="sm"
            className={iconClasses}
          />
        )}
        <span data-testid="trend-child" className={valueClasses}>
          {children}
        </span>
      </div>
    </div>
  );
};

Trend.displayName = 'Trend';

export { Trend };
