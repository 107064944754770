import { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { Separator } from '~/components';
import { Button } from '~/components/Button';
import { FormFieldCombobox } from '~/components/FormField';
import { FormFieldRadioGroup } from '~/components/FormField/components/FormFieldRadioGroup';
import { useIsExternalUser } from '~/hooks/useIsExternalUser';
import { useLabelGroupList } from '~/modules/settings/api/queries/labelGroupList';
import type { LabelListQueryVariables } from '~/modules/settings/api/queries/labelList';
import { Drawer } from '~/modules/settings/components/Drawer';
import { LABEL_TYPES } from '~/modules/settings/constants/Filter';
import type { LabelsFilter } from '~/modules/settings/types/filter';

const TEMP_HARDCODED_VARIABLES: LabelListQueryVariables = {
  skip: 0,
  take: 50,
};

interface LabelsFormFilterProps {
  open: boolean;
  onApply: () => void;
}

export const LabelsFormFilter = ({ open, onApply }: LabelsFormFilterProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('settings');
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const { labelGroupList } = useLabelGroupList(TEMP_HARDCODED_VARIABLES);

  const isCurrentUserInternal = !useIsExternalUser();
  const LABEL_GROUP_OPTIONS = useMemo(
    () =>
      labelGroupList
        ? labelGroupList.data.map((labelGroup) => ({
            value: labelGroup.id,
            label: labelGroup.name,
          }))
        : [],
    [labelGroupList],
  );

  const defaultValues = useMemo<LabelsFilter>(
    () => ({
      labelType: 'all',
      labelGroupIds: [],
    }),
    [],
  );

  const filterForm = useForm<LabelsFilter>({
    defaultValues,
  });

  const isFilterKey = useCallback(
    (key: string): key is keyof LabelsFilter => key in defaultValues,
    [defaultValues],
  );

  useEffect(() => {
    if (open) {
      for (const [key, value] of searchParams) {
        if (isFilterKey(key)) {
          if (Array.isArray(filterForm.getValues(key))) {
            filterForm.setValue(
              key,
              value.split(',').map((val) => ({ value: val })),
            );
          } else {
            filterForm.setValue(key, value);
          }
        }
      }
    }
    // Disabling following line as isFilterKey is not a dependency, as the function does not change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterForm, open, searchParams]);

  const handleClear = () => {
    filterForm.reset(defaultValues);
  };

  const handleApply = (filterData: LabelsFilter) => {
    const params = new URLSearchParams();
    for (const [key, filter] of Object.entries(filterData)) {
      if (Array.isArray(filter) && filter.length) {
        params.set(key, filter.map((item) => item.value).join(','));
      } else if (filter && !Array.isArray(filter)) {
        switch (key) {
          default:
            if (filter === 'all') {
              if (params.has(key)) {
                params.delete(key);
              }
              break;
            }
            params.set(key, filter);
            break;
        }
      }
    }

    navigate(
      { pathname: location.pathname, search: params.toString() },
      { replace: true },
    );
    onApply();
  };

  const labelTypeOptions = useMemo(
    () =>
      LABEL_TYPES.map((labelType) => ({
        value: labelType.value,
        label: t(labelType.label),
      })),
    [t],
  );

  return (
    <FormProvider {...filterForm}>
      <Drawer.Header>
        <Drawer.Title>{t('filters')}</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <FormFieldRadioGroup
          name="labelType"
          label={t('labelType')}
          options={labelTypeOptions}
          className="gap-md"
        />
        {isCurrentUserInternal ? (
          <>
            <Separator className="my-md" />
            <FormFieldCombobox
              name="labelGroupIds"
              label={t('labelGroup.list')}
              multiselect={true}
              displaySelectedOptions={true}
              options={LABEL_GROUP_OPTIONS}
            />
          </>
        ) : null}
      </Drawer.Body>
      <Drawer.Footer>
        <Button
          size="large"
          variant="tertiary"
          onClick={handleClear}
          className="font-normal"
          type="button"
        >
          {t('Clear filters')}
        </Button>
        <Button
          size="large"
          type="button"
          onClick={filterForm.handleSubmit(handleApply)}
        >
          {t('Apply')}
        </Button>
      </Drawer.Footer>
    </FormProvider>
  );
};

LabelsFormFilter.displayName = 'LabelsFormFilter';
