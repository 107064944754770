export interface DropdownOption {
  value: string;
  label: string;
}

export enum ColumnFilterType {
  dateRange = 'DATE_RANGE',
  dropdown = 'DROPDOWN',
  inputNumber = 'INPUT_NUMBER',
  inputText = 'INPUT_TEXT',
}

export interface FilterModel {
  field: string;
  name: string;
  value?: string | string[] | number;
  operand?: DropdownOption;
  type?: ColumnFilterType;
  multiValue?: boolean;
  arrayField?: boolean;
  customOptions?: DropdownOption[];
}

export interface SavedFilterOption extends DropdownOption {
  isShared?: boolean;
  authorId?: number;
}

export interface SavedFilter {
  id?: number;
  title: string;
  isShared: boolean;
  filters: FilterModel[];
}

export interface ColumnFilter extends FilterModel {
  operands: ColumnFilterOperand[];
}

export interface ColumnFilterOperand {
  id: string;
  label: string;
}
