import type { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { env } from '~/lib/env.ts';
import { useUser } from '~/modules/auth/hooks/useUser';

import { LinkSearchTrigger } from '../LinkFarmSearch/LinkSearchTrigger';

export const PageTemplate: FC<
  PropsWithChildren<{ showGreeting?: boolean }>
> = ({ children, showGreeting = false }) => {
  const { name } = useUser();
  const { t } = useTranslation('wmgone');
  const isGlobalSearchEnabled = Boolean(env('VITE_GLOBAL_SEARCH_ENABLED'));

  return (
    <div className="flex w-full flex-1 flex-col gap-xl p-xl">
      {showGreeting || !isGlobalSearchEnabled ? (
        <div className="flex w-full items-center justify-between">
          {showGreeting ? (
            <p className="text-display-md font-bold tracking-[0.031em] text-white">
              {t('static-page.hero.hello')} {name}
            </p>
          ) : null}
          {!isGlobalSearchEnabled ? <LinkSearchTrigger /> : null}
        </div>
      ) : null}
      {children}
    </div>
  );
};

PageTemplate.displayName = 'PageTemplate';
