import { useTranslation } from 'react-i18next';

import { Icon, Separator } from '~/components';
import { HeaderSmall } from '~settings/components/HeaderSmall';
import type { LabelDetailTransformed } from '~settings/utils/transformers';

const namesToIconsMap: Record<string, string> = {
  facebookReference: 'facebook',
  instagramReference: 'instagram',
  twitterReference: 'x-twitter',
  youtubeReference: 'youtube',
  website: 'earth-americas',
};

interface SocialsProps {
  socials: LabelDetailTransformed['socials'];
}

export const Socials = ({ socials }: SocialsProps) => {
  const { t } = useTranslation('settings');

  if (!socials?.length) {
    return null;
  }

  return (
    <div className="text-body-md">
      <Separator className="my-lg" />
      <HeaderSmall> {t('socials')}</HeaderSmall>
      {socials.map(([type, url]: [keyof typeof namesToIconsMap, string]) => (
        <a
          key={type}
          href={url}
          className="text-inverse"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon
            type={namesToIconsMap[type]}
            variant={type === 'website' ? 'solid' : 'brands'}
            className="mr-xs size-md"
            aria-hidden="true"
            size="md"
          />
        </a>
      ))}
    </div>
  );
};
Socials.displayName = 'Socials';
