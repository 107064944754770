import { useTranslation } from 'react-i18next';

export function Header() {
  const { t } = useTranslation('label-perf');
  return (
    <header className="my-md items-center gap-md">
      <h1 className="py-sm text-display-md font-bold tracking-[0.031em]">
        {t('header.title')}
      </h1>
    </header>
  );
}

Header.displayName = 'Header';
