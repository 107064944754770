import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

export function ArtistPageLayout() {
  const { t } = useTranslation('navigation', {
    keyPrefix: 'sidebar.items',
  });

  return (
    <>
      <Helmet>
        <title>{t('roster')}</title>
      </Helmet>
      <Outlet />
    </>
  );
}

ArtistPageLayout.displayName = 'ArtistPageLayout';
