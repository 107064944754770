import type { LegendItem } from '../containers/LabelPerf/components/LegendList';
import {
  type FixesHistValue,
  type HistRange,
  LegendColor,
  LegendName,
} from '../types/label-perf';
import { dateToName, getDates } from './date';

const collapseHistRange = (data: FixesHistValue[]): FixesHistValue[] => {
  // Within same name (date), keep the last value
  const collapsedData: FixesHistValue[] = [];
  let lastDate = '';

  for (const value of data) {
    if (value.name !== lastDate) {
      collapsedData.push(value);
      lastDate = value.name;
    }
  }

  return collapsedData;
};

export const reduceHistRange = (
  data: FixesHistValue[],
  range: HistRange,
): FixesHistValue[] => {
  data = data.map((value) => ({ ...value, name: value.name.split('T')[0] }));

  const dates = getDates(range);
  const reducedData: FixesHistValue[] = [];

  let histValue: Omit<FixesHistValue, 'name'> = {
    inReview: 0,
    fixed: 0,
    needFixes: 0,
  };

  for (const date of dates) {
    const current = data.find((value) => value.name === date);
    if (current) {
      histValue = current;
    }
    reducedData.push({ ...histValue, name: dateToName(date, range) });
  }

  return collapseHistRange(reducedData);
};

export const getLegendItems = (
  chartsData: FixesHistValue[] | undefined,
): LegendItem[] => {
  if (!chartsData) {
    return [];
  }

  const lastData = chartsData[chartsData.length - 1];

  return [
    {
      color: LegendColor.NeedsReview,
      name: LegendName.NeedsReview,
      value: lastData.needFixes,
    },
    {
      color: LegendColor.InReview,
      name: LegendName.InReview,
      value: lastData.inReview,
    },
    {
      color: LegendColor.FixedTracks,
      name: LegendName.FixedTracks,
      value: lastData.fixed,
    },
  ];
};
