export const calculateDeltaRatio = ({
  current,
  previous,
  digits = 1,
}: {
  current: number;
  previous: number;
  digits?: number;
}) => {
  return !current || !previous
    ? 0
    : Number((((current - previous) / previous) * 100).toFixed(digits));
};
