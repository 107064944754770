export interface LegendItem {
  color: string;
  value: number;
}

export interface LegendProps {
  className?: string;
  items: LegendItem[];
  gapSize?: string; // Optional prop for customizing gap size
}

const LegendBar = ({
  className,
  items,
  gapSize = '4px',
}: LegendProps): JSX.Element => {
  const total = items.reduce((acc, item) => acc + item.value, 0);

  return (
    <div className={className} data-testid="legend-container">
      {total > 0 && (
        <div
          className="flex h-xl overflow-hidden rounded-xs"
          style={{ gap: gapSize }}
          role="presentation"
          data-testid="legend-bar"
        >
          {items
            .filter((item) => item.value > 0)
            .map((item, index) => {
              const width = `${(item.value / total) * 100}%`;
              return (
                <div
                  key={index}
                  className="h-full"
                  data-testid="legend-segment"
                  style={{ width, backgroundColor: item.color }}
                />
              );
            })}
        </div>
      )}
    </div>
  );
};

LegendBar.displayName = 'LegendBar';

export { LegendBar };
