import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useIsExternalUser } from '~/hooks/useIsExternalUser';
import { env } from '~/lib/env';
import type { UserListItem } from '~settings/api/lib/schemas';
import { GroupInfo } from '~settings/components/GroupInfo';
import { RelativeDate } from '~settings/components/RelativeDate';
import { UserRole } from '~settings/components/UserRole';
import { UserInfo } from '~settings/modules/user/components/UserInfo';

import { ListUserActions } from '../parts/ListUserActions';

interface UseUsersColumns {
  // TODO: This might change based on the actual implementation
  // if not provided, it will show all relevant columns for all user types
  userTypeFilter?: 'internal' | 'external';
}

export const useUsersColumns = ({ userTypeFilter }: UseUsersColumns = {}) => {
  const { t } = useTranslation('settings');
  const isCurrentUserExternal = useIsExternalUser();
  const readOnlyIsNotEnabled =
    env('VITE_CONTAINER_SETTINGS_READ_ONLY_ENABLED', false) === 'false';

  return useMemo(() => {
    const columnHelper = createColumnHelper<UserListItem>();

    const usersColumns = {
      name: columnHelper.accessor((row) => row.fullName, {
        id: 'fullName',
        header: () => t('name'),
        cell: (info) => (
          <UserInfo
            firstName={info.row.original.firstName}
            lastName={info.row.original.lastName}
            fullName={info.row.original.fullName}
          />
        ),
        enableSorting: true,
      }),
      email: columnHelper.accessor('email', {
        id: 'email',
        header: () => t('email'),
        cell: (cell) => cell.getValue(),
        enableSorting: true,
      }),
      labelGroup: columnHelper.accessor((row) => row.labelGroups[0]?.name, {
        id: 'labelGroup',
        header: () => t('labelGroup.list'),
        cell: (cell) => (cell.getValue() ? cell.getValue() : '-'),
        enableSorting: false,
      }),
      labels: columnHelper.accessor('labels', {
        id: 'labels',
        header: () => t('labels'),
        cell: (cell) => (
          <GroupInfo groups={cell.getValue().map(({ name }) => name)} />
        ),
        enableSorting: false,
      }),
      role: columnHelper.accessor('roles', {
        id: 'role',
        header: () => t('role'),
        cell: (info) => <UserRole roles={info.getValue()} />,
        enableSorting: false,
      }),
      lastLogin: columnHelper.accessor('lastLogin', {
        id: 'lastLogin',
        header: () => t('lastLogin'),
        cell: (info) => <RelativeDate data={info.getValue()} />,
        enableSorting: true,
      }),
      controls: columnHelper.display({
        id: 'actions',
        cell: ({ row }) => (
          <ListUserActions
            // TODO: replace with actual handler
            onDeleteClick={() => console.log(`delete ${row.original.id}`)}
            // TODO: replace with actual handler
            onEditClick={() => console.log(`edit ${row.original.id}`)}
          />
        ),
      }),
    };

    let columnsOrder: Array<keyof typeof usersColumns> = [];

    if (isCurrentUserExternal) {
      columnsOrder = ['name', 'email', 'labels', 'role', 'lastLogin'];
    } else {
      switch (userTypeFilter) {
        case 'external':
          columnsOrder = ['name', 'email', 'labels', 'role', 'lastLogin'];
          break;
        case 'internal':
          columnsOrder = ['name', 'email', 'labelGroup', 'role', 'lastLogin'];
          break;
        default:
          columnsOrder = [
            'name',
            'email',
            'labelGroup',
            'labels',
            'role',
            'lastLogin',
          ];
          break;
      }
    }

    if (readOnlyIsNotEnabled) {
      columnsOrder.push('controls');
    }

    return columnsOrder.map((key) => usersColumns[key]);
  }, [userTypeFilter, t, isCurrentUserExternal, readOnlyIsNotEnabled]);
};
