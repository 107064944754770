import { useTranslation } from 'react-i18next';

interface PerformanceModuleHeaderProps {
  header: string;
  territory: string;
  startDate: string;
  endDate: string;
}

export function PerformanceModuleHeader({
  header,
  territory,
  startDate,
  endDate,
}: PerformanceModuleHeaderProps) {
  const { t } = useTranslation('roster');

  return (
    <div className="flex flex-col">
      <h1 className="text-headline-md font-semibold">{header}</h1>
      <div className="mb-lg text-body-sm font-semibold text-subtle">
        {t('trackDetails.releaseDate', {
          dateStart: startDate,
          dateEnd: endDate,
        })}

        <span className="text-body-lg text-emphasis-strong"> • </span>
        {territory}
      </div>
    </div>
  );
}

PerformanceModuleHeader.displayName = 'PerformanceModuleHeader';
