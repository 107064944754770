import * as zod from 'zod';

enum Errors {
  LabelNameRequired = 'labelName',
  WEARequired = 'weaLabelCode',
  USRequired = 'usLabelCode',
  DealOwnerRequired = 'dealOwner',
  DealRegionScopeRequired = 'dealRegionScope',
  WMIRequired = 'wmiLabelCode',
  MarketingCompanyRequired = 'marketingCompany',
  RepertoireOwnerRequired = 'repertoireOwner',
  WBSElementNumberRequired = 'wbsElementNumber',
  DealIDRequired = 'dealID',
  ScopeIDRequired = 'scopeID',
  DealScopeRequired = 'dealScope',
  ProfitCenterRequired = 'profitCenter',
  PMORequired = 'pmo',
  GVLCodeRequired = 'gvlCode',
  PresentationLabelRequired = 'presentationLabel',
  DistributorsRequired = 'distributors',
}

function nonEmptyErrorMessage(error: Errors) {
  return zod.string({ required_error: error }).nonempty(error);
}

export const labelFormSchema = zod.object({
  labelName: nonEmptyErrorMessage(Errors.LabelNameRequired),
  weaLabelCode: nonEmptyErrorMessage(Errors.WEARequired),
  usLabelCode: nonEmptyErrorMessage(Errors.USRequired),
  dealOwner: nonEmptyErrorMessage(Errors.DealOwnerRequired),
  dealRegionScope: nonEmptyErrorMessage(Errors.DealRegionScopeRequired),
  wmiLabelCode: nonEmptyErrorMessage(Errors.WMIRequired),
  marketingCompany: nonEmptyErrorMessage(Errors.MarketingCompanyRequired),
  repertoireOwner: nonEmptyErrorMessage(Errors.RepertoireOwnerRequired),
  wbsElementNumber: nonEmptyErrorMessage(Errors.WBSElementNumberRequired),
  dealID: nonEmptyErrorMessage(Errors.DealIDRequired),
  scopeID: nonEmptyErrorMessage(Errors.ScopeIDRequired),
  dealScope: nonEmptyErrorMessage(Errors.DealScopeRequired),
  profitCenter: nonEmptyErrorMessage(Errors.ProfitCenterRequired),
  pmo: nonEmptyErrorMessage(Errors.PMORequired),
  gvlCode: nonEmptyErrorMessage(Errors.GVLCodeRequired),
  presentationLabel: nonEmptyErrorMessage(Errors.PresentationLabelRequired),
  distributors: nonEmptyErrorMessage(Errors.DistributorsRequired),
});

export type LabelFormSchema = zod.infer<typeof labelFormSchema>;
