import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '~/components/Icon';
import { Tag } from '~/components/Tag';

type Props = { children: ReactNode; onRemove?: () => void };

export function DropdownTag({ children, onRemove }: Props) {
  const { t } = useTranslation('components', { keyPrefix: 'dropdown' });
  // Prevents opening the dropdown when clicking on the tag
  const onClick = (e: React.MouseEvent) => {
    if (onRemove) {
      e.preventDefault();
      e.stopPropagation();
      onRemove();
    }
  };

  return (
    <Tag variant="secondary">
      {children}
      {onRemove ? (
        <button onClick={onClick} aria-label={t('removeOption')}>
          <Icon type="xmark" size="sm" aria-hidden />
        </button>
      ) : null}
    </Tag>
  );
}

DropdownTag.displayName = 'DropdownTag';
