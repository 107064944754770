import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, Tag, Tooltip } from '~/components';
import { Avatar } from '~/components/Avatar';
import { IconButton } from '~/components/IconButton';
import { ArtistCrossOverIcon } from '~/modules/artist-roster/components/ArtistCrossOverIcon.tsx';
import { useAnalytics } from '~/modules/monitoring/amplitude';

import type { ArtistTableMetaType, ArtistType } from '../lib/types';

export function useArtistColumns() {
  const { t } = useTranslation('roster');
  const { trackAnalytics } = useAnalytics();
  const columnHelper = useMemo(() => createColumnHelper<ArtistType>(), []);

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('name', {
        id: 'name',
        header: () => 'Name',
        cell: ({ row }) => (
          <div className="flex items-center gap-sm">
            {row.original.image ? (
              <img
                src={row.original.image}
                alt={row.original.name}
                className="size-2xl rounded-full"
              />
            ) : (
              <Avatar name={row.original.name} variant="base" />
            )}
            <div className="text-headline-sm font-bold">
              {row.original.name}
            </div>
            {row.original.isCrossOver ? <ArtistCrossOverIcon /> : null}
          </div>
        ),
      }),
      columnHelper.accessor('labels', {
        id: 'labels',
        header: () => 'Label(s)',
        cell: ({ row }) =>
          !!row.original.labels.length && (
            <div className="flex gap-md">
              <span className="text-body-md font-normal">
                {row.original.labels[0].name}
              </span>
              {row.original.labels.length > 1 && (
                <Tooltip
                  content={row.original.labels
                    .slice(1)
                    .map((label) => label.name)
                    .join(', ')}
                  delayDuration={0}
                  side="top"
                  sideOffset={8}
                  triggerAsChild
                  size="sm"
                >
                  <span className="cursor-pointer border-b-px text-body-sm font-semibold border-white">
                    {t('labels.moreLink', {
                      count: row.original.labels.length - 1,
                    })}
                  </span>
                </Tooltip>
              )}
            </div>
          ),
      }),
      columnHelper.accessor('reports', {
        id: 'reports',
        header: () => 'Data tools',
        cell: ({ row, table }) => {
          return (
            <div className="flex flex-row gap-sm">
              {row.original?.reports.map((q) => {
                return (
                  <a
                    key={q.name}
                    href={q.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => {
                      e.stopPropagation();

                      const meta = table.options?.meta as
                        | ArtistTableMetaType
                        | undefined;
                      trackAnalytics('rosterTableArtistInteraction', {
                        artist_id: row.original.id,
                        artist_name: row.original.name,
                        click_type: q.name,
                        roster_label_filters_count: meta?.filtersCount ?? 0,
                        roster_crossover_filter_applied:
                          meta?.crossoverFilterApplied ?? false,
                        roster_label_filters_applied:
                          meta?.filtersApplied ?? false,
                      });
                    }}
                  >
                    <Tag variant="secondary">
                      {q.displayName ?? q.name}
                      <Icon
                        type="external-link"
                        size="sm"
                        variant="regular"
                        role="presentation"
                        aria-hidden
                        aria-label="external-url-icon"
                        className="ml-sm text-white"
                      />
                    </Tag>
                  </a>
                );
              })}
            </div>
          );
        },
      }),
      columnHelper.accessor('socials', {
        id: 'socials',
        header: () => 'Socials',
        meta: {
          className: 'w-[16%]',
        },
        cell: ({ row, table }) => {
          return (
            <div className="-ml-md flex items-center">
              {row.original?.socials?.map((s) => {
                return (
                  <IconButton
                    key={`${s.name}_${s.url}`}
                    iconType={s.name}
                    className="p-lg leading-none transition duration-100 hover:bg-emphasis-strong"
                    iconVariant="brands"
                    size="medium"
                    asLink={true}
                    linkTo={s.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={s.name}
                    onClick={(e) => {
                      e.stopPropagation();

                      const meta = table.options?.meta as
                        | ArtistTableMetaType
                        | undefined;

                      trackAnalytics('rosterTableArtistInteraction', {
                        artist_id: row.original.id,
                        artist_name: row.original.name,
                        click_type: s.name,
                        roster_label_filters_count: meta?.filtersCount ?? 0,
                        roster_crossover_filter_applied:
                          meta?.crossoverFilterApplied ?? false,
                        roster_label_filters_applied:
                          meta?.filtersApplied ?? false,
                      });
                    }}
                  />
                );
              })}
            </div>
          );
        },
      }),
    ];
  }, [columnHelper, t, trackAnalytics]);

  return { columns };
}
