import type { PropsWithChildren } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { convertCoopLocale } from '~/i18n';
import { env } from '~/lib/env.ts';
import { ShowNavContext } from '~/modules/navigation/contexts';

const setHideCoopMenu = (value: string) => {
  localStorage.setItem('container-nav-enabled', value);
};

const getLocaleFromStorage = () => {
  const userLocale = localStorage.getItem('userLocale');
  if (userLocale) {
    try {
      return convertCoopLocale(JSON.parse(userLocale));
    } catch (e) {
      console.error('Error parsing userLocale from localStorage:', e);
    }
  }

  return 'en-US';
};

export function ShowNavProvider({ children }: PropsWithChildren) {
  const [showNav, setShowNav] = useState<boolean>(
    env('VITE_USE_CONTAINER_NAVIGATION', false) === 'true' &&
      getLocaleFromStorage() === 'en-US',
  );
  const setShow = useCallback((to: boolean) => {
    if (env('VITE_USE_CONTAINER_NAVIGATION', false) !== 'true') {
      setShowNav(false);
      setHideCoopMenu('show');

      return;
    }

    setShowNav(to);
    if (to) {
      setHideCoopMenu('hide');
    } else {
      setHideCoopMenu('show');
    }
  }, []);

  const { i18n } = useTranslation();

  const updateCurrentLocale = useCallback(
    (locale: string) => {
      i18n.changeLanguage(locale);
      setShow(true);
    },
    [i18n, setShow],
  );

  const setShowByLocale = useCallback(() => {
    setShow(true);
  }, [setShow]);

  const onLocalStorageChanged = useCallback(
    (event: CustomEvent) => {
      if (event.detail.key === 'userLocale') {
        updateCurrentLocale(getLocaleFromStorage());
      }
    },
    [updateCurrentLocale],
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    window.addEventListener('localStorageChanged', onLocalStorageChanged);

    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      window.removeEventListener('localStorageChanged', onLocalStorageChanged);
    };
  }, [onLocalStorageChanged]);

  return (
    <ShowNavContext.Provider
      value={{
        showNav,
        setShowNav: setShow,
        setShowByLocale,
      }}
    >
      {children}
    </ShowNavContext.Provider>
  );
}

ShowNavProvider.displayName = 'ShowNavProvider';
