export const BRANDS = {
  WMG: 'WMG',
  ADA: 'ADA',
} as const;

export type BrandType = 'WMG' | 'ADA';

export const BRAND_DOMAINS = {
  WMG: {
    dev: 'dev.wmg1.dev',
    staging: 'staging.wmg1.dev',
    prod: 'one.wmg.com',
  },
  ADA: {
    dev: 'dev.partners.ada-music.com',
    staging: 'staging.partners.ada-music.com',
    prod: 'partners.ada-music.com',
  },
} as const;
