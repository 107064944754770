import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { useWmgSession } from '~/shared/hooks';

import { LabelQueryKeys } from '../config/queryKeys';
import { BFFClient } from '../lib/BFFClient';
import { getRequestHeaders } from '../lib/headers';
import {
  type LabelListResponse,
  LabelListResponseSchema,
} from '../lib/schemas';
import type { PresetUseQueryOptions } from '../lib/types';
import type { PaginationVariables } from '../types/paginationVariables';

export interface LabelListQueryVariables extends PaginationVariables {
  userId?: string;
  labelIds?: string;
  labelType?: string;
  parentId?: string;
  sortBy?: string;
  orderDir?: string;
  search?: string;
}

export function useLabelList<TData = LabelListResponse>(
  variables: LabelListQueryVariables,
  options?: PresetUseQueryOptions<LabelListResponse, TData>,
) {
  const session = useWmgSession();

  const { data, ...query } = useQuery({
    queryKey: LabelQueryKeys.list(variables),
    queryFn: async () => {
      const responseData = await BFFClient.get('labels', {
        headers: getRequestHeaders(session.token),
        searchParams: { ...variables },
      }).json();

      return LabelListResponseSchema.parse(responseData);
    },
    placeholderData: keepPreviousData,
    ...options,
  });

  return { labelList: data, ...query };
}
