import type { FC } from 'react';
import { Outlet } from 'react-router-dom';

export const WmgOneLayout: FC = () => {
  return (
    <div className="flex w-full flex-1 overflow-auto bg-gradient bg-[100%,auto] bg-no-repeat bg-level-01/full">
      <Outlet />
    </div>
  );
};

WmgOneLayout.displayName = 'WmgOneLayout';
