import { formatFollowerCount } from './ArtistDetails/utils';

/**
 * Function to format the metrics on the Artist Details pages.
 * Note we currently use the same format as the Social counts but defining
 * a separate function here so that it's clear that if the format changes for
 * either one that we don't accidentially change the other.
 * @param count The metric count to be formatted.
 * @returns The formatted metric count.
 */
export function formatMetricCount(count?: number | null) {
  return formatFollowerCount(count);
}
