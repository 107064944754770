export function formatUtcDate(utcDateString: string): string {
  const date = new Date(utcDateString + 'T00:00:00.000Z');

  if (isNaN(date.getTime())) {
    return 'Invalid date';
  }

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'UTC',
  };

  return date.toLocaleDateString('en-US', options);
}

export function formatMillionsNumber(
  num: number,
  showThousands: boolean = false,
): string {
  if (num >= 1000000) {
    if (num % 1000000 === 0) {
      return `${num / 1000000}M`;
    } else {
      return `${(num / 1000000).toFixed(1)}M`;
    }
  } else if (showThousands && num >= 1000) {
    if (num % 1000 === 0) {
      return `${num / 1000}K`;
    } else {
      return `${(num / 1000).toFixed(1)}K`;
    }
  } else if (num >= 100000) {
    return `${(num / 1000000).toFixed(1)}M`;
  } else {
    return num.toString();
  }
}
