import type { PropsWithChildren } from 'react';
import { useCallback, useState } from 'react';

import {
  CATEGORIES,
  GlobalSearchContext,
} from '../contexts/GlobalSearchContext';
import { useDebouncedSearchValue } from '../hooks/useDebouncedSearch';
import { useSearch } from '../hooks/useSearch';

export function GlobalSearchProvider({
  children,
}: PropsWithChildren): React.ReactNode {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const debouncedSearchQuery = useDebouncedSearchValue(searchQuery, 300);
  const searchResults = useSearch({
    searchQuery: debouncedSearchQuery,
    setIsLoading,
  });
  const [selectedCategory, setSelectedCategory] = useState(CATEGORIES[0]);

  const resetSearch = useCallback(() => {
    setSearchQuery('');
  }, [setSearchQuery]);

  return (
    <GlobalSearchContext.Provider
      value={{
        searchQuery,
        setSearchQuery,
        resetSearch,
        searchResults,
        isLoading,
        selectedCategory,
        setSelectedCategory,
      }}
    >
      {children}
    </GlobalSearchContext.Provider>
  );
}

GlobalSearchProvider.displayName = 'GlobalSearchProvider';
