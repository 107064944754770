import type { HTMLProps } from 'react';

import { cn } from '~/lib/cn';

type Props = HTMLProps<HTMLDivElement>;

export function CTABlock({ className, ...props }: Props) {
  return (
    <footer
      className={cn(
        'sticky',
        'bottom-0',
        '-mx-xl',
        '-mb-xl',
        'px-xl',
        'py-lg',
        'gap-md',
        'bg-level-03',
        'flex',
        'justify-end',
        'z-0',
        className,
      )}
      {...props}
    />
  );
}

CTABlock.displayName = 'CTABlock';
