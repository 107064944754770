import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { FilterChip } from '~/components';
import type { FilterEntity } from '~settings/hooks/useFilterChips';
import { useFilterChips } from '~settings/hooks/useFilterChips';

type FilterListProps = {
  entity: FilterEntity;
};

const FilterList = ({ entity }: FilterListProps) => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation('settings');

  const navigate = useNavigate();

  const filters = useFilterChips(entity);

  const handleDelete = (key: string, value: string) => {
    const currList = searchParams.get(key)?.split(',').filter(Boolean);
    if (!currList) {
      return;
    }

    const index = currList.indexOf(value);
    currList.splice(index, 1);

    if (currList.length) {
      searchParams.set(key, currList.join(','));
    } else {
      searchParams.delete(key);
    }
    navigate(
      { pathname: location.pathname, search: searchParams.toString() },
      { replace: true },
    );
  };

  return (
    <div className="flex gap-md">
      {!filters || filters.length === 0 ? (
        <div className="flex items-center text-body-sm text-subtle">
          {t('noFilters')}
        </div>
      ) : (
        filters.map(({ title, value }) =>
          value.map((item: { id: string; name: string }) => (
            <FilterChip
              key={item.id}
              title={title}
              value={item}
              onRemove={handleDelete}
            />
          )),
        )
      )}
    </div>
  );
};

FilterList.displayName = 'FilterList';
export { FilterList };
