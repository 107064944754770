import { KebabMenu } from '~/components/KebabMenu';
import type { MenuItemState } from '~/components/KebabMenu/components/KebabMenuItem';

export interface Action {
  id: string;
  label: string;
  action: () => void;
  state?: MenuItemState;
}

interface Props {
  actions: Action[];
}

export const ActionsMenu = ({ actions }: Props) => {
  if (!actions.length) {
    return null;
  }
  return (
    <KebabMenu>
      {actions.map(({ id, label, action, state }) => (
        <KebabMenu.Item onClick={action} key={id} state={state}>
          {label}
        </KebabMenu.Item>
      ))}
    </KebabMenu>
  );
};

ActionsMenu.displayName = 'ActionsListMenu';
