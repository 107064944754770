import { DEFAULT_PAGE_SIZE } from '../components/constants';
import { getArtistTracks } from '../lib/tracks';
import { usePaginatedQuery } from './usePaginatedQuery';

interface ArtistTracksQueryProps {
  uaid?: string;
}

export const useArtistTracksQuery = ({ uaid }: ArtistTracksQueryProps) => {
  return usePaginatedQuery({
    queryEnabled: !!uaid,
    queryKey: ['artist-tracks', uaid],
    queryFn: ({ offset, limit }) => {
      return getArtistTracks({
        offset,
        limit,
        uaid,
      });
    },
    pageSize: DEFAULT_PAGE_SIZE,
  });
};
