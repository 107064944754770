import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { env } from '~/lib/env';

import { coopDetector } from './coopDetector';
import { LOCALES } from './locales';

const isDebugMode = env('VITE_I18N_DEBUG', false) === 'true';
const languageDetector = new LanguageDetector();
languageDetector.addDetector(coopDetector);

const extractLanguage = env('VITE_EXTRACT_LANGUAGE', false) === 'true';

i18n
  .use(languageDetector)
  .use(ChainedBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en-US',
    ns: [
      'navigation',
      'errors',
      'auth',
      'remotes',
      'settings',
      'roster',
      'components',
      'ddex-console',
    ],
    debug: isDebugMode,
    detection: {
      order: ['querystring', 'localStorage', 'coopDetector', 'navigator'],
      lookupQuerystring: 'locale',
      lookupLocalStorage: 'locale',
    },
    supportedLngs: LOCALES,
    saveMissing: extractLanguage,
    backend: {
      backends: [
        HttpBackend, // fallback backend
      ],
      backendOptions: [
        {
          loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
      ],
    },
  });

export { i18n };
