import type { MouseEvent } from 'react';

import { Icon } from '~/components/Icon/Icon';
import { cn } from '~/lib/cn';
interface PageButtonProps {
  onPrevious?: (event: MouseEvent<HTMLButtonElement>) => void;
  onNext?: (event: MouseEvent<HTMLButtonElement>) => void;
  currentPage: number;
  totalPages: number;
}

export function PageButtons({
  onPrevious,
  onNext,
  currentPage,
  totalPages,
}: PageButtonProps) {
  const isPreviousDisabled = currentPage === 1;
  const isNextDisabled = currentPage === totalPages;

  return (
    <div className="flex space-x-xs rounded-sm bg-level-03">
      {['previous', 'next'].map((position) => {
        const isPrevious = position === 'previous';
        const isDisabled = isPrevious ? isPreviousDisabled : isNextDisabled;
        const onClick = isPrevious ? onPrevious : onNext;
        const iconType = 'angle-' + (isPrevious ? 'left' : 'right');
        const padding = isPrevious ? 'pl-xs' : 'pr-xs';
        const ariaLabel = `pagination ${position} arrow`;
        const rounded = isPrevious ? 'rounded-s-sm' : 'rounded-e-sm';

        return (
          <div
            role="button"
            aria-label={`${position} page button`}
            key={position}
            className={cn(
              'flex items-center justify-center transition-colors duration-100',
              padding,
              rounded,
              {
                'hover:bg-primary-hover': !isDisabled,
              },
            )}
          >
            <button
              aria-label={ariaLabel}
              className={cn('flex p-sm', {
                'cursor-not-allowed': isDisabled,
              })}
              onClick={onClick}
              disabled={isDisabled}
            >
              <Icon
                className={cn({
                  'text-disabled': isDisabled,
                  'text-default': !isDisabled,
                })}
                type={iconType}
                size="md"
                data-testid={`pagination-${position}-arrow`}
              />
            </button>
          </div>
        );
      })}
    </div>
  );
}

PageButtons.displayName = 'PageButtons';
