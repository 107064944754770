import acidIcon from './acidIcon.png?url';
import adaHubIcon from './adaHubIcon.png?url';
import airtableIcon from './airtableIcon.png?url';
import arrowIcon from './arrowIcon.png?url';
import artistPackIcon from './artistPackIcon.png?url';
import artistRosterIcon from './artistRosterIcon.svg?url';
import atlanticColorIcon from './atlanticColorIcon.png?url';
import galleryIcon from './galleryIcon.png?url';
import luminateIcon from './luminateIcon.png?url';
import mondayIcon from './mondayIcon.png?url';
import opusIcon from './opusIcon.png?url';
import serviceNowIcon from './serviceNowIcon.png?url';
import sodatoneIcon from './sodatoneIcon.png?url';
import songRadarIcon from './songRadarIcon.png?url';
import sunIcon from './sunIcon.png?url';
import tableauIcon from './tableauIcon.png?url';
import warnerBlackIcon from './warnerBlackIcon.png?url';
import warnerBlueIcon from './warnerBlueIcon.png?url';
import warnerChappellGoldIcon from './warnerChappellGoldIcon.png?url';
import warnerChappellIcon from './warnerChappellIcon.png?url';
import wavoIcon from './wavoIcon.png?url';

export type IconType = keyof typeof ICON_BY_TYPE;

export const ICON_BY_TYPE = {
  ACID: acidIcon,
  ADA_HUB: adaHubIcon,
  AIRTABLE: airtableIcon,
  ARROW: arrowIcon,
  ARTIST_PACK: artistPackIcon,
  ARTIST_ROSTER: artistRosterIcon,
  ATLANTIC: atlanticColorIcon,
  CHAPPELL_GOLD: warnerChappellGoldIcon,
  CHAPPELL: warnerChappellIcon,
  GALLERY: galleryIcon,
  LUMINATE: luminateIcon,
  MONDAY: mondayIcon,
  OPUS: opusIcon,
  SERVICE_NOW: serviceNowIcon,
  SODATONE: sodatoneIcon,
  SONG_RADAR: songRadarIcon,
  SUN: sunIcon,
  TABLEAU: tableauIcon,
  WARNER_BLACK: warnerBlackIcon,
  WARNER_BLUE: warnerBlueIcon,
  WAVO: wavoIcon,
} as const;
