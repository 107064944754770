import type { DetailedHTMLProps, HTMLAttributes } from 'react';

import type {
  IconName,
  IconSize,
  IconVariant,
} from '~/components/Icon/types.ts';
import { cn } from '~/lib/cn';

interface IconProps
  extends Omit<
    DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>,
    'children'
  > {
  type: IconName;
  variant?: IconVariant;
  /**
   * @see https://docs.fontawesome.com/web/style/size/
   *
   * @example
   * fa-2xs // 0.625em - 10px
   * fa-xs 	// 0.75em  - 12px
   * fa-sm	// 0.875em - 14px
   * fa-lg	// 1.25em  - 20px
   * fa-xl	// 1.5em   - 24px
   * fa-2xl	// 2em     - 32px
   */
  size?: IconSize;
}

export function Icon({
  size = 'lg',
  type,
  variant = 'solid',
  className,
  ...props
}: IconProps) {
  const fullVariant = `fa-${variant}`;
  const fullName = `fa-${type}`;
  const fullSize = `fa-${size}`;

  return (
    <i
      className={cn(fullVariant, fullName, fullSize, className)}
      role="img"
      {...props}
    />
  );
}

Icon.displayName = 'Icon';
