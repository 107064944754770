import { snakeToCamel } from '~/modules/label-perf/components/DataGrid/utils/case';
import type { DashboardRow } from '~/modules/label-perf/types/dashoard';

const arrayValues: (keyof DashboardRow)[] = [
  'missedTerritoriesAlt',
  'gpid',
  'grid',
  'majorLabel',
  'presentationLabel',
  'releaseType',
  'albumTitle',
  'albumSubtitle',
  'deliveryTerritoryList',
  'appleTerritoryList',
  'spotifyTerritoryList',
  'tsList',
  'partnerList',
  'trackRightsTerritories',
  'missedAppleTerritoriesAlt',
  'missedSpotifyTerritoriesAlt',
  'streamingTerritoryList',
  'missedStreamingTerritories',
  'internalComments',
  'productPaFlags',
  'completeDboms',
  'productAudioPresentations',
];

export const mapPageData = (
  data: Partial<DashboardRow>[],
): Partial<DashboardRow>[] => {
  return data.map((d) => {
    const mapped: { [key: string]: unknown } = {};

    Object.keys(d).forEach((k) => {
      const key = snakeToCamel(k.toLowerCase()) as keyof DashboardRow;
      const val = d[k as keyof typeof d];

      if (arrayValues.includes(key) && typeof val === 'string') {
        try {
          mapped[key] = JSON.parse(val);
        } catch (error) {
          console.warn(`Failed to parse JSON for key "${key}":`, val);
          mapped[key] = val;
        }

        return;
      }

      mapped[key] = val;
    });

    // #DSCCONTO-197 workaround ---> remove after data layer fix
    mapped.missedAppleTerritoriesAlt = mapped.missedAppleTerritoriesAlt ?? [];
    mapped.appleTerritoryList = mapped.appleTerritoryList ?? [];
    mapped.spotifyTerritoryList = mapped.spotifyTerritoryList ?? [];
    mapped.missedSpotifyTerritoriesAlt =
      mapped.missedSpotifyTerritoriesAlt ?? [];

    return mapped as Partial<DashboardRow>;
  });
};
