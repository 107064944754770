import { useDropdownContext } from '~/components/Dropdown/hooks/useDropdownContext';

type DropdownInputProps = {
  invalid?: boolean;
};

export function DropdownInput({ invalid }: DropdownInputProps) {
  const { selected, inputRef } = useDropdownContext();

  return (
    <input
      ref={inputRef}
      type="hidden"
      aria-hidden={true}
      tabIndex={-1}
      aria-invalid={invalid}
      value={selected.map((option) => option.value).join(',')}
    />
  );
}

DropdownInput.displayName = 'DropdownInput';
