import type { FC, PropsWithChildren } from 'react';

const Layout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <main
      data-testid="layout"
      className="grow overflow-auto overscroll-contain bg-gradient bg-[100%,auto] bg-no-repeat p-xl tracking-wide bg-level-01/full"
    >
      {children}
    </main>
  );
};

Layout.displayName = 'Layout';

export { Layout };
