import { type FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { WelcomeLogo } from '~/modules/link-farm/components/WelcomeLogo/WelcomeLogo.tsx';

import { PageTemplate } from './components/PageTemplate';

export const Home: FC = () => {
  const { t } = useTranslation('navigation', {
    keyPrefix: 'sidebar.items',
  });

  return (
    <PageTemplate showGreeting={true}>
      <Helmet>
        <title>{t('wmgHome')}</title>
      </Helmet>
      <div className="flex flex-row gap-[3em] overflow-auto pt-[6em]">
        <WelcomeLogo />
      </div>
    </PageTemplate>
  );
};

Home.displayName = 'LinkFarm';
