import { cn } from '~/lib/cn';
import { PerformanceDeltaLabel } from '~/modules/artist-roster/components/TrackList/PerformanceDeltaLabel';

import { HorizontalBar } from './HorizontalBar';
import type { BarChartDirection } from './types';
import { VerticalBar } from './VerticalBar';

interface BarChartBarProps {
  percentageValues: number[];
  categoryLabel: string;
  valueLabel: string;
  direction: BarChartDirection;
  delta?: number;
}

export const BarChartBar = ({
  percentageValues,
  categoryLabel,
  valueLabel,
  direction,
  delta,
}: BarChartBarProps) => {
  const vertical = direction === 'vertical';

  return (
    <div
      className={cn(
        'flex flex-col text-center text-caption-md text-default',
        vertical ? 'h-full w-fit gap-md px-md' : 'h-fit w-full gap-sm py-sm',
      )}
    >
      {/* Vertical Bar */}
      {vertical ? <VerticalBar percentageValues={percentageValues} /> : null}

      {/* Label */}
      <div
        className={cn('flex', vertical ? 'flex-col gap-md' : 'justify-between')}
      >
        <div className="text-caption-md font-bold">{categoryLabel}</div>
        <div className="flex items-center justify-center gap-sm">
          {valueLabel}
          {delta ? <PerformanceDeltaLabel delta={delta} /> : null}
        </div>
      </div>

      {/* Horizontal Bar */}
      {!vertical ? <HorizontalBar percentageValues={percentageValues} /> : null}
    </div>
  );
};

BarChartBar.displayName = 'BarChartBar';
