import { forwardRef, type HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '~/components';
import { cn } from '~/lib/cn';
import { useAnalytics } from '~/modules/monitoring/amplitude';

import type { IconType } from './LinkItemIcons';
import { ICON_BY_TYPE } from './LinkItemIcons';
import globalProtectIcon from './LinkItemIcons/globalProtectIcon.png?url';

export type LinkItemProps = HTMLAttributes<HTMLDivElement> & {
  actionText: string;
  appName: string;
  category?: string;
  className?: string;
  icon: IconType;
  labelId?: number;
  requestAccess?: string;
  requireVpn?: boolean;
  url: string;
};

const linkItemClasses = [
  'max-w-md',
  'h-full',
  'bg-emphasis-default',
  'border',
  'border-white',
  'border-emphasis-subtle',
  'rounded-sm',
  'flex',
  'flex-col',
  'p-lg',
  'w-full',
  'justify-between',
  'transition',
  'duration-300',
  'tracking-wide',
  'group',
  'hover:border-white',
  'hover:border-primary-default',
  'hover:bg-default-inverse/subtle',
  'focus:border-white',
  'focus:border-opacity-stronger',
  'focus:bg-default-inverse/subtle',
];
export const LinkItem = forwardRef<HTMLDivElement, LinkItemProps>(
  (
    {
      actionText,
      appName,
      category,
      className = '',
      icon,
      requestAccess,
      requireVpn,
      url,
    }: LinkItemProps,
    ref,
  ) => {
    const { t } = useTranslation('wmgone');
    const { trackAnalytics } = useAnalytics();

    const handleCardClick = () => {
      window.open(url, '_blank');
      trackAnalytics('linkFarmLinkClicked', {
        app_name: appName,
        page_name: category,
        url,
      });
    };

    return (
      <div
        role="button"
        aria-label={t('globalSearch.open', { name: appName })}
        onClick={handleCardClick}
        className={cn(linkItemClasses, className)}
        ref={ref}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ' || e.key === 'Spacebar') {
            e.preventDefault();
            handleCardClick();
          }
        }}
      >
        <div className="relative flex w-full flex-row items-center justify-between">
          <img
            src={ICON_BY_TYPE[icon]}
            className="size-2xl rounded-full transition-opacity duration-300 group-hover:opacity-none group-focus:opacity-none"
            alt={`${appName} icon`}
          />
          <div
            aria-hidden
            className="absolute flex size-2xl items-center justify-center rounded-full opacity-none transition duration-300 bg-default-inverse/default group-hover:opacity-full group-focus:opacity-full"
          >
            <Icon
              type="external-link"
              size="lg"
              variant="regular"
              role="presentation"
              aria-hidden
              aria-label="external-url-icon"
              className="text-white"
            />
          </div>

          <div
            aria-describedby={`vpn-required-${appName}`}
            className={`flex-row items-center gap-xs opacity-none transition duration-300 group-hover:opacity-full group-focus:opacity-full ${requireVpn ? 'flex' : 'hidden'}`}
          >
            <img
              src={globalProtectIcon}
              className="size-lg rounded-full"
              alt="global-protect-icon"
            />
            <p className="text-body-xs">{t('links.item.vpnRequired')}</p>
          </div>
        </div>
        <div className="flex w-full flex-col gap-sm">
          <p className="mt-lg text-start text-body-md leading-tight">
            {actionText}
          </p>
          <div className="flex flex-row items-end justify-between text-body-xs">
            <div className="max-w-[11.5em] rounded-xs px-sm py-xs text-left bg-default-inverse/subtle">
              {appName}
            </div>
            {requestAccess ? (
              <p>
                <a
                  aria-roledescription="button"
                  aria-label={`Request access to ${appName}`}
                  className="underline transition-colors duration-150 hover:text-hover"
                  href={requestAccess}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => {
                    e.stopPropagation();
                    trackAnalytics('linkFarmAccessRequested', {
                      app_name: appName,
                      page_name: category,
                      url,
                    });
                  }}
                >
                  {t('links.item.requestAccess')}
                </a>
              </p>
            ) : null}
          </div>
        </div>
      </div>
    );
  },
);

LinkItem.displayName = 'LinkItem';
