export const dashboardMockData = {
  total: 2484959,
  data: [
    {
      ISRC: 'USAT21207276',
      TRACK_TITLE: 'Locked out of Heaven',
      ARTIST: 'Bruno Mars',
      GPID: '[\n  "075679955746"\n]',
      MAJOR_LABEL: '[\n  "Atlantic Recording Corp."\n]',
      PRESENTATION_LABEL: '[\n  "Atlantic Records"\n]',
      RELEASE_TYPE: '[\n  "Album"\n]',
      DELIVERY_TERRITORY_COUNT: 243,
      MISSED_TERRITORIES_COUNT_ALT: 7,
    },
    {
      ISRC: 'USAT21207280',
      TRACK_TITLE: 'When I Was Your Man',
      ARTIST: 'Bruno Mars',
      GPID: '[\n  "075679955746"\n]',
      MAJOR_LABEL: '[\n  "Atlantic Recording Corp."\n]',
      PRESENTATION_LABEL: '[\n  "Atlantic Records"\n]',
      RELEASE_TYPE: '[\n  "Album"\n]',
      DELIVERY_TERRITORY_COUNT: 243,
      MISSED_TERRITORIES_COUNT_ALT: 7,
    },
    {
      ISRC: 'USAT21207278',
      TRACK_TITLE: 'Treasure',
      ARTIST: 'Bruno Mars',
      GPID: '[\n  "075679955746"\n]',
      MAJOR_LABEL: '[\n  "Atlantic Recording Corp."\n]',
      PRESENTATION_LABEL: '[\n  "Atlantic Records"\n]',
      RELEASE_TYPE: '[\n  "Album"\n]',
      DELIVERY_TERRITORY_COUNT: 243,
      MISSED_TERRITORIES_COUNT_ALT: 7,
    },
  ],
};
