import * as RadixAccordion from '@radix-ui/react-accordion';

import { cn } from '~/lib/cn';

import { AccordionItem } from './parts/AccordionItem';

type AccordionProps = {
  children: React.ReactNode;
} & Omit<RadixAccordion.AccordionMultipleProps, 'type'>;

export const Accordion = ({ className, ...props }: AccordionProps) => {
  return (
    <RadixAccordion.Root
      className={cn('flex', 'flex-col', 'gap-md', className)}
      {...props}
      type="multiple"
    />
  );
};

Accordion.displayName = 'Accordion';

Accordion.Item = AccordionItem;
