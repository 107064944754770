import { useTranslation } from 'react-i18next';

import type { LabelGroupListItem } from '~settings/api/lib/schemas';
import { useLabelGroupList } from '~settings/api/queries/labelGroupList';
import { Pagination } from '~settings/components/Pagination';
import { Table } from '~settings/components/Table';
import { Toolbar } from '~settings/components/Toolbar.tsx';
import { usePagination } from '~settings/hooks/usePagination';
import { useSearch } from '~settings/hooks/useSearch';
import { useSort } from '~settings/hooks/useSort';

import { useLabelGroupsColumns } from './hooks/useLabelGroupsColumns';

const labelGroupEmptyArray: LabelGroupListItem[] = [];

export const ListLabelGroupPage = () => {
  const columns = useLabelGroupsColumns();
  const { t } = useTranslation('settings');
  const { searchTerm } = useSearch();
  const {
    sorting,
    setSorting,
    sortParams: { orderDir, sortBy },
  } = useSort();
  const { page, setPageNumber, perPage, skip, take } = usePagination();

  const { isLoading, isError, labelGroupList } = useLabelGroupList({
    skip,
    take,
    ...(orderDir && {
      orderBy: sortBy,
      orderDir,
    }),
    ...(searchTerm && { name: searchTerm }),
  });

  if (isLoading) {
    return <section className="py-md">{t('loading')}</section>;
  }

  if (isError) {
    // eslint-disable-next-line i18next/no-literal-string
    return <section className="py-md">Something went wrong.</section>;
  }

  return (
    <section className="py-lg">
      <Toolbar
        placeholder={t('labelGroup.search')}
        setPageNumber={setPageNumber}
      />
      <div className="flex justify-start">
        <Pagination
          page={page}
          total={labelGroupList!.total}
          perPage={perPage}
          entity="labelGroups"
          setPageNumber={setPageNumber}
        />
      </div>
      <Table
        data={labelGroupList?.data ?? labelGroupEmptyArray}
        columns={columns}
        sort={{
          sorting,
          onSortingChange: setSorting,
        }}
      />
    </section>
  );
};

ListLabelGroupPage.displayName = 'ListLabelGroupPage';
