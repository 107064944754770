import type { HeaderGroup } from '@tanstack/react-table';
import type { HTMLProps } from 'react';

import { cn } from '~/lib/cn';

import { TableHeadCell } from '../TableHeadCell';

type TableHeadProps<TData> = HTMLProps<HTMLTableSectionElement> & {
  headerGroups: HeaderGroup<TData>[];
};

export function TableHead<TData>({
  headerGroups,
  className,
  ...props
}: TableHeadProps<TData>) {
  return (
    <thead
      className={cn('text-body-sm', 'text-left', 'leading-tight', className)}
      {...props}
    >
      {headerGroups.map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <TableHeadCell key={header.id} header={header} />
          ))}
        </tr>
      ))}
    </thead>
  );
}

TableHead.displayName = 'TableHead';
