import { Icon, Tag } from '~/components';
import { Label } from '~/components/Label';

type FilterChipProps = {
  title: { id: string; label: string };
  value: { id: string; name: string };
  onRemove: (key: string, value: string) => void;
};

const FilterChip = ({ title, value, onRemove }: FilterChipProps) => {
  return (
    <Tag key={value.id} variant="secondary" size="sm">
      <Label disabled={true}>{title.label}:</Label>
      <Label>{value.name}</Label>
      <button onClick={() => onRemove(title.id, value.id)}>
        <Icon
          type="circle-xmark"
          variant="regular"
          size="md"
          className="pl-xs"
        />
      </button>
    </Tag>
  );
};

FilterChip.displayName = 'FilterChip';
export { FilterChip };
