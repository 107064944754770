import { forwardRef, type PropsWithChildren } from 'react';
import { useClickAway } from 'react-use';

import { useGlobalSearchContext } from '../../hooks/useGlobalSearchContext';
import { SearchField } from '../SearchField';
import { SearchResults } from '../SearchResults';
import { PageOverlay } from './PageOverlay';

export const SearchWrapper = forwardRef<HTMLDivElement, PropsWithChildren>(
  ({ children }, ref) => {
    const { searchQuery, resetSearch } = useGlobalSearchContext();

    useClickAway(ref as React.RefObject<HTMLDivElement>, () => {
      resetSearch();
    });

    return (
      <div ref={ref} className="flex h-dvh flex-1 flex-col">
        <div className="flex justify-center p-md bg-default">
          <div className="relative w-full max-w-[37.5rem]">
            <SearchField />
            {searchQuery ? <SearchResults /> : null}
          </div>
        </div>
        <div className="relative flex flex-1 flex-col overflow-hidden">
          {children}
          <PageOverlay />
        </div>
      </div>
    );
  },
);

SearchWrapper.displayName = 'SearchWrapper';
