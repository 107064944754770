import type { HTMLProps } from 'react';

import { cn } from '~/lib/cn';

export type HeaderProps = {
  breadcrumbs?: React.ReactNode;
  children?: React.ReactNode;
} & HTMLProps<HTMLDivElement>;

export function Header({
  breadcrumbs,
  children,
  className,
  ...props
}: HeaderProps) {
  return (
    <header
      className={cn('relative', 'flex', 'flex-col', 'gap-lg', className)}
      {...props}
    >
      {breadcrumbs}
      <div className="flex items-center gap-md text-strong-on-dark">
        {children}
      </div>
    </header>
  );
}
Header.displayName = 'Header';
