import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Icon } from '~/components';

import { APIError } from '../../api/errors';
import {
  getFixesHistory,
  getLabelPerfReport,
  getLables,
  getTopTenTracks,
} from '../../api/label-perf';
import { Header } from '../../components/Header';
import {
  CHART_TABS,
  DEFAULT_CHART_RANGE,
  DEFAULT_TOP_TEN_TAB,
} from '../../constants/label-perf';
import {
  type FixesHistValue,
  type HistRange,
  type LabelPerfModel,
  TopTenCategory,
  type TopTenOrder,
  type TopTenType,
  type TopTenVariant,
  type TrackItem,
} from '../../types/label-perf';
import { formatNumberSuffix, getRelMonth } from '../../utils/format';
import { getLegendItems } from '../../utils/hist';
import { getTrendDirection } from '../../utils/trend';
import {
  Card,
  Chart,
  ErrorDisplay,
  LegendBar,
  LegendList,
  SearchBar,
  Tabs,
  Title,
  TopTen,
  Trend,
  Value,
} from './components';
import { NoAccess } from './components/NoAccess';

export function LabelPerf() {
  const { t } = useTranslation('label-perf');
  const [chartTab, setChartTab] = useState<HistRange>(DEFAULT_CHART_RANGE);
  const [topTenTab, setTopTenTab] =
    useState<TopTenVariant>(DEFAULT_TOP_TEN_TAB);

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchInputValue, setSearchInputValue] = useState('');

  const [labels, setLabels] = useState(
    searchParams.get('labels')?.split(',') || [],
  );

  const {
    data: labelsData,
    isLoading: labesDataLoading,
    error: labelsError,
  } = useQuery({
    queryKey: ['labels', searchInputValue],
    queryFn: () => getLables('major_label', searchInputValue),
  });

  const initialLabelsCount = useRef<number | null>(null);

  /* 
    When displaying the total count of labels in the search bar, we want the number
    to be the initial number of labels that were found without filtering the results. 
  */
  useEffect(() => {
    if (
      searchInputValue === '' &&
      labelsData?.length !== undefined &&
      initialLabelsCount.current === null
    ) {
      initialLabelsCount.current = labelsData.length;
    }
  }, [labelsData?.length, searchInputValue]);

  const {
    data: labelPerfData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['label-perf', labels],
    queryFn: () => getLabelPerfReport(labels),
  });

  const {
    data: chartData,
    isLoading: chartLoading,
    isError: chartError,
  } = useQuery({
    queryKey: ['fixes-history', labels, chartTab],
    queryFn: ({ queryKey }) => {
      const [, , histRange] = queryKey;
      return getFixesHistory(labels, histRange as HistRange);
    },
  });

  const {
    data: topTenData,
    isLoading: topTenDataLoading,
    isError: topTenDataError,
  } = useQuery({
    queryKey: ['top-ten', labels, topTenTab],
    queryFn: () => {
      const metric: TopTenType = topTenTab.includes('revenue')
        ? 'revenue'
        : 'streams';
      const order: TopTenOrder = topTenTab.includes('Up') ? 'top' : 'bottom';

      return getTopTenTracks(metric, order, labels);
    },
  });

  const updateSearchParams = useCallback(
    (selectedLabels: string[]) => {
      const params = new URLSearchParams('');

      if (selectedLabels && selectedLabels.length) {
        params.set('labels', selectedLabels.toString());
      }

      setSearchParams(params);
    },
    [setSearchParams],
  );

  const onSearchInputClear = useCallback(() => {
    setSearchInputValue('');
  }, []);

  const onSubmitLabels = useCallback(
    (selectedLabels: string[]) => {
      setTopTenTab(DEFAULT_TOP_TEN_TAB);
      setLabels(selectedLabels);
      updateSearchParams(selectedLabels);
    },
    [updateSearchParams],
  );

  const renderLoadingState = (): JSX.Element => (
    <p>
      <Icon type="spinner" variant="solid" size="lg" className="fa-spin" />
    </p>
  );

  const renderPerformanceCards = (data: LabelPerfModel): JSX.Element => (
    <div className="grid grid-cols-3">
      <Card className="rounded-l-sm border-r bg-level-02 border-emphasis-subtle">
        <Title>{t('body.totalAssets')}</Title>
        <Value className="!text-display-5xl leading-none">
          {formatNumberSuffix(data.totalAssets)}
        </Value>
      </Card>

      <Card className="border-r border-emphasis-subtle">
        <Title className="text-subtle">{t('body.overallMonthlyStreams')}</Title>
        <div>
          <Trend
            direction={getTrendDirection(data.overallMonthlyStreamsChange)}
          >
            {formatNumberSuffix(data.overallMonthlyStreamsChange, 1, false)}
          </Trend>
          <span className="text-[18px] font-normal">
            {t('body.monthComparison', {
              first: getRelMonth(-1),
              second: getRelMonth(-2),
            })}
          </span>
          <Value>{formatNumberSuffix(data.overallMonthlyStreams)}</Value>
        </div>
      </Card>

      <Card className="rounded-r-sm">
        <Title className="text-subtle">{t('body.overallMonthlyRevenue')}</Title>
        <div>
          <Trend
            direction={getTrendDirection(data.overallMonthlyRevenueChange)}
          >
            {'$' +
              formatNumberSuffix(data.overallMonthlyRevenueChange, 1, false)}
          </Trend>
          <span className="text-[18px] font-normal">
            {t('body.monthComparison', {
              first: getRelMonth(-2),
              second: getRelMonth(-3),
            })}
          </span>
          <Value>
            ${formatNumberSuffix(data.overallMonthlyRevenue)}
            <span>
              {' '}
              {t('in')} {getRelMonth(-3)}
            </span>
          </Value>
        </div>
      </Card>
    </div>
  );

  const renderOpportunityCards = (data: LabelPerfModel): JSX.Element => (
    <div className="grid grid-cols-3">
      <Card className="rounded-l-sm border-r bg-primary-default border-emphasis-subtle">
        <Title className="text-white">
          {t('body.yearlyRevenueOpportunity')}
          <Icon
            type="info-circle"
            variant="solid"
            size="xs"
            className="ml-xs"
          />
        </Title>
        <Value>
          ${formatNumberSuffix(data.opportunityRevenueFromFixes)}
          <span className="text-display-sm">/{t('date.year')}</span>
        </Value>
      </Card>

      <Card className="border-r border-emphasis-subtle">
        <Title className="text-subtle">
          {t('body.monthlyStreamsIncrement')}
          <Icon
            type="info-circle"
            variant="solid"
            size="xs"
            className="ml-xs"
          />
        </Title>
        <div>
          <Trend direction={getTrendDirection(data.streamsFromFixesChange)}>
            {formatNumberSuffix(data.streamsFromFixesChange, 1, false)}
          </Trend>
          <span className="text-[18px] font-normal">
            {t('body.monthComparison', {
              first: getRelMonth(-1),
              second: getRelMonth(-2),
            })}
          </span>
          <Value>{formatNumberSuffix(data.streamsFromFixes)}</Value>
        </div>
      </Card>

      <Card className="rounded-r-sm">
        <Title className="text-subtle">
          {t('body.monthlyRevenueIncrement')}
          <Icon
            type="info-circle"
            variant="solid"
            size="xs"
            className="ml-xs"
          />
        </Title>
        <div>
          <Trend direction={getTrendDirection(data.revenueFromFixesChange)}>
            {'$' + formatNumberSuffix(data.revenueFromFixesChange, 1, false)}
          </Trend>
          <span className="text-[18px] font-normal">
            {t('body.monthComparison', {
              first: getRelMonth(-2),
              second: getRelMonth(-3),
            })}
          </span>
          <Value>${formatNumberSuffix(data.revenueFromFixes)}</Value>
        </div>
      </Card>
    </div>
  );

  const renderChartsData = (
    data: LabelPerfModel,
    chartsData: FixesHistValue[] | undefined,
  ): JSX.Element => (
    <div className="mt-lg rounded-sm border px-2xl py-lg bg-level-01 border-emphasis-subtle">
      {/* HEADER */}
      <div className="mb-md flex justify-between">
        <p className="text-body-sm font-bold">
          {t('body.needsReviewAccurate')}
        </p>
        <Tabs
          defaultTab={chartTab}
          items={CHART_TABS}
          loading={chartLoading}
          onChange={(tabItem) => {
            setChartTab(tabItem.id as HistRange);
          }}
          theme="toggler"
        />
      </div>
      {/* INFO */}
      <div className="mb-md grid grid-cols-2 gap-4xl">
        <div>
          <div className="flex">
            <div>
              <p className="text-body-md font-bold text-subtle">
                {t('body.needsReview')}
                <Icon
                  type="magnifying-glass"
                  variant="solid"
                  size="sm"
                  className="ml-sm"
                />
              </p>
              <p>
                <span className="text-display-lg">
                  {formatNumberSuffix(data.totalGaps)}
                </span>
                <span className="ml-sm text-display-sm text-subtle">
                  ({((data.totalGaps / data.totalAssets) * 100).toFixed(2)}%)
                </span>
                <span className="text-subtle">
                  {t('body.ofTotalAssets', {
                    totalAssets: formatNumberSuffix(data.totalAssets),
                  })}
                </span>
              </p>
            </div>
            <div className="ml-auto">
              {chartsData ? (
                <LegendList items={getLegendItems(chartData)} />
              ) : null}
            </div>
          </div>
          {chartsData ? (
            <LegendBar className="mt-sm" items={getLegendItems(chartData)} />
          ) : null}
        </div>
        <div />
      </div>
      {/* CHART CANVAS */}
      <div className="relative">
        <Chart
          className="-ml-md"
          data={chartsData || []}
          isLoading={chartLoading}
          isError={chartError}
        />
      </div>
    </div>
  );

  const renderTopTenTable = (
    data: TrackItem[] | undefined,
    isLoadingTopTen: boolean,
    isErrorTopTen: boolean,
  ): JSX.Element => (
    <div className="mt-lg">
      <Tabs
        items={[
          { id: TopTenCategory.StreamsUp, name: t('body.topTenStreamsGrowth') },
          { id: TopTenCategory.StreamsDown, name: t('body.topTenStreamsLoss') },
          { id: TopTenCategory.RevenueUp, name: t('body.topTenRevenueGrowth') },
          { id: TopTenCategory.RevenueDown, name: t('body.topTenRevenueLoss') },
        ]}
        onChange={(tab) => {
          setTopTenTab(tab.id as TopTenVariant);
        }}
        theme="default"
      />
      <TopTen
        data={data}
        type={topTenTab}
        isLoading={isLoadingTopTen}
        isError={isErrorTopTen}
      />
    </div>
  );

  const isForbidden =
    labelsError instanceof APIError && labelsError.status === 403;
  const noAccess =
    (!searchInputValue && labelsData?.length === 0 && !labesDataLoading) ||
    isForbidden;

  if (noAccess) {
    return <NoAccess />;
  }

  return (
    <>
      <div className="mb-sm flex items-center justify-between">
        <Header />
      </div>
      <div className="max-w-[86em]">
        <Title className="mb-sm text-body-md text-default">
          {t('body.majorLabels')}
        </Title>
        <SearchBar
          data={labelsData || []}
          totalLabelsCount={initialLabelsCount.current || 0}
          isLoading={labesDataLoading}
          inputValue={searchInputValue}
          selectedValues={labels}
          placeholder={t('body.searchLabels')}
          onSubmit={onSubmitLabels}
          onChange={setSearchInputValue}
          onClear={onSearchInputClear}
          aria-label="Search labels by label name"
        />

        {isLoading ? renderLoadingState() : null}
        {isError && !labesDataLoading ? (
          <ErrorDisplay
            title={t('error.failedLoading')}
            description={t('error.refresh')}
            className="min-h-[20em]"
          />
        ) : null}

        {labelPerfData ? (
          <div>
            {/* Header Section */}
            <div className="mb-md flex items-end justify-between">
              <Title className="text-body-lg text-default">
                {t('body.overallPerformance')}
              </Title>
              <p className="text-body-md">
                <Icon type="info-circle" variant="solid" size="sm" />
                <span className="ml-xs">
                  {t('body.streamCountInfo')}{' '}
                  <span className="underline">{t('estimated')}</span>.
                </span>
              </p>
            </div>

            {/* Performance Cards */}
            <div className="mb-2xl overflow-hidden rounded-sm border border-emphasis-subtle">
              {renderPerformanceCards(labelPerfData)}
            </div>

            {/* TOP10 */}
            {renderTopTenTable(topTenData, topTenDataLoading, topTenDataError)}

            {/* Opportunity Section */}
            <Title className="mb-md mt-2xl text-body-lg text-default">
              {t('body.territoryDSPRevenueOpportunities')}
            </Title>

            <div className="overflow-hidden rounded-sm border border-emphasis-subtle">
              {renderOpportunityCards(labelPerfData)}
            </div>

            {/* CHART */}
            {renderChartsData(labelPerfData, chartData)}
          </div>
        ) : null}
      </div>
    </>
  );
}

LabelPerf.displayName = 'LabelPerf';
