import type React from 'react';
import type { Dispatch, SetStateAction } from 'react';
import { createContext } from 'react';

import type { createCollection } from '~/components/Dropdown/utils/createCollection';

import type { DropdownOption } from '../types/DropdownOption';

export interface DropdownContextType {
  collection: (DropdownOption & {
    ref: React.RefObject<HTMLDivElement> | null;
  })[];
  setOptions: Dispatch<SetStateAction<DropdownOption[]>>;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  selected: ReturnType<typeof createCollection>;
  onSelect: (item: string) => void;
  displaySelectedOptions: boolean;
  multiselect: boolean;
  isSelected: (item: string) => boolean;
  inputRef?: React.Ref<HTMLInputElement>;
}

// Using `null as unknown as DropdownContextType` to identify whether the context is initialized
// It prevents hard to identify problems when the context is not used within the provider
export const DropdownContext = createContext<DropdownContextType | null>(null);

DropdownContext.displayName = 'DropdownContext';
