import { useEffect, useState } from 'react';

import type { ComboboxOption } from '../types/ComboboxOption';

type UseSearchProps = {
  /**
   * Current selected option (this needs to stay in options even if it doesn't match the search)
   */
  value?: ComboboxOption[];

  /**
   * Search string
   */
  search?: string;

  /**
   * Callback for search change
   */
  onSearchChange?: (search: string) => void;

  /**
   * Predicate to filter options on the client side
   */
  searchPredicate?: (option: ComboboxOption, search: string) => boolean;

  /**
   * If true, the search will be performed on the client side
   * @default true
   */
  clientSearch?: boolean;

  /**
   * List of options to filter
   */
  options: ComboboxOption[];
};

type UseSearchReturn = {
  /**
   * Current search string
   */
  search: string;

  /**
   * Callback for search change
   */
  setSearch: (search: string) => void;

  /**
   * Filtered options
   */
  options: ComboboxOption[];
};

export const SEARCH_BY_LABEL = <
  ComboboxOptionExtended extends { label: string },
>(
  option: ComboboxOptionExtended,
  search: string,
) => option.label.toLocaleLowerCase().includes(search.toLocaleLowerCase());

const SEARCH_BY_SELECTED = (
  option: ComboboxOption,
  values: ComboboxOption[],
): boolean => {
  return values.some((v) => v.value === option.value);
};

const filterOptions = ({
  searchPredicate,
  options,
  search,
  value,
}: Pick<
  UseSearchProps,
  'searchPredicate' | 'options' | 'search' | 'value'
>): ComboboxOption[] => {
  if (!searchPredicate || !search) {
    return options;
  }
  return options.filter(
    (option) =>
      searchPredicate(option, search) ||
      SEARCH_BY_SELECTED(option, value ?? []),
  );
};

export const useSearch = ({
  search: initialSearch,
  onSearchChange,
  searchPredicate,
  clientSearch,
  options,
  value,
}: UseSearchProps): UseSearchReturn => {
  const [search, setSearch] = useState('');

  useEffect(() => {
    setSearch(initialSearch ?? '');
  }, [initialSearch]);

  const searchChangeHandler = (searchText: string) => {
    setSearch(searchText);
    onSearchChange?.(searchText);
  };

  return {
    search,
    setSearch: searchChangeHandler,
    options: clientSearch
      ? filterOptions({ options, search, searchPredicate, value })
      : options,
  };
};
