import { Icon } from '~/components';
import { cn } from '~/lib/cn';

interface ErrorStateProps {
  title: string;
  description?: string;
  iconType?: string;
  className?: string;
}

const ErrorDisplay = ({
  title,
  description,
  iconType = 'info-circle',
  className,
}: ErrorStateProps): React.ReactNode => {
  return (
    <div
      data-testid="error-display"
      className={cn(
        'flex w-full justify-center rounded-xs p-lg bg-level-02',
        className,
      )}
    >
      <div className="flex w-full items-center justify-center rounded-xs border border-dashed border-emphasis-subtle">
        <div className="text-center">
          <p className="text-body-md text-error-default">
            <Icon type={iconType} variant="light" size="4x" />
          </p>
          <p className="mb-sm mt-lg text-body-lg font-semibold">{title}</p>
          {description ? (
            <p className="text-body-md text-subtle">{description}</p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

ErrorDisplay.displayName = 'ErrorDisplay';

export { ErrorDisplay };
