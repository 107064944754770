import type { ComboboxProps } from '../Combobox';

export const assertMultiSelect = <TMultiselect extends boolean>({
  multiselect,
  value,
}: Pick<ComboboxProps<TMultiselect>, 'multiselect' | 'value'>) => {
  if (!multiselect) {
    return;
  }
  if (!!value && !Array.isArray(value)) {
    throw new Error('value must be an array for multiselect combobox');
  }
};
