import { useTranslation } from 'react-i18next';

import { Icon } from '~/components/Icon';
import { Tooltip } from '~/components/Tooltip';

import type { Role } from '../api/lib/schemas';
import { RoleMap } from '../constants/RoleMap';

const getRole = (roles: Role[]) => {
  const adminRole = roles.find((role) => role === 'LABEL_ADMIN');

  return adminRole || roles[0];
};

export const UserRole = ({ roles = [] }: { roles: Role[] }) => {
  const { t } = useTranslation('settings', { keyPrefix: 'roles' });
  const role = getRole(roles);

  if (!roles.length || !RoleMap[role]) {
    return null;
  }

  const { name, description } = RoleMap[role];

  return (
    <span className="whitespace-nowrap">
      {t(`${name}`)}
      <Tooltip content={t(`${description}`)} side="top" delayDuration={0}>
        <Icon
          type="circle-info"
          variant="light"
          className="ml-sm text-subtlest"
          size="sm"
        />
      </Tooltip>
    </span>
  );
};

UserRole.displayName = 'UserRole';
