import type { ValueFormatterFunc } from 'ag-grid-community';

import { COUNTRIES } from '../../constants/countries';
import type { DashboardRow } from '../../types/dashoard';

// Formats array values into a comma-separated string
export const listFormatter: ValueFormatterFunc<
  Partial<DashboardRow>,
  string[]
> = (p) => {
  const uniqueValues = Array.from(new Set(p.value ?? []));
  return uniqueValues.join(', ');
};

export const numberFormat = (value: number | undefined | null): string => {
  if (value === undefined || value === null) {
    return '--';
  }

  if (Math.abs(value) < 1e-10) {
    return '0';
  }

  const absValue = Math.abs(value);

  let formattedValue: string;

  if (absValue >= 1e6) {
    // If the value is 1 million or more
    formattedValue =
      new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      }).format(value / 1e6) + 'M';
  } else if (absValue >= 1e3) {
    // If the value is 1 thousand or more
    formattedValue =
      new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      }).format(value / 1e3) + 'K';
  } else {
    formattedValue = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  }
  return formattedValue;
};

export const zeroFormatter: ValueFormatterFunc<
  Partial<DashboardRow>,
  number
> = ({ value }) => {
  return numberFormat(value);
};

export const numberFormatDecimal = (
  value: number | undefined | null,
): string => {
  if (value !== undefined && value !== null && Math.abs(value) < 1e-10) {
    return '0';
  } else if (value !== undefined && value !== null) {
    const formattedValue = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(value);
    return formattedValue;
  } else {
    return '--';
  }
};

export const formatPercentage = (value: number | undefined | null): string => {
  if (value !== undefined && value !== null && Math.abs(value) < 1e-10) {
    return '0%';
  } else if (value !== undefined && value !== null) {
    const percentageValue = value * 100;
    const formattedValue = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(percentageValue);
    return `${formattedValue}%`;
  } else {
    return '--';
  }
};

export const dateFormatter: ValueFormatterFunc<
  Partial<DashboardRow>,
  string[]
> = (p) => {
  if (!p.value) {
    return '';
  }
  if (typeof p.value === 'string') {
    return new Date(p.value).toLocaleDateString();
  }
  return '--';
};

export const countryString = (code: string): string => {
  const country = COUNTRIES.find((c) => c.code === code);
  if (!country) {
    return code;
  }
  return country.name;
};

export const countryRenderer = (
  code: string,
  format: 'name' | 'detailed' = 'name',
): JSX.Element => {
  const country = COUNTRIES.find((c) => c.code === code);
  if (!country) {
    return <>{code}</>;
  }

  if (format === 'detailed') {
    return (
      <>
        <span className="">{country.name}</span>
        <span className="text-body-xs"> ({country.code})</span>
      </>
    );
  }

  return <>{country.name}</>;
};

export const autoPluralize = (
  value: number = 0,
  singular: string,
  plural: string,
): string => {
  return Math.abs(value) === 1 ? singular : plural;
};
