export const EXTERNAL_ROLES = [
  { value: 'LABEL_ADMIN', label: 'roles.labelAdmin.name' },
  { value: 'RELEASE_COORDINATOR', label: 'roles.releaseCoordinator.name' },
  { value: 'VIDEO_COORDINATOR', label: 'roles.videoCoordinator.name' },
];

export const INTERNAL_ROLES = [
  { value: 'OPS_ADMIN', label: 'roles.opsAdmin.name' },
  { value: 'RELEASE_MANAGER', label: 'roles.releaseManager.name' },
  { value: 'VIDEO_MANAGER', label: 'roles.videoManager.name' },
  { value: 'SUPPRORT_ADMIN', label: 'roles.supportAdmin.name' },
];

export const LABEL_TYPES = [
  { value: 'all', label: 'all' },
  { value: 'parent_label', label: 'parentLabel' },
  { value: 'sub_label', label: 'sublabel' },
];

export const USER_TYPES = [
  { value: 'all', label: 'all' },
  { value: 'true', label: 'internal' },
  { value: 'false', label: 'external' },
];
