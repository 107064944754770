import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import type { LabelGroupListItem } from '~settings/api/lib/schemas';
import { ActionsMenu } from '~settings/components/ActionsMenu';

export const useLabelGroupsColumns = () => {
  const { t } = useTranslation('settings');
  const navigate = useNavigate();

  return useMemo(() => {
    const columnHelper = createColumnHelper<LabelGroupListItem>();

    const usersColumns = {
      name: columnHelper.accessor((row) => row.name, {
        id: 'name',
        cell: (cell) => cell.getValue(),
        header: () => t('name'),
        enableSorting: true,
      }),
      numberOfLabels: columnHelper.accessor((row) => row.numberOfLabels, {
        id: 'labels',
        cell: (cell) => {
          return cell.getValue() ? cell.getValue() : '-';
        },
        header: () => t('labels'),
        enableSorting: false,
      }),
      numberOfUsers: columnHelper.accessor((row) => row.numberOfUsers, {
        id: 'users',
        cell: (cell) => {
          return cell.getValue() ? cell.getValue() : '-';
        },
        header: () => t('users'),
        enableSorting: false,
      }),
      controls: columnHelper.display({
        id: 'actions',
        cell: ({ row }) => {
          const actions = [
            {
              id: 'edit',
              label: t('labelGroup.editLink'),
              action: () =>
                navigate(`/settings/label-groups/${row.original.id}/edit`),
            },
          ];
          return <ActionsMenu actions={actions} />;
        },
      }),
    };

    return Object.values(usersColumns);
  }, [navigate, t]);
};
