import { camelToSnake, snakeToCamel } from './case';

export function mapPageData<T>(data: Partial<T>[]): Partial<T>[] {
  return data.map((d) => {
    const mapped: { [key: string]: unknown } = {};
    Object.keys(d).forEach((k) => {
      const key = snakeToCamel(k.toLowerCase());
      const val = d[k as keyof typeof d];
      mapped[key] = val;
    });
    return mapped as Partial<T>;
  });
}

export function unmapPageData<T>(data: Partial<T>[]): Partial<T>[] {
  return data.map((d) => {
    const unmapped: { [key: string]: unknown } = {};
    Object.keys(d).forEach((k) => {
      const key = camelToSnake(k);
      const val = d[k as keyof typeof d];
      unmapped[key] = val;
    });
    return unmapped as Partial<T>;
  });
}

export const isValidDate = (strDate: string): boolean => {
  const date = new Date(strDate);
  return date instanceof Date && !isNaN(date as unknown as number);
};
