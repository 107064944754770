import { Route, Routes } from 'react-router-dom';

import { TabsPagesLayout } from '../../components';
import { CreateLabelPage } from './pages/CreateLabelPage';
import { DetailLabelPage } from './pages/DetailLabelPage';
import { ListLabelPage } from './pages/ListLabelPage';

export function LabelRouter() {
  return (
    <Routes>
      <Route path="create" element={<CreateLabelPage />} />
      <Route path=":labelId" element={<DetailLabelPage />} />
      <Route element={<TabsPagesLayout />}>
        <Route path="*" element={<ListLabelPage />} />
      </Route>
    </Routes>
  );
}
LabelRouter.displayName = 'LabelRouter';
