import type { QueryKey } from '@tanstack/react-query';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

interface PaginatedQueryProps<T> {
  queryEnabled?: boolean;
  queryKey: QueryKey;
  queryFn: (params: {
    offset: number;
    limit: number;
  }) => Promise<T> | undefined;
  pageSize: number;
  extractTotalRecords?: (response: T) => number;
}

export const usePaginatedQuery = <T extends { pagination: { total: number } }>({
  queryEnabled = true,
  queryKey,
  queryFn,
  pageSize,
  extractTotalRecords = (data) => data.pagination.total,
}: PaginatedQueryProps<T>) => {
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page');

  const [pageNumber, setPageNumber] = useState(page && +page ? +page : 1);

  const { data, error, isLoading, isFetching } = useQuery({
    enabled: queryEnabled,
    queryKey: [...queryKey, pageNumber],
    queryFn: () =>
      queryFn({
        offset: (pageNumber - 1) * pageSize,
        limit: pageSize,
      }),
    placeholderData: keepPreviousData,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const totalItems = data ? extractTotalRecords(data) : 0;
  const totalPages = Math.ceil(totalItems / pageSize);

  const onNext = () => {
    setPageNumber((prev) => Math.min(prev + 1, totalPages));
  };

  const onPrevious = () => {
    setPageNumber((prev) => Math.max(prev - 1, 1));
  };

  const pageStart = (pageNumber - 1) * pageSize + 1;
  const pageEnd = Math.min(pageNumber * pageSize, totalItems);

  return {
    data,
    error,
    isLoading,
    isFetching,
    totalItems,
    pageNumber,
    setPageNumber,
    totalPages,
    onNext,
    onPrevious,
    pageStart,
    pageEnd,
  };
};
