import type { LinkFarmCategory, LinkFarmLink } from './types';

export const LINK_FARM_CATEGORIES = [
  'wmg1',
  'marketing',
  'data',
  'distribution',
  'finance',
  'publishing',
] as const;

export const LINK_FARM: Record<LinkFarmCategory, LinkFarmLink[]> = {
  wmg1: [
    {
      icon: 'ARTIST_ROSTER',
      category: 'Artist Roster',
      path: '/roster',
      appName: '',
      url: '/roster',
      actionText: 'Artist Roster',
      requestAccess: '',
      requireVpn: true,
      additionalMaterials: '',
      description: '',
    },
  ],
  marketing: [
    {
      icon: 'ACID',
      category: 'Marketing',
      path: '/marketing',
      appName: 'ACID',
      url: 'https://acid.wmg.com',
      actionText: 'Track a marketing campaign',
      requestAccess: 'mailto:acid-support@warnermusic.com',
      requireVpn: true,
      additionalMaterials: '[Internal] Product Guide: How to Use ACID',
      description: '',
    },
    {
      icon: 'AIRTABLE',
      category: 'Marketing',
      path: '/marketing',
      appName: 'Airtable',
      url: 'https://airtable.com/apph3YYri1vmH3L15/pagF4zxT6k5fcG1Vy?N0XEb=sfs8Ltl7UTD8ZI5fZ',
      actionText: 'Manage artist roster',
      requestAccess:
        'https://airtable.com/appXOMlu1K321IZNX/pagkp2IKyPU8MdH2c/form',
      requireVpn: false,
      additionalMaterials: '',
      description: '',
    },
    {
      icon: 'AIRTABLE',
      category: 'Marketing',
      path: '/marketing',
      appName: 'Airtable',
      url: 'https://airtable.com/apph3YYri1vmH3L15/pagn2RZvd8CPfeIJC?WyEcR=allRecords',
      actionText: 'Plan a new release',
      requestAccess:
        'https://airtable.com/appXOMlu1K321IZNX/pagkp2IKyPU8MdH2c/form',
      requireVpn: false,
      additionalMaterials: '',
      description: '',
    },
    {
      icon: 'AIRTABLE',
      category: 'Marketing',
      path: '/marketing',
      appName: 'Airtable',
      url: 'https://airtable.com/apph3YYri1vmH3L15/pagn2RZvd8CPfeIJC?WyEcR=allRecords',
      actionText: 'Plan a catalog release',
      requestAccess:
        'https://airtable.com/appXOMlu1K321IZNX/pagkp2IKyPU8MdH2c/form',
      requireVpn: false,
      additionalMaterials: '',
      description: '',
    },
    {
      icon: 'AIRTABLE',
      category: 'Marketing',
      path: '/marketing',
      appName: 'Airtable',
      url: 'https://airtable.com/apph3YYri1vmH3L15/pagn2RZvd8CPfeIJC?WyEcR=allRecords',
      actionText: 'Plan a marketing campaign',
      requestAccess:
        'https://airtable.com/appXOMlu1K321IZNX/pagkp2IKyPU8MdH2c/form',
      requireVpn: false,
      additionalMaterials: '',
      description: 'Release & Marketing Planning',
    },
    {
      icon: 'WARNER_BLACK',
      category: 'Marketing',
      path: '/marketing',
      appName: 'Warner Connect',
      url: 'https://wmgconnect.com/',
      actionText: 'Run a marketing campaign',
      requestAccess: '',
      requireVpn: false,
      additionalMaterials: 'Product Guide | Warner Connect',
      description: '',
    },
    {
      icon: 'WAVO',
      category: 'Marketing',
      path: '/marketing',
      appName: 'WAVO',
      url: 'https://dashboards.cloud.wavo.me/',
      actionText: 'Monitor digital marketing campaign performance',
      requestAccess: 'mailto:wavo-access@wmg.com',
      requireVpn: false,
      additionalMaterials:
        ' [Internal Only | Confidential] Product Guide: Wavo',
      description: '',
    },
  ],
  data: [
    {
      icon: 'WARNER_BLUE',
      category: 'Data Tools',
      path: '/data',
      appName: 'Amplify',
      url: 'https://amplify.dsc-wmg.com/dashboard/kpi',
      actionText: 'Check label and catalog performance',
      requestAccess: 'mailto:andrew.koltyakov@wmg.com',
      requireVpn: false,
      additionalMaterials: '',
      description: '',
    },
    {
      icon: 'ARTIST_PACK',
      category: 'Data Tools',
      path: '/data',
      appName: 'Artist Packs',
      url: 'https://audience-insights.onrender.com',
      actionText: 'Get artist insights slide deck',
      requestAccess: '',
      requireVpn: false,
      additionalMaterials: 'Product Guide | Artist Pack',
      description: 'Artist Reports',
    },
    {
      icon: 'WARNER_BLACK',
      category: 'Data Tools',
      path: '/data',
      appName: 'GCDMi',
      url: 'https://gcdmreport.prod.wmg.com/gcdmreport/home.jsf',
      actionText: 'Check artist and release data',
      requestAccess:
        'https://warnermusic.service-now.com/servicedesk?id=sc_cat_item&sys_id=1e7797541b6ed1105f0eed74bd4bcb0f&sysparm_category=bd68e12e1b282990994b15ff034bcb8e',
      requireVpn: true,
      additionalMaterials: 'https://wmg.continu.co/login',
      description: '',
    },
    {
      icon: 'LUMINATE',
      category: 'Data Tools',
      path: '/data',
      appName: 'Luminate',
      url: 'https://login.mrc-data.com/',
      actionText: 'Show industry data',
      requestAccess: 'mailto:blake.foster@wmg.com',
      requireVpn: false,
      additionalMaterials: '',
      description: '',
    },
    {
      icon: 'OPUS',
      category: 'Data Tools',
      path: '/data',
      appName: 'Opus',
      url: 'https://opus.wmg.com',
      actionText: 'Check on-roster song, album, and artist performance',
      requestAccess:
        'https://warnermusic.service-now.com/servicedesk?id=sc_cat_item&sys_id=3c9d0922db3663002ea64cd2399619d3',
      requireVpn: false,
      additionalMaterials: 'Product Guide | Opus',
      description: 'Old Reporting Tool that will be deprecated in FY26',
    },
    {
      icon: 'SODATONE',
      category: 'Data Tools',
      path: '/data',
      appName: 'Sodatone',
      url: 'https://app.sodatone.com/',
      actionText: 'Discover artists and track performance',
      requestAccess: 'https://app.sodatone.com/auth/request-access',
      requireVpn: false,
      additionalMaterials:
        'Product Guide | Sodatone, https://app.sodatone.com/university',
      description: 'Artist Discovery & Tracking Career Growth',
    },
    {
      icon: 'TABLEAU',
      category: 'Data Tools',
      path: '/data',
      appName: 'Tableau',
      url: 'https://prod-useast-b.online.tableau.com/#/site/wmg/workbooks/2170947/views',
      actionText: 'Benchmark upcoming and released tracks',
      requestAccess:
        'https://warnermusic.service-now.com/servicedesk?id=sc_cat_item&sys_id=f20c9cdf1bbd18100dac628a2d4bcbe2&sysparm_category=bd68e12e1b282990994b15ff034bcb8e',
      requireVpn: false,
      additionalMaterials: '',
      description: 'Track Reports',
    },
    {
      icon: 'TABLEAU',
      category: 'Data Tools',
      path: '/data',
      appName: 'Tableau',
      url: 'https://prod-useast-b.online.tableau.com/#/site/wmg/views/ListenerLevels/ListenerLevels?:iid=2',
      actionText: 'Track listener levels',
      requestAccess:
        'https://warnermusic.service-now.com/servicedesk?id=sc_cat_item&sys_id=f20c9cdf1bbd18100dac628a2d4bcbe2&sysparm_category=bd68e12e1b282990994b15ff034bcb8e',
      requireVpn: false,
      additionalMaterials: 'Product Guide | Listener Levels',
      description: '',
    },
    {
      icon: 'TABLEAU',
      category: 'Data Tools',
      path: '/data',
      appName: 'Tableau',
      url: 'https://prod-useast-b.online.tableau.com/#/site/wmg/views/MarketingCampaign-ArtistDashboard/MarketingCampaignArtistDashboard?:iid=1',
      actionText: 'Track marketing performance',
      requestAccess: 'mailto:marielouise.letendre@warnermusic.com',
      requireVpn: false,
      additionalMaterials: 'Currently limited to GM only',
      description: '',
    },
    {
      icon: 'TABLEAU',
      category: 'Data Tools',
      path: '/data',
      appName: 'Tableau',
      url: 'https://prod-useast-b.online.tableau.com/#/site/wmg/views/DRAFTUGCCreations/MultiplePartners-TrendingTracksbyUGCCreations?:iid=4',
      actionText: 'Show UGC creation data',
      requestAccess:
        'https://warnermusic.service-now.com/servicedesk?id=sc_cat_item&sys_id=f20c9cdf1bbd18100dac628a2d4bcbe2&sysparm_category=bd68e12e1b282990994b15ff034bcb8e',
      requireVpn: false,
      additionalMaterials: '',
      description: '',
    },
    {
      icon: 'TABLEAU',
      category: 'Data Tools',
      path: '/data',
      appName: 'Tableau',
      url: 'https://prod-useast-b.online.tableau.com/#/site/wmg/views/TikTokMusicTastemakersandInfluencers/FullList/6a163ef5-afb6-4661-8162-74a58e4f65d6/60dead49-1a2b-42a3-aed5-335018f7b6c2?:iid=5',
      actionText: 'Show TikTok influencer data',
      requestAccess:
        'https://warnermusic.service-now.com/servicedesk?id=sc_cat_item&sys_id=f20c9cdf1bbd18100dac628a2d4bcbe2&sysparm_category=bd68e12e1b282990994b15ff034bcb8e',
      requireVpn: false,
      additionalMaterials: '',
      description: '',
    },
    {
      icon: 'TABLEAU',
      category: 'Data Tools',
      path: '/data',
      appName: 'Tableau',
      url: 'https://prod-useast-b.online.tableau.com/#/site/wmg/views/MarketOpportunityExplorer/Trends',
      actionText: 'View current Global and Regional Genre Trends',
      requestAccess: '',
      requireVpn: false,
      additionalMaterials: '',
      description: '',
    },
  ],
  distribution: [
    {
      icon: 'ADA_HUB',
      category: 'Distribution & Operations',
      path: '/distribution',
      appName: 'ADA Hub',
      url: 'https://partners.ada-music.com/',
      actionText: 'Manage ADA releases and claim videos',
      requestAccess: 'mailto:coop-application-support@wmg.com',
      requireVpn: false,
      additionalMaterials: 'Co-Op Help Center',
      description: '',
    },
    {
      icon: 'WARNER_BLACK',
      category: 'Distribution & Operations',
      path: '/distribution',
      appName: 'Art Track Generator & Ringtone Robot',
      url: 'https://oas-proxy.wmg.cloud/cam/automated_ringtone_pkg.ringtone_search',
      actionText: 'Manage ringtones',
      requestAccess:
        'https://warnermusic.service-now.com/servicedesk?id=sc_cat_item&sys_id=f09f97a21baf7510696155392a4bcb6a',
      requireVpn: true,
      additionalMaterials: '',
      description: '',
    },
    {
      icon: 'WARNER_BLACK',
      category: 'Distribution & Operations',
      path: '/distribution',
      appName: 'DDCM',
      url: 'https://csa-proxy.wmg.cloud/ddcm/campaigns/campaign_search.htm',
      actionText: 'Manage release campaign prices',
      requestAccess:
        'https://warnermusic.service-now.com/servicedesk?id=sc_cat_item&sys_id=f09f97a21baf7510696155392a4bcb6a',
      requireVpn: true,
      additionalMaterials: '',
      description: 'Manage prices for Digital release campaigns',
    },
    {
      icon: 'WARNER_BLACK',
      category: 'Distribution & Operations',
      path: '/distribution',
      appName: 'DDCM',
      url: 'https://csa-proxy.wmg.cloud/ddcmpricing/pricechange/dpcproduct_search.htm',
      actionText: 'Manage digital release prices',
      requestAccess:
        'https://warnermusic.service-now.com/servicedesk?id=sc_cat_item&sys_id=f09f97a21baf7510696155392a4bcb6a',
      requireVpn: true,
      additionalMaterials: '',
      description: 'Manage prices for Digital releases',
    },
    {
      icon: 'WARNER_BLACK',
      category: 'Distribution & Operations',
      path: '/distribution',
      appName: 'DDCM',
      url: 'https://csa-proxy.wmg.cloud/takedown/digitalrelease/digital_release_search.htm',
      actionText: 'Take down digital releases',
      requestAccess:
        'https://warnermusic.service-now.com/servicedesk?id=sc_cat_item&sys_id=f09f97a21baf7510696155392a4bcb6a',
      requireVpn: true,
      additionalMaterials: '',
      description: 'Takedown Digital releases',
    },
    {
      icon: 'WARNER_BLACK',
      category: 'Distribution & Operations',
      path: '/distribution',
      appName: 'GCDM',
      url: 'https://gcdm.prod.wmg.com/gcdm/home.jsf',
      actionText: 'Update release information',
      requestAccess:
        'https://warnermusic.service-now.com/servicedesk?id=sc_cat_item&sys_id=1e7797541b6ed1105f0eed74bd4bcb0f&sysparm_category=bd68e12e1b282990994b15ff034bcb8e',
      requireVpn: true,
      additionalMaterials: 'https://wmg.continu.co/login',
      description: '',
    },
    {
      icon: 'WARNER_BLACK',
      category: 'Distribution & Operations',
      path: '/distribution',
      appName: 'Legacy DPPS',
      url: 'https://oas-proxy.wmg.cloud/dmd/dpps_control_panel.main',
      actionText: 'Manage delivery to DSPs',
      requestAccess:
        'https://warnermusic.service-now.com/servicedesk?id=sc_cat_item&sys_id=f09f97a21baf7510696155392a4bcb6a',
      requireVpn: true,
      additionalMaterials: '',
      description:
        'DSP delivery management (from legacy system) - app will be deprecated in 2025',
    },
    {
      icon: 'WARNER_BLUE',
      category: 'Distribution & Operations',
      path: '/distribution',
      appName: 'LIW',
      url: 'https://party-liw.wmg.com/',
      actionText: 'Update artist data',
      requestAccess:
        'https://warnermusic.service-now.com/servicedesk?id=sc_cat_item&sys_id=f09f97a21baf7510696155392a4bcb6a',
      requireVpn: false,
      additionalMaterials: '',
      description: '',
    },
    {
      icon: 'WARNER_BLACK',
      category: 'Distribution & Operations',
      path: '/distribution',
      appName: 'MARS',
      url: 'https://mars-proxy.wmg.cloud/',
      actionText: 'Manage UGC and sample clip policies\n',
      requestAccess:
        'https://warnermusic.service-now.com/servicedesk?id=sc_cat_item&sys_id=f09f97a21baf7510696155392a4bcb6a',
      requireVpn: true,
      additionalMaterials: '',
      description:
        'Manage policies for Sample Clips & User Generated Content (UGC)',
    },
    {
      icon: 'WARNER_BLACK',
      category: 'Distribution & Operations',
      path: '/distribution',
      appName: 'MLS',
      url: 'https://oas-proxy.wmg.cloud/cam/library_search_pkg.search',
      actionText: 'Manage WMG archive library assets',
      requestAccess:
        'https://warnermusic.service-now.com/servicedesk?id=sc_cat_item&sys_id=f09f97a21baf7510696155392a4bcb6a',
      requireVpn: true,
      additionalMaterials: '',
      description: "Manage assets within WMG's archive library.",
    },
    {
      icon: 'WARNER_BLUE',
      category: 'Distribution & Operations',
      path: '/distribution',
      appName: 'Mothership',
      url: 'https://mothership.wmg.cloud/',
      actionText: 'Distribute a release',
      requestAccess:
        'https://warnermusic.service-now.com/servicedesk?id=sc_cat_item&sys_id=f59ebba31b141e14696155392a4bcbda&sysparm_category=bd68e12e1b282990994b15ff034bcb8e',
      requireVpn: true,
      additionalMaterials: '',
      description: 'Digital Supply Chain',
    },
    {
      icon: 'WARNER_BLACK',
      category: 'Distribution & Operations',
      path: '/distribution',
      appName: 'Mothership',
      url: 'https://mothership.wmg.cloud/orders',
      actionText: 'Manage delivery to DSPs',
      requestAccess:
        'https://warnermusic.service-now.com/servicedesk?id=sc_cat_item&sys_id=f09f97a21baf7510696155392a4bcb6a',
      requireVpn: true,
      additionalMaterials: '',
      description:
        'DSP delivery management (from legacy system) - app will be deprecated in 2025',
    },
    {
      icon: 'WARNER_BLACK',
      category: 'Distribution & Operations',
      path: '/distribution',
      appName: 'NR Maintenance',
      url: 'https://oas-proxy.wmg.cloud/newrelease/new_releasemaint_pkg.mainmenu',
      actionText: 'Manage NRS label settings',
      requestAccess:
        'https://warnermusic.service-now.com/servicedesk?id=sc_cat_item&sys_id=f09f97a21baf7510696155392a4bcb6a',
      requireVpn: true,
      additionalMaterials: '',
      description: 'NRS Label Settings Management',
    },
    {
      icon: 'WARNER_BLACK',
      category: 'Distribution & Operations',
      path: '/distribution',
      appName: 'NRCS',
      url: 'https://oas-proxy.wmg.cloud/newrelease/newrel.main',
      actionText: 'Show release schedule',
      requestAccess:
        'https://warnermusic.service-now.com/servicedesk?id=sc_cat_item&sys_id=f09f97a21baf7510696155392a4bcb6a',
      requireVpn: true,
      additionalMaterials: '',
      description: 'Release scheduling visibility (read-only) (DSP scheduling)',
    },
    {
      icon: 'WARNER_BLACK',
      category: 'Distribution & Operations',
      path: '/distribution',
      appName: 'NRS',
      url: 'https://oas-proxy.wmg.cloud/newrelease/new_release_pkg.mainmenu',
      actionText: 'Manage release reschedules',
      requestAccess:
        'https://warnermusic.service-now.com/servicedesk?id=sc_cat_item&sys_id=f09f97a21baf7510696155392a4bcb6a',
      requireVpn: true,
      additionalMaterials: '',
      description: 'Release scheduling management (DSP scheduling)',
    },
    {
      icon: 'WARNER_BLACK',
      category: 'Distribution & Operations',
      path: '/distribution',
      appName: 'Product Data Management Docs',
      url: 'https://wmg.continu.co/explore?category=5ae8cae54e9b670010a3ea5c',
      actionText: 'Learn how to manage WMG data',
      requestAccess: 'mailto:mdhq.support@warnermusic.com',
      requireVpn: false,
      additionalMaterials: '',
      description: '',
    },
    {
      icon: 'WARNER_BLUE',
      category: 'Distribution & Operations',
      path: '/distribution',
      appName: 'Reports on RDR/SDR',
      url: 'https://oas-proxy.wmg.cloud/cam/REPORT_REQUEST_PKG.main',
      actionText: 'Run internal operations reports',
      requestAccess:
        'https://warnermusic.service-now.com/servicedesk?id=sc_cat_item&sys_id=f09f97a21baf7510696155392a4bcb6a',
      requireVpn: true,
      additionalMaterials: '',
      description:
        'Run internal Operations reports & request order creation for SRR & RDR ad-hoc deliveries. Single Resource Release = an ISRC based ERN delivery which contains just one ISRC / track. Recording Data & Rights = an ISRC based delivery for Neighboring Rights.',
    },
    {
      icon: 'WARNER_BLUE',
      category: 'Distribution & Operations',
      path: '/distribution',
      appName: 'Reports on Schedule',
      url: 'https://reports-ui-proxy.wmg.cloud/',
      actionText: 'Run internal production schedule reports.',
      requestAccess:
        'https://warnermusic.service-now.com/servicedesk?id=sc_cat_item&sys_id=f09f97a21baf7510696155392a4bcb6a',
      requireVpn: true,
      additionalMaterials: '',
      description: 'Run internal production schedule reports.',
    },
    {
      icon: 'SERVICE_NOW',
      category: 'Distribution & Operations',
      path: '/distribution',
      appName: 'ServiceNow',
      url: 'https://warnermusic.service-now.com/servicedesk?id=sc_cat_item&sys_id=1b2421841b061d105f0eed74bd4bcb7a',
      actionText: 'Add an artist for distribution',
      requestAccess: '',
      requireVpn: false,
      additionalMaterials: '',
      description: '',
    },
    {
      icon: 'SERVICE_NOW',
      category: 'Distribution & Operations',
      path: '/distribution',
      appName: 'ServiceNow',
      url: 'https://dscsupport.wmg.com/',
      actionText: 'Submit a ticket for distribution support',
      requestAccess: '',
      requireVpn: false,
      additionalMaterials: '',
      description:
        'Get support with DSC tools. Also another link for getting access to tools like SUN: https://warnermusic.service-now.com/servicedesk?id=sc_category&sys_id=bd68e12e1b282990994b15ff034bcb8e ',
    },
    {
      icon: 'SUN',
      category: 'Distribution & Operations',
      path: '/distribution',
      appName: 'SUN',
      url: 'https://sun.dsc-wmg.com/',
      actionText: 'Upload images, music, and videos',
      requestAccess:
        'https://warnermusic.service-now.com/servicedesk?id=sc_cat_item&sys_id=f09f97a21baf7510696155392a4bcb6a',
      requireVpn: false,
      additionalMaterials: 'Secure Upload Network (SUN) User Guide',
      description: '',
    },
  ],
  finance: [
    {
      icon: 'WARNER_BLACK',
      category: 'Finance',
      path: '/finance',
      appName: 'RAD | Salesforce',
      url: 'https://warnermusicgroup.lightning.force.com/lightning/page/home',
      actionText: 'Show rights from contracts',
      requestAccess:
        'https://warnermusic.service-now.com/servicedesk?id=sc_cat_item&sys_id=bc546174db0749100577e415ca9619c7&sysparm_category=bd68e12e1b282990994b15ff034bcb8e',
      requireVpn: false,
      additionalMaterials: 'RAD Tech 360',
      description: '',
    },
    {
      icon: 'WARNER_BLACK',
      category: 'Finance',
      path: '/finance',
      appName: 'Royalties Portal',
      url: 'https://artistroyalties.wmg.com',
      actionText: 'View recorded music artist statements',
      requestAccess:
        "mailto:ashely.oliver@wmg.com?subject=Royalty%20Portal%20Access%20Request&body=Hi%20there%2C%0A%0AI%20would%20like%20access%20to%20the%20Royalty%20Portal.%20Here%20are%20my%20details%3A%0A%0AReason%20I%20need%20access%3A%20%0ATerritory%20(e.g.%20Mexico)%3A%20%0AOrg%20(e.g.%20WM%20or%20ADA)%3A%20%0AAccess%20Type%20Required%20(e.g.%20Admin%20or%20Admin%20Viewer)%3A%20%0AIs%20there%20a%20colleague's%20access%20we%20can%20mirror%3F%3A",
      requireVpn: false,
      additionalMaterials: '',
      description: '',
    },
    {
      icon: 'WARNER_BLACK',
      category: 'Finance',
      path: '/finance',
      appName: 'Royalties Portal - Admin',
      url: 'https://art-admin.wmg.com/home',
      actionText: 'Manage access to recorded music artist statements',
      requestAccess:
        "mailto:ashely.oliver@wmg.com?subject=Royalty%20Portal%20Access%20Request&body=Hi%20there%2C%0A%0AI%20would%20like%20access%20to%20the%20Royalty%20Portal.%20Here%20are%20my%20details%3A%0A%0AReason%20I%20need%20access%3A%20%0ATerritory%20(e.g.%20Mexico)%3A%20%0AOrg%20(e.g.%20WM%20or%20ADA)%3A%20%0AAccess%20Type%20Required%20(e.g.%20Admin%20or%20Admin%20Viewer)%3A%20%0AIs%20there%20a%20colleague's%20access%20we%20can%20mirror%3F%3A",
      requireVpn: true,
      additionalMaterials: '',
      description: '',
    },
    {
      icon: 'CHAPPELL',
      category: 'Finance',
      path: '/finance',
      appName: 'WCM Client Portal',
      url: 'https://www.mywarnerchappell.com',
      actionText: 'Manage songwriter payments',
      requestAccess:
        'https://warnermusic.service-now.com/servicedesk?id=sc_cat_item&sys_id=f09f97a21baf7510696155392a4bcb6a',
      requireVpn: false,
      additionalMaterials: 'Product Guide Portal Home/Payment Dash Changes 2',
      description: '',
    },
  ],
  publishing: [
    {
      icon: 'CHAPPELL',
      category: 'Publishing',
      path: '/publishing',
      appName: 'ADM/WCM Center',
      url: 'http://wcmcenter.com/servlet/Login',
      actionText: 'Manage US archives',
      requestAccess:
        'https://warnermusic.service-now.com/servicedesk?id=sc_cat_item&sys_id=f09f97a21baf7510696155392a4bcb6a',
      requireVpn: true,
      additionalMaterials: '',
      description: 'WCM US Archive Document Management system',
    },
    {
      icon: 'ARROW',
      category: 'Publishing',
      path: '/publishing',
      appName: 'ARROW',
      url: 'https://arrow.warnerchappell.com',
      actionText: 'Pitch song demos',
      requestAccess: 'https://arrow.warnerchappell.com/registration',
      requireVpn: false,
      additionalMaterials: 'ARROW User Guide V2 .pdf',
      description:
        'Pitch demos of songs by WCM Clients to get recorded by labels (internal + external)',
    },
    {
      icon: 'CHAPPELL_GOLD',
      category: 'Publishing',
      path: '/publishing',
      appName: 'Client Portal',
      url: 'https://www.mywarnerchappell.com/user-management/clients',
      actionText: 'Manage songwriter portal',
      requestAccess:
        'https://warnermusic.service-now.com/servicedesk?id=sc_cat_item&sys_id=f09f97a21baf7510696155392a4bcb6a',
      requireVpn: false,
      additionalMaterials: '',
      description:
        'Financial reporting, analytics, and catalog source of truth for songwriters',
    },
    {
      icon: 'TABLEAU',
      category: 'Publishing',
      path: '/publishing',
      appName: 'Data Studio | Tableau',
      url: 'https://prod-useast-b.online.tableau.com/#/site/wcmprod/home',
      actionText: 'Analyze statement and revenue reports',
      requestAccess:
        'https://warnermusic.service-now.com/servicedesk?id=sc_cat_item&sys_id=f20c9cdf1bbd18100dac628a2d4bcbe2&sysparm_category=bd68e12e1b282990994b15ff034bcb8e',
      requireVpn: false,
      additionalMaterials: '',
      description:
        'Contains key reports on processed statements, revenue & expenses by deal, royalty calculations, and more',
    },
    {
      icon: 'GALLERY',
      category: 'Publishing',
      path: '/publishing',
      appName: 'Gallery',
      url: 'https://gallery.warnerchappell.com/#/hierarchies',
      actionText: 'Show assets, graphics, icons, fonts, guides and more',
      requestAccess:
        'https://warnermusic.service-now.com/servicedesk?id=sc_cat_item&sys_id=8c90e0681b9115105f0eed74bd4bcbe4&sysparm_category=bd68e12e1b282990994b15ff034bcb8e',
      requireVpn: false,
      additionalMaterials: '',
      description:
        'Contains WCM assets., graphics, icons, fonts, guides and more.',
    },
    {
      icon: 'CHAPPELL',
      category: 'Publishing',
      path: '/publishing',
      appName: 'Global Match',
      url: 'https://globalmatch.wmg.com/home',
      actionText: 'Match and claim songs',
      requestAccess:
        'https://forms.monday.com/forms/d8061633058c4adfd5a1a892f4f9832b?r=use1',
      requireVpn: true,
      additionalMaterials: 'Product Guide | Global Match Portal',
      description:
        "Match list of songs to WCM's catalogue. Generally used for claiming.",
    },
    {
      icon: 'WARNER_BLUE',
      category: 'Publishing',
      path: '/publishing',
      appName: 'GLS',
      url: 'https://sync.wmg.com/',
      actionText: 'Manage song synchronization rights',
      requestAccess:
        'https://warnermusic.service-now.com/servicedesk?id=sc_cat_item&sys_id=b0cf2db81b8c1e10696155392a4bcb57&sysparm_category=bd68e12e1b282990994b15ff034bcb8e',
      requireVpn: true,
      additionalMaterials: '',
      description: 'Pitch/License songs for synchronization ',
    },
    {
      icon: 'MONDAY',
      category: 'Publishing',
      path: '/publishing',
      appName: 'Monday.com',
      url: 'https://wmg.monday.com',
      actionText: 'Manage songwriter deals',
      requestAccess: '',
      requireVpn: false,
      additionalMaterials: '',
      description:
        "Makeshift new deal tracking for A&R don't have a full fledged CRM or pipeline",
    },
    {
      icon: 'MONDAY',
      category: 'Publishing',
      path: '/publishing',
      appName: 'Monday.com',
      url: 'https://wmg.monday.com/boards/5806791860',
      actionText: 'Show global songwriter charts',
      requestAccess: '',
      requireVpn: false,
      additionalMaterials: '',
      description:
        'Global collection of charts. WCM owned songs highlighted and market share analysis.',
    },
    {
      icon: 'CHAPPELL_GOLD',
      category: 'Publishing',
      path: '/publishing',
      appName: 'Production Music',
      url: 'https://www.warnerchappellpm.com/',
      actionText: 'Pitch music for synchronization',
      requestAccess: 'https://www.warnerchappellpm.com/user/signup',
      requireVpn: false,
      additionalMaterials: '',
      description:
        'Used by hundreds of clients to search for Production Music for synchronization into TV, Film, Advertising,  Video Games and more!',
    },
    {
      icon: 'SONG_RADAR',
      category: 'Publishing',
      path: '/publishing',
      appName: 'Song Radar',
      url: 'https://songradar.wmg.com/',
      actionText: 'Find new songwriters',
      requestAccess: '',
      requireVpn: true,
      additionalMaterials: '',
      description:
        'Lead generation and deal optimization for signing new songwriters',
    },
    {
      icon: 'WARNER_BLUE',
      category: 'Publishing',
      path: '/publishing',
      appName: 'Tango',
      url: 'https://musicpublishing.wmg.com/#/',
      actionText: 'Show publishing metadata and process royalties',
      requestAccess:
        'https://warnermusic.service-now.com/servicedesk?id=sc_cat_item&sys_id=f09f97a21baf7510696155392a4bcb6a',
      requireVpn: true,
      additionalMaterials: '',
      description:
        'Contains publishing metadata across deals/ works/ clients\nTool for works registration and royalty processing',
    },
  ],
};
