import { useTranslation } from 'react-i18next';

import { Tag } from '~/components';
import { Avatar } from '~/components/Avatar/Avatar';
import { IconButton } from '~/components/IconButton/IconButton';
import { formatUtcDate } from '~/modules/artist-roster/components/TrackDetails/utils';
import { TRACK_SOCIALS_ORDER } from '~/modules/artist-roster/lib/constants';
import type {
  ArtistDetails,
  AssetFamily,
} from '~/modules/artist-roster/lib/types';

import placeHolderImage from './images/track_placeholder.svg?url';

export interface TrackHeaderProps {
  artistData?: ArtistDetails;
  trackData: AssetFamily;
}

export function TrackHeader({ artistData, trackData }: TrackHeaderProps) {
  const { t } = useTranslation('roster');

  if (!artistData) {
    return null;
  }

  // @TODO WMG1-246 Replace mock data with data from track family API call
  const mainArtist = artistData?.name || '';
  const featuredArtists = [
    { name: 'Joel Corry', image: artistData?.image },
    { name: 'Sacha', image: artistData?.image },
  ];
  const date = '2024-10-28';

  return (
    <>
      <div className="flex w-full items-stretch justify-center gap-2xl">
        <img
          src={trackData.image ? trackData.image : placeHolderImage}
          alt={trackData.description}
          className="size-[9.25rem] object-cover"
        />

        <div className="flex grow flex-col items-start justify-center space-y-sm text-body-sm">
          <div className="inline-flex items-center">
            <h1 className="text-body-xl font-bold">{trackData.title}</h1>
            <Tag className="ml-md" variant="secondary">
              {t('trackDetails.versions', { count: 4 })}
            </Tag>
          </div>

          <p className="inline-flex content-center items-center font-semibold">
            <span className="inline-flex">
              {artistData?.image ? (
                <img
                  src={artistData.image}
                  alt={mainArtist}
                  className="mr-sm size-lg rounded-full"
                />
              ) : (
                <span className="mr-sm inline-flex">
                  <Avatar name={mainArtist} size="sm" variant="base" />
                </span>
              )}
              <span className="content-center">{mainArtist}</span>
            </span>
            <span
              className="mx-md cursor-default text-emphasis-strong"
              role="separator"
              aria-orientation="vertical"
            >
              •
            </span>
            {featuredArtists.map(({ name, image }) => (
              <span key={name} className="mr-md inline-flex">
                {image ? (
                  <img
                    src={image}
                    alt={name}
                    className="mr-sm size-lg rounded-full"
                  />
                ) : (
                  <span className="mr-sm inline-flex">
                    <Avatar name={name} size="sm" variant="base" />
                  </span>
                )}
                <span className="content-center">{name}</span>
              </span>
            ))}
          </p>

          <p>
            {t('trackDetails.release')}{' '}
            <span className="font-semibold">{formatUtcDate(date)}</span>
          </p>
          <div className="flex items-center justify-center gap-sm">
            {TRACK_SOCIALS_ORDER.map((social) => {
              return (
                <div key={`${social}`} className="flex items-center gap-md">
                  <div className="rounded-full outline outline-1 bg-emphasis-default outline-emphasis-subtle">
                    <IconButton
                      iconType={social}
                      className=""
                      iconVariant="brands"
                      size="medium"
                      asLink={true}
                      linkTo="https://google.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label={social}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

TrackHeader.displayName = 'TrackHeader';
