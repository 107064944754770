import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useLabelList } from '~/modules/settings/api/queries/labelList';
import { useSearch } from '~/modules/settings/hooks/useSearch';
import { useSort } from '~/modules/settings/hooks/useSort';
import type { LabelListItem } from '~settings/api/lib/schemas';
import { ListControls } from '~settings/components/ListControls';
import { Table } from '~settings/components/Table';
import { SETTINGS_ROUTES } from '~settings/config/routes';
import { usePagination } from '~settings/hooks/usePagination';

import { useSublabelsColumns } from './hooks/useSublabelsColumns';

const labelEmptyArray: LabelListItem[] = [];

interface SublabelProps {
  labelId?: string;
  parentLabelId?: string;
}

export const Sublabels = ({ parentLabelId }: SublabelProps) => {
  const columns = useSublabelsColumns();

  const { t } = useTranslation('settings');
  const { page, setPageNumber, perPage, skip, take } = usePagination();
  const { sorting, setSorting, sortParams } = useSort();

  const { searchTerm } = useSearch();

  const navigate = useNavigate();
  const onClick = (row: LabelListItem) => {
    navigate(SETTINGS_ROUTES.LABEL_DETAIL(row.id));
  };

  const { labelList, isFetching } = useLabelList({
    skip,
    take,
    labelType: 'sub_label',
    parentId: parentLabelId,
    ...sortParams,
    ...(searchTerm && { search: searchTerm }),
  });

  return (
    <>
      <ListControls
        placeholder={t('searchLabels')}
        page={page}
        total={labelList?.total ?? 0}
        perPage={perPage}
        entity="sublabels"
        setPageNumber={setPageNumber}
      />

      <Table
        data={labelList?.data ?? labelEmptyArray}
        columns={columns}
        rowClassName="odd:bg-level-03"
        onRowClick={onClick}
        sort={{
          sorting,
          onSortingChange: setSorting,
        }}
        loading={isFetching}
      />
    </>
  );
};

Sublabels.displayName = 'Sublabels';
