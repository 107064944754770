export const arrayUniqueByKey = <T extends object>(
  array: T[],
  key: keyof T,
): T[] => {
  const seen: T[] = [];
  return array.filter((item) => {
    if (seen.some((seenItem) => seenItem[key] === item[key])) {
      return false;
    }
    seen.push(item);
    return true;
  });
};

export const arrayUnique = <T>(array: T[]): T[] => {
  return Array.from(new Set(array));
};
