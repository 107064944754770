import '@okta/okta-signin-widget/css/okta-sign-in.min.css';
import '../styles/ada-okta-override.css';

import type { Tokens } from '@okta/okta-auth-js';
import type {
  EventContext,
  EventErrorContext,
  WidgetOptions,
} from '@okta/okta-signin-widget';
import OktaSignIn from '@okta/okta-signin-widget';
import { useContext, useEffect, useMemo, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AdaLogo } from '~/components/AdaLogo';
import { BrandContext } from '~/modules/branding';
import type { Brand } from '~/modules/branding/types';

import { getOktaConfig } from '../lib/okta-config';

interface OktaSignInWidgetProps {
  config?: Partial<WidgetOptions>;
  onError?: (...args: unknown[]) => void;
  onSuccess?: (tokens: Tokens) => void;
}

function buildOktaWidget(brand: Brand, overrides?: Partial<WidgetOptions>) {
  const widget = new OktaSignIn(getOktaConfig(brand, overrides));

  widget.on('afterRender', function (context: EventContext) {
    if (context.controller === 'mfa-verify') {
      const pushButton = document.querySelector(
        '.o-form-button-bar',
      ) as HTMLInputElement;
      pushButton?.classList.add('large-margin');
      const headerText = document.querySelector('.okta-form-title');

      if (!pushButton || !headerText) {
        return;
      }

      if (
        !headerText.nextElementSibling ||
        headerText.nextElementSibling.tagName.toLowerCase() !== 'p'
      ) {
        // Verify and Device name both served in single innerHTTML
        const innerText = headerText.textContent?.split(' ');
        if (!innerText || innerText.length < 2) {
          return;
        }
        const phoneName = innerText[1].replace(/^\(|\)$/g, '');

        const phoneSpan = `<span id="device-name"><p>${phoneName}</p></span>`;

        headerText.textContent = innerText[0];

        headerText.insertAdjacentHTML('afterend', phoneSpan);
      }

      // what will happen once a user clicks the "Send Push button"
      pushButton.addEventListener('click', () => {
        document.getElementById('device-name')?.remove();
        pushButton.classList.add('hide-button');
        headerText.textContent = 'Push Sent'; // localization?

        const parentForm = headerText.parentElement;
        if (!parentForm) {
          return;
        }
        const existingIcon = parentForm.querySelector('i');

        if (!existingIcon) {
          const iconElement = document.createElement('i');
          iconElement.classList.add('far', 'fa-check-circle', 'center-green');
          parentForm.insertBefore(iconElement, parentForm.firstChild);
        }
      });

      const enterCode = document.querySelector('[data-se="inline-totp-add"]');

      enterCode?.addEventListener('click', () => {
        const linkButton = document.querySelector('.inline-totp-verify');
        linkButton?.classList.add('button-primary');
        linkButton?.classList.remove('margin-top-30');
      });
    }
  });

  return widget;
}

export function OktaSignInWidget({
  config = {},
  onError,
  onSuccess,
}: OktaSignInWidgetProps) {
  const widgetRef = useRef<HTMLDivElement>(null);
  const brand = useContext(BrandContext);
  const widget = useMemo(() => buildOktaWidget(brand, config), [brand, config]);
  const navigate = useNavigate();
  const { t } = useTranslation('auth');

  useEffect(() => {
    if (!widgetRef.current) {
      return;
    }

    widget
      .showSignInToGetTokens({
        // el wants a string for some reason, but it can take a ref
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        el: widgetRef.current,
      })
      .then(onSuccess)
      .catch(onError);

    widget.on(
      'afterError',
      function (context: EventContext, error: EventErrorContext) {
        if (
          context.controller === 'password-reset' &&
          error.name === 'OAUTH_ERROR' &&
          error.message ===
            'The client specified not to prompt, but the client app requires re-authentication or MFA.'
        ) {
          navigate('/');
        }
      },
    );
    return () => widget.remove();
  }, [config, onError, onSuccess, navigate, widget]);

  return (
    <>
      <Helmet>
        <title>{t('oktaSignIn.title')}</title>
      </Helmet>
      <div className="absolute left-0 top-0 mt-xl flex w-full justify-center px-2xl md:block">
        <AdaLogo className="h-xl text-white" />
      </div>
      <div className="light flex items-center">
        <div ref={widgetRef} />
      </div>
    </>
  );
}

OktaSignInWidget.displayName = 'OktaSignInWidget';
