import type { LabelData, LabelDataResponse } from './types';

const DEFAULT_LABEL_LIMIT = 100;
export const getLabels = async ({
  name = '',
  limit = DEFAULT_LABEL_LIMIT,
  offset = 0,
}: {
  name?: string;
  limit?: number;
  offset?: number;
}) => {
  try {
    const response = await fetch(
      `/api/roster/labels?name=${encodeURIComponent(name)}&limit=${limit}&offset=${offset}`,
    );

    if (!response.ok) {
      throw new Error(`Failed to fetch labels: ${response.statusText}`);
    }

    const data: LabelDataResponse = await response.json();
    return data.items.map((item) => {
      const lastRoster = item.rosters?.find(
        (roster) => roster.roster_type === 1,
      );
      return {
        labelId: item.label_id,
        name: item.name,
        updatedAt: lastRoster?.updated_at ?? '',
        updatedBy: lastRoster?.updated_by ?? '',
      } as LabelData;
    });
  } catch (error) {
    console.error('Error fetching labels:', error);
    throw error;
  }
};

export const getLabelHierarchy = async (hasRosters = true) => {
  try {
    const response = await fetch(
      '/api/roster/label-hierarchies?has_rosters=' + hasRosters,
    );

    if (!response.ok) {
      throw new Error(`Failed to fetch labels: ${response.statusText}`);
    }

    return response.json();
  } catch (error) {
    console.error('Error fetching labels:', error);
    throw error;
  }
};
