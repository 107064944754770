interface SelectedLabelsCounterProps {
  selectedCount: number;
  totalCount: number;
  label: string;
}

const SelectedLabelsCounter = ({
  selectedCount,
  totalCount,
  label,
}: SelectedLabelsCounterProps): JSX.Element => {
  const selectedLabelsText = selectedCount
    ? `${selectedCount} / ${totalCount}`
    : `${totalCount} (All)`;

  return (
    <p data-testid="selected-labels-counter">
      {label}: <span>{selectedLabelsText}</span>
    </p>
  );
};

SelectedLabelsCounter.displayName = 'SelectedLabelsCounter';

export { SelectedLabelsCounter };
