import { useTranslation } from 'react-i18next';

import { Icon, Separator, Tag, Tooltip } from '~/components';
import type { LabelDetail } from '~settings/api/lib/schemas';
import { HeaderSmall } from '~settings/components/HeaderSmall';

interface DistributionTerritoriesProps {
  distributionGroups: LabelDetail['distributionGroups'];
}

export const DistributionTerritories = ({
  distributionGroups,
}: DistributionTerritoriesProps) => {
  const { t } = useTranslation('settings');

  if (!distributionGroups?.length) {
    return null;
  }

  return (
    <div>
      <Separator className="my-lg" />
      <HeaderSmall>{t('distributionTerritories')}</HeaderSmall>
      <ul className="flex flex-wrap gap-x-xs gap-y-sm">
        {distributionGroups.map((group) => {
          const countries = group.distributionGroupCountries
            .reduce<string[]>((acc, { country }) => {
              if (country?.name?.length) {
                acc.push(country.name);
              }
              return acc;
            }, [])
            .join(', ');

          return (
            <Tag key={group.id} variant="secondary" size="lg" asChild>
              <li>
                <span>{group.name}</span>
                {countries.length ? (
                  <Tooltip content={countries} side="top" delayDuration={0}>
                    <Icon
                      type="circle-info"
                      variant="light"
                      className="ml-xs text-subtlest"
                      size="md"
                    />
                  </Tooltip>
                ) : null}
              </li>
            </Tag>
          );
        })}
      </ul>
    </div>
  );
};

DistributionTerritories.displayName = 'DistributionTerritories';
