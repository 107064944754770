import type React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';

import { Separator } from '~/components/Separator';
import { env } from '~/lib/env';
import { hasSettingsEnabled } from '~/lib/navigationPermissions';
import { useUser } from '~/modules/auth/hooks/useUser';
import { BrandContext } from '~/modules/branding';
import { HoverIconItem } from '~/modules/navigation/components/Sidebar/parts/HoverIconItem';
import { BRANDS } from '~/shared/lib/brands';
import { dataTestIds } from '~/test-utils/dataTestIds';

const SUPPORT_URL = 'https://warnermusic.service-now.com/adahub?id=coop_faq';
export const WMG_FEEDBACK_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLSefQ1_QjxKfrYjKxX0fDwgOr8iwGy8ZH0wIAO6Gz6HlU0YvJw/viewform?usp=sf_link';
export const WMG_SUPPORT_URL =
  'https://docs.google.com/document/d/1jSWAS6HTCA68ccCRBOqo7MXHVYatskeqj8OzrUk8Qko/';

interface SidebarPopoverContentProps {
  name: string;
  onItemClick?: (event?: React.MouseEvent<HTMLElement>) => void;
}

export const SidebarPopoverContent: React.FC<SidebarPopoverContentProps> = ({
  name,
  onItemClick,
}) => {
  const isVideoManagement = useMatch('/video-management/*');
  const brand = useContext(BrandContext);
  const { features } = useUser();
  const canAccessSettings = hasSettingsEnabled(features);
  const { t } = useTranslation('navigation', {
    keyPrefix: 'sidebar.popover',
  });

  return (
    <div
      className="mb-lg flex h-auto w-[15rem] animate-fade flex-col content-center justify-between gap-md rounded-xs border-px border-solid border-opacity-subtle p-lg bg-level-02 border-default text-white"
      data-testid={dataTestIds.USER_PROFILE_POPOVER_MENU}
    >
      <p className="text-headline-sm">{name}</p>
      <Separator className="m-0 w-full" />
      {brand.name !== BRANDS.WMG ? (
        <>
          <HoverIconItem
            icon="user"
            text={t('profile')}
            to="/my-profile"
            data-testid={dataTestIds.USER_VIEW_PROFILE_BUTTON}
            onClick={onItemClick}
          />
          {env('VITE_CONTAINER_PROFILE_ENABLED', false) === 'true' &&
          canAccessSettings ? (
            <HoverIconItem
              icon="user"
              text={t('myProfileNew')}
              to="/my-profile-2"
              data-testid={dataTestIds.USER_VIEW_NEW_PROFILE_BUTTON}
              onClick={onItemClick}
            />
          ) : null}
          <HoverIconItem
            icon="circle-question"
            text={t('support')}
            to={SUPPORT_URL}
            target="_blank"
            rel="noopener noreferrer"
            onClick={onItemClick}
          />
          {!isVideoManagement ? (
            <HoverIconItem
              icon="comment-lines"
              text={t('feedback')}
              to="#feedback_modal"
              data-testid="feedback"
            />
          ) : null}
        </>
      ) : (
        <>
          <HoverIconItem
            icon="comment-lines"
            text={t('feedback')}
            to={WMG_FEEDBACK_URL}
            target="_blank"
            rel="noopener noreferrer"
          />
          <HoverIconItem
            icon="circle-question"
            text={t('support')}
            to={WMG_SUPPORT_URL}
            target="_blank"
            rel="noopener noreferrer"
          />
        </>
      )}

      <Separator className="m-0 w-full" />
      <HoverIconItem
        icon="right-from-bracket"
        text={t('signOut')}
        to="/logout"
        data-testid={dataTestIds.USER_SIGN_OUT_BUTTON}
      />
    </div>
  );
};

SidebarPopoverContent.displayName = 'SidebarPopoverContent';
