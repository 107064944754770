import type { TabItem } from '../containers/LabelPerf/components/Tabs';
import type { HistRange } from '../types/label-perf';

export const HIST_RANGE: Record<HistRange, number> = {
  week: 7,
  month: 30,
  year: 365,
};

export const CHART_TABS: TabItem[] = [
  { id: 'year', name: '12M' },
  { id: 'month', name: '30D' },
  { id: 'week', name: '7D' },
];

export const DEFAULT_TOP_TEN_TAB = 'streamsUp';
export const DEFAULT_CHART_RANGE = 'year';
