import { format, parseISO, subDays } from 'date-fns';

import { HIST_RANGE } from '../constants/label-perf';
import type { HistRange } from '../types/label-perf';

export const getDates = (range: HistRange): string[] => {
  return Array.from({ length: HIST_RANGE[range] }, (_, i) => {
    return format(subDays(new Date(), i), 'yyyy-MM-dd');
  }).reverse();
};

export const dateToName = (date: string, range: HistRange): string => {
  const d = parseISO(date);

  const formatterOptions: Intl.DateTimeFormatOptions = {
    timeZone: 'UTC',
    day: range === 'week' || range === 'month' ? '2-digit' : undefined,
    month: range === 'week' ? 'short' : '2-digit',
    year: range === 'year' ? '2-digit' : undefined,
  };

  const formatter = new Intl.DateTimeFormat('en-US', formatterOptions);

  return formatter.format(d);
};
