import type { FC, PropsWithChildren } from 'react';

import { Icon } from '~/components';
import type { IconVariant } from '~/components/Icon';

type ResultSectionProps = PropsWithChildren & {
  iconType: string;
  iconVariant?: IconVariant;
  title: string;
};

export const ResultSection: FC<ResultSectionProps> = ({
  iconType,
  iconVariant = 'light',
  title,
  children,
}) => {
  return (
    <div
      className="flex flex-col border-b-px border-opacity-subtle pb-md pt-sm border-white last:border-0 last:pb-0"
      role="group"
    >
      <div className="mb-md flex flex-row items-center">
        <Icon
          className="pr-sm"
          type={iconType}
          aria-label={iconType}
          variant={iconVariant}
          size="sm"
          aria-hidden
        />
        <h4 className="text-body-sm font-semibold">{title}</h4>
      </div>
      {children}
    </div>
  );
};

ResultSection.displayName = 'ResultSection';
