import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { InternalChips } from '~/components/FilterChip/InternalChip';
import { useUserList } from '~/modules/settings/api/queries/userList';
import { FilterDrawer } from '~/modules/settings/components/Filter';
import { USER_TYPES } from '~/modules/settings/constants/Filter';
import { useFilters } from '~/modules/settings/hooks/useFilters';
import { usePagination } from '~/modules/settings/hooks/usePagination';
import { useSearch } from '~/modules/settings/hooks/useSearch';
import { useSort } from '~/modules/settings/hooks/useSort';
import { UsersFilterForm } from '~/modules/settings/modules/user/components/UserFilter/UserFormFilter';
import type { UserListItem } from '~settings/api/lib/schemas';
import { HeaderSmall } from '~settings/components/HeaderSmall';
import { ListControls } from '~settings/components/ListControls';
import { Table } from '~settings/components/Table';
import { SETTINGS_ROUTES } from '~settings/config/routes';

import { useUsersColumns } from './hooks/useUsersColumns';

const userEmptyArray: UserListItem[] = [];

interface UsersProps {
  isSublabel: boolean;
  labelId?: string;
  total: number;
}

export const Users = ({ isSublabel, labelId, total }: UsersProps) => {
  const { t } = useTranslation('settings');
  const columns = useUsersColumns(labelId);
  const navigate = useNavigate();

  const { sorting, setSorting, sortParams } = useSort();
  const { searchTerm } = useSearch();
  const { page, setPageNumber, perPage, skip, take } = usePagination();
  const { filterData, drawerOpen, closeDrawer, openDrawer } = useFilters();

  const { userList, isFetching } = useUserList({
    skip,
    take,
    labelIds: labelId,
    ...sortParams,
    ...filterData,
    ...(searchTerm && { search: searchTerm }),
  });

  const onClick = (row: UserListItem) => {
    navigate(SETTINGS_ROUTES.USER_DETAIL(row.id));
  };

  return (
    <>
      {isSublabel ? (
        <HeaderSmall>
          {t('labelDetails.usersCount', {
            usersCount: total,
          })}
        </HeaderSmall>
      ) : null}
      <ListControls
        placeholder={t('user.search')}
        page={page}
        total={userList?.total ?? 0}
        perPage={perPage}
        entity="users"
        onFilterClick={openDrawer}
        internalFilter={
          <InternalChips options={USER_TYPES} path="isInternal" />
        }
        setPageNumber={setPageNumber}
      />

      <Table
        data={userList?.data ?? userEmptyArray}
        columns={columns}
        rowClassName="odd:bg-level-03"
        onRowClick={onClick}
        sort={{
          sorting,
          onSortingChange: setSorting,
        }}
        loading={isFetching}
      />
      <FilterDrawer open={drawerOpen} onOpenChange={closeDrawer}>
        <UsersFilterForm open={drawerOpen} onApply={closeDrawer} />
      </FilterDrawer>
    </>
  );
};

Users.displayName = 'LabelUsers';
