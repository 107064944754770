import { useCallback, useEffect, useMemo, useState } from 'react';

import type { DropdownOption } from '~/components/Dropdown/types/DropdownOption';
import { createCollection } from '~/components/Dropdown/utils/createCollection';

import { DropdownContext } from './DropdownContext';

export interface DropdownContextProviderProps<
  TMultiselect extends boolean | undefined,
> {
  children: React.ReactNode;
  options: DropdownOption[];
  /**
   * If set to false, only "10 selected" will be shown
   * If set to true, all tags will be shown with the ability to remove them
   * Only works when `multiselect` is true
   */
  displaySelectedOptions?: TMultiselect extends true ? boolean : never;
  multiselect?: TMultiselect;
  value?: TMultiselect extends true ? string[] : string;
  onChange?: (selected: TMultiselect extends true ? string[] : string) => void;
  inputRef?: React.Ref<HTMLInputElement>;
}

export const DropdownContextProvider = <
  TMultiselect extends boolean | undefined,
>({
  children,
  options: initialOptions,
  multiselect,
  displaySelectedOptions,
  onChange,
  value: initialValue,
  inputRef,
}: DropdownContextProviderProps<TMultiselect>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState<DropdownOption[]>([]);
  const collection = useMemo(() => createCollection(options), [options]);
  const [selected, setSelected] = useState<string[]>([]);

  useEffect(() => {
    if (multiselect) {
      setSelected((initialValue as string[]) ?? []);
    } else {
      setSelected(initialValue ? [initialValue as string] : []);
    }
  }, [initialValue, multiselect]);

  useEffect(() => {
    setOptions(initialOptions);
    setSelected((selectedValues) =>
      selectedValues.filter((item) =>
        initialOptions.some(({ value }) => value === item),
      ),
    );
  }, [initialOptions]);

  const isSelected = useCallback(
    (item: string) => selected.includes(item),
    [selected],
  );

  const onSelect = useCallback(
    (item: string) => {
      if (multiselect) {
        setSelected((prev) => {
          let newVal;
          if (prev.includes(item)) {
            newVal = prev.filter((i) => i !== item);
          } else {
            newVal = [...prev, item];
          }
          (onChange as (selected: string[]) => void)?.(newVal);
          return newVal;
        });
      } else {
        setSelected([item]);
        (onChange as (selected: string) => void)?.(item);
        setIsOpen(false);
      }
    },
    [multiselect, onChange],
  );

  const providerValue = {
    collection,
    setOptions,
    isOpen,
    setIsOpen,
    selected: collection.filter(({ value }) => selected.includes(value)),
    onSelect,
    multiselect: Boolean(multiselect),
    displaySelectedOptions:
      Boolean(multiselect) && Boolean(displaySelectedOptions),
    isSelected,
    inputRef,
  };

  return (
    <DropdownContext.Provider value={providerValue}>
      {children}
    </DropdownContext.Provider>
  );
};

DropdownContextProvider.displayName = 'DropdownContextProvider';
