import { useCallback } from 'react';

import { Icon } from '~/components/Icon';
import { IconButton } from '~/components/IconButton';
import { cn } from '~/lib/cn';

interface ArtistSearchBarProps {
  inputValue?: string;
  placeholder: string;
  onSubmit: () => void;
  onChange: (value: string) => void;
  onClear: () => void;
  className?: string;
}

const ArtistSearchBar = ({
  inputValue,
  placeholder,
  onSubmit,
  onChange,
  onClear,
  className = '',
}: ArtistSearchBarProps) => {
  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      onChange(event.target.value);
    },
    [onChange],
  );

  return (
    <form
      className={cn(
        'flex items-center',
        'bg-emphasis-default',
        'outline',
        'outline-1',
        'outline-emphasis-subtle',
        'w-full',
        'py-sm',
        'px-sm',
        'gap-sm',
        'rounded-sm',
        'focus-within:bg-emphasis-strong',
        'focus-within:outline-emphasis-stronger',
        'hover:bg-emphasis-strong',
        'hover:outline-emphasis-strong',
        'hover:focus-within:outline-emphasis-stronger',
        className,
      )}
      role="search"
      aria-label="Search artists by name"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <Icon
        className={cn(
          inputValue ? 'text-default' : 'text-subtle',
          'py-md',
          'px-sm',
        )}
        type="search"
        variant="light"
        size="sm"
        aria-hidden
      />
      <input
        className={cn(
          'bg-transparent',
          'focus:outline-none',
          'grow',
          'italic',
          'text-default',
          'text-body-sm',
        )}
        placeholder={placeholder}
        value={inputValue}
        onChange={handleInputChange}
        aria-label="Search input for artist name"
      />
      {inputValue ? (
        <IconButton
          type="button"
          iconType="circle-x"
          size="small"
          onClick={() => {
            onClear();
          }}
          aria-label="Clear search input"
        />
      ) : null}
    </form>
  );
};

ArtistSearchBar.displayName = 'ArtistSearchBar';

export { ArtistSearchBar };
