import { ArtistCrossOverTooltip } from './ArtistCrossOverTooltip';

export const ArtistCrossOverIcon = () => {
  return (
    <ArtistCrossOverTooltip>
      <span
        data-testid="artist-cross-over-icon"
        className="rounded-xs px-sm py-xs text-center text-body-xs font-semibold bg-tertiary-default text-strong-on-light"
      >
        C
      </span>
    </ArtistCrossOverTooltip>
  );
};

ArtistCrossOverIcon.displayName = 'ArtistCrossOverIcon';
