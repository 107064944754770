import type { FieldValues } from 'react-hook-form';
import { useController } from 'react-hook-form';

import type { ToggleProps } from '~/components/Toggle';
import { Toggle } from '~/components/Toggle';

import type { FormFieldProps } from '../../FormField';
import { FormField } from '../../FormField';

type FormFieldToggleProps<TFormFields extends FieldValues> = Omit<
  FormFieldProps<TFormFields>,
  'children'
> &
  Omit<ToggleProps, 'name' | 'className'>;

export const FormFieldToggle = <TFormFields extends FieldValues>({
  name,
  label,
  className,
  ...toggleProps
}: FormFieldToggleProps<TFormFields>) => {
  const { field } = useController({ name, disabled: toggleProps.disabled });

  return (
    <FormField name={name} label={label} className={className}>
      <Toggle
        {...field}
        pressed={field.value}
        onPressedChange={field.onChange}
        {...toggleProps}
      />
    </FormField>
  );
};

FormFieldToggle.displayName = 'FormFieldToggle';
