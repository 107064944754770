import { cloneElement, type ReactElement } from 'react';
import { Flyout, type FlyoutProps } from 'victory';

const OUTER_CIRCLE_RADIUS = 3;
const INNER_CIRCLE_RADIUS = 2;
const CURSOR_Y1 = '5%';
const CURSOR_Y2 = '85%';

interface LineGraphFlyoutProps extends FlyoutProps {
  datum?: {
    x: string | number;
    y: string | number;
    label?: string;
  };
  lineColor: string;
  customFlyoutComponent?: ReactElement | undefined;
}

const LineGraphFlyout = (props: LineGraphFlyoutProps) => {
  const { lineColor, customFlyoutComponent, ...rest } = props;
  const x = props.x ?? 0;
  const y = props.y ?? 0;

  let flyoutComponent;
  if (customFlyoutComponent) {
    // Pass current datapoint to custom flyout component
    const customComponentWithDatum = cloneElement(customFlyoutComponent, {
      ...rest,
      datum: props.datum,
    });

    flyoutComponent = (
      <foreignObject x={x + 4} y={y} width={1} height={1} overflow="visible">
        {customComponentWithDatum}
      </foreignObject>
    );
  } else {
    flyoutComponent = <Flyout {...rest} />;
  }

  return (
    <g>
      {customFlyoutComponent || props.datum?.label ? flyoutComponent : null}
      <circle
        cx={x}
        cy={y}
        r={OUTER_CIRCLE_RADIUS}
        stroke={lineColor}
        fill="none"
      />
      <circle cx={x} cy={y} r={INNER_CIRCLE_RADIUS} fill={lineColor} />
      <line x1={x} x2={x} y1={CURSOR_Y1} y2={CURSOR_Y2} stroke={lineColor} />
    </g>
  );
};

LineGraphFlyout.displayName = 'LineGraphFlyout';
export { LineGraphFlyout };
