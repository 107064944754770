import type { AuthState, UserClaims } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import {
  type PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { ErrorPage as ErrorScreen } from '~/components/ErrorPage';
import { convertCoopLocale } from '~/i18n';
import { env } from '~/lib/env';
import type { User } from '~/modules/auth';
import type { UserResponse } from '~/modules/auth/lib/bff-client';
import { bffClient } from '~/modules/auth/lib/bff-client';
import { BrandContext } from '~/modules/branding';
import { useShowNav } from '~/modules/navigation';
import { LoadingScreen } from '~/modules/navigation/components/LoadingScreen';

import type { UserProfile } from '../types';
import { WmgSessionContext } from './WmgSessionContext';

const getUserProfile = (authState?: AuthState | null): UserProfile => {
  const claims = authState?.accessToken?.claims as Partial<
    UserClaims<UserProfile>
  >;

  const {
    businessUnit = '',
    jobFamilyGroup = '',
    team = '',
    label = '',
    title = '',
    countryCode = '',
    organization = '',
    company = '',
    userType = '',
    description = undefined,
    department = undefined,
  } = claims || {};

  return {
    businessUnit,
    jobFamilyGroup,
    team,
    label,
    title,
    countryCode,
    organization,
    company,
    userType,
    description,
    department,
  };
};

const getDefaultUser = ({
  user,
  authState,
}: {
  user?: Partial<User>;
  authState?: AuthState | null;
}) => {
  return {
    name: user?.name ?? '',
    email: user?.email ?? '',
    permissions: user?.permissions ?? {},
    isInternal: user?.isInternal ?? false,
    features: user?.features ?? [],
    profile: getUserProfile(authState),
  };
};

const WmgSessionProvider = ({ children }: PropsWithChildren) => {
  const { authState, oktaAuth } = useOktaAuth();
  const brand = useContext(BrandContext);
  const [error, setError] = useState<string | undefined>();
  const [user, setUser] = useState<UserResponse | undefined>();
  const { setShowNav, setShowByLocale } = useShowNav();
  const [userInfo, setUserInfo] = useState<User>(getDefaultUser({}));

  const useContainerNav =
    env('VITE_USE_CONTAINER_NAVIGATION', false) === 'true';
  const bffGracefulFallback =
    env('VITE_BFF_GRACEFUL_FALLBACK', false) === 'true';
  const [authAttempted, setAuthAttempted] = useState(false);

  const oktaToken = authState?.accessToken?.accessToken;
  const oktaUserId = authState?.accessToken?.claims?.uid as string;

  const authenticateUser = useCallback(
    async (token?: string, userId?: string) => {
      if (!token || !userId) {
        return;
      }

      setError(undefined);

      if (useContainerNav) {
        try {
          const fetchedUser = await bffClient.getUser(token);
          const fetchedUserInfo = await bffClient.getUserInfo(token, userId);

          setUser(fetchedUser);
          setShowByLocale(convertCoopLocale(fetchedUserInfo.userLocale));

          // We know that for a WMG user the fetchedUser comes back with empty
          // values (except userLocale). So get the user info from the Okta
          // token instead.
          if (brand.name === 'WMG') {
            const oktaUser = await oktaAuth.getUser();

            setUserInfo((prevState) => ({
              ...prevState,
              name: oktaUser.name ?? '',
              email: oktaUser.email ?? '',
              isInternal: true, // WMG users are always internal.
            }));
          } else {
            setUserInfo((prevState) => ({
              ...prevState,
              name: fetchedUser.name,
              email: fetchedUserInfo.email,
              permissions: fetchedUser.permissions,
              features: fetchedUserInfo.userFeatures,
              isInternal: fetchedUserInfo.isInternal,
            }));
          }
        } catch (e) {
          if (brand.name === 'ADA' && bffGracefulFallback) {
            setShowNav(false);
          } else {
            setError((e as Error).message || 'Unexpected error');
            setShowNav(true);
          }
        } finally {
          setAuthAttempted(true);
        }
      } else {
        setShowNav(false);
        setAuthAttempted(true);
      }
    },
    [
      bffGracefulFallback,
      brand.name,
      oktaAuth,
      setShowByLocale,
      setShowNav,
      useContainerNav,
    ],
  );

  useEffect(() => {
    authenticateUser(oktaToken, oktaUserId);
  }, [authenticateUser, oktaToken, oktaUserId]);

  if (error) {
    return (
      <ErrorScreen
        message={error}
        onRetry={() => authenticateUser(oktaToken, oktaUserId)}
      />
    );
  }

  if (
    authState?.accessToken?.accessToken &&
    !user &&
    useContainerNav &&
    !authAttempted
  ) {
    return <LoadingScreen />;
  }

  return (
    <WmgSessionContext.Provider
      value={{
        user: getDefaultUser({ user: userInfo, authState }),
        token: oktaToken ?? '',
      }}
    >
      {children}
    </WmgSessionContext.Provider>
  );
};

WmgSessionProvider.displayName = 'WmgSessionProvider';

export { WmgSessionContext, WmgSessionProvider };
