import type { PropsWithChildren } from 'react';

export const AuthLayout = ({ children }: PropsWithChildren) => {
  return (
    <div className="flex min-h-screen w-full items-center justify-center bg-brand bg-no-repeat md:justify-start md:px-2xl">
      {children}
    </div>
  );
};

AuthLayout.displayName = 'AuthLayout';
