import { Close } from '@radix-ui/react-dialog';
import type { PropsWithChildren } from 'react';

import { IconButton } from '~/components/IconButton';

export function DrawerHeader({ children }: PropsWithChildren) {
  return (
    <header className="relative mb-lg px-md pt-lg">
      {children}

      <Close
        asChild
        className="absolute right-sm top-md"
        aria-label="Close Drawer"
      >
        <IconButton iconType="xmark" size="xlarge" />
      </Close>
    </header>
  );
}
DrawerHeader.displayName = 'DrawerHeader';
