import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import type { LinkItem } from '../../lib/types';
import { ResultSection } from '../ResultSection';

export type TracksListProps = {
  items: LinkItem[];
};

export const TracksList: FC<TracksListProps> = ({ items }) => {
  const { t } = useTranslation('wmgone');

  if (!items.length) {
    return null;
  }

  return (
    <ResultSection
      iconType="music"
      title={t('globalSearch.result.tracks.title')}
    >
      {t('notImplemented')}
    </ResultSection>
  );
};

TracksList.displayName = 'TracksList';
