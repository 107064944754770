import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { useAnalytics } from '~/modules/monitoring/amplitude';
import { PageButtons } from '~/modules/pagination/PageButtons';

import { useArtistTracksQuery } from '../../hooks/useArtistTracksQuery';
import type { ArtistDetails } from '../../lib/types';
import { DEFAULT_PAGE_SIZE } from '../constants';
import { TrackTable } from './TrackTable';

interface TrackListProps {
  uaid: string;
  artistData?: ArtistDetails;
}

export const TrackList = ({ uaid, artistData }: TrackListProps) => {
  const { t } = useTranslation('roster');
  const { trackAnalytics } = useAnalytics();
  const {
    data: tracksData,
    error,
    isLoading: isLoadingTracks,
    isFetching: isFetchingTracks,
    totalItems: totalTracks,
    pageNumber,
    totalPages,
    onNext,
    onPrevious,
    pageStart,
    pageEnd,
  } = useArtistTracksQuery({ uaid });

  const setSearchParams = useSearchParams()[1];

  useEffect(() => {
    const params = new URLSearchParams('');
    if (pageNumber > 1) {
      params.set('page', pageNumber.toString());
    }
    setSearchParams(params);
  }, [pageNumber, setSearchParams]);

  if (!artistData) {
    return null;
  }

  if (error) {
    return <div>{t('trackList.errors.loadingTracks')}</div>;
  }

  const trackTableMeta = {
    artistName: artistData.name,
    filtersCount: 0,
    filtersApplied: false,
    uaid,
  };

  const onPreviousPageButtonClick = () => {
    onPrevious();
    trackAnalytics('artistTrackTablePaginationClick', {
      artist_id: artistData.id,
      artist_name: artistData.name,
      direction: 'previous_page',
    });
  };

  const onNextPageButtonClick = () => {
    onNext();
    trackAnalytics('artistTrackTablePaginationClick', {
      artist_id: artistData.id,
      artist_name: artistData.name,
      direction: 'next_page',
    });
  };

  return (
    <div className="relative w-full">
      <div className="my-md inline-flex min-h-xl w-full items-center justify-between">
        <div className="flex gap-xs">
          {t('trackList.header.title')}
          {!isLoadingTracks ? (
            <p className="text-subtle">({totalTracks})</p>
          ) : null}
        </div>
        {totalTracks > DEFAULT_PAGE_SIZE ? (
          <div id="right">
            <div className="inline-flex items-center">
              <span
                className="text-nowrap pr-sm text-body-sm font-semibold text-disabled"
                aria-live="polite"
              >
                {t('trackList.pagination.label', {
                  start: pageStart,
                  end: pageEnd,
                  total: totalTracks,
                  type: t('trackList.pagination.type'),
                })}
              </span>
              <PageButtons
                currentPage={pageNumber}
                totalPages={totalPages}
                onPrevious={onPreviousPageButtonClick}
                onNext={onNextPageButtonClick}
              />
            </div>
          </div>
        ) : null}
      </div>
      <TrackTable
        data={tracksData?.items ?? []}
        meta={trackTableMeta}
        artistData={artistData}
        isLoadingTracks={isLoadingTracks}
        isFetchingTracks={isFetchingTracks}
      />
    </div>
  );
};

TrackList.displayName = 'TrackList';
