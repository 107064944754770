import type { CheckedState } from '@radix-ui/react-checkbox';

import type { CheckboxTreeItem } from './types.ts';

export const calculateChecked = (treeItem: CheckboxTreeItem): CheckedState => {
  if (!treeItem?.children) {
    return Boolean(treeItem?.checked);
  }

  let hasChecked = false;
  let hasUnchecked = false;
  const filteredChildren = treeItem.children.filter((item) => item.isVisible);
  for (const item of filteredChildren) {
    const isChecked = calculateChecked(item);

    if (isChecked === 'indeterminate') {
      return 'indeterminate';
    }

    if (isChecked) {
      hasChecked = true;
    } else {
      hasUnchecked = true;
    }

    if (hasChecked && hasUnchecked) {
      return 'indeterminate';
    }
  }
  return hasChecked;
};

export const applyTreeValue = (
  value: boolean,
  treeItem: CheckboxTreeItem,
): CheckboxTreeItem => {
  if (treeItem.children) {
    const updatedChildren = treeItem.children.map((item) =>
      applyTreeValue(value, item),
    );

    return { ...treeItem, children: updatedChildren };
  }

  return { ...treeItem, checked: value && treeItem.isVisible };
};

export function searchCheckboxTree(
  items: CheckboxTreeItem[],
  keyword: string,
): CheckboxTreeItem[] {
  if (keyword.length < 2) {
    return [];
  }
  const lowerKeyword = keyword.toLowerCase();
  let filteredItems: CheckboxTreeItem[] = [];

  items.forEach((item) => {
    const words = item.label.split(/\s+/);
    if (words.some((word) => word.toLowerCase().startsWith(lowerKeyword))) {
      filteredItems.push({
        id: item.id,
        label: item.label,
        checked: item.checked,
        isExpanded: true,
        isVisible: true,
      });
    }

    if (item.children) {
      filteredItems = filteredItems.concat(
        searchCheckboxTree(item.children, keyword),
      );
    }
  });

  return filteredItems.slice(0, 5);
}

export function searchCheckboxTreeWithStructure(
  items: CheckboxTreeItem[],
  keyword: string,
): CheckboxTreeItem[] {
  // If keyword is empty, mark all as search results
  if (keyword.length === 0) {
    return items.map((item) => ({
      ...item,
      isVisible: true,
      isExpanded: false,
      children: item.children
        ? searchCheckboxTreeWithStructure(item.children, keyword)
        : undefined,
    }));
  }

  const lowerKeyword = keyword.toLowerCase();
  const searchTerms = lowerKeyword.split(/\s+/);
  const filteredItems: CheckboxTreeItem[] = [];

  for (const item of items) {
    const itemWords = item.label.toLowerCase().split(/\s+/);
    const isMatched = searchTerms.some((term) =>
      itemWords.some((word) => word.startsWith(term)),
    );

    const newItem: CheckboxTreeItem = {
      id: item.id,
      label: item.label,
      checked: item.checked,
      isVisible: isMatched,
      isExpanded: isMatched,
    };

    if (item.children && item.children.length > 0) {
      const filteredChildren = searchCheckboxTreeWithStructure(
        item.children,
        keyword,
      );

      newItem.isVisible =
        filteredChildren.some((ic) => ic.isVisible) || isMatched;
      newItem.isExpanded = newItem.isVisible;
      newItem.children = filteredChildren;
    }
    filteredItems.push(newItem);
  }

  return filteredItems;
}

export function flattenCheckboxTree(
  items: CheckboxTreeItem[],
): CheckboxTreeItem[] {
  let result: CheckboxTreeItem[] = [];

  items.forEach((item) => {
    result.push({
      id: item.id,
      label: item.label,
      checked: item.checked,
      isVisible: item.isVisible,
      isExpanded: item.isExpanded,
    });

    if (item.children) {
      result = result.concat(flattenCheckboxTree(item.children));
    }
  });

  return result;
}

export function isAnyItemChecked(items: CheckboxTreeItem[]): boolean {
  for (const item of items) {
    if (item.checked) {
      return true;
    }

    if (item.children && isAnyItemChecked(item.children)) {
      return true;
    }
  }
  return false;
}

export function toggleItemByLabel(
  items: CheckboxTreeItem[],
  id: string,
): CheckboxTreeItem[] {
  return items.map((item) => {
    if (item.id === id) {
      return { ...item, checked: !item.checked };
    }

    if (item.children) {
      return { ...item, children: toggleItemByLabel(item.children, id) };
    }
    return item;
  });
}

export function checkAllItems(items: CheckboxTreeItem[]): CheckboxTreeItem[] {
  return items.map((item) => ({
    ...item,
    checked: item.isVisible ? true : item.checked,
    children: item.children ? checkAllItems(item.children) : undefined,
  }));
}

export function uncheckAllItems(items: CheckboxTreeItem[]): CheckboxTreeItem[] {
  return items.map((item) => ({
    ...item,
    checked: item.isVisible ? false : item.checked,
    children: item.children ? uncheckAllItems(item.children) : undefined,
  }));
}

export function findCheckboxTreeItemById(
  items: CheckboxTreeItem[],
  id: string,
): CheckboxTreeItem | undefined {
  for (const item of items) {
    if (item.id === id) {
      return item;
    }
    if (item.children) {
      const found = findCheckboxTreeItemById(item.children, id);
      if (found) {
        return found;
      }
    }
  }
  return undefined;
}
