import type { ReactNode } from 'react';
import type { FieldValues, Path } from 'react-hook-form';
import { useController } from 'react-hook-form';

import { cn } from '~/lib/cn';

import { FieldMessage } from '../FieldMessage';
import { Label } from '../Label';
import { assertNonEmptyName } from './assertions/nonEmptyName';

export interface FormFieldProps<TFieldValues extends FieldValues> {
  name: Path<TFieldValues>;
  label: string;
  disabled?: boolean;
  className?: string;
  children: ReactNode;

  /**
   * Disables the label HTML tag and uses span instead.
   * Useful when the inner content contains its own labels or multiple inputs.
   *
   * @default false
   */
  labelAsSpan?: boolean;
}

const FormField = <TFieldValues extends FieldValues>({
  name,
  label,
  disabled,
  className,
  children,
  labelAsSpan,
}: FormFieldProps<TFieldValues>) => {
  assertNonEmptyName(name);

  const {
    fieldState: { error },
  } = useController<TFieldValues>({ name, disabled });

  return (
    <Label
      id={name}
      disabled={disabled}
      className={cn('flex', 'flex-col', 'gap-sm', className)}
      asSpan={labelAsSpan}
    >
      {label}
      {children}
      {error ? (
        <FieldMessage state="invalid">{error.message}</FieldMessage>
      ) : null}
    </Label>
  );
};

FormField.displayName = 'FormField';
export { FormField };
