import type { FilterModel } from '../types/filters';
import { ColumnFilterType } from '../types/filters';

export const filterIsValid = (f: FilterModel | null | undefined): boolean => {
  if (!f) {
    return false;
  }
  if (!f.field) {
    return false;
  }
  if (!f.operand) {
    return false;
  }

  if (typeof f.value === 'string') {
    return Boolean(f.value.trim());
  }

  if (f.type === ColumnFilterType.inputNumber) {
    return !isNaN(Number(f.value));
  }

  return Boolean(f.value);
};
