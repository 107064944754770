import type { ColumnFilterOperand } from '../types';

export const EQUALS: ColumnFilterOperand = { id: 'equals', label: 'Equals' };
export const EQUALS_ALL: ColumnFilterOperand = {
  id: 'equalsAll',
  label: 'Equals (all)',
};

export const NOT_EQUALS: ColumnFilterOperand = {
  id: 'notEquals',
  label: 'Not equals',
};

export const GREATER_THAN: ColumnFilterOperand = {
  id: 'greaterThan',
  label: 'Greater than',
};

export const GREATER_THAN_RELATIVE: ColumnFilterOperand = {
  id: 'greaterThanRelative',
  label: 'Greater than (relative)',
};

export const GREATER_OR_EQUAL_TO: ColumnFilterOperand = {
  id: 'greaterOrEqualTo',
  label: 'Greater or equal to',
};

export const GREATER_OR_EQUAL_TO_RELATIVE: ColumnFilterOperand = {
  id: 'greaterOrEqualToRelative',
  label: 'Greater or equal to (relative)',
};

export const LESS_THAN: ColumnFilterOperand = {
  id: 'lessThan',
  label: 'Less than',
};

export const LESS_THAN_RELATIVE: ColumnFilterOperand = {
  id: 'lessThanRelative',
  label: 'Less than (relative)',
};

export const LESS_OR_EQUAL_TO: ColumnFilterOperand = {
  id: 'lessOrEqualTo',
  label: 'Less or equal to',
};

export const LESS_OR_EQUAL_TO_RELATIVE: ColumnFilterOperand = {
  id: 'lessOrEqualToRelative',
  label: 'Less or equal to (relative)',
};

export const CONTAINS: ColumnFilterOperand = {
  id: 'contains',
  label: 'Contains',
};

export const STARTS_WITH: ColumnFilterOperand = {
  id: 'startsWith',
  label: 'Starts with',
};

export const INPUT_TEXT_OPERANDS: ColumnFilterOperand[] = [
  EQUALS,
  NOT_EQUALS,
  CONTAINS,
  STARTS_WITH,
];

export const INPUT_NUMBER_OPERANDS: ColumnFilterOperand[] = [
  EQUALS,
  NOT_EQUALS,
  GREATER_THAN,
  GREATER_OR_EQUAL_TO,
  LESS_THAN,
  LESS_OR_EQUAL_TO,
];

export const DROPDOWN_OPERANDS: ColumnFilterOperand[] = [
  EQUALS,
  EQUALS_ALL,
  NOT_EQUALS,
];

export const DATE_RANGE_OPERANDS: ColumnFilterOperand[] = [
  GREATER_THAN,
  GREATER_THAN_RELATIVE,
  GREATER_OR_EQUAL_TO,
  GREATER_OR_EQUAL_TO_RELATIVE,
  LESS_THAN,
  LESS_THAN_RELATIVE,
  LESS_OR_EQUAL_TO,
  LESS_OR_EQUAL_TO_RELATIVE,
];
