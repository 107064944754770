import { format, formatDistanceToNow, isValid, parseISO } from 'date-fns';

import { Icon } from '~/components/Icon';
import { Tooltip } from '~/components/Tooltip';

interface RelativeDateProps {
  data: string | null;
}

export const RelativeDate = ({ data }: RelativeDateProps) => {
  if (!data || !isValid(new Date(data))) {
    return '';
  }

  const date = parseISO(data);

  const formattedDate = format(date, 'MMMM d, yyyy  h:mm a');

  return (
    <span className="whitespace-nowrap">
      {formatDistanceToNow(date, { addSuffix: true })}
      <Tooltip content={formattedDate} side="top" delayDuration={0} size="sm">
        <Icon
          type="circle-info"
          variant="light"
          className="ml-sm text-subtlest"
          size="sm"
        />
      </Tooltip>
    </span>
  );
};

RelativeDate.displayName = 'RelativeDate';
