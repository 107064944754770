/**
 * Environment variables.
 *
 * Never make this dynamic: Vite needs `import.meta.env.*` syntax to build envs
 * statically.
 *
 * Ignoring test coverage for these variables, as there's no functional value in
 * gating coverage here.
 */
/* istanbul ignore next */
export const variables = {
  VITE_ADA_OKTA_CLIENT_ID: import.meta.env.VITE_ADA_OKTA_CLIENT_ID,
  VITE_ADA_OKTA_DOMAIN: import.meta.env.VITE_ADA_OKTA_DOMAIN,
  VITE_ADA_OKTA_ISSUER: import.meta.env.VITE_ADA_OKTA_ISSUER,
  VITE_ADA_OKTA_HELP_URL: import.meta.env.VITE_ADA_OKTA_HELP_URL,
  VITE_WMG_OKTA_CLIENT_ID: import.meta.env.VITE_WMG_OKTA_CLIENT_ID,
  VITE_WMG_OKTA_DOMAIN: import.meta.env.VITE_WMG_OKTA_DOMAIN,
  VITE_WMG_OKTA_ISSUER: import.meta.env.VITE_WMG_OKTA_ISSUER,
  VITE_WMG_OKTA_HELP_URL: import.meta.env.VITE_WMG_OKTA_HELP_URL,
  ADA_BASE_URL: import.meta.env.ADA_BASE_URL,
  WMG_BASE_URL: import.meta.env.WMG_BASE_URL,
  VITE_NR_ACCOUNT_ID: import.meta.env.VITE_NR_ACCOUNT_ID,
  VITE_NR_BEACON: import.meta.env.VITE_NR_BEACON,
  VITE_NR_LICENSE_KEY: import.meta.env.VITE_NR_LICENSE_KEY,
  VITE_NR_AGENT_ID: import.meta.env.VITE_NR_AGENT_ID,
  VITE_NR_APPLICATION_ID: import.meta.env.VITE_NR_APPLICATION_ID,
  VITE_NR_TRUST_KEY: import.meta.env.VITE_NR_TRUST_KEY,
  VITE_AMPLITUDE_API_KEY: import.meta.env.VITE_AMPLITUDE_API_KEY,
  VITE_PULSE_INSIGHTS_TOKEN: import.meta.env.VITE_PULSE_INSIGHTS_TOKEN,
  VITE_USE_CONTAINER_NAVIGATION: import.meta.env.VITE_USE_CONTAINER_NAVIGATION,
  VITE_BFF_GRACEFUL_FALLBACK: import.meta.env.VITE_BFF_GRACEFUL_FALLBACK,
  VITE_I18N_DEBUG: import.meta.env.VITE_I18N_DEBUG,
  VITE_EXTRACT_LANGUAGE: import.meta.env.VITE_EXTRACT_LANGUAGE,
  VITE_CONTAINER_SETTINGS_ENABLED: import.meta.env
    .VITE_CONTAINER_SETTINGS_ENABLED,
  VITE_CONTAINER_SETTINGS_READ_ONLY_ENABLED: import.meta.env
    .VITE_CONTAINER_SETTINGS_READ_ONLY_ENABLED,
  VITE_CONTAINER_SETTINGS_LABEL_GROUPS_ENABLED: import.meta.env
    .VITE_CONTAINER_SETTINGS_LABEL_GROUPS_ENABLED,
  VITE_CONTAINER_SETTINGS_MANAGE_LABEL: import.meta.env
    .VITE_CONTAINER_SETTINGS_MANAGE_LABEL,
  VITE_CONTAINER_PROFILE_ENABLED: import.meta.env
    .VITE_CONTAINER_PROFILE_ENABLED,
  VITE_BRAND: import.meta.env.VITE_BRAND,
  MODE: import.meta.env.MODE,
  VITE_GLOBAL_SEARCH_ENABLED: Boolean(
    import.meta.env.VITE_GLOBAL_SEARCH_ENABLED === 'true',
  ),
  VITE_WMG_ASSET_PERFORMANCE_ENABLED:
    import.meta.env.VITE_WMG_ASSET_PERFORMANCE_ENABLED || '',
  VITE_CONTAINER_DDEX_CONSOLE_ENABLED: import.meta.env
    .VITE_CONTAINER_DDEX_CONSOLE_ENABLED,
};
