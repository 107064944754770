import type { FilterData } from '../hooks/useFilterChips';

export const idToName = ({
  ids,
  data,
}: {
  ids: string[];
  data: FilterData[];
}) => {
  if (!ids || !data) {
    return [];
  }
  return ids.reduce<{ id: string; name: string }[]>((acc, id) => {
    const item = data.find((curr) => curr.id === id);
    return item ? [...acc, { id, name: item.name }] : acc;
  }, []);
};
