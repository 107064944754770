import { type FieldValues, useController } from 'react-hook-form';

import { Combobox, type ComboboxProps } from '~/components/Combobox';

import { FormField, type FormFieldProps } from '../../FormField';

type FormFieldComboboxProps<
  TFormFields extends FieldValues,
  TMultiselect extends boolean | undefined,
> = Omit<FormFieldProps<TFormFields>, 'children'> &
  Omit<ComboboxProps<TMultiselect>, 'name' | 'className'>;

// TODO: `state` prop support in standalone `Combobox` component
export function FormFieldCombobox<
  TFormFields extends FieldValues,
  TMultiselect extends boolean | undefined,
>({
  name,
  label,
  className,
  ...comboboxProps
}: FormFieldComboboxProps<TFormFields, TMultiselect>) {
  const { field } = useController({ name, disabled: comboboxProps.disabled });

  return (
    <FormField name={name} label={label} className={className}>
      <Combobox {...field} {...comboboxProps} />
    </FormField>
  );
}
FormFieldCombobox.displayName = 'FormFieldCombobox';
