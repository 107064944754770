import type {
  ExportCSVPayload,
  ExportCSVResponse,
  FetchFiltersRefinersPayload,
  PageQuery,
  RefinerResponse,
} from '../components/DataGrid/types';
import { mapPageData as mapNotificationData } from '../components/DataGrid/utils/common';
import { URL_PREFIX } from '../constants/api';
// import { snakeToCamel } from '../components/DataGrid/utils/case';
import { mapPageData } from '../containers/Dashboard/utils/data';
import type {
  DashboardItem,
  DashboardPageColl,
  DashboardRow,
  DataRowKey,
  DataRowKeyWithPrefix,
  NotificationRow,
  PageDataResponse,
  RawTrackVersionResponse,
  TrackVersion,
} from '../types/dashoard';
import { dashboardMockData } from './dashboardMock';

export const getPageData = async (
  query: PageQuery<DataRowKey, DataRowKeyWithPrefix>,
): Promise<DashboardPageColl> => {
  const defaultLimit = 100;

  const limit = query.limit ?? defaultLimit;
  // const select = query.select?.map(camelToSnake);
  // const order = query.order?.map(camelToSnake);

  try {
    // const url = `${URL_PREFIX}/api/v1/dashboard`;
    // const body = JSON.stringify({
    //   limit,
    //   select,
    //   order,
    //   filter: query.filter,
    //   offset: query.offset,
    // });

    // const response = await fetch(url, {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body,
    // });

    // if (!response.ok) {
    //   throw new Error(`Failed to fetch: ${response.statusText}`);
    // }

    const r = dashboardMockData as PageDataResponse; // (await response.json()) as PageDataResponse;

    return {
      data: mapPageData(r.data),
      total: r.total,
      nextPageToken:
        r.data.length === limit ? `offset=${(query.offset ?? 0) + limit}` : '',
    };
  } catch (error) {
    console.warn('Failed to get report:', error);
    throw error;
  }
};

export const getItemData = async (isrc: string): Promise<DashboardItem> => {
  const url = `${URL_PREFIX}/api/v1/dashboard`;
  const body = JSON.stringify({
    limit: 1,
    select: ['*' as keyof DashboardRow], // Force select all columns
    filter: `isrc = '${isrc}'`,
  } as PageQuery<DataRowKey, DataRowKeyWithPrefix>);

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch report: ${response.statusText}`);
    }

    const r = (await response.json()) as PageDataResponse;

    return mapPageData(r.data)[0] as DashboardItem;
  } catch (error) {
    console.warn('Failed to get report:', error);
    throw error;
  }
};

export const getTrackVersions = async (
  isrc: string,
): Promise<TrackVersion[]> => {
  const url = `${URL_PREFIX}/api/v1/dashboard/versions/${isrc}`;

  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`Failed to fetch artists: ${response.statusText}`);
    }

    const r: RawTrackVersionResponse = await response.json();

    const data = r.data.map((item) => {
      return {
        ver: item.VER,
        date: new Date(item.TS),
        territories: item.TERRITORIES,
        partners: item.PARTNERS,
        appleTerrs: item.APPLE_TERRS,
        spotifyTerrs: item.SPOTIFY_TERRS,
      } as TrackVersion;
    });

    return data;
  } catch (error) {
    console.warn('Failed to get:', error);
    throw error;
  }
};

export const exportCSV = async (
  gridData: ExportCSVPayload,
): Promise<ExportCSVResponse> => {
  const url = `${URL_PREFIX}/api/v1/dashboard/export`;
  const body = JSON.stringify(JSON.stringify(gridData));

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch: ${response.statusText}`);
    }

    const r = (await response.json()) as ExportCSVResponse;

    return r;
  } catch (error) {
    console.warn('Failed to get report:', error);
    throw error;
  }
};

export const getFiltersRefiners = async (
  payload: FetchFiltersRefinersPayload,
): Promise<RefinerResponse> => {
  const url = `${URL_PREFIX}/api/v1/dashboard/refiners`;
  const body = JSON.stringify(JSON.stringify(payload));

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch: ${response.statusText}`);
    }

    const r = (await response.json()) as RefinerResponse;

    return r;
  } catch (error) {
    console.warn('Failed to get report:', error);
    throw error;
  }
};

export const getNotificationById = async (
  id: number,
): Promise<NotificationRow> => {
  try {
    const url = `${URL_PREFIX}/api/v1/notification/${id}`;
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`Failed to fetch: ${response.statusText}`);
    }

    const data = await response.json();
    const mapped = mapNotificationData([data])[0] as Promise<NotificationRow>;

    return mapped;
  } catch (error) {
    console.warn(`Error fetching notification with ID ${id}:`, error);
    throw error;
  }
};
