import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Spinner } from '~/components/Spinner';
import { TrackHeader } from '~/modules/artist-roster/components/TrackDetails/TrackHeader';
import { Breadcrumbs } from '~/modules/navigation/components/Breadcrumbs';

import { useArtistDetailsQuery } from '../../hooks/useArtistDetailsQuery';
import { useTrackDetailsQuery } from '../../hooks/useTrackDetailsQuery';
import { PerformanceView } from './PerformanceView';

export function TrackDetails() {
  const { t } = useTranslation('roster');
  const { uaid } = useParams<{ uaid: string }>();
  const { familyId } = useParams<{ familyId: string }>();
  const { data: artistData } = useArtistDetailsQuery({ uaid });

  const {
    data: trackData,
    error: tracksError,
    isFetching: isFetchingTracks,
  } = useTrackDetailsQuery({ familyId });

  const artistName = artistData?.name;

  // @TODO WMG1-167 Add system notification component
  if (tracksError) {
    return (
      <div className="flex w-full items-center justify-start p-2xl">
        <p className="m-0 font-header text-display-xl leading-none text-default">
          {t('errors.loadingTrackDetails')}
        </p>
      </div>
    );
  }

  if (!uaid || isNaN(Number(uaid))) {
    return (
      <div className="flex w-full items-center justify-start p-2xl">
        <p className="m-0 font-header text-display-xl leading-none text-default">
          {t('errors.invalidArtistId')}
        </p>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-between">
      <div className="flex w-full flex-col items-center justify-between gap-lg">
        <Breadcrumbs.Root className="flex w-full items-center justify-start gap-sm text-body-xs">
          <Breadcrumbs.Item
            to="/roster"
            className="font-semibold underline transition text-hover hover:text-white"
          >
            {t('header.title')}
          </Breadcrumbs.Item>
          <Breadcrumbs.Item
            to={`/roster/${uaid}`}
            className="font-semibold underline transition text-hover hover:text-white"
          >
            {artistName}
          </Breadcrumbs.Item>
          <Breadcrumbs.Item>{trackData?.title || ''}</Breadcrumbs.Item>
        </Breadcrumbs.Root>
        {isFetchingTracks ? (
          <div className="flex w-full items-center justify-center p-2xl">
            <Spinner />
          </div>
        ) : trackData ? (
          <div className="flex w-full flex-col items-start gap-lg">
            <TrackHeader artistData={artistData} trackData={trackData} />
            <PerformanceView familyId={trackData.id} />
          </div>
        ) : null}
      </div>
    </div>
  );
}

TrackDetails.displayName = 'TrackDetails';
