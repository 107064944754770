import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Icon } from '~/components';
import { Avatar } from '~/components/Avatar';
import { Button } from '~/components/Button';
import { env } from '~/lib/env';
import { Breadcrumbs } from '~/modules/navigation/components/Breadcrumbs';
import { Header } from '~/modules/settings/components/Header';
import { SETTINGS_ROUTES } from '~settings/config/routes';

export interface UserHeaderProps {
  name: string;
  userId?: string;
}

// TODO: Current Avatar styles are different from the design (@see https://www.figma.com/design/MmWqrR1POzXR13XgUem4XP/Permissions?node-id=8795-97834&t=n6LYg5AqAAevCn4j-4)
// ? Support for `children` - to handle case with some CTA for editing the user?
export function UserHeader({ name, userId }: UserHeaderProps) {
  const { t } = useTranslation('settings');
  const readOnlyIsNotEnabled =
    env('VITE_CONTAINER_SETTINGS_READ_ONLY_ENABLED', false) === 'false';

  return (
    <Header
      breadcrumbs={
        <Breadcrumbs.Root>
          <Breadcrumbs.Item to={SETTINGS_ROUTES.ROOT}>
            <Icon type="gear" size="sm" variant="regular" className="mr-sm" />
            {t('title')}
          </Breadcrumbs.Item>
          <Breadcrumbs.Item to={SETTINGS_ROUTES.USER_LIST}>
            {t('users')}
          </Breadcrumbs.Item>
          <Breadcrumbs.Item>{name}</Breadcrumbs.Item>
        </Breadcrumbs.Root>
      }
    >
      <Avatar name={name} size="lg" />
      <h1 className="grow text-display-sm">{name}</h1>
      {readOnlyIsNotEnabled && userId ? (
        <Button
          asChild
          variant="primary"
          className="shrink-0 self-center"
          size="large"
        >
          <Link to={SETTINGS_ROUTES.USER_EDIT(userId)}>
            {t('user.editLink')}
          </Link>
        </Button>
      ) : null}
    </Header>
  );
}
UserHeader.displayName = 'UserHeader';
