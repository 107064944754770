import {
  DATE_RANGE_OPERANDS,
  DROPDOWN_OPERANDS,
  INPUT_NUMBER_OPERANDS,
  INPUT_TEXT_OPERANDS,
} from '../../components/DataGrid/constants/filters';
import type { ColumnFilter } from '../../components/DataGrid/types/filters';
import { ColumnFilterType } from '../../components/DataGrid/types/filters';
import { COUNTRIES } from '../../constants/countries';
import { columnDefs } from './columnsDefs';

export interface DropdownOption {
  value: string;
  label: string;
}

const setInputTextFilter = (
  column: ColumnFilter,
  opts?: Partial<ColumnFilter>,
): void => {
  column.type = ColumnFilterType.inputText;
  if (opts?.arrayField) {
    column.arrayField = opts.arrayField;
  }
};

const setInputNumberFilter = (column: ColumnFilter): void => {
  column.type = ColumnFilterType.inputNumber;
};

const setDropdownFilter = (
  column: ColumnFilter,
  opts?: Partial<ColumnFilter>,
): void => {
  column.type = ColumnFilterType.dropdown;
  if (opts?.arrayField) {
    column.arrayField = opts.arrayField;
  }
  if (opts?.multiValue) {
    column.multiValue = opts.multiValue;
  }
  if (opts?.customOptions) {
    column.customOptions = opts.customOptions;
  }
};

const setDateFilter = (column: ColumnFilter): void => {
  column.type = ColumnFilterType.dateRange;
};

const countryFieldOptions: DropdownOption[] = COUNTRIES.map((c) => ({
  value: c.code,
  label: c.name,
}));

// ToDo: Remove switch, refactor to model approach
export const filterColumns: ColumnFilter[] = columnDefs
  .filter((c) => {
    if (c.field?.startsWith('_')) {
      return false;
    } // Exclude ephemeral columns

    // Add here non-filterable columns
    return true;
  })
  .map((c) => {
    const filterColumn: ColumnFilter = {
      ...c,
      operands: [],
      field: c.field as string,
      name: (c.filterName ?? c.headerName) as string,
      type: ColumnFilterType.inputText,
      arrayField: false,
      multiValue: false,
    };

    switch (c.field) {
      case 'artist':
      case 'trackTitle':
      case 'isrc':
      case 'shortTitle':
      case 'recordingVersion':
      case 'paFlag':
      case 'audioPresentation':
        setInputTextFilter(filterColumn);
        break;
      case 'albumTitle':
      case 'albumSubtitle':
      case 'gpid':
      case 'grid':
      case 'internalComments':
        setInputTextFilter(filterColumn, { arrayField: true });
        break;
      case 'majorLabel':
      case 'releaseType':
      case 'partnerList':
      case 'presentationLabel':
      case 'productPaFlags':
      case 'completeDboms':
      case 'productAudioPresentations':
        setDropdownFilter(filterColumn, { arrayField: true, multiValue: true });
        break;
      case 'trackRightsTerritories':
      case 'deliveryTerritoryList':
      case 'appleTerritoryList':
      case 'spotifyTerritoryList':
      case 'streamingTerritoryList':
      case 'missedTerritoriesAlt':
      case 'missedAppleTerritoriesAlt':
      case 'missedSpotifyTerritoriesAlt':
      case 'missedStreamingTerritories':
      case 'monthlyTrendFixesAddedTerritoryList':
      case 'monthlyTrendFixesRemovedTerritoryList':
        setDropdownFilter(filterColumn, {
          arrayField: true,
          multiValue: true,
          customOptions: countryFieldOptions,
        });
        break;
      case 'explicit':
      case 'trackRightType':
      case 'recType':
      case 'recSubType':
      case 'repertoireOwner':
        setDropdownFilter(filterColumn, { multiValue: true });
        break;
      case 'optTerritory':
      case 'deliveryTerritoryCount':
      case 'appleTerritoryCount':
      case 'spotifyTerritoryCount':
      case 'streamingTerritoryCount':
      case 'partnerCount':
      case 'missedTerritoriesCountAlt':
      case 'misstedStreamingTerritoryCount':
      case 'missedAppleTerritoriesCountAlt':
      case 'missedSpotifyTerritoriesCountAlt':
      case 'dailyStreamsAvg':
      case 'dailyStreamsAvgLw':
      case 'lastCompleteWeekStreams':
      case 'wtdStreams':
      case 'mtdStreams':
      case 'momPctStreams':
      case 'ytdStreams':
      case 'momPctRevenue':
      case 'lmrGrossValue':
      case 'revenueYtdUsd':
      case 'projectedMissedStreams':
      case 'monthlyTrendRevenue':
      case 'monthlyTrendStreams':
      case 'trackRightsTerritoryCount':
      case 'monthlyTrendFixesAddedRevenue':
      case 'monthlyTrendFixesAddedStreams':
      case 'monthlyTrendFixesRemovedRevenue':
      case 'monthlyTrendFixesRemovedTerritoryCount':
        setInputNumberFilter(filterColumn);
        break;
      case 'latestProductTs':
        setDateFilter(filterColumn);
        break;
    }

    switch (filterColumn.type) {
      case ColumnFilterType.inputText:
        filterColumn.operands = INPUT_TEXT_OPERANDS;
        break;
      case ColumnFilterType.inputNumber:
        filterColumn.operands = INPUT_NUMBER_OPERANDS;
        break;
      case ColumnFilterType.dropdown:
        filterColumn.operands = DROPDOWN_OPERANDS;
        break;
      case ColumnFilterType.dateRange:
        filterColumn.operands = DATE_RANGE_OPERANDS;
        break;
      default:
        filterColumn.operands = INPUT_TEXT_OPERANDS;
        break;
    }

    return filterColumn;
  });
