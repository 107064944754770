import type { PropsWithChildren } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from '~/components/Button';

export interface ErrorContentProps extends PropsWithChildren {
  type: 'default' | 'noAccess' | 'notFound';
  fallbackRoute?: {
    href: string;
    label: string;
  };
}

export function ErrorContent({ type, fallbackRoute }: ErrorContentProps) {
  const { t } = useTranslation('errors');

  return (
    <div className="-m-xl flex h-svh flex-col justify-center bg-default">
      <div className="flex size-full items-center whitespace-break-spaces bg-gradient bg-cover bg-bottom bg-no-repeat marker:flex-col">
        <div className="mb-lg ml-3xl" data-testid="error-banner">
          <h1 className="m-0 font-header text-display-5xl uppercase leading-none">
            <Trans
              t={t}
              i18nKey={`${type}.header`}
              components={{ br: <br /> }}
            />
          </h1>
          <p className="mt-sm text-body-md">{t(`${type}.description`)}</p>
          {fallbackRoute ? (
            <Button asChild size="large" variant="primary" className="mt-lg">
              <Link to={fallbackRoute.href}>{fallbackRoute.label}</Link>
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
}
ErrorContent.displayName = 'ErrorContent';
