import { forwardRef, type InputHTMLAttributes } from 'react';

import { cn } from '~/lib/cn';
import { variants } from '~/lib/variants';

import type { InputRounded, InputSize, InputState, InputType } from './types';

export interface InputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> {
  type?: InputType;
  size?: InputSize;
  rounded?: InputRounded;
  value?: string;
  state?: keyof typeof InputState;
}

const input = variants({
  base: [
    'w-full',
    'outline',
    'outline-1',
    'outline-emphasis-subtle',
    'outline-offset-[-1px]',
    'focus:ring-none',
    'text-body-sm',
    'py-md',
    'px-md',
    'bg-emphasis-default',
    'text-strong',
    'resize-none',
    'disabled:outline-opacity-subtle',
    'disabled:outline-px',
    'hover:outline-opacity-stronger',
  ],
  variants: {
    state: {
      default: [
        'focus:outline-secondary-default',
        'hover:outline-disabled',
        'focus:outline-2',
        'hover:outline-2',
      ],
      error: ['outline', 'outline-error-default', 'outline-2'],
      warning: ['outline', 'outline-warning-default', 'outline-2'],
      success: ['outline', 'outline-success-default', 'outline-2'],
    },
    size: {
      xlarge: ['leading-6'],
      large: ['leading-4'],
    },
    rounded: {
      xsmall: ['rounded-xs'],
      small: ['rounded-sm'],
      medium: ['rounded-md'],
    },
    disabled: {
      true: [
        'outline',
        'outline-disabled',
        'cursor-not-allowed',
        'pointer-events-none',
        'border-emphasis-stronger',
      ],
    },
  },
});

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type,
      size = 'large',
      rounded = 'xsmall',
      state = 'default',
      disabled,
      className,
      ...props
    },
    ref,
  ) => {
    const stateClasses = input({ state, rounded, size, disabled });

    return (
      <input
        aria-invalid={state === 'error'}
        className={cn(stateClasses, className)}
        disabled={disabled}
        type={type}
        ref={ref}
        {...props}
      />
    );
  },
);

Input.displayName = 'Input';

export { Input };
