import { useState } from 'react';

import { cn } from '~/lib/cn';

export type TabsTheme = 'default' | 'toggler';

export interface TabItem {
  id: string;
  name: string;
  hidden?: boolean;
}

export interface TabsProps {
  className?: string;
  items: TabItem[];
  onChange: (item: TabItem) => void;
  theme?: TabsTheme;
  defaultTab?: string;
  loading?: boolean;
}

interface ThemeClasses {
  ul: string;
  li: string;
  liActive: string;
  liInactive: string;
}

const themeClasses: Record<TabsTheme, ThemeClasses> = {
  default: {
    ul: 'inline-flex select-none w-full gap-sm items-baseline',
    li: 'text-default text-body-sm font-bold py-lg px-md w-full border-x border-t border-emphasis-subtle',
    liActive:
      'text-white bg-level-02 pb-2xl z-10 -mb-xs rounded-x-sm rounded-t-sm',
    liInactive: 'cursor-pointer rounded-sm border border-emphasis-subtle',
  },
  toggler: {
    ul: 'inline-flex select-none rounded-lg bg-level-03 py-sm px-sm shadow-black border-x border-t border-emphasis-subtle',
    li: 'text-default text-body-sm font-bold px-lg py-xs border-b-none',
    liActive: 'bg-tertiary-default text-black rounded-lg shadow-yellow',
    liInactive: 'cursor-pointer',
  },
};

const Tabs = ({
  className,
  items,
  theme,
  defaultTab,
  loading,
  onChange,
}: TabsProps): JSX.Element => {
  const [activeTab, setActiveTab] = useState<string>(defaultTab ?? items[0].id);

  const handleClick = (item: TabItem): void => {
    setActiveTab(item.id);
    onChange(item);
  };

  const currentTheme = themeClasses[theme ?? 'default'];

  return (
    <ul className={cn(currentTheme.ul, className)}>
      {items
        .filter((item) => !item.hidden)
        .map((item, index) => {
          const isActive = activeTab === item.id;
          return (
            <li
              className={cn(
                currentTheme.li,
                isActive ? currentTheme.liActive : currentTheme.liInactive,
                loading && 'cursor-default',
              )}
              key={index}
              data-testid={`tab-item-${item.id}`}
            >
              <button
                data-testid={`tab-btn-${item.id}`}
                onClick={() => handleClick(item)}
                disabled={loading || isActive}
                className="m-0 size-full p-0 text-left"
              >
                {theme === 'default' && (
                  <div
                    className={cn(
                      'mb-md h-xs w-full rounded-full bg-emphasis-default',
                      isActive && 'shadow-yellow bg-tertiary-default',
                    )}
                  />
                )}
                <span>{item.name}</span>
              </button>
            </li>
          );
        })}
    </ul>
  );
};

Tabs.displayName = 'Tabs';

export { Tabs };
