import type { MouseEvent } from 'react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Icon, Separator, Tooltip } from '~/components';
import { Popover } from '~/components/Popover';
import { useAnalytics } from '~/modules/monitoring/amplitude';

import { useArtistDetailsQuery } from '../../hooks/useArtistDetailsQuery';
import type { AssetFamily } from '../../lib/types';

interface TrackTableLinkMenuProps {
  trackData: AssetFamily;
}

export function TrackTableLinkMenu({ trackData }: TrackTableLinkMenuProps) {
  const { t } = useTranslation('roster');
  const { trackAnalytics } = useAnalytics();
  const { uaid } = useParams<{ uaid: string }>();
  const { data: artistData } = useArtistDetailsQuery({ uaid });

  const handleLinkClick = ({
    event,
    linkName,
  }: {
    event: MouseEvent;
    linkName: string;
  }) => {
    event.stopPropagation();

    if (artistData) {
      trackAnalytics('artistTrackTableInteraction', {
        artist_id: artistData.id,
        artist_name: artistData.name,
        click_type: linkName,
        track_name: trackData.title,
        // @TODO WMG1-246 Add Family ID when available.
        family_id: trackData.id,
      });
    }
  };

  return (
    <Popover
      content={
        <div className="flex flex-col rounded-sm bg-level-04 text-white">
          {trackData.links
            ? Object.entries(trackData.links).map(
                ([key, value], index, array) => {
                  const isPreRelease =
                    !trackData.initialReleaseDate ||
                    new Date(trackData.initialReleaseDate) >= new Date();
                  if (key !== 'airtable' && isPreRelease) {
                    return null;
                  }
                  return (
                    <Fragment key={key}>
                      <a
                        href={value}
                        target="_blank"
                        className="flex w-full items-center justify-between gap-xl px-md py-sm transition-colors first:rounded-t-sm last:rounded-b-sm hover:bg-emphasis-default"
                        rel="noopener noreferrer"
                        onClick={(event) =>
                          handleLinkClick({ event, linkName: key })
                        }
                      >
                        <span>{t(`trackList.header.${key}`)}</span>
                        <Icon type="external-link" variant="solid" size="sm" />
                      </a>
                      {index < array.length - 1 && !isPreRelease ? (
                        <Separator className="m-0 w-full" />
                      ) : null}
                    </Fragment>
                  );
                },
              )
            : null}
        </div>
      }
      stopPropagation
      triggerAsChild
    >
      <button
        aria-label={t('trackList.header.additionalLinks')}
        className="rounded-full border p-sm transition-colors border-transparent hover:bg-emphasis-subtle hover:border-emphasis-strong"
      >
        <Tooltip
          content={t('trackList.tooltips.additionalMenu')}
          delayDuration={0}
          sideOffset={16}
          side="bottom"
          align="end"
          size="md"
          triggerAsChild
        >
          <div className="flex size-md items-center justify-center">
            <Icon type="ellipsis-vertical" variant="regular" size="sm" />
          </div>
        </Tooltip>
      </button>
    </Popover>
  );
}

TrackTableLinkMenu.displayName = 'TrackTableLinkMenu';
