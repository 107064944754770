import { cn } from '~/lib/cn';

import { BACKGROUND_COLORS } from './constants';
import { arraySum } from './utils';

interface VerticalBarProps {
  percentageValues: number[];
}

export const VerticalBar = ({ percentageValues }: VerticalBarProps) => {
  const totalPct = arraySum(percentageValues);

  return (
    <div className="flex h-full justify-center">
      <div className="flex w-xl flex-col-reverse gap-xs rounded-full bg-emphasis-default">
        {percentageValues.map((pct, index) => {
          return (
            <div
              key={index}
              className={cn('w-full grow-0', BACKGROUND_COLORS[index % 4], {
                'rounded-b-full': index === 0,
                'rounded-t-full': index === percentageValues.length - 1,
              })}
              style={{ height: `${pct}%` }}
            />
          );
        })}
        <div className="grow-0">{`${totalPct}%`}</div>
      </div>
    </div>
  );
};
VerticalBar.displayName = 'VerticalBar';
