import type { ColumnDef } from '@tanstack/react-table';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Icon } from '~/components';
import { cn } from '~/lib/cn';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '~/modules/artist-roster/components/Table';
import { useAnalytics } from '~/modules/monitoring/amplitude';

import type {
  ArtistTableHeaderMetaType,
  ArtistTableMetaType,
  ArtistType,
} from '../lib/types';
import { SkeletonTable } from './Table/SkeletonTable.tsx';

interface ArtistDataTableProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<ArtistType, any>[];
  data: ArtistType[];
  isLoading: boolean;
  meta?: ArtistTableMetaType;
  className?: string;
}

export function ArtistDataTable({
  columns,
  data,
  meta,
  isLoading,
  className,
}: ArtistDataTableProps) {
  const { t } = useTranslation('roster');
  const { trackAnalytics } = useAnalytics();
  const table = useReactTable({
    data,
    columns,
    meta,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleArtistLinkClick = ({
    artistData,
  }: {
    artistData: ArtistType;
  }) => {
    const tableMeta = table.options?.meta as ArtistTableMetaType;
    const {
      filtersCount = 0,
      crossoverFilterApplied = false,
      filtersApplied = false,
    } = tableMeta || {};
    trackAnalytics('rosterTableArtistClick', {
      artist_id: artistData.id,
      artist_name: artistData.name,
      roster_label_filters_count: filtersCount,
      roster_crossover_filter_applied: crossoverFilterApplied,
      roster_label_filters_applied: filtersApplied,
    });
  };

  return (
    <Table
      aria-label="Artists roster list"
      className={cn('w-full table-fixed text-left', className)}
    >
      <TableHeader>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              const headerMeta = header.column.columnDef
                .meta as ArtistTableHeaderMetaType;

              return (
                <TableHead
                  key={header.id}
                  className={cn(
                    'text-body-sm font-normal first:pl-lg',
                    headerMeta?.className,
                  )}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </TableHead>
              );
            })}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody>
        {isLoading ? (
          <SkeletonTable
            size={12}
            headers={table.getHeaderGroups()[0].headers}
          />
        ) : table.getRowModel().rows?.length ? (
          table.getRowModel().rows.map((row, rowIndex) => (
            <TableRow
              aria-label={`Row for ${row.original?.name || 'artist'}`}
              key={row.id}
              data-state={row.getIsSelected() && 'selected'}
              className="group relative transition-colors duration-75 hover:bg-emphasis-subtle"
            >
              {row.getVisibleCells().map((cell) => {
                return (
                  <TableCell
                    key={cell.id}
                    className={cn(
                      'border-y p-0 border-transparent last:py-sm',
                      'group-hover:border-emphasis-strong',
                      'first:rounded-l-sm first:border-l',
                      'last:rounded-r-sm last:border-r',
                      rowIndex % 2 === 0 && 'bg-default-inverse/default',
                    )}
                  >
                    {cell.column.id === 'name' ? (
                      <Link
                        to={`/roster/${row.original.uaid}`}
                        className="flex items-center gap-sm py-md pl-lg hover:underline"
                        onClick={() =>
                          handleArtistLinkClick({ artistData: row.original })
                        }
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </Link>
                    ) : (
                      flexRender(cell.column.columnDef.cell, cell.getContext())
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={4}>
              <div className="mt-md flex w-full flex-col items-center justify-center gap-lg border-2xs border-dashed py-5xl tracking-wider border-strong-on-light">
                <span className="flex shrink-0 items-center justify-center">
                  <Icon
                    type="user-music"
                    variant="light"
                    size="4x"
                    aria-hidden
                    className="text-default"
                  />
                </span>
                <div className="flex w-full flex-col items-center justify-center gap-sm">
                  <p className="text-body-lg font-semibold text-white">
                    {t('noData.title')}
                  </p>
                  <p className="text-body-md font-normal text-subtle">
                    {t('noData.sub')}
                  </p>
                </div>
              </div>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

ArtistDataTable.displayName = 'ArtistDataTable';
