import type { DialogContentProps } from '@radix-ui/react-dialog';
import { Content } from '@radix-ui/react-dialog';
import { forwardRef } from 'react';

export const DrawerContent = forwardRef<
  HTMLDivElement,
  Omit<DialogContentProps, 'asChild'>
>(function DrawerContent({ children, ...delegatedProps }, forwardedRef) {
  return (
    <Content
      asChild
      ref={forwardedRef}
      className="absolute right-0 top-0 flex min-h-svh w-[400px] flex-col bg-level-01/full text-default"
      role="complementary"
      // @see https://www.radix-ui.com/primitives/docs/components/dialog#description
      aria-describedby={undefined}
      {...delegatedProps}
    >
      <aside>{children}</aside>
    </Content>
  );
});
DrawerContent.displayName = 'DrawerContent';
