import { cn } from '~/lib/cn';

import { useGlobalSearchContext } from '../../hooks/useGlobalSearchContext';

export const PageOverlay = () => {
  const { searchQuery, resetSearch } = useGlobalSearchContext();

  const handleClick = () => {
    resetSearch();
  };

  return (
    <div
      className={cn(
        'absolute inset-0 z-10 backdrop-blur-lg transition delay-100',
        {
          'pointer-events-none opacity-none': !searchQuery,
        },
      )}
      onClick={handleClick}
      aria-hidden
    />
  );
};

PageOverlay.displayName = 'PageOverlay';
