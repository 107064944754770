import { useState } from 'react';

import { Separator } from '~/components/Separator';

interface Props {
  tabs: Array<{
    title: string;
    component: JSX.Element;
  }>;
}
export function Tabs({ tabs }: Props) {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div>
      <div className="flex h-full gap-lg" role="tablist" aria-label="Tabs">
        {tabs.map((tab, i) => (
          <button
            key={tab.title}
            className={
              activeTab === i
                ? "relative pb-sm font-medium after:absolute after:bottom-0 after:left-0 after:h-[3px] after:w-full after:rounded-sm after:content-[''] after:bg-primary-default"
                : 'pb-sm font-medium text-subtle'
            }
            onClick={() => setActiveTab(i)}
            role="tab"
            aria-selected={activeTab === i}
          >
            {tab.title}
          </button>
        ))}
      </div>
      <Separator role="presentation" />
      <section role="tabpanel">
        {tabs[activeTab] ? tabs[activeTab].component : null}
      </section>
    </div>
  );
}
Tabs.displayName = 'Tabs';
