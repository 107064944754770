import type { PropsWithChildren } from 'react';

export function DrawerFooter({ children }: PropsWithChildren) {
  return (
    <footer className="mt-auto flex justify-end gap-md border-t-px p-md border-emphasis-subtle">
      {children}
    </footer>
  );
}
DrawerFooter.displayName = 'DrawerFooter';
