import { createRef } from 'react';

import type { DropdownOption } from '~/components/Dropdown/types/DropdownOption';

export function createCollection(
  options: DropdownOption[],
): (DropdownOption & { ref: React.RefObject<HTMLDivElement> | null })[] {
  return options.map((item) => {
    return {
      ...item,
      ref: createRef() as React.RefObject<HTMLDivElement> | null,
    };
  });
}
