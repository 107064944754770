import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { InternalChips } from '~/components/FilterChip/InternalChip';
import { useLabelList } from '~/modules/settings/api/queries/labelList';
import { FilterDrawer } from '~/modules/settings/components/Filter';
import { LABEL_TYPES } from '~/modules/settings/constants/Filter';
import { useFilters } from '~/modules/settings/hooks/useFilters';
import { useSearch } from '~/modules/settings/hooks/useSearch';
import { useSort } from '~/modules/settings/hooks/useSort';
import { LabelsFormFilter } from '~/modules/settings/modules/label/components/LabelFilter/LabelsFormFilter';
import type { Label, LabelListItem } from '~settings/api/lib/schemas';
import { HeaderSmall } from '~settings/components/HeaderSmall';
import { ListControls } from '~settings/components/ListControls';
import { Table } from '~settings/components/Table';
import { SETTINGS_ROUTES } from '~settings/config/routes';
import { usePagination } from '~settings/hooks/usePagination';

import { useUserLabelsColumns } from '../../hooks/useUserLabelsColumns';

const emptyLabels: LabelListItem[] = [];

type UserDetailsRouteParams = {
  userId?: string;
};

export const UserLabels = ({ userId }: UserDetailsRouteParams) => {
  const columns = useUserLabelsColumns();
  const { t } = useTranslation('settings');

  const { page, setPageNumber, perPage, skip, take } = usePagination();
  const { sorting, setSorting, sortParams } = useSort();
  const { filterData, drawerOpen, closeDrawer, openDrawer } = useFilters();
  const { searchTerm } = useSearch();
  const { labelList, isFetching } = useLabelList({
    skip,
    take,
    userId,
    ...(searchTerm && { search: searchTerm }),
    ...sortParams,
    ...filterData,
  });

  const navigate = useNavigate();
  const onClick = (row: Label) => {
    navigate(SETTINGS_ROUTES.LABEL_DETAIL(row.id));
  };

  return (
    <>
      <HeaderSmall>{t('labels')}</HeaderSmall>
      <ListControls
        placeholder={t('searchLabels')}
        page={page}
        total={labelList?.total ?? 0}
        perPage={perPage}
        entity="labels"
        onFilterClick={openDrawer}
        internalFilter={
          <InternalChips options={LABEL_TYPES} path="labelType" />
        }
        setPageNumber={setPageNumber}
      />

      <Table
        data={labelList?.data ?? emptyLabels}
        columns={columns}
        rowClassName="odd:bg-level-03"
        onRowClick={onClick}
        sort={{
          sorting,
          onSortingChange: setSorting,
        }}
        loading={isFetching}
      />
      <FilterDrawer open={drawerOpen} onOpenChange={closeDrawer}>
        <LabelsFormFilter open={drawerOpen} onApply={closeDrawer} />
      </FilterDrawer>
    </>
  );
};

UserLabels.displayName = 'UserLabels';
