import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Tag } from '~/components';

interface Option {
  label: string;
  value: string;
}

interface InternalChipsProps {
  options: Option[];
  path: string;
}

export const InternalChips = ({ options, path }: InternalChipsProps) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation('settings');

  const [activeOption, setActiveOption] = useState<string | null>(null);

  const handleOptionClick = (selected: string) => {
    setActiveOption(selected);

    const params = new URLSearchParams(searchParams);
    if (selected === 'all') {
      params.delete(path);
    } else {
      params.set(path, selected);
    }

    navigate(
      { pathname: location.pathname, search: params.toString() },
      { replace: true },
    );
  };

  useEffect(() => {
    const paramValue = searchParams.get(path) || 'all';
    setActiveOption((prev) => {
      if (prev === paramValue) {
        return prev;
      }
      return paramValue;
    });
  }, [activeOption, path, searchParams]);

  return (
    <ul className="flex cursor-pointer flex-wrap gap-sm pr-sm">
      {options.map((choice) => (
        <Tag
          key={choice.value}
          variant={activeOption === choice.value ? 'active' : 'secondary'}
          size="lg"
          onClick={() => handleOptionClick(choice.value)}
        >
          {t(choice.label)}
        </Tag>
      ))}
      <span className="inline-block w-px bg-emphasis-subtle" />
    </ul>
  );
};

InternalChips.displayName = 'InternalChips';
