import type { ReactNode } from 'react';

import { State } from './types.ts';

const STATE_CLASSES: Record<keyof typeof State, string> = {
  default: 'text-default',
  valid: 'text-success-default',
  invalid: 'text-error-default',
  disabled: 'text-disabled',
  warning: 'text-warning-default',
};

interface FieldMessageProps {
  children: ReactNode;
  state?: keyof typeof State;
}

const FieldMessage = ({
  children,
  state = 'default',
  ...props
}: FieldMessageProps) => {
  const stateClass = STATE_CLASSES[state];

  return (
    <p {...props} className={`text-body-sm ${stateClass}`}>
      {children}
    </p>
  );
};

FieldMessage.displayName = 'FieldMessage';
FieldMessage.State = State;

export { FieldMessage };
