import { Icon } from '~/components';
import { cn } from '~/lib/cn';

import type { ColDef } from '../../components/DataGrid/types';
import type { DashboardRow, TrackMark } from '../../types/dashoard';
import { OptTerrRenderer } from './components/OptTerrRenderer';
import { dateFormatter, listFormatter, zeroFormatter } from './renderers';

type Ephimeral = keyof DashboardRow;

export const columnDefs: ColDef[] = [
  {
    field: 'isrc',
    headerName: 'ISRC',
    width: 155,
    cellClass: 'text-white font-semibold text-body-xs',
    sortable: true,
    cellRenderer: (p) => {
      const lock = p.data.trackLock as TrackMark;
      const ignore = p.data.trackIgnore as TrackMark;

      return (
        <div
          data-testid="isrc-container"
          className={cn(
            'flex items-center',
            ignore && 'line-through text-subtle',
          )}
        >
          <span
            data-testid="track-ignore"
            title={
              ignore
                ? `Disabled by: ${(ignore as { owner: { name: string }; createdAt: Date }).owner.name} 
                   on ${(ignore as { owner: { name: string }; createdAt: Date }).createdAt.toLocaleDateString()}`
                : undefined
            }
          >
            <a data-testid="isrc-link" href={`/dashboard?id=${p.data.isrc}`}>
              {p.data.isrc as string}
            </a>
          </span>
          {lock ? (
            <Icon
              className={cn(
                'ml-xs text-body-xs',
                (lock as { self?: boolean }).self
                  ? 'text-subtle'
                  : 'text-error-alert',
              )}
              type="eye"
              variant="light"
              title={(() => {
                const lockData = lock as {
                  owner?: { name: string };
                  createdAt?: Date;
                };
                return lockData.owner && lockData.createdAt
                  ? `Locked by: ${lockData.owner.name} on ${lockData.createdAt.toLocaleDateString()}`
                  : undefined;
              })()}
            />
          ) : null}
        </div>
      );
    },
  },
  {
    field: '_rollupTitle' as Ephimeral,
    headerName: 'Rollup Title',
    flex: 1,
    cellClass: 'text-white font-semibold text-body-xs',
    cellRenderer: (p) => {
      if (!p?.data) {
        return null;
      }

      const data = p.data as Record<string, unknown>;
      const trackTitle = data.trackTitle as string;
      const artist = data.artist as string;

      return (
        <div>
          <span>{trackTitle}</span>
          <span className="font-extralight text-default"> by... </span>
          <span className="font-light">{artist}</span>
        </div>
      );
    },
    colDependsOn: ['trackTitle', 'artist'],
    sortable: true,
  },
  {
    field: 'artist',
    headerName: 'Artist',
    flex: 1,
    hide: true,
    sortable: true,
  },
  {
    field: 'trackTitle',
    headerName: 'Track Title',
    flex: 1,
    hide: true,
    sortable: true,
  },
  {
    field: 'shortTitle',
    headerName: 'Short Title',
    width: 120,
    sortable: true,
    hide: true,
  },
  {
    field: 'recordingVersion',
    headerName: 'Recording Version',
    width: 120,
    sortable: true,
    hide: true,
  },
  {
    field: 'recType',
    headerName: 'Recording Type',
    width: 120,
    sortable: true,
    hide: true,
  },
  {
    field: 'recSubType',
    headerName: 'Recording Sub Type',
    width: 120,
    sortable: true,
    hide: true,
  },
  {
    field: 'audioPresentation',
    headerName: 'Audio Presentation',
    width: 120,
    sortable: true,
    hide: true,
  },
  {
    field: 'gpid',
    headerName: 'GPID(s)',
    filterName: 'GPID',
    cellClass: 'text-white font-semibold text-body-xs',
    width: 140,
    cellRenderer: (p) => {
      if (!p?.data?.gpid) {
        return null;
      }

      const gpids = (p.data as { gpid?: string[] }).gpid ?? [];

      return (
        <div data-testid="gpid-container" className="flex">
          {gpids.length > 0 && <span data-testid="gpid-value">{gpids[0]}</span>}
          {gpids.length > 1 && (
            <span data-testid="gpid-more" className="ml-sm">
              (+{gpids.length - 1})
            </span>
          )}
        </div>
      );
    },
    sortable: true,
  },
  {
    field: 'albumTitle',
    headerName: 'Album Title',
    flex: 1,
    valueFormatter: listFormatter,
    hide: true,
    sortable: true,
  },
  {
    field: 'albumSubtitle',
    headerName: 'Album Subtitle',
    flex: 1,
    valueFormatter: listFormatter,
    hide: true,
    sortable: true,
  },
  {
    field: 'majorLabel',
    headerName: 'Major Label',
    flex: 1,
    cellClass: 'text-white font-semibold text-body-xs',
    valueFormatter: listFormatter,
    sortable: true,
  },
  {
    field: 'presentationLabel',
    headerName: 'Presentation Label',
    cellClass: 'text-white font-semibold text-body-xs',
    valueFormatter: listFormatter,
    flex: 1,
    sortable: true,
  },
  {
    field: 'repertoireOwner',
    headerName: 'Repertoire Owner',
    flex: 1,
    sortable: true,
    hide: true,
  },
  {
    field: 'releaseType',
    headerName: 'Release Type(s)',
    filterName: 'Release Type',
    width: 140,
    cellClass: 'text-white font-semibold text-body-xs',
    valueFormatter: listFormatter,
    sortable: true,
  },
  {
    field: 'paFlag',
    headerName: 'PA Flag',
    width: 120,
    sortable: true,
    hide: true,
  },
  {
    field: 'explicit',
    headerName: 'Explicit',
    flex: 1,
    sortable: true,
    hide: true,
  },
  {
    field: 'trackRightType',
    headerName: 'Track Right Type',
    flex: 1,
    sortable: true,
    hide: true,
  },
  {
    field: '_optTerritory' as Ephimeral,
    headerName: 'KPI: Territory Delivery',
    width: 140,
    resizable: false,
    cellRenderer: OptTerrRenderer(
      'deliveryTerritoryCount',
      'missedTerritoriesCountAlt',
    ),
    cellClass: 'text-white font-semibold text-body-xs',
    colDependsOn: ['deliveryTerritoryCount', 'missedTerritoriesCountAlt'],
    sortable: true,
  },
  {
    field: 'projectedMissedStreams',
    headerName: 'KPI: Missed Streams / Year',
    width: 120,
    sortable: true,
    hide: true,
    valueFormatter: zeroFormatter,
    valueGetter: (p) => {
      return parseInt(
        String(
          (p.data as { projectedMissedStreams?: number })
            .projectedMissedStreams ?? '0',
        ),
        10,
      );
    },
  },
  {
    field: '_appleTerritoryCount' as Ephimeral,
    headerName: 'KPI: Apple Delivery',
    width: 140,
    cellRenderer: OptTerrRenderer(
      'appleTerritoryCount',
      'missedAppleTerritoriesCountAlt',
    ),
    colDependsOn: ['appleTerritoryCount', 'missedAppleTerritoriesCountAlt'],
    sortable: true,
    hide: true,
  },
  {
    field: '_spotifyTerritoryCount' as Ephimeral,
    headerName: 'KPI: Spotify Delivery',
    width: 140,
    cellRenderer: OptTerrRenderer(
      'spotifyTerritoryCount',
      'missedSpotifyTerritoriesCountAlt',
    ),
    colDependsOn: ['spotifyTerritoryCount', 'missedSpotifyTerritoriesCountAlt'],
    sortable: true,
    hide: true,
  },
  {
    field: '_streamingTerritoryCount' as Ephimeral,
    headerName: 'KPI: Streaming Terr.',
    width: 140,
    cellRenderer: OptTerrRenderer(
      'streamingTerritoryCount',
      'misstedStreamingTerritoryCount',
    ),
    colDependsOn: ['streamingTerritoryCount', 'misstedStreamingTerritoryCount'],
    sortable: true,
    hide: true,
  },
  {
    field: 'monthlyTrendRevenue',
    headerName: 'KPI: Revenue Monthly Trend',
    width: 120,
    sortable: true,
    hide: true,
    valueFormatter: zeroFormatter,
  },
  {
    field: 'monthlyTrendStreams',
    headerName: 'KPI: Streams Monthly Trend',
    width: 120,
    sortable: true,
    hide: true,
    valueFormatter: zeroFormatter,
  },
  // List / Comparison
  {
    field: 'trackRightsTerritories',
    headerName: 'Track Rights Terr.',
    flex: 1,
    valueFormatter: listFormatter,
    hide: true,
  },
  {
    field: 'deliveryTerritoryCount',
    headerName: '# Delivery Terr.',
    width: 120,
    sortable: true,
    hide: true,
  },
  {
    field: 'deliveryTerritoryList',
    headerName: 'Delivery Terr.',
    flex: 1,
    valueFormatter: listFormatter,
    hide: true,
  },
  {
    field: 'missedTerritoriesCountAlt',
    headerName: '# Missed Terr.',
    width: 120,
    sortable: true,
    hide: true,
  },
  {
    field: 'missedTerritoriesAlt',
    headerName: 'Missed Terr.',
    flex: 1,
    valueFormatter: listFormatter,
    hide: true,
  },
  {
    field: 'trackRightsTerritoryCount',
    headerName: '# Track Rights Terr.',
    width: 140,
    sortable: true,
    hide: true,
  },
  {
    field: 'partnerCount',
    headerName: '# Partners',
    width: 120,
    sortable: true,
    hide: true,
  },
  {
    field: 'partnerList',
    headerName: 'Partners',
    flex: 1,
    valueFormatter: listFormatter,
    hide: true,
  },
  {
    field: 'missedAppleTerritoriesCountAlt',
    headerName: 'Apple: # Missed Terr.',
    width: 120,
    sortable: true,
    hide: true,
  },
  {
    field: 'missedAppleTerritoriesAlt',
    headerName: 'Apple: Missed Terr.',
    flex: 1,
    valueFormatter: listFormatter,
    hide: true,
  },
  {
    field: 'appleTerritoryCount',
    headerName: 'Apple: # Terr.',
    width: 140,
    sortable: true,
    hide: true,
  },
  {
    field: 'missedSpotifyTerritoriesCountAlt',
    headerName: 'Spotify: # Missed Terr.',
    width: 120,
    sortable: true,
    hide: true,
  },
  {
    field: 'missedSpotifyTerritoriesAlt',
    headerName: 'Spotify: Missed Terr.',
    flex: 1,
    valueFormatter: listFormatter,
    hide: true,
  },
  {
    field: 'spotifyTerritoryCount',
    headerName: 'Spotify: # Terr.',
    width: 140,
    sortable: true,
    hide: true,
  },
  {
    field: 'streamingTerritoryCount',
    headerName: 'Streaming: # Terr.',
    width: 140,
    sortable: true,
    hide: true,
  },
  {
    field: 'streamingTerritoryList',
    headerName: 'Streaming: Territories',
    flex: 1,
    valueFormatter: listFormatter,
    hide: true,
  },
  {
    field: 'misstedStreamingTerritoryCount',
    headerName: 'Streaming: # Missed Terr.',
    width: 120,
    sortable: true,
    hide: true,
  },
  {
    field: 'missedStreamingTerritories',
    headerName: 'Streaming: Missed Terr.',
    flex: 1,
    valueFormatter: listFormatter,
    hide: true,
  },
  // Streaming statistic
  {
    field: 'dailyStreamsAvg',
    headerName: 'Daily Stream Avg.',
    width: 120,
    sortable: true,
    hide: true,
    valueFormatter: zeroFormatter,
  },
  {
    field: 'dailyStreamsAvgLw',
    headerName: 'Daily Streaming Avg., Last Week',
    width: 120,
    sortable: true,
    hide: true,
    valueFormatter: zeroFormatter,
  },
  {
    field: 'wtdStreams',
    headerName: 'Week to Day Streams',
    width: 120,
    sortable: true,
    hide: true,
    valueFormatter: zeroFormatter,
  },
  {
    field: 'lastCompleteWeekStreams',
    headerName: 'Last Complete Week Streams',
    width: 120,
    sortable: true,
    hide: true,
    valueFormatter: zeroFormatter,
  },
  {
    field: 'mtdStreams',
    headerName: 'Month to Date Streams',
    width: 120,
    sortable: true,
    hide: true,
    valueFormatter: zeroFormatter,
  },
  {
    field: 'momPctStreams',
    headerName: 'Month over Month % Streams',
    width: 120,
    sortable: true,
    hide: true,
    valueFormatter: zeroFormatter,
  },
  {
    field: 'ytdStreams',
    headerName: 'Year to Day Streams',
    width: 120,
    sortable: true,
    hide: true,
    valueFormatter: zeroFormatter,
  },
  // Revenue
  {
    field: 'momPctRevenue',
    headerName: 'Month over Month % Revenue Change',
    width: 120,
    sortable: true,
    hide: true,
    valueFormatter: zeroFormatter,
  },
  {
    field: 'lmrGrossValue',
    headerName: 'Last Month Gross Revenue',
    width: 120,
    sortable: true,
    hide: true,
    valueFormatter: zeroFormatter,
  },
  {
    field: 'llmrGrossValue',
    headerName: 'Last to Last Month Gross Revenue',
    width: 120,
    sortable: true,
    hide: true,
    valueFormatter: zeroFormatter,
  },
  {
    field: 'revenueYtdUsd',
    headerName: 'Revenue Year to Date, USD',
    width: 120,
    sortable: true,
    hide: true,
    valueFormatter: zeroFormatter,
  },
  {
    field: 'monthlyTrendFixesAddedRevenue',
    headerName: 'Fixes: Added Revenue',
    width: 120,
    sortable: true,
    hide: true,
    valueFormatter: zeroFormatter,
  },
  {
    field: 'monthlyTrendFixesRemovedRevenue',
    headerName: 'Fixes: TKD Revenue Miss',
    width: 120,
    sortable: true,
    hide: true,
    valueFormatter: zeroFormatter,
  },
  {
    field: 'monthlyTrendFixesAddedStreams',
    headerName: 'Fixes: Added Streams',
    width: 120,
    sortable: true,
    hide: true,
    valueFormatter: zeroFormatter,
  },
  {
    field: 'monthlyTrendFixesRemovedStreams',
    headerName: 'Fixes: TKD Streams Miss',
    width: 120,
    sortable: true,
    hide: true,
    valueFormatter: zeroFormatter,
  },
  {
    field: 'completeDboms',
    headerName: 'DBoM Complete',
    width: 90,
    sortable: false,
    hide: true,
    valueFormatter: listFormatter,
  },
  {
    field: 'latestProductTs',
    headerName: 'Update Date',
    width: 120,
    sortable: true,
    hide: true,
    valueFormatter: dateFormatter,
  },
];
