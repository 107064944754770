import type { PropsWithChildren } from 'react';

export function Layout({ children }: PropsWithChildren) {
  return (
    <main className="grow overflow-auto overscroll-contain bg-gradient bg-[100%,auto] bg-no-repeat p-xl tracking-wide bg-level-01/full">
      {children}
    </main>
  );
}

Layout.displayName = 'Layout';
