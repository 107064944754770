import { useState } from 'react';

import { Icon } from '~/components';
import { cn } from '~/lib/cn';
import { useAnalytics } from '~/modules/monitoring/amplitude';

import { exportArtistsCSV } from '../../lib/artists';

interface ArtistExportProps {
  search?: string;
  labels?: number[];
  isCrossOver?: boolean;
  className?: string;
}

const ArtistExport = ({
  search = '',
  className = '',
  isCrossOver = false,
  labels = [],
}: ArtistExportProps) => {
  const [isExporting, setIsExporting] = useState(false);
  const { trackAnalytics } = useAnalytics();

  const onExportClick = async () => {
    setIsExporting(true);
    const exported = await exportArtistsCSV({ search, labels, isCrossOver });
    if (exported) {
      trackAnalytics('rosterExported', {
        roster_label_filters_count: labels.length,
        roster_label_filters_applied: labels.length > 0,
        roster_crossover_filter_applied: isCrossOver,
      });
    }

    setIsExporting(false);
  };

  return (
    <button
      className={cn(
        'flex items-center justify-center gap-sm font-semibold underline',
        {
          'cursor-not-allowed text-disabled': isExporting,
          'text-white': !isExporting,
        },
        className,
      )}
      onClick={onExportClick}
      disabled={isExporting}
    >
      {isExporting ? 'Exporting' : 'Export'}
      <Icon type="file-export" variant="regular" size="sm" />
    </button>
  );
};

ArtistExport.displayName = 'ArtistExport';

export { ArtistExport };
