import { useContext } from 'react';

import { GlobalSearchContext } from '../contexts/GlobalSearchContext';

export function useGlobalSearchContext() {
  const context = useContext(GlobalSearchContext);

  if (!context) {
    throw new Error(
      'useGlobalSearchContext must be used within GlobalSearchProvider',
    );
  }

  return context;
}
