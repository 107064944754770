import type { SortingState } from '@tanstack/react-table';
import { useState } from 'react';

export const useSort = () => {
  const [sorting, setSorting] = useState<SortingState>([]);

  const sortBy = sorting.length ? sorting[0].id : '';
  const orderDir = sorting.length ? (sorting[0].desc ? 'desc' : 'asc') : '';

  return {
    sorting,
    setSorting,
    sortParams: sortBy ? { sortBy, orderDir } : {},
  };
};
