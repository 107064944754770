import { useContext } from 'react';

import { WmgSessionContext } from '../contexts';

const useWmgSession = () => {
  const { user, token } = useContext(WmgSessionContext);

  return { ...user, token };
};

export { useWmgSession };
