import { Trans, useTranslation } from 'react-i18next';

interface ErrorPageProps {
  type?: 'default' | 'noAccessPage' | 'notFoundPage';
  message?: string;
  onRetry?: () => void;
}

const ErrorPage = ({ type = 'default', message, onRetry }: ErrorPageProps) => {
  const { t } = useTranslation('errors');

  return (
    <div className="absolute left-0 top-0 flex size-full flex-col justify-center bg-default">
      <div className="flex size-full items-center whitespace-break-spaces bg-gradient bg-cover bg-bottom bg-no-repeat marker:flex-col">
        <div className="ml-3xl">
          <div className="mb-lg" data-testid="Error Banner">
            <p className="m-0 font-header text-display-5xl uppercase leading-none">
              <Trans
                t={t}
                i18nKey={`${type}.header`}
                components={{ br: <br /> }}
              />
            </p>
            <p className="mt-sm text-body-md">{t(`${type}.description`)}</p>
            {message ? <p className="mt-sm text-body-sm">{message}</p> : null}
          </div>
          {onRetry ? (
            <button
              className="rounded-full px-md py-sm bg-primary-default text-default hover:bg-primary-hover focus:bg-primary-hover active:bg-primary-pressed disabled:bg-primary-disabled disabled:text-disabled"
              onClick={onRetry}
            >
              {t(`${type}.retryButton`)}
            </button>
          ) : (
            <a href="/">
              <button className="rounded-full px-md py-sm bg-primary-default text-default hover:bg-primary-hover focus:bg-primary-hover active:bg-primary-pressed disabled:bg-primary-disabled disabled:text-disabled">
                {t(`${type}.retryButton`)}
              </button>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

ErrorPage.displayName = 'ErrorPage';

export { ErrorPage };
