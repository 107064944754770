import { useQuery } from '@tanstack/react-query';
import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '~/components/Button';
import { Dropdown } from '~/components/Dropdown';
import { IconButton } from '~/components/IconButton';
import { Label } from '~/components/Label/Label';
import { Separator } from '~/components/Separator';
import { cn } from '~/lib/cn';
import { mapLabelHierarchyToCheckboxTreeItem } from '~/modules/artist-roster/components/ArtistFilter/utils.ts';
import { CheckboxTree } from '~/modules/artist-roster/components/CheckboxTree';
import type { CheckboxTreeItem } from '~/modules/artist-roster/components/CheckboxTree/types.ts';
import { flattenCheckboxTree } from '~/modules/artist-roster/components/CheckboxTree/utils.ts';
import { getLabelHierarchy } from '~/modules/artist-roster/lib/labels';

import { ArtistCrossoverFilter } from './ArtistCrossoverFilter';
import type { ArtistFiltersType } from './ArtistFiltersType';
import { DEFAULT_ARTIST_FILTERS } from './ArtistFiltersType';

interface ArtistFilterPanelProps {
  originalFilters: ArtistFiltersType;
  onClose: () => void;
  onApplyFilters: (filters: ArtistFiltersType) => void;
  filtersToApply: ArtistFiltersType;
  setFiltersToApply: Dispatch<SetStateAction<ArtistFiltersType>>;
}

const ArtistFilterSidePanel = ({
  originalFilters,
  onClose,
  onApplyFilters,
  filtersToApply,
  setFiltersToApply,
}: ArtistFilterPanelProps) => {
  const { t } = useTranslation('roster');
  const [labels, setLabels] = useState<CheckboxTreeItem[]>([]);

  const { data } = useQuery({
    queryKey: ['labels-hierarchies'],
    queryFn: () => getLabelHierarchy(),
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (data?.items) {
      setLabels(
        mapLabelHierarchyToCheckboxTreeItem(
          data.items,
          new Set(originalFilters.labels.map((label) => label.labelId)),
        ),
      );
    }
  }, [data, originalFilters.labels]);

  useEffect(() => {
    setFiltersToApply((filters) => {
      return {
        ...filters,
        labels: flattenCheckboxTree(labels)
          .filter((label) => label.checked)
          .map((label) => ({
            labelId: parseInt(label.id),
            name: label.label,
          })),
      };
    });
  }, [labels, setFiltersToApply]);

  const onSelectCrossoverFilter = useCallback(
    (isCrossover: boolean) => {
      setFiltersToApply({
        ...filtersToApply,
        isCrossover,
      });
    },
    [filtersToApply, setFiltersToApply],
  );

  const onClickApply = useCallback(() => {
    onApplyFilters(filtersToApply);
    onClose();
  }, [onApplyFilters, onClose, filtersToApply]);

  const isApplyButtonDisabled =
    originalFilters.labels.length === filtersToApply.labels.length &&
    originalFilters.labels.every((value) =>
      filtersToApply.labels.includes(value),
    ) &&
    originalFilters.isCrossover === filtersToApply.isCrossover;

  return (
    <div
      className={cn(
        'fixed',
        'top-0',
        'right-0',
        'w-[400px]',
        'h-full',
        'bg-level-02',
      )}
    >
      <div className="flex flex-col gap-lg p-lg">
        <div className="flex justify-between">
          <div className="text-headline-md font-bold">{t('Filter')}</div>
          <IconButton
            iconType="x"
            iconVariant="regular"
            size="medium"
            onClick={onClose}
          />
        </div>
        <div className="flex flex-col gap-sm">
          <Label>{t('Label')}</Label>
          <Dropdown
            options={[]}
            placeholder={
              filtersToApply.labels.length > 0
                ? `Labels (${filtersToApply.labels.length.toString()})`
                : 'Select record label(s)'
            }
            contentClassName="z-10"
          >
            <CheckboxTree
              value={labels}
              setValue={setLabels}
              onChange={(value) => {
                setLabels(value);
              }}
            />
          </Dropdown>
        </div>
        <Separator />
        <div>
          <Label>{t('Artist type')}</Label>
          <ArtistCrossoverFilter
            isCrossoverFilterSelected={filtersToApply.isCrossover}
            onSelect={onSelectCrossoverFilter}
          />
        </div>
        <Separator />
      </div>

      <div
        className={cn(
          'flex justify-end',
          'gap-lg',
          'absolute',
          'bottom-0',
          'border-t-px',
          'border-white',
          'border-opacity-subtle',
          'w-full',
          'py-md',
          'px-lg',
        )}
      >
        <Button
          size="large"
          variant="tertiary"
          disabled={
            filtersToApply.labels.length === 0 && !filtersToApply.isCrossover
          }
          onClick={() => {
            setFiltersToApply(DEFAULT_ARTIST_FILTERS);
          }}
        >
          {t('Clear filters')}
        </Button>
        <Button
          size="large"
          disabled={isApplyButtonDisabled}
          onClick={onClickApply}
        >
          {t('Apply')}
        </Button>
      </div>
    </div>
  );
};

ArtistFilterSidePanel.displayName = 'ArtistFilterSidePanel';

const ArtistFilterPanel = (props: ArtistFilterPanelProps) => {
  const blurredRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    blurredRef.current?.addEventListener('click', props.onClose);
  });

  return (
    <div className="fixed left-0 top-0 z-10 size-full">
      <div
        ref={blurredRef}
        className="size-full backdrop-blur"
        data-testid="artist-filter-panel-blur-overlay"
      />
      <ArtistFilterSidePanel {...props} />
    </div>
  );
};

ArtistFilterPanel.displayName = 'ArtistFilterPanel';
export { ArtistFilterPanel };
