import { type FieldValues, useController } from 'react-hook-form';

import { RadioGroup, type RadioGroupProps } from '~/components/RadioGroup';

import { FormField, type FormFieldProps } from '../../FormField';

type FormFieldRadioGroupProps<TFormFields extends FieldValues> = Omit<
  FormFieldProps<TFormFields>,
  'children'
> &
  Omit<RadioGroupProps, 'name' | 'className'>;

export const FormFieldRadioGroup = <TFormFields extends FieldValues>({
  name,
  label,
  className,
  ...radioGroupProps
}: FormFieldRadioGroupProps<TFormFields>) => {
  const { field } = useController({ name, disabled: radioGroupProps.disabled });

  return (
    <FormField name={name} label={label} className={className} labelAsSpan>
      <RadioGroup {...field} {...radioGroupProps} />
    </FormField>
  );
};

FormFieldRadioGroup.displayName = 'FormFieldRadioGroup';
