import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { Button } from '~/components/Button';
import { InternalChips } from '~/components/FilterChip/InternalChip';
import { USER_TYPES } from '~/modules/settings/constants/Filter';
import { useFilters } from '~/modules/settings/hooks/useFilters';
import { useSearch } from '~/modules/settings/hooks/useSearch';
import { useSort } from '~/modules/settings/hooks/useSort';
import { UsersFilterForm } from '~/modules/settings/modules/user/components/UserFilter/UserFormFilter';
import type { UserListItem } from '~settings/api/lib/schemas';
import { useUserList } from '~settings/api/queries/userList';
import { FilterDrawer } from '~settings/components/Filter';
import { ListControls } from '~settings/components/ListControls';
import { Table } from '~settings/components/Table';
import { SETTINGS_ROUTES } from '~settings/config/routes';
import { usePagination } from '~settings/hooks/usePagination';

import { useUsersColumns } from './hooks/useUsersColumns';

const userEmptyArray: UserListItem[] = [];

export const ListUserPage = () => {
  const { page, setPageNumber, perPage, skip, take } = usePagination();
  const columns = useUsersColumns();
  const { t } = useTranslation('settings', { keyPrefix: 'user' });
  const navigate = useNavigate();
  const { searchTerm } = useSearch();

  const { filterData, drawerOpen, closeDrawer, openDrawer } = useFilters();
  const { sorting, setSorting, sortParams } = useSort();

  const { isLoading, isFetching, isError, userList } = useUserList({
    take,
    skip,
    ...sortParams,
    ...filterData,
    ...(searchTerm && { search: searchTerm }),
  });

  const onClick = (row: UserListItem) => {
    navigate(SETTINGS_ROUTES.USER_DETAIL(row.id));
  };

  if (isLoading) {
    return <section className="py-md">Loading...</section>;
  }
  if (isError) {
    // eslint-disable-next-line i18next/no-literal-string
    return <section className="py-md">Something went wrong.</section>;
  }

  return (
    <>
      <ListControls
        placeholder={t('search')}
        total={userList?.total ?? 0}
        page={page}
        perPage={perPage}
        entity="users"
        onFilterClick={openDrawer}
        setPageNumber={setPageNumber}
        toolbarRightAddon={
          <Button
            asChild
            variant="primary"
            className="shrink-0 self-center"
            size="large"
          >
            <Link to={SETTINGS_ROUTES.USER_CREATE}>{t('addLink')}</Link>
          </Button>
        }
        internalFilter={
          <InternalChips options={USER_TYPES} path="isInternal" />
        }
      />
      <Table
        data={userList?.data ?? userEmptyArray}
        columns={columns}
        onRowClick={onClick}
        sort={{
          sorting,
          onSortingChange: setSorting,
        }}
        loading={isFetching}
      />
      <FilterDrawer open={drawerOpen} onOpenChange={closeDrawer}>
        <UsersFilterForm open={drawerOpen} onApply={closeDrawer} />
      </FilterDrawer>
    </>
  );
};
ListUserPage.displayName = 'ListUserPage';
