import type React from 'react';

import { useIsExternalUser } from '~/hooks/useIsExternalUser';
import { env } from '~/lib/env';
import type { FilterEntity } from '~settings/hooks/useFilterChips';

import type {
  PaginationEntity,
  PaginationProps,
} from '../components/Pagination/Pagination';
import { FilterList } from './Filter/FilterList';
import { Pagination } from './Pagination';
import { Toolbar } from './Toolbar';

interface ListControlsProps extends PaginationProps {
  entity: PaginationEntity | FilterEntity;
  placeholder: string;
  setPageNumber: (page: number) => void;
  onFilterClick?: () => void;
  toolbarRightAddon?: React.ReactNode;
  internalFilter?: React.ReactNode;
  searchTerm?: string;
  setSearchTerm?: (term: string) => void;
}

export const ListControls: React.FC<ListControlsProps> = ({
  page,
  total,
  perPage,
  entity,
  placeholder,
  setPageNumber,
  onFilterClick,
  toolbarRightAddon,
  internalFilter,
}) => {
  const isCurrentUserExternal = useIsExternalUser();
  const readOnlyIsNotEnabled =
    env('VITE_CONTAINER_SETTINGS_READ_ONLY_ENABLED', false) === 'false';

  return (
    <>
      {readOnlyIsNotEnabled ? (
        <>
          <Toolbar
            placeholder={placeholder}
            rightAddon={toolbarRightAddon}
            onFilterClick={onFilterClick}
            setPageNumber={setPageNumber}
          />
          <div className="flex min-h-xl justify-start gap-sm">
            {!isCurrentUserExternal && internalFilter}
            {onFilterClick ? (
              <FilterList entity={entity as FilterEntity} />
            ) : null}
            <Pagination
              page={page}
              total={total}
              perPage={perPage}
              entity={entity}
              setPageNumber={setPageNumber}
            />
          </div>
        </>
      ) : null}
    </>
  );
};

ListControls.displayName = 'ListControls';
