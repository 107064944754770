import { useTranslation } from 'react-i18next';

import { Icon, Tag } from '~/components';
import { cn } from '~/lib/cn';

import { ArtistCrossOverTooltip } from '../ArtistCrossOverTooltip';

interface ArtistCrossoverFilterProps {
  isCrossoverFilterSelected: boolean;
  onSelect: (isCrossover: boolean) => void;
}

const ArtistCrossoverFilter = ({
  isCrossoverFilterSelected,
  onSelect,
}: ArtistCrossoverFilterProps) => {
  const { t } = useTranslation('roster');
  const selectedTagClassName = cn(
    'bg-default-inverse/full',
    'text-strong-on-light',
    'hover:outline-hover',
    'hover:bg-default-inverse/full',
    'focus:outline-hover',
    'focus:bg-default-inverse/full',
  );
  return (
    <div className="flex gap-sm py-sm">
      <Tag
        variant="secondary"
        className={isCrossoverFilterSelected ? '' : selectedTagClassName}
        disabled={!isCrossoverFilterSelected}
        onClick={() => {
          onSelect(false);
        }}
      >
        {t('all.label')}
      </Tag>
      <Tag
        variant="secondary"
        className={isCrossoverFilterSelected ? selectedTagClassName : ''}
        onClick={() => {
          onSelect(!isCrossoverFilterSelected);
        }}
      >
        {t('crossover.label')}
        <ArtistCrossOverTooltip>
          <Icon
            data-testid="crossover-info-tooltip"
            type="circle-info"
            variant="light"
            size="md"
          />
        </ArtistCrossOverTooltip>
      </Tag>
    </div>
  );
};

ArtistCrossoverFilter.displayName = 'ArtistCrossoverFilter';
export { ArtistCrossoverFilter };
