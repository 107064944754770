import type { ReactElement } from 'react';
import { useState } from 'react';
import type { PaddingOrCallback, VictoryAxisProps } from 'victory';
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory';

import { AreaGradient } from './AreaGradient';
import { LineGraphFlyout } from './LineGraphFlyout';
import { VictoryWmgTheme } from './VictoryWmgTheme';

export type LineGraphData = {
  x: string | number;
  y: string | number;
  label?: string;
}[];

interface LineGraphProps {
  data: LineGraphData;
  lineColor?: string;
  containerClassName?: string;
  xAxisProps?: VictoryAxisProps;
  yAxisProps?: VictoryAxisProps;
  tooltip?: ReactElement | undefined;
  tooltipPadding?: PaddingOrCallback;
}

const LineGraph = ({
  data,
  containerClassName = '',
  lineColor = '#000000',
  xAxisProps = {},
  yAxisProps = {},
  tooltip = undefined,
  tooltipPadding = 12,
}: LineGraphProps) => {
  const [hovered, setHovered] = useState(false);
  const areaGradientId = 'areaGradient';

  return (
    <div className={containerClassName}>
      <AreaGradient id={areaGradientId} lineColor={lineColor} />
      <VictoryChart
        padding={{ top: 5, left: 0, right: 40, bottom: 15 }}
        height={100}
        domainPadding={10}
        theme={VictoryWmgTheme}
        containerComponent={
          <VictoryVoronoiContainer
            events={{
              onMouseOver: () => setHovered(true),
              onMouseOut: () => setHovered(false),
            }}
          />
        }
      >
        <VictoryAxis crossAxis {...xAxisProps} />
        <VictoryAxis dependentAxis {...yAxisProps} />
        <VictoryArea
          labelComponent={
            <VictoryTooltip
              orientation="right"
              flyoutComponent={
                <LineGraphFlyout
                  lineColor={lineColor}
                  customFlyoutComponent={tooltip}
                />
              }
              flyoutPadding={tooltipPadding}
            />
          }
          labels={({ datum }) => datum.label ?? ''}
          interpolation="natural"
          data={data}
          style={{
            data: {
              stroke: lineColor,
              fill: `url(#${areaGradientId})`,
              fillOpacity: hovered ? 100 : 0,
            },
          }}
        />
      </VictoryChart>
    </div>
  );
};

LineGraph.displayName = 'LineGraph';
export { LineGraph };
