import { useLocation } from 'react-router-dom';

import { ErrorBoundary } from '~/modules/errors/components/ErrorBoundary';

import { Layout } from './components/Layout';
import { Router } from './router';

export function App() {
  const location = useLocation();

  return (
    <Layout>
      <ErrorBoundary key={location.pathname}>
        <Router />
      </ErrorBoundary>
    </Layout>
  );
}

App.displayName = 'DDEXConsoleApp';
