import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { env } from '~/lib/env.ts';
import { useUser } from '~/modules/auth/hooks/useUser.ts';
import { BrandContext } from '~/modules/branding';
import { GlobalSearch } from '~/modules/global-search';
import { Nav } from '~/modules/navigation/components';
import { PulseInsights } from '~/modules/survey';

export function PrimaryLayout(): React.ReactNode {
  const { email } = useUser();
  const { i18n } = useTranslation();
  const brand = useContext(BrandContext);

  return (
    <>
      <Nav />
      {brand.name === 'WMG' && env('VITE_GLOBAL_SEARCH_ENABLED') ? (
        <GlobalSearch>
          <Outlet />
        </GlobalSearch>
      ) : (
        <Outlet />
      )}
      <PulseInsights
        email={email}
        lang={i18n.language}
        token={env('VITE_PULSE_INSIGHTS_TOKEN', false)}
      />
    </>
  );
}
PrimaryLayout.displayName = 'PrimaryLayout';
