import type React from 'react';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'react-use';

import { Button } from '~/components/Button';
import { Icon } from '~/components/Icon';
import { Input } from '~/components/Input/Input';

interface ToolbarProps {
  onFilterClick?: () => void;
  placeholder: string;
  rightAddon?: React.ReactNode;
  setPageNumber?: (pageNumber: number) => void;
}

export const Toolbar: React.FC<ToolbarProps> = ({
  onFilterClick,
  placeholder,
  rightAddon,
  setPageNumber,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchTerm = searchParams.get('search') || '';
  const [localSearchTerm, setLocalSearchTerm] = useState(searchTerm);

  const handleSearch = (debouncedValue: string) => {
    if (debouncedValue !== searchTerm) {
      if (debouncedValue) {
        setSearchParams({ search: debouncedValue });
      } else {
        searchParams.delete('search');
        setSearchParams(searchParams);
      }
      setPageNumber?.(1);
    }
  };

  useDebounce(
    () => {
      handleSearch(localSearchTerm);
    },
    300,
    [localSearchTerm],
  );

  const handleInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value;
    setLocalSearchTerm(value);
  };

  useEffect(() => {
    setLocalSearchTerm(searchTerm);
  }, [searchTerm]);

  return (
    <div className="flex gap-md py-lg">
      {onFilterClick ? (
        <Button
          onClick={onFilterClick}
          variant="tertiary"
          size="large"
          className="rounded-sm border px-md bg-level-03 border-emphasis-subtle hover:bg-level-04"
        >
          <Icon type="sliders-simple" variant="solid" size="md" />
        </Button>
      ) : null}

      <div className="group relative w-full">
        <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-sm">
          <Icon
            type="search"
            size="md"
            className="flex items-center justify-center p-sm text-subtle group-hover:text-default"
          />
        </div>
        <Input
          placeholder={placeholder}
          type="search"
          aria-controls="tool-bar-search"
          value={localSearchTerm}
          onChange={handleInputChange}
          rounded="small"
          className="border ps-2xl !outline-none bg-level-03 border-emphasis-subtle placeholder:text-subtle hover:bg-level-04 group-hover:placeholder:text-default"
        />
      </div>
      {rightAddon}
    </div>
  );
};

Toolbar.displayName = 'Toolbar';
