export const SETTINGS_ROUTES = {
  ROOT: '/settings',

  USER_LIST: '/settings/users',
  USER_CREATE: '/settings/users/create',
  USER_DETAIL: (userId: string) => `/settings/users/${userId}` as const,
  USER_EDIT: (userId: string) => `/settings/users/${userId}/edit` as const,

  LABEL_LIST: '/settings/labels',
  LABEL_CREATE: '/settings/labels/create',
  LABEL_DETAIL: (labelId: string) => `/settings/labels/${labelId}` as const,

  LABEL_GROUP_LIST: '/settings/label-groups',
} as const;
