import type { FC } from 'react';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { cn } from '~/lib/cn';
import { useKeyboardNavigationContext } from '~/modules/global-search/hooks/useKeyboardNavigationContext';

import { useGlobalSearchContext } from '../../hooks/useGlobalSearchContext';
import type { SearchResultCategoryKeysWithAllResults } from '../../lib/types';
import { CategoryButton } from './CategoryButton';

export type CategorySelectorProps = {
  categories: Array<[SearchResultCategoryKeysWithAllResults, boolean]>;
  selectedCategory: string;
};

export const CategorySelector: FC<CategorySelectorProps> = ({
  categories,
  selectedCategory,
}) => {
  const { t } = useTranslation('wmgone');
  const { isLoading, setSelectedCategory } = useGlobalSearchContext();
  const { elementRefs, registerElements } = useKeyboardNavigationContext();
  const buttonRefs = useRef<HTMLButtonElement[]>([]);
  const registerButtonsOnce = useRef(false);

  // Register category buttons once on mount.
  useEffect(() => {
    if (
      buttonRefs.current.length === categories.length &&
      !registerButtonsOnce.current
    ) {
      registerElements({ type: 'categories', payload: buttonRefs.current });
      registerButtonsOnce.current = true;
    }
  }, [registerElements, categories, elementRefs]);

  return (
    <div
      className={cn(
        'flex flex-row gap-xs rounded-full border-t-px p-sm shadow-lg drop-shadow-lg border-t-emphasis-subtle',
        'bg-[linear-gradient(259.72deg,oklch(var(--color-emphasis)/0.2)-90.82%,oklch(var(--bg-default)/0.2)35.55%),linear-gradient(0deg,oklch(var(--bg-level-04)),oklch(var(--bg-level-04)))]',
      )}
    >
      {categories.map(([category, isEnabled], index) => (
        <CategoryButton
          ref={(element) => {
            if (element && !registerButtonsOnce.current) {
              buttonRefs.current[index] = element;
            }
          }}
          key={category}
          data-category={category}
          isActive={selectedCategory === category}
          onClick={() => setSelectedCategory(category)}
          disabled={(isLoading && category !== 'allResults') || !isEnabled}
        >
          {t(`globalSearch.categories.${category}`)}
        </CategoryButton>
      ))}
    </div>
  );
};

CategorySelector.displayName = 'CategorySelector';
