import { Icon } from '~/components';

interface PerformanceDeltaLabelProps {
  delta?: number;
  dateRange?: string;
}

export function PerformanceDeltaLabel({
  delta,
  dateRange,
}: PerformanceDeltaLabelProps) {
  if (!delta) {
    return null;
  }

  return (
    <div
      className={`flex items-center gap-xs ${delta > 0 ? 'text-success-default' : 'text-error-default'}`}
    >
      <Icon type={delta > 0 ? 'caret-up' : 'caret-down'} size="sm" />
      <span className="text-body-xs font-bold">{`${delta}%`}</span>
      {dateRange ? (
        <span className="text-body-xs font-bold">{dateRange}</span>
      ) : null}
    </div>
  );
}
PerformanceDeltaLabel.displayName = 'PerformanceDeltaLabel';
