import { useMemo } from 'react';

import { PageButtons } from '~/modules/pagination/PageButtons';

import { usePaginationMessage } from '../../hooks/usePagination';
export type PaginationEntity = 'sublabels' | 'labels' | 'users' | 'labelGroups';

export interface PaginationProps {
  page: number;
  perPage: number;
  setPageNumber: (page: number) => void;
  total: number;
  entity: PaginationEntity;
}

export const Pagination = ({
  page,
  total,
  entity,
  perPage,
  setPageNumber,
}: PaginationProps) => {
  const totalPages = useMemo(
    () => Math.ceil(total / perPage),
    [total, perPage],
  );

  const message = usePaginationMessage(page, perPage, total, entity);

  if (total === 0) {
    return null;
  }

  return (
    <div className="ml-auto">
      <div className="inline-flex items-center">
        <span className="text-nowrap pr-sm text-body-sm font-semibold text-disabled">
          {message}
        </span>
        <PageButtons
          onNext={() => setPageNumber(page + 1)}
          onPrevious={() => setPageNumber(page - 1)}
          currentPage={page}
          totalPages={totalPages}
        />
      </div>
    </div>
  );
};

Pagination.displayName = 'Pagination';
