export const searchFilter = (search: string): string => {
  // Check if search wrapped to double quotes -> do exact match
  if (search.startsWith('"') && search.endsWith('"')) {
    const s = search.slice(1, -1);
    return (
      `track_title = '${s}' or ` +
      `artist = '${s}' or ` +
      `isrc = '${s.toUpperCase()}' or ` +
      `array_contains('${s}'::VARIANT, gpid)`
    );
  }
  return (
    `track_title ilike '${search}%' or ` +
    `artist ilike '${search}%' or ` +
    `isrc ilike '${search}%' or ` +
    `concat('|', array_to_string(gpid, '|')) ilike '%|${search}%'`
  );
};
