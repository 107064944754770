import { type FC, Fragment, useMemo } from 'react';

import { ArtistsList } from '~/modules/global-search/components/ArtistsList';
import { CategorySelector } from '~/modules/global-search/components/CategorySelector';
import { EmptyResult } from '~/modules/global-search/components/EmptyResult';
import { LabelsList } from '~/modules/global-search/components/LabelsList';
import { Skeletons } from '~/modules/global-search/components/Skeletons/Skeletons';
import { ToolsList } from '~/modules/global-search/components/ToolsList';
import { TracksList } from '~/modules/global-search/components/TracksList/TracksList';
import { CATEGORIES } from '~/modules/global-search/contexts/GlobalSearchContext';

import { useGlobalSearchContext } from '../../hooks/useGlobalSearchContext';
import { MAX_ITEMS_UNDER_ALL_RESULTS } from '../../lib/constants';
import type {
  GlobalSearchResultItems,
  SearchResultCategoryKeysWithAllResults,
} from '../../lib/types';

export const SearchResults: FC = () => {
  const {
    searchResults: items,
    setSelectedCategory,
    selectedCategory,
    isLoading,
  } = useGlobalSearchContext();

  const categories: Array<[SearchResultCategoryKeysWithAllResults, boolean]> =
    useMemo(
      () =>
        CATEGORIES.map((category) => {
          const isEnabled =
            category === 'allResults' || !!items[category]?.length;

          // If selected category has no results, reset back to "All Results"
          if (
            category !== 'allResults' &&
            selectedCategory === category &&
            !isEnabled
          ) {
            setSelectedCategory(CATEGORIES[0]);
          }

          return [category, isEnabled];
        }),
      [items, selectedCategory, setSelectedCategory],
    );

  const isEmptyResult = useMemo(
    () => !Object.values(items).find((item) => item?.length),
    [items],
  );

  const filteredCategory = useMemo(() => {
    // If selected category is "All Results", show only 5 results per category.
    if (selectedCategory === 'allResults') {
      return Object.fromEntries(
        Object.entries(items).map(([key, value]) => [
          key,
          value.slice(0, MAX_ITEMS_UNDER_ALL_RESULTS),
        ]),
      );
    }

    return Object.fromEntries(
      Object.entries(items).map(([key, value]) => [
        key,
        selectedCategory === key ? value : [],
      ]),
    );
  }, [items, selectedCategory]) as GlobalSearchResultItems;

  return (
    <div
      role="listbox"
      aria-live="polite"
      className="absolute z-50 mt-xs flex max-h-[80dvh] w-full flex-col gap-md overflow-auto rounded-sm px-xl py-lg bg-level-02"
    >
      <CategorySelector
        categories={categories}
        selectedCategory={selectedCategory}
      />
      {isLoading ? (
        <Skeletons />
      ) : isEmptyResult ? (
        <EmptyResult />
      ) : (
        <Fragment>
          <ArtistsList items={filteredCategory.artists || []} />
          <TracksList items={[]} />
          <LabelsList items={filteredCategory.labels || []} />
          <ToolsList items={filteredCategory.tools || []} />
        </Fragment>
      )}
    </div>
  );
};

SearchResults.displayName = 'SearchResults';
