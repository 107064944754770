import { useCallback, useEffect, useRef, useState } from 'react';

import { useKeyboardNavigationContext } from '~/modules/global-search/hooks/useKeyboardNavigationContext';

import { MAX_ITEMS_UNDER_ALL_RESULTS } from '../lib/constants';
import type {
  ArtistLinkItem,
  LinkItem,
  SearchResultCategoryKeys,
} from '../lib/types';
import { useGlobalSearchContext } from './useGlobalSearchContext';

export const useKeyboardCategoryHelper = ({
  category,
  items,
}: {
  category: SearchResultCategoryKeys;
  items: (ArtistLinkItem | LinkItem)[];
}) => {
  const { registerElements } = useKeyboardNavigationContext();
  const { selectedCategory, searchResults } = useGlobalSearchContext();
  const elementRefs = useRef<(HTMLButtonElement | HTMLAnchorElement)[]>([]);
  const [isRendering, setIsRendering] = useState(true);

  /**
   * Register the order of the categories with the KeyboardNavigationContext.
   * This is used to determine the order of the categories when navigating with the keyboard.
   * This is only done once, when the component mounts.
   */
  useEffect(() => {
    if (isRendering) {
      registerElements({
        type: 'categoryIndex',
        payload: category,
      });
    }
  }, [isRendering, category, registerElements]);

  const registerElement = useCallback(
    ({
      element,
      index,
    }: {
      element: HTMLAnchorElement | HTMLButtonElement | null;
      index: number;
    }) => {
      if (!element) {
        return;
      }

      elementRefs.current[index] = element;

      // Account for the "Show all <category> results" button in the itemsLength.
      const itemsLength =
        selectedCategory === 'allResults' &&
        searchResults[category] &&
        searchResults[category].length > MAX_ITEMS_UNDER_ALL_RESULTS
          ? items.length
          : items.length - 1;

      if (index === itemsLength) {
        // Slice the list to remove references to Search Result elements that no longer exist.
        elementRefs.current = elementRefs.current.slice(0, itemsLength + 1);
        setIsRendering(false);
      }
    },
    [items, category, selectedCategory, searchResults],
  );

  /**
   * If active category is NOT this Category or "All Results",
   * unregister the Search Result elements from the KeyboardNavigationContext.
   */
  useEffect(() => {
    if (![category, 'allResults'].includes(selectedCategory)) {
      registerElements({
        type: 'categoryResults',
        payload: {
          category,
          results: [],
        },
      });
    }
  }, [category, selectedCategory, registerElements]);

  /**
   * If active category IS this Category or "All Results",
   * register the Search Result elements with the KeyboardNavigationContext.
   */
  useEffect(() => {
    if (!isRendering && [category, 'allResults'].includes(selectedCategory)) {
      registerElements({
        type: 'categoryResults',
        payload: {
          category,
          results: elementRefs.current,
        },
      });
    }
  }, [category, selectedCategory, registerElements, isRendering, items]);

  return {
    registerElement,
  };
};

useKeyboardCategoryHelper.displayName = 'useKeyboardCategoryHelper';
