import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Separator } from '~/components';
import { Spinner } from '~/components/Spinner';
import { useAnalytics } from '~/modules/monitoring/amplitude';
import { Breadcrumbs } from '~/modules/navigation/components/Breadcrumbs';

import { useArtistDetailsQuery } from '../../hooks/useArtistDetailsQuery';
import { ArtistDetailsHeader } from '../ArtistDetailsHeader/ArtistDetailsHeader';
import { TrackList } from '../TrackList/TrackList';

export function ArtistDetails() {
  const { t } = useTranslation('roster');
  const { uaid } = useParams<{ uaid: string }>();
  const { trackAnalytics } = useAnalytics();
  const {
    data: artistData,
    error,
    isLoading: isLoadingArtist,
  } = useArtistDetailsQuery({ uaid });

  useEffect(() => {
    if (artistData) {
      trackAnalytics('artistView', {
        artist_id: artistData.id,
        artist_name: artistData.name,
      });
    }
  }, [trackAnalytics, artistData]);

  if (!uaid || isNaN(Number(uaid))) {
    return (
      <div className="flex w-full items-center justify-start p-2xl">
        <p className="m-0 font-header text-display-xl leading-none text-default">
          {t('errors.invalidArtistId')}
        </p>
      </div>
    );
  }

  // @TODO WMG1-247 Add system notification component
  if (error) {
    return (
      <div className="flex w-full items-center justify-start p-2xl">
        <p className="m-0 font-header text-display-xl leading-none text-default">
          {t('errors.loadingArtist')}
        </p>
      </div>
    );
  }

  if (isLoadingArtist) {
    return (
      <div className="flex w-full items-center justify-center p-2xl">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-between">
      <div className="flex w-full flex-col items-center justify-between gap-lg">
        <Breadcrumbs.Root className="flex w-full items-center justify-start gap-sm text-body-xs">
          <Breadcrumbs.Item
            to="/roster"
            className="font-semibold underline transition text-hover hover:text-white"
          >
            {t('header.title')}
          </Breadcrumbs.Item>
          <Breadcrumbs.Item>{artistData?.name}</Breadcrumbs.Item>
        </Breadcrumbs.Root>
        <ArtistDetailsHeader artistData={artistData} />
        <Separator className="mx-0 my-sm w-full" />
        <TrackList uaid={uaid} artistData={artistData} />
      </div>
    </div>
  );
}

ArtistDetails.displayName = 'ArtistDetails';
