import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@radix-ui/react-accordion';
import type { ReactNode } from 'react';

import { Icon } from '~/components';

type AccordionContentBoxProps = {
  title: string;
  children: ReactNode;
  defaultOpen?: boolean;
  className?: string;
};

export function AccordionContentBox({
  title,
  children,
  defaultOpen = true,
  className = '',
}: AccordionContentBoxProps) {
  const contentId = `accordion-content-${title.replace(/\s+/g, '-').toLowerCase()}`;

  return (
    <Accordion
      type="single"
      defaultValue={defaultOpen ? contentId : undefined}
      collapsible
    >
      <AccordionItem
        value={contentId}
        className={`flex flex-col gap-lg rounded-sm p-lg bg-level-02 ${className}`}
      >
        <AccordionTrigger className="group flex items-center justify-between">
          <div
            id={contentId}
            className="text-headline-sm font-semibold leading-tight"
          >
            {title}
          </div>
          <Icon
            className="transition-transform group-data-[state=open]:rotate-180"
            type="chevron-down"
          />
        </AccordionTrigger>
        <AccordionContent>{children}</AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}

AccordionContentBox.displayName = 'AccordionContentBox';
