import type { Direction } from '../types/label-perf';

export const getTrendDirection = (value: number): Direction => {
  if (value > 0) {
    return 'up';
  }
  if (value < 0) {
    return 'down';
  }
  return 'none';
};
