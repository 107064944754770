import { cn } from '~/lib/cn';

import { BACKGROUND_COLORS } from './constants';
import { arraySum } from './utils';

interface HorizontalBarProps {
  percentageValues: number[];
}

export const HorizontalBar = ({ percentageValues }: HorizontalBarProps) => {
  const totalPct = arraySum(percentageValues);

  return (
    <div className="flex w-full">
      <div className="flex h-lg w-full justify-items-center gap-xs rounded-full bg-emphasis-default">
        {percentageValues.map((pct, index) => {
          return (
            <div
              key={index}
              className={cn('h-full grow-0', BACKGROUND_COLORS[index % 4], {
                'rounded-l-full': index === 0,
                'rounded-r-full': index === percentageValues.length - 1,
              })}
              style={{ width: `${pct}%` }}
            />
          );
        })}
        <div className="flex grow-0 flex-col justify-center px-xs">{`${totalPct}%`}</div>
      </div>
    </div>
  );
};
HorizontalBar.displayName = 'HorizontalBar';
