import * as Accordion from '@radix-ui/react-accordion';
import type { CheckedState } from '@radix-ui/react-checkbox';
import type { Dispatch, FC, SetStateAction } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '~/components';
import { Button } from '~/components/Button';
import { Checkbox } from '~/components/Checkbox';
import { ArtistSearchBar } from '~/modules/artist-roster/components/ArtistSearchBar/ArtistSearchBar.tsx';

import type { CheckboxTreeItem } from './types.ts';
import {
  applyTreeValue,
  calculateChecked,
  isAnyItemChecked,
  searchCheckboxTreeWithStructure,
} from './utils.ts';

type CheckboxTreeProps = {
  value: CheckboxTreeItem[];
  setValue: Dispatch<SetStateAction<CheckboxTreeItem[]>>;
  onChange?(value: CheckboxTreeItem[]): void;
  isRoot?: boolean;
};

export const CheckboxTree: FC<CheckboxTreeProps> = ({
  value,
  setValue,
  isRoot = true,
  onChange,
}) => {
  const { t } = useTranslation('roster');

  const headerClassNames = `flex py-xs pl-md pr-lg flex-row items-center ${isRoot ? 'data-[state=closed]:bg-level-03' : ''}`;
  const triggerClassNames =
    'font-semibold flex flex-1 h-2xl items-center justify-end text-body-sm [&>i]:data-[state=open]:rotate-180';

  const onCheckedChange = useCallback(
    (
      state: CheckedState,
      treeItem: CheckboxTreeItem | CheckboxTreeItem[],
      index: number = 0,
    ) => {
      const newValue = [...value];

      if (Array.isArray(treeItem)) {
        treeItem.forEach((item) => {
          newValue[value.indexOf(item)] = applyTreeValue(Boolean(state), item);
        });
      } else {
        newValue[index] = applyTreeValue(Boolean(state), treeItem);
      }
      onChange?.(newValue);
    },
    [value, onChange],
  );

  const onExpansionChange = useCallback(
    (state: boolean, index: number) => {
      const newValue = [...value];
      newValue[index].isExpanded = state;
      onChange?.(newValue);
    },
    [value, onChange],
  );

  const onChangeInTree = useCallback(
    (treeItem: CheckboxTreeItem[], index: number) => {
      const newValue = [...value];
      newValue[index].children = treeItem;
      onChange?.(newValue);
    },
    [value, onChange],
  );

  return (
    <>
      {isRoot ? (
        <div className="flex flex-col gap-sm p-sm bg-level-03">
          <ArtistSearchBar
            placeholder="Search"
            onSubmit={() => {}}
            onChange={(val) => {
              setValue(searchCheckboxTreeWithStructure(value, val));
            }}
            onClear={() => {
              setValue(searchCheckboxTreeWithStructure(value, ''));
            }}
            className="h-xl border bg-level-04 border-default hover:bg-level-04 focus:bg-level-04"
          />
          {isAnyItemChecked(value) ? (
            <Button
              variant="secondary"
              className="border-2xs"
              onClick={() => {
                onCheckedChange(
                  false,
                  value.filter((item) => item.isVisible),
                );
              }}
            >
              {t('Clear all')}
            </Button>
          ) : (
            <Button
              variant="secondary"
              className="border-2xs"
              onClick={() => {
                onCheckedChange(
                  true,
                  value.filter((item) => item.isVisible),
                );
              }}
            >
              {t('Select all')}
            </Button>
          )}
        </div>
      ) : null}
      <Accordion.Root
        type="multiple"
        className="w-full"
        value={value
          .filter((item) => item.isExpanded)
          .map((item) => item.label)}
      >
        {isRoot && !value.some((item) => item.isVisible) ? (
          <div className="p-md text-center text-body-sm text-subtle">
            {t('No results found')}
          </div>
        ) : null}
        {value
          .filter((item) => item.isVisible)
          .map((item) => (
            <Accordion.Item value={item.label} key={`${item.id}:${item.label}`}>
              <Accordion.Header className={headerClassNames}>
                <Checkbox
                  className="px-sm py-xs text-body-sm tracking-wide"
                  label={item.label}
                  checked={calculateChecked(item)}
                  onCheckedChange={(state) =>
                    onCheckedChange(state, item, value.indexOf(item))
                  }
                />
                <Accordion.Trigger
                  className={triggerClassNames}
                  onClick={() => {
                    onExpansionChange(!item.isExpanded, value.indexOf(item));
                  }}
                  disabled={!item.children?.length}
                >
                  {item.children?.length ? (
                    <Icon
                      type="chevron-down"
                      variant="solid"
                      className="text-white"
                      size="md"
                      aria-hidden
                      aria-label="dropdown-chevron"
                    />
                  ) : null}
                </Accordion.Trigger>
              </Accordion.Header>
              {item.children?.length ? (
                <Accordion.Content className="pl-lg">
                  <CheckboxTree
                    value={item.children}
                    setValue={setValue}
                    isRoot={false}
                    onChange={(newValue) =>
                      onChangeInTree(newValue, value.indexOf(item))
                    }
                  />
                </Accordion.Content>
              ) : null}
            </Accordion.Item>
          ))}
      </Accordion.Root>
    </>
  );
};

CheckboxTree.displayName = 'CheckboxTree';
