import { Skeleton } from '~/components/Skeleton/Skeleton';

export const Skeletons = () => {
  const SkeletonCategory = (
    <div className="space-y-sm border-b-px pb-lg border-white last:border-0 last:pb-0">
      <Skeleton className="h-md w-[12em] rounded-sm bg-level-05" />
      <span className="flex gap-sm">
        <Skeleton className="size-xl rounded-full bg-level-05" />
        <Skeleton className="h-xl grow rounded-sm bg-level-05" />
      </span>
      <span className="flex gap-sm">
        <Skeleton className="size-xl rounded-full bg-level-05" />
        <Skeleton className="h-xl grow rounded-sm bg-level-05" />
      </span>
      <Skeleton className="h-md w-[12em] rounded-sm bg-level-05" />
    </div>
  );
  return (
    <div className="space-y-lg">
      {SkeletonCategory}
      {SkeletonCategory}
    </div>
  );
};

Skeletons.displayName = 'Skeletons';
