import type { FC } from 'react';
import { useMemo } from 'react';

import { Skeleton } from '~/components/Skeleton';
import { cn } from '~/lib/cn';
import { TableCell, TableRow } from '~/modules/artist-roster/components/Table';

interface SkeletonTableProps {
  size: number;
  headers: { id: string }[];
}

export const SkeletonTable: FC<SkeletonTableProps> = ({ size, headers }) => {
  const items = useMemo(() => Array.from({ length: size }), [size]);

  return items.map((_, i) => (
    <TableRow key={i} data-testid={`skeleton-table-row-${i}`}>
      {headers.map((cell) => (
        <TableCell
          key={cell.id}
          className={cn(
            'py-md first:rounded-l-sm first:pl-lg last:rounded-r-sm',
            i % 2 === 0 && 'bg-default-inverse/default',
          )}
        >
          <div className="space-y-sm">
            <Skeleton className="h-md w-[12em] rounded-sm bg-level-04" />
            <Skeleton className="h-md w-[10em] rounded-sm bg-level-04" />
          </div>
        </TableCell>
      ))}
    </TableRow>
  ));
};
