import { lazy, Suspense, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '~/components';

import {
  exportCSV,
  getNotificationById,
  getPageData,
} from '../../api/dashboard';
import { MenuItem } from '../../components/DataGrid/components/MenuItem';
import {
  type ColDef,
  ColumnFilterType,
  type DataGridState,
  type ExportCSVPayload,
  type ExportCSVResponse,
  type FilterModel,
} from '../../components/DataGrid/types';
import type {
  DataLoader,
  DataQuery,
} from '../../components/DataGrid/types/query';
import {
  arrayUnique,
  camelToSnake,
  isValidDate,
  serialize,
} from '../../components/DataGrid/utils';
import type { DashboardRow, DataRowKeyWithPrefix } from '../../types/dashoard';
import { columnDefs } from './columnsDefs';
import { filterColumns } from './filters';
import { searchFilter } from './utils/search';

const LazyAgGridReact = lazy(() =>
  import('../../components/DataGrid').then((module) => ({
    default: module.DataGrid,
  })),
);

const defaultColDef: ColDef = {
  sortable: false,
  resizable: true,
};

const Dashboard = (): JSX.Element => {
  const { t } = useTranslation('label-perf');
  const [search, setSearch] = useState<string>('');
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<Partial<DashboardRow>[]>();
  const [bulkMode, setBulkMode] = useState<boolean>(true);
  const [bulkLoading, setBulkLoading] = useState<boolean>(false);

  const ContextToolbar = useMemo(() => {
    // No Context Toolbar with no selected rows
    if (!selectedRows || selectedRows?.length === 0) {
      return undefined;
    }

    const toReviewISRCs = selectedRows
      .filter((row) => !row.trackLock)
      .map((row) => row.isrc as string);

    const lockedISRCs = selectedRows
      .filter((row) => row.trackLock)
      .filter((row) => {
        // if (isAdmin) return true; // Admin can unlock any track
        return row.trackLock!.self; // Only lock owner can unlock
      })
      .map((row) => row.isrc as string);

    if (selectedRows.filter((row) => row.isrc).length === 0) {
      return (
        <div className="rounded-lg border px-md py-sm text-center shadow-sm">
          <div className="mb-xs">
            <Icon
              className="mr-sm text-error-default"
              type="triangle-exclamation"
            />{' '}
            {t('ISRC must be in the columns')}
            {t('list to activate bulk actions')}
          </div>
          <div className="group relative mb-xs w-[5em] cursor-pointer rounded-md border px-sm py-xs text-body-sm hover:bg-secondary-default hover:text-white focus:outline-none">
            <button onClick={() => setBulkMode(false)}>OK</button>
          </div>
        </div>
      );
    }

    const bulkLockTracksHandler = async (isrcs: string[]): Promise<void> => {
      console.log(isrcs);
      setBulkLoading(true);
      // await bulkLockTracks(client, isrcs);
      setBulkLoading(false);
      // Remove selection
      setBulkMode(false);
      setTimeout(() => setBulkMode(true), 0);
      // Refresh data
      // setEnrichmentRefresher(new Date().toISOString());
    };

    const bulkUnlockTracksHandler = async (isrcs: string[]): Promise<void> => {
      console.log(isrcs);
      setBulkLoading(true);
      // await bulkUnlockTracks(client, isrcs);
      setBulkLoading(false);
      // Remove selection
      setBulkMode(false);
      setTimeout(() => setBulkMode(true), 0);
      // Refresh data
      // setEnrichmentRefresher(new Date().toISOString());
    };

    return (
      <div className="flex items-center pt-lg">
        {bulkLoading ? (
          <div className="flex items-center align-middle">
            <Icon type="spin" className="mr-sm" /> Processing...
          </div>
        ) : null}
        {!bulkLoading && (
          <>
            <p className="mr-sm text-white">{t('Bulk actions')}:</p>
            <div className="flex items-center gap-x-sm">
              <MenuItem
                counter={toReviewISRCs.length}
                disabled={toReviewISRCs.length === 0}
                icon="eye"
                onClick={() => bulkLockTracksHandler(toReviewISRCs)}
                title="In Review"
              />
              <MenuItem
                counter={lockedISRCs.length}
                disabled={lockedISRCs.length === 0}
                icon="eye"
                onClick={() => bulkUnlockTracksHandler(lockedISRCs)}
                title="Cancel Review"
              />
            </div>
          </>
        )}
      </div>
    );
  }, [bulkLoading, selectedRows, t]);

  const combineFilters = (
    _search: string | undefined,
    filter: string | undefined,
  ): string => {
    const searchCriteria = _search ? searchFilter(_search) : '';
    const serializedFilter = filter || '';

    if (searchCriteria && serializedFilter) {
      return `${serializedFilter} AND (${searchCriteria})`;
    }

    if (searchCriteria) {
      return searchCriteria;
    }

    return serializedFilter.trim();
  };

  const dataLoader: DataLoader<Partial<DashboardRow>> = useCallback(
    (query: DataQuery<Partial<DashboardRow>>) => {
      const combinedFilter = combineFilters(search, query.filter);
      return getPageData({
        ...query,
        offset: query.nextPageToken
          ? Number(query.nextPageToken.split('=')[1])
          : 0,
        select: query.select,
        order: query.order?.map((s) =>
          s.direction === 'asc' ? s.field : `-${s.field}`,
        ) as DataRowKeyWithPrefix[],
        filter: combinedFilter,
      });
    },
    [search],
  );

  const downloadCSVFile = (url: string): void => {
    try {
      const downloadAnchor = document.createElement('a');
      downloadAnchor.style.display = 'none';
      downloadAnchor.href = url;
      downloadAnchor.download = 'CSV Export';
      downloadAnchor.click();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error downloading CSV file:', error);
    }
  };

  const exportCSVFile = async (context: DataGridState): Promise<void> => {
    const { select, order, filter } = context;
    const serializedFilter = serialize(filter);
    const combinedFilter = combineFilters(search, serializedFilter);

    const gridData: ExportCSVPayload = {
      select: arrayUnique(select.map((s) => camelToSnake(s))),
      order: order.map(
        (o) => `${o.direction === 'desc' ? '-' : ''}${camelToSnake(o.field)}`,
      ),
      filter: combinedFilter,
    };

    setExportLoading(true);
    const res = await exportCSV(gridData).catch((error) => {
      // eslint-disable-next-line no-console
      console.log('error', error);
      setExportLoading(false);
    });
    const url = (res as ExportCSVResponse)?.url;

    if (url) {
      downloadCSVFile(url);
      setExportLoading(false);
    }
  };

  const onExport = (context: DataGridState): void => {
    exportCSVFile(context).catch((error) => {
      // eslint-disable-next-line no-console
      console.log('error', error);
    });
  };

  const urlFiltersLoader = async (): Promise<FilterModel[]> => {
    const q = new URLSearchParams(window.location.search);
    const alertId = q.get('alertId');
    const ts = q.get('ts');
    if (alertId) {
      try {
        const notification = await getNotificationById(Number(alertId)).catch(
          (error) => {
            // eslint-disable-next-line no-console
            console.error('Error loading notification:', error);
            return { model: '[]' };
          },
        );
        const filterModel = JSON.parse(
          notification.model ?? '[]',
        ) as FilterModel[];
        if (ts && isValidDate(ts)) {
          filterModel.push({
            arrayField: false,
            field: 'latestProductTs',
            multiValue: false,
            name: 'Updates since date',
            operand: {
              value: 'greaterOrEqualTo',
              label: 'Greater or equal to',
            },
            type: ColumnFilterType.dateRange,
            value: ts,
          });
        }
        return filterModel;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error loading filters:', error);
      }
    }
    return [];
  };

  return (
    <>
      <div className="flex h-[82vh] flex-wrap">
        <Suspense fallback={<div>Loading Data Grid...</div>}>
          <LazyAgGridReact
            columnDefs={columnDefs}
            contextToolbarElement={ContextToolbar}
            dataLoader={dataLoader}
            defaultColDef={defaultColDef}
            enableConfig={true}
            enableExpand={true}
            enableExport={true}
            enableFilters={true}
            exportLoading={exportLoading}
            filterColumns={filterColumns}
            filtersLoader={urlFiltersLoader}
            id="main"
            limit={100}
            onExport={onExport}
            onSearch={setSearch}
            onSelectionChanged={setSelectedRows}
            selectionMode={bulkMode ? 'multi' : undefined}
            viewIcon="microscope"
            views={['Territories', 'Derivative', 'TrackRollup']}
            onViewChanged={(view) => {
              if (view === 'Territories') {
                return;
              }
              if (view === 'Derivative') {
                // void router.push('/derivative');
              }
              if (view === 'TrackRollup') {
                // void router.push('/track-rollup');
              }
            }}
          />
        </Suspense>
      </div>
    </>
  );
};

Dashboard.displayName = 'Dashboard';

export { Dashboard };
