import type { ColumnDef } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, Tooltip } from '~/components';
import { env } from '~/lib/env';

import type { AssetFamily } from '../../lib/types';
import { PerformanceDeltaLabel } from './PerformanceDeltaLabel';
import { ReleaseTag } from './ReleaseTag';
import { TimeRangeLabel } from './TimeRangeLabel';
import { TrackTableLinkMenu } from './TrackTableLinkMenu';

type TimeRange = 'TW' | 'TM' | 'TY';

export const useTrackTableColumns = ({ range }: { range: TimeRange }) => {
  const { t } = useTranslation('roster');

  const isAssetPerformanceEnabled =
    env('VITE_WMG_ASSET_PERFORMANCE_ENABLED') === 'true';
  const today = useMemo(() => new Date(), []);

  return useMemo(() => {
    const columnHelper = createColumnHelper<AssetFamily>();

    const formatMetric = (
      row: AssetFamily,
      metricNumber: number | undefined,
    ) => {
      const releaseDate = row.initialReleaseDate
        ? new Date(row.initialReleaseDate)
        : null;
      if (
        releaseDate &&
        releaseDate < today &&
        (!!metricNumber || metricNumber === 0) &&
        Number.isFinite(metricNumber)
      ) {
        return metricNumber.toLocaleString();
      } else {
        return null;
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const trackTableColumns: ColumnDef<AssetFamily, any>[] = [
      columnHelper.accessor('title', {
        id: 'title',
        header: () => t('trackList.header.trackTitle'),
        cell: ({ row }) => (
          <div className="flex items-center gap-sm">
            <div className="font-bold">{row.original.title}</div>
            <ReleaseTag releaseDateString={row.original.initialReleaseDate} />
          </div>
        ),
      }),
      // @TODO WMG1-272 Add sorting to the track table
      columnHelper.accessor('initialReleaseDate', {
        id: 'initialReleaseDate',
        header: () => (
          <div className="flex items-center gap-sm">
            <span>{t('trackList.header.releaseDate')}</span>
            <Tooltip
              content={
                <span className="font-semibold">
                  {t('trackList.tooltips.sortBy', {
                    field: t('trackList.header.releaseDate'),
                  })}
                </span>
              }
              delayDuration={0}
              sideOffset={16}
              side="top"
              align="end"
              size="md"
            >
              <Icon type="arrow-down" size="sm" />
            </Tooltip>
          </div>
        ),
        cell: ({ row }) => (
          <div>
            {row.original.initialReleaseDate ? (
              new Date(row.original.initialReleaseDate).toLocaleDateString(
                'en-US',
                {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                },
              )
            ) : (
              <span className="pl-xl text-subtle">--</span>
            )}
          </div>
        ),
      }),
    ];

    // Only create performance columns if feature is enabled
    if (isAssetPerformanceEnabled) {
      trackTableColumns.push(
        columnHelper.accessor('performance.streaming.aggregated.totalStreams', {
          id: 'streamsRTD',
          header: () => (
            <div className="flex items-end gap-xs text-nowrap">
              <span>{t('trackList.header.streams')}</span>
              <TimeRangeLabel timeRange="RTD" />
            </div>
          ),
          cell: ({ row }) => {
            const totalStreams = formatMetric(
              row.original,
              row.original.performance?.streaming?.aggregated?.totalStreams,
            );

            return totalStreams ? (
              <span>{totalStreams}</span>
            ) : (
              <span className="pl-md text-subtle">--</span>
            );
          },
        }),

        columnHelper.accessor(
          'performance.streaming.aggregated.periodStreams.current',
          {
            id: 'streams',
            header: () => (
              <div className="flex items-end gap-xs text-nowrap">
                <span>{t('trackList.header.streams')}</span>
                <TimeRangeLabel timeRange={range} />
              </div>
            ),
            cell: ({ row }) => {
              const periodStreams = formatMetric(
                row.original,
                row.original.performance?.streaming?.aggregated?.periodStreams
                  ?.current,
              );
              return periodStreams ? (
                <div className="flex flex-col">
                  <span>{periodStreams}</span>
                  <PerformanceDeltaLabel
                    delta={
                      row.original.performance?.streaming?.aggregated
                        ?.periodStreams?.deltaRatio
                    }
                  />
                </div>
              ) : (
                <span className="pl-md text-subtle">--</span>
              );
            },
          },
        ),

        columnHelper.accessor(
          'performance.streaming.aggregated.organicStreams.current',
          {
            id: 'organicStreams',
            header: () => (
              <div className="flex items-end gap-xs text-nowrap">
                <span>{t('trackList.header.organicStreams')}</span>
                <TimeRangeLabel timeRange={range} />
              </div>
            ),
            cell: ({ row }) => {
              const organicStreams = formatMetric(
                row.original,
                row.original.performance?.streaming?.aggregated?.organicStreams
                  ?.current,
              );
              return organicStreams ? (
                <div className="flex flex-col">
                  <span>{organicStreams}</span>
                  <PerformanceDeltaLabel
                    delta={
                      row.original.performance?.streaming?.aggregated
                        ?.organicStreams?.deltaRatio
                    }
                  />
                </div>
              ) : (
                <span className="pl-md text-subtle">--</span>
              );
            },
          },
        ),
        columnHelper.accessor(
          'performance.engagement.aggregated.socialCreations.current',
          {
            id: 'socialCreations',
            header: () => (
              <div className="flex items-end gap-xs text-nowrap">
                <span>{t('trackList.header.socialCreations')}</span>
                <TimeRangeLabel timeRange="TW" />
              </div>
            ),
            cell: ({ row }) => {
              const socialCreations = formatMetric(
                row.original,
                row.original.performance?.engagement?.aggregated
                  ?.socialCreations?.current,
              );
              return socialCreations ? (
                <div className="flex flex-col">
                  <span>{socialCreations}</span>
                  <PerformanceDeltaLabel
                    delta={
                      row.original.performance?.engagement?.aggregated
                        ?.socialCreations?.deltaRatio
                    }
                  />
                </div>
              ) : (
                <span className="pl-md text-subtle">--</span>
              );
            },
          },
        ),
        columnHelper.display({
          id: 'additionalMenu',
          header: () => null,
          cell: ({ row }) =>
            row.original.links?.airtable ? (
              <TrackTableLinkMenu trackData={row.original} />
            ) : null,
        }),
      );
    }

    return trackTableColumns;
  }, [t, range, isAssetPerformanceEnabled, today]);
};
