import { Trans, useTranslation } from 'react-i18next';

import { Icon, Tag, Tooltip } from '~/components';
import { IconButton } from '~/components/IconButton';
import { formatFollowerCount } from '~/modules/artist-roster/components/ArtistDetails/utils';
import { useAnalytics } from '~/modules/monitoring/amplitude';

import { getCountryName } from '../../lib/artists';
import type { ArtistDetails } from '../../lib/types';
import { ArtistCrossOverIcon } from '../ArtistCrossOverIcon';

interface ArtistDetailsHeaderProps {
  artistData?: ArtistDetails;
}

export const ArtistDetailsHeader = ({
  artistData,
}: ArtistDetailsHeaderProps) => {
  const { t } = useTranslation('roster');
  const { t: nameTranslation } = useTranslation('names');
  const { trackAnalytics } = useAnalytics();

  if (!artistData) {
    return null;
  }

  const handleSocialIconButtonClick = () => {
    trackAnalytics('artistHeroInteraction', {
      artist_id: artistData.id,
      artist_name: artistData.name,
    });
  };

  const handleDataToolsLinkClick = handleSocialIconButtonClick;

  return (
    <div className="flex w-full items-stretch justify-center gap-2xl">
      <img
        src={artistData.image}
        alt={artistData.name}
        className="size-[9.25rem] object-cover"
      />
      <div className="flex grow flex-col items-start justify-center gap-sm text-body-sm">
        <div className="flex items-center gap-sm">
          <h1 className="text-display-md font-bold">{artistData.name}</h1>
          {artistData.isCrossOver ? <ArtistCrossOverIcon /> : null}
        </div>

        <p>
          {artistData.countryOfOriginISO
            ? getCountryName(artistData.countryOfOriginISO, navigator.language)
            : ''}
        </p>
        <p>
          {t('details.labels')}:{' '}
          <span>{artistData.labels.map((label) => label.name).join(', ')}</span>
        </p>
        <div className="mt-auto flex w-full items-center justify-between">
          <div className="flex items-center justify-center gap-md">
            {artistData.socials.map((social) => {
              return (
                <div
                  key={`${social.name}_${social.url}`}
                  className="flex items-center gap-xs rounded-full"
                >
                  <Tooltip
                    content={
                      <span>
                        <Trans
                          t={t}
                          i18nKey="labels.goto"
                          values={{
                            social: nameTranslation(social.name),
                          }}
                          components={{
                            strong: <span className="font-semibold" />,
                          }}
                        />
                      </span>
                    }
                    delayDuration={0}
                    side="bottom"
                    sideOffset={4}
                    align="start"
                    triggerAsChild
                    size="md"
                  >
                    <IconButton
                      iconType={social.name}
                      className="transition duration-100 hover:bg-emphasis-strong"
                      iconVariant="brands"
                      size="medium"
                      asLink={true}
                      linkTo={social.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label={social.name}
                      onClick={handleSocialIconButtonClick}
                    />
                  </Tooltip>

                  <Tooltip
                    content={
                      <span>
                        <Trans
                          t={t}
                          i18nKey={
                            social.name === 'youtube'
                              ? 'labels.subscriberFrom'
                              : 'labels.followerFrom'
                          }
                          values={{
                            number: social.followerCount
                              ? social.followerCount.toLocaleString()
                              : 0,
                            social: nameTranslation(social.name),
                          }}
                          components={{
                            strong: <span className="font-semibold" />,
                          }}
                        />
                      </span>
                    }
                    delayDuration={0}
                    side="bottom"
                    sideOffset={4}
                    align="start"
                    triggerAsChild
                    size="md"
                  >
                    <span>{formatFollowerCount(social.followerCount)}</span>
                  </Tooltip>
                </div>
              );
            })}
          </div>
          <div className="flex items-center justify-center gap-sm">
            {artistData.reports.map((report) => (
              <a
                key={report.name}
                href={report.url}
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleDataToolsLinkClick}
              >
                <Tag variant="secondary">
                  {report.displayName ?? report.name}
                  <Icon
                    type="external-link"
                    size="sm"
                    variant="regular"
                    role="presentation"
                    aria-hidden
                    aria-label="external-url-icon"
                    className="ml-sm text-white"
                  />
                </Tag>
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

ArtistDetailsHeader.displayName = 'ArtistDetailsHeader';
