import type { Tokens } from '@okta/okta-auth-js';
import { LoginCallback, useOktaAuth } from '@okta/okta-react';
import type { WidgetOptions } from '@okta/okta-signin-widget';
import { useCallback, useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { ErrorPage } from '~/components/ErrorPage';
import { AuthLayout, OktaSignInWidget } from '~/modules/auth/components';
import { getOktaConfig } from '~/modules/auth/lib/okta-config.ts';
import { BrandContext } from '~/modules/branding';
import { LoadingScreen } from '~/modules/navigation/components/LoadingScreen';

function getConfigOverrides() {
  const loginUrl = new URLSearchParams(window.location.search);
  const recovery = loginUrl.get('recovery') ?? undefined;
  const activate = loginUrl.get('activate') ?? undefined;

  const config: Partial<WidgetOptions> = {
    recoveryToken: recovery ?? activate,
  };

  if (activate) {
    config.i18n = {
      en: {
        'password.reset.title.generic': 'Set your password',
        'password.reset': 'Create My Account',
        'factor.push.description': 'Set up Okta Verify to continue.',
        'enroll.choices.submit.configure': 'Continue',
      },
    };
  }

  return config;
}

let sessionChecked = false;

const useCheckSession = () => {
  const { oktaAuth } = useOktaAuth();

  const checkSession = useCallback(async () => {
    const tokenResponse = await oktaAuth.token.getWithoutPrompt({
      responseType: ['token'],
    });

    if (tokenResponse) {
      return await oktaAuth.handleLoginRedirect(tokenResponse.tokens);
    }
  }, [oktaAuth]);

  useEffect(() => {
    if (sessionChecked) {
      return;
    }
    checkSession();
    sessionChecked = true;
  }, [checkSession]);
};

/**
 * Uniform Router.
 */
const AuthRouter = () => {
  const brand = useContext(BrandContext);
  const config = getOktaConfig(brand, getConfigOverrides());
  const { oktaAuth, authState } = useOktaAuth();

  const onSuccess = (tokens: Tokens) => {
    oktaAuth.handleLoginRedirect(tokens);
  };

  const onError = (...args: unknown[]) => {
    console.log('Sign in error:', args);
  };

  useCheckSession();

  if (authState?.isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <Routes data-testid="auth-router">
      <Route
        path="/callback"
        element={
          <div
            data-testid="login-callback"
            className="flex min-h-dvh min-w-full"
          >
            <LoginCallback
              errorComponent={({ error }) => (
                <ErrorPage type="noAccessPage" message={error.message} />
              )}
              loadingElement={<LoadingScreen />}
            />
          </div>
        }
      />
      <Route
        key="Home"
        path="/welcome"
        element={
          <AuthLayout>
            <OktaSignInWidget
              config={config}
              onError={onError}
              onSuccess={onSuccess}
            />
          </AuthLayout>
        }
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

AuthRouter.displayName = 'AuthRouter';

export { AuthRouter };
