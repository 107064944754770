import type { WidgetOptions } from '@okta/okta-signin-widget';

import type { Brand } from '~/modules/branding/types';

export const getOktaConfig = (
  brand: Brand,
  overrides: Partial<WidgetOptions> = {},
): WidgetOptions => {
  const i18n = {};

  for (const k in brand.oktaConfig.i18n) {
    // Needed because WidgetOptions does not define a type for `i18n` other than
    // `any`.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    i18n[k] = {
      // Needed because WidgetOptions does not define a type for `i18n` other
      // than `any`.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      ...brand.oktaConfig.i18n[k],
      ...(overrides.i18n && k in overrides.i18n ? overrides.i18n[k] : {}),
    };
  }

  for (const k in overrides.i18n) {
    if (!(k in brand.oktaConfig.i18n)) {
      // Needed because WidgetOptions does not define a type for `i18n` other
      // than `any`.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      i18n[k] = overrides.i18n[k];
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  return {
    ...brand.oktaConfig,
    ...overrides,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    authParams: {
      ...brand.oktaConfig.authParams,
      ...overrides.authParams,
    },
    features: {
      ...brand.oktaConfig.features,
      ...overrides.features,
    },
    colors: {
      ...brand.oktaConfig.colors,
      ...overrides.colors,
    },
    i18n,
  };
};
