import { useQuery } from '@tanstack/react-query';

import { getArtistDetail } from '../lib/artists';

interface ArtistDetailsQueryProps {
  uaid?: string;
}

export const useArtistDetailsQuery = ({ uaid }: ArtistDetailsQueryProps) => {
  return useQuery({
    enabled: !!uaid,
    queryKey: ['artist-details', uaid],
    queryFn: () => {
      return getArtistDetail({ uaid });
    },
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
};
