import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getArtistTracksPerformance } from '../../lib/tracks';
import { formatMetricCount } from '../utils';
import { PerformanceModules } from './PerformanceModules';
import { PerformanceSelectorCard } from './PerformanceSelectorCard';

interface PerformanceViewProps {
  familyId: number;
}

export type MetricType =
  | 'streamsRTD'
  | 'periodStreams'
  | 'organicStreams'
  | 'socialCreations';

export function PerformanceView({ familyId }: PerformanceViewProps) {
  const { t } = useTranslation('roster');
  const [selectedMetricType, setSelectedMetricType] =
    useState<MetricType>('streamsRTD');

  const { data: performancesMap } = useQuery({
    queryKey: ['artist-tracks-performance', familyId],
    queryFn: () => {
      if (familyId) {
        return getArtistTracksPerformance({ familyIds: [familyId] });
      }
      return Promise.resolve(null);
    },
    enabled: !!familyId,
    placeholderData: keepPreviousData,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const performance = useMemo(() => {
    if (!performancesMap || !familyId) {
      return null;
    }
    return performancesMap.get(familyId);
  }, [familyId, performancesMap]);

  if (!performance) {
    return null;
  }

  return (
    <div className="flex w-full flex-col items-start gap-lg">
      <div className="flex w-full gap-sm">
        <PerformanceSelectorCard
          title={t('trackDetails.labels.streamsRTD')}
          value={formatMetricCount(
            performance.streaming?.aggregated?.totalStreams,
          )}
          isActive={selectedMetricType === 'streamsRTD'}
          onClick={() => setSelectedMetricType('streamsRTD')}
        />
        <PerformanceSelectorCard
          title={t('trackDetails.labels.periodStreams')}
          value={formatMetricCount(
            performance.streaming?.aggregated?.periodStreams?.current,
          )}
          delta={performance.streaming?.aggregated?.periodStreams?.deltaRatio}
          isActive={selectedMetricType === 'periodStreams'}
          onClick={() => setSelectedMetricType('periodStreams')}
        />
        <PerformanceSelectorCard
          title={t('trackDetails.labels.organicStreams')}
          value={formatMetricCount(
            performance.streaming?.aggregated?.organicStreams?.current,
          )}
          delta={performance.streaming?.aggregated?.organicStreams?.deltaRatio}
          isActive={selectedMetricType === 'organicStreams'}
          onClick={() => setSelectedMetricType('organicStreams')}
        />
        <PerformanceSelectorCard
          title={t('trackDetails.labels.socialCreations')}
          value={formatMetricCount(
            performance.engagement?.aggregated?.socialCreations?.current,
          )}
          delta={
            performance.engagement?.aggregated?.socialCreations?.deltaRatio
          }
          isActive={selectedMetricType === 'socialCreations'}
          onClick={() => setSelectedMetricType('socialCreations')}
        />
      </div>

      {/* Display different performance modules based on the metric type */}
      <PerformanceModules
        familyId={familyId}
        metricType={selectedMetricType}
        performance={performance}
      />
    </div>
  );
}

PerformanceView.displayName = 'PerformanceView';
