import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { useWmgSession } from '~/shared/hooks';

import { LabelGroupQueryKeys } from '../config/queryKeys';
import { getRequestHeaders } from '../lib/headers';
import { LegacyCoopClient } from '../lib/LegacyCoopClient';
import {
  type LabelGroupListResponse,
  LabelGroupListResponseSchema,
} from '../lib/schemas';
import type { PresetUseQueryOptions } from '../lib/types';
import type { PaginationVariables } from '../types/paginationVariables';

export interface LabelGroupListQueryVariables extends PaginationVariables {
  name?: string;
}

export function useLabelGroupList<TData = LabelGroupListResponse>(
  variables: LabelGroupListQueryVariables,
  options?: PresetUseQueryOptions<LabelGroupListResponse, TData>,
) {
  const session = useWmgSession();

  const { data, ...query } = useQuery({
    queryKey: LabelGroupQueryKeys.list(variables),
    queryFn: async () => {
      const responseData = await LegacyCoopClient.get('labels/groups', {
        headers: getRequestHeaders(session.token),
        searchParams: { ...variables, newSettingsPage: true },
      }).json();

      return LabelGroupListResponseSchema.parse(responseData);
    },
    placeholderData: keepPreviousData,
    ...options,
  });

  return { labelGroupList: data, ...query };
}
