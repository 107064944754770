import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from '~/components/Button';
import { useIsExternalUser } from '~/hooks/useIsExternalUser';
import { CTABlock } from '~/modules/settings/components/CTABlock';
import { SettingsPageHeader } from '~/modules/settings/components/SettingsPageHeader';
import { SETTINGS_ROUTES } from '~/modules/settings/config/routes';
import type {
  UserFormSchema,
  UserFormSchemaForExternalUser,
} from '~/modules/settings/modules/user/components/UserForm';
import { UserForm } from '~/modules/settings/modules/user/components/UserForm';

const FORM_ID = 'createUserForm';

export function CreateUserPage() {
  const { t } = useTranslation('settings');
  const isExternal = useIsExternalUser();

  const onSubmit = (data: UserFormSchema | UserFormSchemaForExternalUser) => {
    console.log(data);
  };

  return (
    <article className="relative h-full">
      <SettingsPageHeader
        breadcrumbItems={[
          { to: SETTINGS_ROUTES.ROOT, label: 'title' },
          { to: SETTINGS_ROUTES.USER_LIST, label: 'users' },
          { to: '', label: 'user.createUserTitle' },
        ]}
        title="user.createUserTitle"
      />
      <UserForm onSubmit={onSubmit} id={FORM_ID} isExternal={isExternal} />
      <CTABlock>
        <Button asChild variant="secondary" size="large">
          <Link to={SETTINGS_ROUTES.USER_LIST}>{t('cancelButton')}</Link>
        </Button>
        <Button variant="primary" type="submit" size="large" form={FORM_ID}>
          {t('user.addUserButton')}
        </Button>
      </CTABlock>
    </article>
  );
}
CreateUserPage.displayName = 'CreateUserPage';
