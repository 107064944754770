import { type FieldValues, useController } from 'react-hook-form';

import { Dropdown, type DropdownProps } from '~/components/Dropdown';

import { FormField, type FormFieldProps } from '../../FormField';

type FormFieldDropdownProps<
  TFormFields extends FieldValues,
  TMultiselect extends boolean | undefined,
> = Omit<FormFieldProps<TFormFields>, 'children'> &
  Omit<DropdownProps<TMultiselect>, 'name' | 'className' | 'onChange'>;

export function FormFieldDropdown<
  TFormFields extends FieldValues,
  TMultiselect extends boolean | undefined,
>({
  name,
  label,
  className,
  ...dropdownProps
}: FormFieldDropdownProps<TFormFields, TMultiselect>) {
  const {
    field,
    fieldState: { invalid },
  } = useController({ name, disabled: dropdownProps.disabled });

  return (
    <FormField name={name} label={label} className={className}>
      <Dropdown
        {...field}
        state={invalid ? 'error' : undefined}
        {...dropdownProps}
      />
    </FormField>
  );
}

FormFieldDropdown.displayName = 'FormFieldDropdown';
