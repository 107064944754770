import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '~/components';

import { LinkSearch } from './LinkSearch';

export function LinkSearchTrigger() {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation('wmgone');

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="flex h-fit max-w-[166px] items-center justify-center gap-sm rounded-full border-px px-md py-xs text-body-sm duration-100 bg-transparent border-default text-white hover:bg-default-inverse/subtle active:bg-level-03"
      >
        <Icon type="search" variant="solid" size="md" aria-hidden />
        <span>{t('links.search.trigger')}</span>
      </button>
      <LinkSearch
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      />
    </>
  );
}
LinkSearchTrigger.displayName = 'LinkSearchTrigger';
