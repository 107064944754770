import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { env } from '~/lib/env';
import { SETTINGS_ROUTES } from '~settings/config/routes';
import { useCanAccessView, VIEWS } from '~settings/hooks/useCanAccessView';

import { type NavTab, NavTabs } from './NavTabs';
import { TabsHeader } from './TabsHeader';

export function TabsPagesLayout() {
  const { t } = useTranslation('settings');
  const canViewLabelGroups = useCanAccessView(VIEWS.LABEL_GROUPS_LIST);
  const labelGroupsEnabled =
    env('VITE_CONTAINER_SETTINGS_LABEL_GROUPS_ENABLED', false) === 'true';

  const tabs: NavTab[] = [
    { label: t('users'), path: SETTINGS_ROUTES.USER_LIST },
    { label: t('labels'), path: SETTINGS_ROUTES.LABEL_LIST },
  ];

  if (canViewLabelGroups && labelGroupsEnabled) {
    tabs.push({
      label: t('labelGroup.list'),
      path: SETTINGS_ROUTES.LABEL_GROUP_LIST,
    });
  }

  return (
    <>
      <TabsHeader />
      <NavTabs tabs={tabs} ariaLabel={t('settingsTabLabel')} />
      <Outlet />
    </>
  );
}

TabsPagesLayout.displayName = 'TabsPagesLayout';
