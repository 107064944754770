export interface AgeRangeData {
  total: number;
  change: number;
}

interface DemographicData {
  total_streams: number;
  '13-17': AgeRangeData;
  '18-24': AgeRangeData;
  '24-34': AgeRangeData;
  '35-44': AgeRangeData;
  '45-54': AgeRangeData;
  '55-64': AgeRangeData;
  '65+': AgeRangeData;
}

interface DemographicStreamData {
  isrc: string;
  total_streams: number;
  streams_by_demographics: {
    all: DemographicData;
    female: DemographicData;
    male: DemographicData;
    'non-binary': DemographicData;
    unknown: DemographicData;
  };
}

export const mockStreamingData: DemographicStreamData = {
  isrc: 'USNLR1300366',
  total_streams: 12500000,
  streams_by_demographics: {
    all: {
      total_streams: 5000000,
      '13-17': { total: 750000, change: -50000 },
      '18-24': { total: 1250000, change: 100000 },
      '24-34': { total: 1000000, change: 50000 },
      '35-44': { total: 900000, change: -25000 },
      '45-54': { total: 600000, change: -50000 },
      '55-64': { total: 400000, change: -75000 },
      '65+': { total: 100000, change: -25000 },
    },
    female: {
      total_streams: 6000000,
      '13-17': { total: 400000, change: -35000 },
      '18-24': { total: 1500000, change: 125000 },
      '24-34': { total: 1250000, change: 75000 },
      '35-44': { total: 1000000, change: -15000 },
      '45-54': { total: 750000, change: -40000 },
      '55-64': { total: 600000, change: -50000 },
      '65+': { total: 500000, change: 15000 },
    },
    male: {
      total_streams: 4000000,
      '13-17': { total: 350000, change: -15000 },
      '18-24': { total: 1000000, change: 75000 },
      '24-34': { total: 900000, change: 40000 },
      '35-44': { total: 750000, change: -10000 },
      '45-54': { total: 500000, change: -25000 },
      '55-64': { total: 300000, change: -40000 },
      '65+': { total: 50000, change: -10000 },
    },
    'non-binary': {
      total_streams: 1500000,
      '13-17': { total: 150000, change: 5000 },
      '18-24': { total: 500000, change: 50000 },
      '24-34': { total: 400000, change: 25000 },
      '35-44': { total: 250000, change: 0 },
      '45-54': { total: 100000, change: -5000 },
      '55-64': { total: 75000, change: -10000 },
      '65+': { total: 25000, change: -5000 },
    },
    unknown: {
      total_streams: 1000000,
      '13-17': { total: 100000, change: 0 },
      '18-24': { total: 300000, change: 15000 },
      '24-34': { total: 250000, change: 5000 },
      '35-44': { total: 150000, change: -2500 },
      '45-54': { total: 100000, change: -5000 },
      '55-64': { total: 75000, change: -5000 },
      '65+': { total: 25000, change: -2500 },
    },
  },
};
