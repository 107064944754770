import { Trans, useTranslation } from 'react-i18next';

import { Icon } from '~/components';

const NoAccess = (): React.ReactNode => {
  const { t } = useTranslation('label-perf');

  return (
    <div
      data-testid="no-access-container"
      className="flex h-full flex-col items-center justify-center text-center"
    >
      <Icon type="bell-on" variant="regular" size="4x" className="mb-md" />
      <p className="mb-sm text-body-xl font-normal">
        {t('error.noAccessTitle')}
      </p>
      <p className="text-body-md text-subtle">
        <Trans
          i18nKey="error.noAccessDescription"
          t={t}
          components={[
            <br key="0" />,
            <a
              key="1"
              href="mailto:andrew.koltyakov@wmg.com?subject=WMG1 Amplify: Permissions request"
              className="underline"
              target="blank"
            >
              ...
            </a>,
          ]}
        />
      </p>
    </div>
  );
};

NoAccess.displayName = 'NoAccess';

export { NoAccess };
