import type { DetailedHTMLProps, HTMLAttributes } from 'react';

import { cn } from '~/lib/cn';

interface SeparatorProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLHRElement>, HTMLHRElement> {}

export function Separator({ className, ...props }: SeparatorProps) {
  return (
    <hr
      className={cn('border-px border-emphasis-subtle', className)}
      {...props}
    />
  );
}

Separator.displayName = 'Separator';
