import type { Table } from '@tanstack/react-table';
import { flexRender } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Icon, Skeleton } from '~/components';
import { cn } from '~/lib/cn';
import { env } from '~/lib/env';
import {
  TableBody,
  TableCell,
  TableRow,
} from '~/modules/artist-roster/components/Table';
import { useAnalytics } from '~/modules/monitoring/amplitude';

import type { ArtistDetails, AssetFamily } from '../../lib/types';
import { SkeletonTable } from '../Table/SkeletonTable';
import type { TrackTableMeta } from './TrackTable';

interface TrackTableBodyProps {
  table: Table<AssetFamily>;
  meta: TrackTableMeta;
  artistData: ArtistDetails;
  isFetchingTracks: boolean;
  isLoadingPerformance: boolean;
}

const PERFORMANCE_COLUMNS = [
  'streamsRTD',
  'streams',
  'organicStreams',
  'socialCreations',
];

export const TrackTableBody = ({
  table,
  meta,
  artistData,
  isFetchingTracks,
  isLoadingPerformance,
}: TrackTableBodyProps) => {
  const { t } = useTranslation('roster');
  const { trackAnalytics } = useAnalytics();

  const isAssetPerformanceEnabled =
    env('VITE_WMG_ASSET_PERFORMANCE_ENABLED') === 'true';

  if (isFetchingTracks) {
    return (
      <TableBody>
        <SkeletonTable size={10} headers={table.getHeaderGroups()[0].headers} />
      </TableBody>
    );
  }

  if (!table.getRowModel().rows?.length) {
    return (
      <TableBody>
        <TableRow>
          <TableCell colSpan={4}>
            <div className="mt-md flex w-full flex-col items-center justify-center gap-lg border-2xs border-dashed py-5xl tracking-wider border-strong-on-light">
              <span className="flex shrink-0 items-center justify-center">
                <Icon
                  type="turntable"
                  variant="solid"
                  size="4x"
                  aria-hidden
                  className="text-default"
                />
              </span>
              <div className="flex w-full flex-col items-center justify-center gap-sm">
                <p className="text-body-lg font-semibold text-white">
                  {t('trackList.noData.title')}
                </p>
                <p className="text-body-md font-normal text-subtle">
                  {t('trackList.noData.sub', {
                    artistName: meta?.artistName || 'artist',
                  })}
                </p>
              </div>
            </div>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  const handleTrackTableRowClick = ({
    trackData,
  }: {
    trackData: AssetFamily;
  }) => {
    if (isAssetPerformanceEnabled && meta) {
      if (artistData) {
        trackAnalytics('artistTrackListTrackClick', {
          artist_id: artistData.id,
          artist_name: artistData.name,
          track_name: trackData.title,
          // @TODO WMG1-246 Add Family ID when available.
          family_id: trackData.id,
        });
      }
    }
  };

  return (
    <TableBody>
      {table.getRowModel().rows.map((row, index) => (
        <TableRow
          aria-label={`Row for ${row.original?.id || 'track'}`}
          key={row.id}
          data-state={row.getIsSelected() && 'selected'}
          className={
            isAssetPerformanceEnabled
              ? cn(
                  'group/row relative transition-all duration-75 hover:bg-emphasis-subtle',
                  'hover:!opacity-full group-hover/table:opacity-stronger',
                )
              : ''
          }
        >
          {row.getVisibleCells().map((cell) => (
            <TableCell
              key={cell.id}
              scope="row"
              className={cn(
                'border-y p-0 transition-all duration-75 border-transparent',
                'group-hover/row:border-emphasis-strong',
                'first:rounded-l-sm first:border-l',
                'last:rounded-r-sm last:border-r last:pr-sm',
                index % 2 === 0 && 'bg-default-inverse/default',
              )}
            >
              {PERFORMANCE_COLUMNS.includes(cell.column.id) &&
              isLoadingPerformance ? (
                <div className="space-y-xs">
                  <Skeleton className="h-md w-[6em] rounded-sm bg-level-04" />
                  <Skeleton className="h-md w-[5em] rounded-sm bg-level-04" />
                </div>
              ) : cell.column.id === 'title' && isAssetPerformanceEnabled ? (
                <Link
                  // @TODO WMG1-246 Replace track title with track family ID
                  to={`/roster/${meta.uaid}/${row.original.id}`}
                  className="flex items-center gap-sm py-md pl-lg hover:underline"
                  onClick={() =>
                    handleTrackTableRowClick({ trackData: row.original })
                  }
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Link>
              ) : (
                <div className="flex items-center gap-sm py-md pl-lg">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </div>
              )}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
};

TrackTableBody.displayName = 'TrackTableBody';
