import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const WelcomeLogo: FC = () => {
  const { t } = useTranslation('wmgone');

  return (
    <div className="flex w-[21em] flex-col gap-[1.5em]">
      <div className="font-dazzed text-[7em] font-bold text-tertiary-default">
        <h2>WMG</h2>
        <h2 className="-mt-4xl">ONE</h2>
      </div>
      <p className="text-headline-md font-bold text-white">
        {t('static-page.hero.title')}
      </p>
      <p className="w-[18em] text-body-md font-normal text-white">
        {t('static-page.hero.description')}
      </p>
    </div>
  );
};

WelcomeLogo.displayName = 'WelcomeLogo';
