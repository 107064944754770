import * as RadixAccordion from '@radix-ui/react-accordion';

import { Icon } from '~/components/Icon';
import { cn } from '~/lib/cn';

type AccordionItemProps = RadixAccordion.AccordionItemProps & {
  title: string;
  titleSlot?: React.ReactNode;
};

export const AccordionItem = ({
  className,
  children,
  title,
  titleSlot,
  ...props
}: AccordionItemProps) => {
  return (
    <RadixAccordion.Item
      className={cn(
        'bg-level-02',
        'rounded-sm',
        'p-lg',
        'flex',
        'flex-col',
        'data-[state="open"]:gap-lg',
      )}
      {...props}
    >
      <RadixAccordion.Header>
        <RadixAccordion.Trigger
          className={cn(
            'w-full',
            'flex',
            'items-center',
            'justify-between',
            'text-headline-sm',
            'text-strong',
            'group',
          )}
        >
          <span>{title}</span>
          {titleSlot}
          <Icon
            type="chevron-up"
            size="sm"
            className="-rotate-180 transition-transform duration-300 group-aria-expanded:rotate-0"
          />
        </RadixAccordion.Trigger>
      </RadixAccordion.Header>
      <RadixAccordion.Content className={className}>
        {children}
      </RadixAccordion.Content>
    </RadixAccordion.Item>
  );
};

AccordionItem.displayName = 'AccordionItem';
