import type { HTMLAttributes, MouseEvent } from 'react';
import { forwardRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAnalytics } from '~/modules/monitoring/amplitude';

import type { LinkItem } from '../../lib/types';
import { HighlightedText } from '../HighlightedText';
import { RequestAccessLink } from './RequestAccessLink';
import { RequiredVpn } from './RequiredVpn';
import { ToolItemIcon } from './ToolItemIcon';

export type ToolItemProps = HTMLAttributes<HTMLButtonElement> & {
  item: LinkItem;
};

export const ToolItem = forwardRef<HTMLButtonElement, ToolItemProps>(
  ({ item }, ref) => {
    const { t } = useTranslation('wmgone');
    const { trackAnalytics } = useAnalytics();

    const handleCardClick = useCallback(() => {
      window.open(item.url, '_blank');
      trackAnalytics('linkFarmLinkClicked', {
        app_name: item.appName,
        page_name: item.category,
        url: item.url,
      });
    }, [item.url, item.appName, item.category, trackAnalytics]);

    const handleRequestLinkClick = useCallback(
      (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        trackAnalytics('linkFarmAccessRequested', {
          app_name: item.appName,
          page_name: item.category,
          url: item.url,
        });
      },
      [item.appName, item.url, item.category, trackAnalytics],
    );

    return (
      <button
        ref={ref}
        onClick={handleCardClick}
        aria-label={t('globalSearch.open', { name: item.appName })}
        className="group mb-sm flex-1 rounded-xs border-px border-opacity-strong px-lg py-sm transition duration-150 border-transparent hover:bg-emphasis-strong hover:border-white focus-visible:ring"
      >
        <div className="flex flex-1 flex-row">
          <ToolItemIcon icon={item.icon} />
          <div className="flex flex-1 flex-col items-baseline pl-lg">
            <HighlightedText className="font-semibold" text={item.actionText} />
            <HighlightedText className="text-body-sm" text={item.appName} />
            <div className="flex flex-row justify-between pt-md">
              <RequestAccessLink
                requestAccessUrl={item.requestAccess}
                onClick={handleRequestLinkClick}
              />
              <RequiredVpn visible={Boolean(item.requireVpn)} />
            </div>
          </div>
        </div>
      </button>
    );
  },
);

ToolItem.displayName = 'ToolItem';
