import { DrawerBody } from './DrawerBody';
import { DrawerContent } from './DrawerContent';
import { DrawerFooter } from './DrawerFooter';
import { DrawerHeader } from './DrawerHeader';
import { DrawerRoot } from './DrawerRoot';
import { DrawerTitle } from './DrawerTitle';

export const Drawer = {
  Root: DrawerRoot,
  Content: DrawerContent,
  Header: DrawerHeader,
  Title: DrawerTitle,
  Body: DrawerBody,
  Footer: DrawerFooter,
};
