import type { MouseEventHandler } from 'react';

import { useDropdownContext } from '~/components/Dropdown/hooks/useDropdownContext';

export const useTriggerEventHandlers = () => {
  const { setIsOpen, collection, selected } = useDropdownContext();
  const onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    switch (e.code) {
      case 'Escape':
        setIsOpen(false);
        break;
      case 'ArrowDown':
        setIsOpen(true);
        setTimeout(() => collection[0].ref?.current?.focus());
        break;
      case 'ArrowUp':
        setIsOpen(true);
        setTimeout(() =>
          collection[collection.length - 1].ref?.current?.focus(),
        );
        break;
      case 'Enter':
        setIsOpen(true);
        if (selected.length === 1) {
          setTimeout(() => selected[0].ref?.current?.focus());
        }
        break;
      default:
        break;
    }
  };

  const onClick: MouseEventHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsOpen((prev) => !prev);
    if (selected.length === 1) {
      setTimeout(() => selected[0].ref?.current?.focus());
    }
  };

  return {
    onClick,
    onKeyDown,
  };
};
