import type { PropsWithChildren } from 'react';

export interface PropertyListValueProps extends PropsWithChildren {}

export function PropertyListValue({ children }: PropertyListValueProps) {
  return (
    <dd className="overflow-hidden text-ellipsis text-nowrap">{children}</dd>
  );
}
PropertyListValue.displayName = 'PropertyListValue';
