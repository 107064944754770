import { useTranslation } from 'react-i18next';

import { Icon } from '~/components';
import {
  ICON_BY_TYPE,
  type IconType,
} from '~/modules/link-farm/components/LinkList/LinkItemIcons';

export type ToolItemIconProps = {
  icon: IconType;
};

export function ToolItemIcon({ icon }: ToolItemIconProps) {
  const { t } = useTranslation('wmgone');

  return (
    <div className="relative">
      <img
        src={ICON_BY_TYPE[icon]}
        className="size-2xl rounded-full transition delay-150 duration-300 group-hover:opacity-none group-focus:opacity-none"
        alt={t('globalSearch.icon', { icon })}
      />
      <div
        aria-hidden
        className="absolute top-0 flex size-2xl items-center justify-center rounded-full opacity-none transition duration-150 bg-emphasis-strong group-hover:opacity-full group-focus:opacity-full"
      >
        <Icon
          type="external-link"
          size="lg"
          variant="regular"
          role="presentation"
          aria-hidden
          aria-label="external-url-icon"
          className="text-white"
        />
      </div>
    </div>
  );
}

ToolItemIcon.displayName = 'ToolItemIcon';
