import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { Button } from '~/components/Button';
import { DetailPageError } from '~/modules/errors/components/DetailPageError';
import { CTABlock } from '~/modules/settings/components/CTABlock';
import { SettingsPageHeader } from '~/modules/settings/components/SettingsPageHeader';
import { SETTINGS_ROUTES } from '~/modules/settings/config/routes';
import { useUser } from '~settings/api/queries/user';

import type { UserFormSchema } from '../../components/UserForm';
import { UserForm } from '../../components/UserForm';

const FORM_ID = 'createUserForm';

export function EditUserPage() {
  const { t } = useTranslation('settings');
  const { userId } = useParams();

  const { isLoading, error, user } = useUser(userId || '');
  const userValues = useMemo<UserFormSchema | null>(
    () =>
      user
        ? ({
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            jobTitle: user.jobTitle,
            labelGroups: user.labelGroups,
            phone: user.phone ?? '',
            userType: 'external',
          } as const)
        : null,
    [user],
  );

  if (isLoading) {
    return <section className="py-md">Loading...</section>;
  }

  if (error || !user || !userValues || !userId) {
    return (
      <DetailPageError
        errorCode={error?.response?.status}
        fallbackRoute={{
          href: SETTINGS_ROUTES.ROOT,
          label: t('backToUserManagement'),
        }}
      />
    );
  }

  const onSubmit = (data: UserFormSchema) => {
    console.log(data);
  };

  return (
    <article className="relative h-full">
      <SettingsPageHeader
        breadcrumbItems={[
          { to: SETTINGS_ROUTES.ROOT, label: 'title' },
          { to: SETTINGS_ROUTES.USER_LIST, label: 'users' },
          { to: '', label: 'user.editUserTitle' },
        ]}
        title="user.editUserTitle"
      />
      <UserForm onSubmit={onSubmit} id={FORM_ID} values={userValues} />
      <CTABlock>
        <Button asChild variant="secondary" size="large">
          <Link to={SETTINGS_ROUTES.USER_DETAIL(userId)}>
            {t('cancelButton')}
          </Link>
        </Button>
        <Button variant="primary" type="submit" size="large" form={FORM_ID}>
          {t('user.updateUserButton')}
        </Button>
      </CTABlock>
    </article>
  );
}

EditUserPage.displayName = 'EditUserPage';
