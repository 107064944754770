export const IDENTIFIER_TYPES = {
  SODATONE: 'sodatone',
  OPUS: 'opus',
  ARTIST_PACK: 'artist_packs',
  SPOTIFY: 'spotify',
  INSTAGRAM: 'instagram',
  TIKTOK: 'tiktok',
  YOUTUBE: 'youtube',
};

export const SEARCH_PARAM_TYPE = {
  SEARCH: 'search',
  PAGE: 'page',
  LABELS: 'labels',
  CROSSOVER: 'crossover',
  LABELID: 'labelId',
  LABELNAME: 'labelName',
};

export const DATA_TOOLS_ORDER = ['Opus', 'Sodatone', 'Artist Pack'];
export const SOCIALS_ORDER = ['spotify', 'youtube', 'tiktok', 'instagram'];
export const TRACK_SOCIALS_ORDER = ['spotify', 'apple', 'amazon', 'youtube'];
