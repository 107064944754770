export const derivativeMockData = {
  data: [
    {
      ARTIST: 'Luka Basi',
      CLEAN_TITLE: 'Istrijanko ružo moja',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Bertin Osborne',
      CLEAN_TITLE: 'Yo tengo una amiga',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Roberta Di Mario',
      CLEAN_TITLE: 'The Color of You',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Ankush Raja, Shilpi Raj & Pallavi Giri',
      CLEAN_TITLE: 'Hamke Dulhin Banala',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'André Bourvil - Pierrette Bruno',
      CLEAN_TITLE: "Ce p'tit air là",
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Ghulam Ali',
      CLEAN_TITLE: 'Thi Wasi Mein Fikr - E - Judai',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Chris Spedding',
      CLEAN_TITLE: 'Radio Times',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Kai & ViAM',
      CLEAN_TITLE: 'Yêu Nhưng Không Thể Lấy ',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'The Helio Sequence',
      CLEAN_TITLE: 'All of These Things',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Tuija Hakkila',
      CLEAN_TITLE:
        'Piano Sonata No. 16 in C Major, K. 545 "Semplice": I. Allegro',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 1,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Babybird',
      CLEAN_TITLE: 'Cornershop',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Dj Rani no Beat',
      CLEAN_TITLE: 'Vício',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Nii Otoo',
      CLEAN_TITLE: 'Relaxation & Healing Pt. 16',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Escalandrum',
      CLEAN_TITLE: 'Fuga 9',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Okidoki',
      CLEAN_TITLE: 'Eetsmakelijk',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Mike Tramp',
      CLEAN_TITLE: "Ain't The Life I Asked For",
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST:
        "Sesame Street's Bob & Sesame Street's Gordon & Sesame Street's Maria & Sesame Street's Susan",
      CLEAN_TITLE: 'My Name',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: "Donna D'Cruz",
      CLEAN_TITLE: 'Youth',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 1,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Blasfem & Geni Colegui',
      CLEAN_TITLE: 'Dulce o Salado',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Jelena Jevremović',
      CLEAN_TITLE: 'Ćao',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST:
        'Wiener Philharmoniker, Wilhelm Furtwängler, Dragica Martinis, Ramon Vinay, Sieglinde Wagner',
      CLEAN_TITLE:
        'Otello, IGV 21, Act IV: "Chi è là?" (Desdemona, Otello, Emilia)',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 1,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Maruthi Nayak & Kusuma Shree',
      CLEAN_TITLE: 'Dharani Mandala',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'George Wassouf',
      CLEAN_TITLE: 'Ma Teoulou Leeh',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Duo Dinamico',
      CLEAN_TITLE: 'Sueña, ama y canta ',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 1,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'V.M. Mahalingam & Sriraman',
      CLEAN_TITLE: 'Vetrivel Muruga',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Elisha Toto',
      CLEAN_TITLE: 'Dwaro Ji Ariyo',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Drumification',
      CLEAN_TITLE: 'Outburst',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Philadelphia Orchestra/Wolfgang Sawallisch',
      CLEAN_TITLE: 'Till Eulenspiegels lustige Streiche, Op. 28',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Pongsit Kampee',
      CLEAN_TITLE: 'Ban ',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 1,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Bibi und Tina',
      CLEAN_TITLE: 'Kapitel 20 - Das Weihnachtsfest ',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Blino',
      CLEAN_TITLE: 'Blinobaby',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Julian Byzantine',
      CLEAN_TITLE:
        'Vals venezolano No. 3 (Vals criollo) (1981 Remastered Version)',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 1,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST:
        'Elisabeth Schwarzkopf/Philharmonia Orchestra/Herbert von Karajan',
      CLEAN_TITLE:
        'Così fan tutte, K. 588, Act 1 Scene 11: No. 14, Recitativo accompagnato ed Aria, "L\'intatta fede che per noi già si diede … Come scoglio" (Fiordiligi)',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Babita Mishra',
      CLEAN_TITLE: 'Lalka Chunariya',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Gucci Mane',
      CLEAN_TITLE: 'Rolly Up ',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 2,
    },
    {
      ARTIST: 'Kent Nagano',
      CLEAN_TITLE:
        'Billy Budd, Op. 50, Act 1: "Billy Budd, King of the Birds!" (Billy, Flint, Redburn, Ratcliffe, Seamen)',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Harris',
      CLEAN_TITLE: '6 Mütter',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Peter Frankl',
      CLEAN_TITLE: 'Davidsbündlertänze, Op. 6: II. Innig',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Michel Béroff',
      CLEAN_TITLE: "Deux souvenirs d'enfance: Niania et moi",
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Chanticleer',
      CLEAN_TITLE: 'El Manisero',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Timbuktu',
      CLEAN_TITLE: 'Tänk om',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'D. Imman',
      CLEAN_TITLE: 'Send Off',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Little Jinder',
      CLEAN_TITLE: 'Ingenting e viktigt',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Coucheron',
      CLEAN_TITLE: 'Chocolate Milk ',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 1,
    },
    {
      ARTIST: 'MADREX',
      CLEAN_TITLE: 'Funky Sex Machine',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Ý Lan',
      CLEAN_TITLE: 'Chiều Vàng Nhung Nhớ',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Raveendran, Bichu Thirumala & M. G. Radhakrishnan',
      CLEAN_TITLE: 'Saraswathi Bit',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: '360 Music Factory',
      CLEAN_TITLE: 'Who Dat? ',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 1,
    },
    {
      ARTIST: 'Sanoj Sarabi',
      CLEAN_TITLE: 'Sugwa Dele Jhuthiyai',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Monica Starck',
      CLEAN_TITLE: 'Blind To See',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Jake Troth',
      CLEAN_TITLE: 'Sunday Smile',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Chi Ching Ching',
      CLEAN_TITLE: 'Famous ',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Lexicon Project',
      CLEAN_TITLE: 'Xeimonas',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Jari Lappalainen',
      CLEAN_TITLE: 'Hiekkaa',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Lucie Bílá',
      CLEAN_TITLE: 'Život vypadal krásně',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Band Of Skulls',
      CLEAN_TITLE: 'Back of Beyond',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Yuni Shara',
      CLEAN_TITLE: 'Makanya',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Pro Cantione Antiqua',
      CLEAN_TITLE: 'Laudate pueri',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Johnny Osbourne',
      CLEAN_TITLE: 'Baccara',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Neffa',
      CLEAN_TITLE: 'Venere',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Đình Nguyên',
      CLEAN_TITLE: 'Thành Phố Sương',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Bibi und Tina',
      CLEAN_TITLE: 'Kapitel 10 - retten die Biber ',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'La Hoàng Phúc',
      CLEAN_TITLE: 'Tổn Thương',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Michel Legrand',
      CLEAN_TITLE: 'Caresse (Rev. Caby)',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Example',
      CLEAN_TITLE: 'Need to Know ',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 1,
    },
    {
      ARTIST: 'Hacki Tamás',
      CLEAN_TITLE: 'Greensleeves',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Amageza Amahle',
      CLEAN_TITLE: 'Bayamphika Utshatha',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Ecos de las Marismas (F)',
      CLEAN_TITLE: 'Sevilla y tú',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Red Roses',
      CLEAN_TITLE: 'CUORI UGUALI',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'KLa Project',
      CLEAN_TITLE: 'Anak Dara ',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 2,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Citizen King',
      CLEAN_TITLE: 'Checkout Line',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Bossa de Novo',
      CLEAN_TITLE: 'Brucia la terra',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Nek',
      CLEAN_TITLE: 'La vida es',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Anders Widmark, Anders Widmark Trio',
      CLEAN_TITLE: 'En vänlig grönskas rika dräkt ',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Platform & Darassa',
      CLEAN_TITLE: 'Natepeta',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Ian Bostridge, Antonio Pappano',
      CLEAN_TITLE: 'Schwanengesang, D. 957: No. 14, Die Taubenpost',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Jaymeet',
      CLEAN_TITLE: 'Move On',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'drg.',
      CLEAN_TITLE: 'ก่อนที่เราบอกลา ',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 1,
    },
    {
      ARTIST: 'Padralph',
      CLEAN_TITLE: 'Count my Blessings',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'The New Wave',
      CLEAN_TITLE: 'Kiss the Mountain ',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'The Fourmost',
      CLEAN_TITLE: 'So Fine',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 2,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Anne-Marie',
      CLEAN_TITLE: 'IRISH GOODBYE ',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Clubsoul',
      CLEAN_TITLE: '사랑, 이별은 같았어',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 1,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Ilse Delange',
      CLEAN_TITLE: 'Good Thing',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Julien Clerc',
      CLEAN_TITLE: 'Long Distance Call',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'B.R.Chaya',
      CLEAN_TITLE: 'Bale Aarathinu Belagale',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Pequ',
      CLEAN_TITLE: 'BILLETE PÚRPURA',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Telebit & Armenia',
      CLEAN_TITLE: 'Niños Perdidos',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Arevalo',
      CLEAN_TITLE: 'Sigo Buscándote ',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Green Day',
      CLEAN_TITLE: 'Song of the Century',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Sabroso',
      CLEAN_TITLE: 'Aprende de Mí ',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'ELÍAS',
      CLEAN_TITLE: 'Tiempo de bailar ',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 1,
    },
    {
      ARTIST: 'The Residents',
      CLEAN_TITLE: 'Famine',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Antonio Pappano',
      CLEAN_TITLE:
        'Aïda, Act 2: "Salvator della patria" (King, Radamès, Ramfis, Priests)',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Lil Uzi Vert',
      CLEAN_TITLE: 'Scott and Ramona',
      IMMERSIVE_CNT: 1,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Pretenders',
      CLEAN_TITLE: 'Message of Love',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'VRF',
      CLEAN_TITLE: 'Han Bing Zhang',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Teijo Joutsela ja Humppa-Veikot',
      CLEAN_TITLE: 'Pyhäjärvi',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'S Light',
      CLEAN_TITLE: 'Tỉ Phú Tự Thân',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
    {
      ARTIST: 'Steve Kekana',
      CLEAN_TITLE: 'Rorisang Morena',
      IMMERSIVE_CNT: 0,
      SPED_UP_CNT: 0,
      SLOWED_DOWN_CNT: 0,
      ACOUSTIC_CNT: 0,
      INSTRUMENTAL_CNT: 0,
      REMIX_CNT: 0,
      REMASTER_CNT: 0,
      LIVE_CNT: 0,
      EXCLUSIVE_CNT: 0,
      AMENDED_CNT: 0,
      PIANO_CNT: 0,
      STRINGS_CNT: 0,
      DEMO_CNT: 0,
      FEATURED_CNT: 0,
    },
  ],
  total: 1991224,
};
