import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { useWmgSession } from '~/shared/hooks';

import { UserQueryKeys } from '../config/queryKeys';
import { BFFClient } from '../lib/BFFClient';
import { getRequestHeaders } from '../lib/headers';
import { type UserListResponse, UserListResponseSchema } from '../lib/schemas';
import type { PresetUseQueryOptions } from '../lib/types';
import type { PaginationVariables } from '../types/paginationVariables';

export interface UserListQueryVariables extends PaginationVariables {
  roles?: string;
  labelIds?: string;
  search?: string;
  sortBy?: string;
  orderDir?: string;
}

export function useUserList<TData = UserListResponse>(
  variables: UserListQueryVariables,
  options?: PresetUseQueryOptions<UserListResponse, TData>,
) {
  const session = useWmgSession();

  const { data, ...query } = useQuery({
    queryKey: UserQueryKeys.list(variables),
    queryFn: async () => {
      const responseData = await BFFClient.get('users', {
        headers: getRequestHeaders(session.token),
        searchParams: { ...variables },
      }).json();

      return UserListResponseSchema.parse(responseData);
    },
    placeholderData: keepPreviousData,
    ...options,
  });

  return { userList: data, ...query };
}
