import { forwardRef, useEffect, useState } from 'react';

import { cn } from '~/lib/cn';

import { Dropdown } from '../Dropdown';
import { Input } from '../Input/Input';
import { assertMultiSelect } from './assertions/multiSelect';
import { assertSingleSelect } from './assertions/singleSelect';
import { SEARCH_BY_LABEL, useSearch } from './hooks/useSearch';
import type { ComboboxOption } from './types/ComboboxOption';
import { extendOptionsWithValues } from './utils/extendOptionsWithValues';

export type ComboboxProps<TMultiselect extends boolean | undefined> = {
  options: ComboboxOption[];
  multiselect?: TMultiselect;

  /**
   * If value contains both value and label, they will be added to the options list
   */
  value?: TMultiselect extends true ? ComboboxOption[] : ComboboxOption;

  /**
   * Callback for value change
   */
  onChange?: (
    selected: TMultiselect extends true ? ComboboxOption[] : ComboboxOption,
  ) => void;

  /**
   * If true, the options will be extended with the value
   */
  extendOptions?: boolean;

  search?: string;
  onSearchChange?: (search: string) => void;

  /**
   * If true, the search will be performed on the client side
   * @default true
   */
  clientSearch?: boolean;

  /**
   * Predicate to filter options on the client side
   * @param option
   * @param search
   * @returns
   */
  searchPredicate?: (option: ComboboxOption, search: string) => boolean;

  /**
   * Placeholder for the options list
   */
  placeholder?: string;

  /**
   * Placeholder for the search input
   */
  searchPlaceholder?: string;

  /**
   * If true, the selected options will be displayed in the dropdown
   */
  displaySelectedOptions?: TMultiselect extends true ? boolean : never;

  /**
   * Name of the input
   */
  name?: string;
};

function ComboboxWithoutRef<TMultiselect extends boolean | undefined>(
  {
    options: initialOptions,
    value: initialValue,
    name,
    onChange,
    multiselect,
    extendOptions,
    search: initialSearch = '',
    onSearchChange,
    clientSearch = true,
    searchPredicate,
    placeholder,
    searchPlaceholder,
    displaySelectedOptions,
  }: ComboboxProps<TMultiselect>,
  ref: React.Ref<HTMLInputElement>,
) {
  assertMultiSelect({ multiselect, value: initialValue });
  assertSingleSelect({
    multiselect,
    value: initialValue,
    displaySelectedOptions,
  });
  const [value, setValue] = useState<ComboboxOption[]>([]);

  useEffect(() => {
    if (multiselect) {
      setValue((initialValue as ComboboxOption[]) ?? []);
    } else {
      setValue(initialValue ? [initialValue as ComboboxOption] : []);
    }
  }, [initialValue, multiselect]);

  const options = extendOptions
    ? extendOptionsWithValues(initialOptions, initialValue)
    : initialOptions;

  const {
    options: searchedOptions,
    search,
    setSearch,
  } = useSearch({
    search: initialSearch,
    clientSearch,
    options,
    searchPredicate: searchPredicate ?? SEARCH_BY_LABEL,
    onSearchChange,
    value,
  });

  const onDropdownChange = (selected: string[] | string) => {
    const newVal = options.filter((option) => selected.includes(option.value));
    if (multiselect) {
      setValue(newVal);
      onChange?.(
        newVal as TMultiselect extends true ? ComboboxOption[] : ComboboxOption,
      );
    } else {
      setValue([newVal[0]]);
      onChange?.(
        newVal[0] as TMultiselect extends true
          ? ComboboxOption[]
          : ComboboxOption,
      );
    }
  };

  const dropdownValue = (
    multiselect ? value.map((v) => v.value) : value[0]?.value
  ) as TMultiselect extends true ? string[] : string;

  return (
    <>
      <Dropdown
        ref={ref}
        options={searchedOptions}
        multiselect={multiselect}
        placeholder={placeholder}
        onChange={onDropdownChange}
        value={dropdownValue}
        displaySelectedOptions={displaySelectedOptions}
        name={name}
      >
        <div
          className={cn(
            'p-md',
            'mb-md',
            'border-emphasis-strong',
            'border-b-px',
          )}
        >
          <Input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={searchPlaceholder}
          />
        </div>
      </Dropdown>
    </>
  );
}
ComboboxWithoutRef.displayName = 'Combobox';

export const Combobox = forwardRef(ComboboxWithoutRef) as <
  TMultiselect extends boolean | undefined = false,
>(
  props: ComboboxProps<TMultiselect> & {
    ref?: React.ForwardedRef<HTMLInputElement>;
  },
) => ReturnType<typeof ComboboxWithoutRef<TMultiselect>>;
