import type { ComboboxOption } from '../types/ComboboxOption';

type ComboboxValue = string | ComboboxOption;

export const extendOptionsWithValues = (
  options: ComboboxOption[],
  value?: ComboboxValue | ComboboxValue[],
): ComboboxOption[] => {
  if (
    !value ||
    (Array.isArray(value) && typeof value[0] === 'string') ||
    typeof value === 'string'
  ) {
    return options;
  }
  const result = [...options];
  const newOptions = (
    Array.isArray(value) ? value : [value]
  ) as ComboboxOption[];
  for (const option of newOptions) {
    if (!options.some((o) => o.value === option.value)) {
      result.push(option);
    }
  }
  return result;
};
