import { zodResolver } from '@hookform/resolvers/zod';
import type { HTMLProps } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Accordion } from '~/components/Accordion';
import { FormFieldDropdown, FormFieldInput } from '~/components/FormField';
import { cn } from '~/lib/cn';
import { getI18nErrorMap } from '~/lib/getI18nErrorMap';

import {
  type UserFormSchema,
  userFormSchema,
  type UserFormSchemaForExternalUser,
  userFormSchemaForExternalUser,
} from './schema';

const LABEL_GROUPS = [
  { value: '1', label: 'Label Group 1' },
  { value: '2', label: 'Label Group 2' },
  { value: '3', label: 'Label Group 3' },
];

const ROLES = [
  { value: '1', label: 'Role 1' },
  { value: '2', label: 'Role 2' },
  { value: '3', label: 'Role 3' },
];

const USER_TYPES = [
  { value: 'internal', label: 'Internal' },
  { value: 'external', label: 'External' },
];

type UserFormProps<TExternal extends boolean = false> = {
  isExternal?: TExternal;
  values?: TExternal extends true
    ? UserFormSchemaForExternalUser
    : UserFormSchema;
  onSubmit: (
    data: TExternal extends true
      ? UserFormSchemaForExternalUser
      : UserFormSchema,
  ) => void;
} & Omit<HTMLProps<HTMLFormElement>, 'method' | 'onSubmit'>;

export function UserForm<TExternal extends boolean = false>({
  values,
  onSubmit,
  className,
  isExternal,
  ...formProps
}: UserFormProps<TExternal>) {
  const { t } = useTranslation('settings', { keyPrefix: 'user' });
  const form = useForm<
    TExternal extends true ? UserFormSchemaForExternalUser : UserFormSchema
  >({
    values,
    resolver: zodResolver(
      isExternal ? userFormSchemaForExternalUser : userFormSchema,
      {
        errorMap: getI18nErrorMap((s) => t(`validations.${s}`)),
      },
    ),
  });

  // Path gets broken with TExternal generic
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectedUserType = form.watch('userType' as any);

  // TODO: implement actual label groups and roles fetching

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className={cn('flex', 'flex-col', 'gap-md', className)}
        {...formProps}
      >
        <Accordion
          defaultValue={['basicInfo', 'contactInfo', 'userPermissions']}
        >
          <Accordion.Item
            value="basicInfo"
            title={t('basicInformationTitle')}
            className="grid grid-cols-3 gap-md bg-level-02"
          >
            <FormFieldInput name="firstName" label={t('fields.firstName')} />
            <FormFieldInput name="lastName" label={t('fields.lastName')} />
            {!isExternal && (
              <FormFieldDropdown
                options={USER_TYPES}
                name="userType"
                label={t('fields.userType')}
              />
            )}
            {!isExternal && selectedUserType === 'internal' ? (
              <FormFieldInput
                name="organization"
                label={t('fields.organization')}
              />
            ) : null}
            <FormFieldInput name="jobTitle" label={t('fields.jobTitle')} />
          </Accordion.Item>
          <Accordion.Item
            value="contactInfo"
            title={t('contactInformationTitle')}
            className="grid grid-cols-3 gap-md bg-level-02"
          >
            <FormFieldInput
              name="email"
              type="email"
              label={t('fields.email')}
            />
            <FormFieldInput name="phone" type="tel" label={t('fields.phone')} />
          </Accordion.Item>
          <Accordion.Item
            value="userPermissions"
            title={t('permissionsTitle')}
            className="grid grid-cols-3 gap-md bg-level-02"
          >
            <p className="col-span-3 text-subtle">{t('roleLabelNotice')}</p>
            <FormFieldDropdown
              options={ROLES}
              className="col-span-2"
              name="role"
              label={t('fields.role')}
            />
            <FormFieldDropdown
              options={LABEL_GROUPS}
              className="col-span-2"
              name="labelGroup"
              label={t('fields.labelGroup')}
            />
          </Accordion.Item>
        </Accordion>
      </form>
    </FormProvider>
  );
}

UserForm.displayName = 'UserForm';
