import { useContext } from 'react';

import { cn } from '~/lib/cn';
import { BrandContext } from '~/modules/branding';

const BrandLogo = ({ className }: { className?: string }) => {
  const { logo } = useContext(BrandContext);
  if (!logo) {
    return null;
  }
  return (
    <logo.type
      data-testid="brand-logo"
      className={cn(logo.props.className, className)}
    />
  );
};

BrandLogo.displayName = 'BrandLogo';

export { BrandLogo };
