import { forwardRef, type HTMLAttributes } from 'react';

import { cn } from '~/lib/cn';

export type CategoryButtonProps = {
  isActive: boolean;
  disabled?: boolean;
} & HTMLAttributes<HTMLButtonElement>;

export const CategoryButton = forwardRef<
  HTMLButtonElement,
  CategoryButtonProps
>(({ isActive, disabled, children, ...props }, ref) => (
  <button
    {...props}
    ref={ref}
    className={cn(
      '-mt-px flex-1 rounded-full px-md py-sm text-body-sm font-semibold leading-tight duration-150 enabled:hover:bg-emphasis-strong disabled:cursor-not-allowed disabled:opacity-strong',
      {
        'shadow-[0_0_7px_0_oklch(var(--bg-tertiary-default)/0.95)] bg-tertiary-default text-strong-on-light enabled:hover:bg-tertiary-default':
          isActive,
      },
    )}
    disabled={disabled}
  >
    {children}
  </button>
));

CategoryButton.displayName = 'CategoryButton';
