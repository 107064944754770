import { type FC, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import { ErrorPage } from '~/components/ErrorPage';
import { ErrorBoundary } from '~/modules/errors/components/ErrorBoundary.tsx';

import { WmgOneLayout } from './components/WmgOneLayout';
import { Home } from './Home.tsx';
import { LINK_FARM_CATEGORIES } from './linkFarm.ts';
import { LinkPage } from './LinkPage.tsx';

export const LinkFarmRouter: FC = () => {
  return (
    <ErrorBoundary>
      <Suspense>
        <Routes>
          <Route element={<WmgOneLayout />}>
            <Route index path="/" element={<Home />} />
            {LINK_FARM_CATEGORIES.map((category) => (
              <Route
                key={category}
                path={category}
                element={<LinkPage category={category} />}
              />
            ))}
          </Route>
          <Route path="*" element={<ErrorPage type="notFoundPage" />} />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
};

LinkFarmRouter.displayName = 'LinkFarmRouter';
