import { NavLink } from 'react-router-dom';

import { Separator } from '~/components';

export interface NavTab {
  label: string;
  path: string;
}

interface NavTabsProps {
  tabs: NavTab[];
  ariaLabel?: string;
  ariaLabelledBy?: string;
}

export function NavTabs({ tabs, ariaLabel, ariaLabelledBy }: NavTabsProps) {
  return (
    <div>
      <nav
        className="flex h-full gap-lg font-medium"
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledBy}
      >
        {tabs.map((tab) => (
          <NavLink
            key={tab.path}
            to={tab.path}
            end
            className={({ isActive }) =>
              isActive
                ? "relative pb-sm after:absolute after:bottom-0 after:left-0 after:h-[3px] after:w-full after:rounded-sm after:content-[''] after:bg-primary-default"
                : 'text-subtle'
            }
          >
            {tab.label}
          </NavLink>
        ))}
      </nav>
      <Separator role="presentation" />
    </div>
  );
}

NavTabs.displayName = 'NavTabs';
