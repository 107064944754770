import { useContext } from 'react';

import type { DropdownContextType } from '~/components/Dropdown/contexts/DropdownContext';
import { DropdownContext } from '~/components/Dropdown/contexts/DropdownContext';

export function useDropdownContext(): DropdownContextType {
  const context = useContext(DropdownContext);

  if (!context) {
    throw new Error(
      'useDropdownContext must be used within a DropdownContextProvider',
    );
  }

  return context;
}
