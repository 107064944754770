import { useQuery } from '@tanstack/react-query';
import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useState } from 'react';

import { getArtists } from '~/modules/artist-roster/lib/artists.ts';
import { getLabels } from '~/modules/artist-roster/lib/labels';
import type { LabelData } from '~/modules/artist-roster/lib/types';
import { ICON_BY_TYPE } from '~/modules/link-farm/components/LinkList/LinkItemIcons';
import { LINK_FARM } from '~/modules/link-farm/linkFarm';

import type {
  ArtistLinkItem,
  GlobalSearchResultItems,
  LabelLinkItem,
  LinkItem,
} from '../lib/types';

const linkFarmItems = Object.values(LINK_FARM)
  .flat()
  .map((item, index) => ({
    id: `${index}`,
    icon: item.icon,
    appName: item.appName,
    url: item.url,
    actionText: item.actionText,
    category: item.category,
    requestAccess: item.requestAccess,
    requireVpn: item.requireVpn,
  }));

interface UseSearchProps {
  searchQuery: string;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export const useSearch = ({
  searchQuery,
  setIsLoading,
}: UseSearchProps): GlobalSearchResultItems => {
  const [tools, setTools] = useState<LinkItem[]>([]);
  const [artists, setArtists] = useState<ArtistLinkItem[]>([]);
  const [labels, setLabels] = useState<LabelLinkItem[]>([]);
  const { data } = useQuery({
    queryKey: ['artistAndLabelsSearch', searchQuery],
    queryFn: async () => {
      setIsLoading(true);
      const [artistsResult, labelsResult] = await Promise.all([
        getArtists({ search: searchQuery }),
        getLabels({}),
      ]);
      return { artistsResult, labelsResult };
    },
    enabled: searchQuery.length > 0,
  });

  useEffect(() => {
    if (data?.labelsResult && searchQuery) {
      const matchedLabels = data.labelsResult.filter((label: LabelData) =>
        label.name.toLowerCase().includes(searchQuery.toLowerCase()),
      );

      setLabels(
        matchedLabels.map((label: LabelData) => ({
          icon: ICON_BY_TYPE.WARNER_BLACK,
          id: label.labelId.toString(),
          appName: label.name,
          url: `roster?labels=${label.labelId}`,
          actionText: label.name,
          category: 'label',
        })),
      );
    } else {
      setLabels([]);
    }

    setTools(
      searchQuery
        ? linkFarmItems.filter((item) =>
            [item.actionText, item.appName, item.url].some((field) =>
              field.toLowerCase().includes(searchQuery.toLowerCase()),
            ),
          )
        : [],
    );

    if (data?.artistsResult) {
      setArtists(
        data.artistsResult.items.map((artist) => ({
          icon: artist.image ?? ICON_BY_TYPE.WARNER_BLACK,
          id: artist.id.toString(),
          appName: artist.name,
          url: `roster/${artist.uaid}`,
          actionText: artist.name,
          category: 'artist',
          requestAccess: 'false',
          requireVpn: false,
        })),
      );
    } else {
      setArtists([]);
    }

    if (data) {
      setIsLoading(false);
    }
  }, [searchQuery, data, setIsLoading]);

  return {
    tools,
    artists,
    tracks: [],
    labels,
  };
};
