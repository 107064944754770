import * as zod from 'zod';

import { BaseEntitySchema } from '~/modules/settings/api/lib/schemas';

enum Errors {
  FirstNameRequired = 'firstNameRequired',
  LastNameRequired = 'lastNameRequired',
  UserTypeRequired = 'userTypeRequired',
  EmailRequired = 'emailRequired',
  EmailInvalid = 'emailInvalid',
  PhoneRequired = 'phoneRequired',
  PhoneInvalid = 'phoneInvalid',
  RoleRequired = 'roleRequired',
  LabelGroupRequired = 'labelGroupRequired',
  JobTitleRequired = 'jobTitleRequired',
  OrganizationRequired = 'organizationRequired',
}

const commonUserFormSchema = zod.object({
  firstName: zod
    .string({ required_error: Errors.FirstNameRequired })
    .nonempty(Errors.FirstNameRequired),
  lastName: zod
    .string({ required_error: Errors.LastNameRequired })
    .nonempty(Errors.LastNameRequired),
  jobTitle: zod
    .string({ required_error: Errors.JobTitleRequired })
    .nonempty(Errors.JobTitleRequired),
  email: zod
    .string({ required_error: Errors.EmailRequired })
    .email(Errors.EmailInvalid),
  phone: zod
    .string({ required_error: Errors.PhoneRequired })
    .nonempty(Errors.PhoneInvalid),
  labelGroups: zod.array(BaseEntitySchema, {
    required_error: Errors.LabelGroupRequired,
  }),
});

const internalUserFormSchema = commonUserFormSchema.merge(
  zod.object({
    userType: zod.literal('internal'),
    organization: zod
      .string({ required_error: Errors.OrganizationRequired })
      .nonempty(Errors.OrganizationRequired),
  }),
);

const externalUserFormSchema = commonUserFormSchema.merge(
  zod.object({
    userType: zod.literal('external').default('external'),
  }),
);

export const userFormSchema = zod.discriminatedUnion(
  'userType',
  [internalUserFormSchema, externalUserFormSchema],
  {
    errorMap: ({ code, path }, { defaultError }) => ({
      message:
        code === 'invalid_union_discriminator' && path[0] === 'userType'
          ? Errors.UserTypeRequired
          : defaultError,
    }),
  },
);

export type UserFormSchema = zod.infer<typeof userFormSchema>;

export const userFormSchemaForExternalUser = externalUserFormSchema;

export type UserFormSchemaForExternalUser = zod.infer<
  typeof userFormSchemaForExternalUser
>;
