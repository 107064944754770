import { cn } from '~/lib/cn';

function Skeleton({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      role="presentation"
      className={cn(
        'h-md w-2xl animate-pulse rounded-md bg-level-02',
        className,
      )}
      {...props}
    />
  );
}
Skeleton.displayName = 'Skeleton';
export { Skeleton };
