import type { Ref } from 'react';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useGlobalSearchContext } from '../../hooks/useGlobalSearchContext';
import { MAX_ITEMS_UNDER_ALL_RESULTS } from '../../lib/constants';
import type { SearchResultCategoryKeys } from '../../lib/types';

interface ShowAllCategoryResultsButtonProps {
  category: SearchResultCategoryKeys;
}

export const SHOW_ALL_CATEGORY_RESULTS_BUTTON = 'ShowAllCategoryResultsButton';

export const ShowAllCategoryResultsButton = forwardRef(
  (
    { category, ...props }: ShowAllCategoryResultsButtonProps,
    ref: Ref<HTMLButtonElement>,
  ) => {
    const { t } = useTranslation('wmgone');
    const { selectedCategory, setSelectedCategory } = useGlobalSearchContext();
    const { searchResults } = useGlobalSearchContext();

    if (
      selectedCategory !== 'allResults' ||
      (selectedCategory === 'allResults' &&
        searchResults[category] &&
        searchResults[category].length <= MAX_ITEMS_UNDER_ALL_RESULTS)
    ) {
      return null;
    }

    return (
      <button
        {...props}
        ref={ref}
        className="mb-sm w-fit cursor-pointer text-body-sm underline underline-offset-2 focus-visible:ring"
        onClick={() => {
          setSelectedCategory(category);
        }}
        data-type={SHOW_ALL_CATEGORY_RESULTS_BUTTON}
      >
        {t(`Show all ${category} results`)}
      </button>
    );
  },
);

ShowAllCategoryResultsButton.displayName = SHOW_ALL_CATEGORY_RESULTS_BUTTON;
