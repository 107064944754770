import type { FC, PropsWithChildren } from 'react';
import { useContext } from 'react';
import { Helmet } from 'react-helmet-async';

import { BrandContext } from '~/modules/branding';

const RootLayout: FC<PropsWithChildren> = ({ children }: PropsWithChildren) => {
  const brand = useContext(BrandContext);

  return (
    <>
      <Helmet titleTemplate={brand.titleTemplate} defaultTitle={brand.title}>
        <link
          rel="icon"
          type="image/png"
          href={`/images/${brand.name?.toLowerCase()}-logo.webp`}
        />
      </Helmet>
      <section className="flex min-h-dvh min-w-full bg-default text-default">
        {children}
      </section>
    </>
  );
};

RootLayout.displayName = 'RootLayout';

export { RootLayout };
