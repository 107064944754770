export const splitToHighlightedPart = (
  str: string = '',
  highlight?: string,
) => {
  let indexMatch =
    highlight && str.toLowerCase().indexOf(highlight?.toLowerCase());
  if (indexMatch === '' || typeof indexMatch === 'undefined') {
    indexMatch = -1;
  }

  const containsHighlightedPart = highlight && indexMatch !== -1;

  return {
    startPart: containsHighlightedPart ? str.slice(0, indexMatch) : str,
    matchingPart: containsHighlightedPart
      ? str.slice(indexMatch, indexMatch + highlight.length)
      : '',
    endPart: containsHighlightedPart
      ? str.slice(indexMatch + highlight.length, str.length)
      : '',
  };
};

export const hasFocusDeep = (element?: HTMLElement | null) =>
  element === document.activeElement ||
  element?.contains(document.activeElement);
