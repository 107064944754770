import { createContext } from 'react';

import type { User } from '~/modules/auth';

export type WmgSession = {
  token: string;
  user: User;
};

const WmgSessionContext = createContext<WmgSession>({
  user: {
    name: '',
    email: '',
    isInternal: false,
    features: [],
    permissions: {},
  },
  token: '',
});

WmgSessionContext.displayName = 'WmgSessionContext';

export { WmgSessionContext };
