import { type FC, useMemo } from 'react';

import { cn } from '~/lib/cn';

import { useGlobalSearchContext } from '../../hooks/useGlobalSearchContext';
import { splitToHighlightedPart } from '../../lib/utils';

export type HighlightedTextProps = {
  text: string;
  className?: string;
};

export const HighlightedText: FC<HighlightedTextProps> = ({
  text,
  className,
}) => {
  const { searchQuery } = useGlobalSearchContext();
  const highlightedDescription = useMemo(
    () => splitToHighlightedPart(text, searchQuery),
    [text, searchQuery],
  );

  return (
    <span className={cn('text-default', className)}>
      <span className="text-subtle">{highlightedDescription.startPart}</span>
      {highlightedDescription.matchingPart}
      <span className="text-subtle">{highlightedDescription.endPart}</span>
    </span>
  );
};

HighlightedText.displayName = 'HighlightedText';
