import { useTranslation } from 'react-i18next';

import { KebabMenu } from '~/components/KebabMenu';

type ListUserActionsProps = {
  onEditClick: () => void;
  onDeleteClick: () => void;
};

export const ListUserActions = ({
  onEditClick,
  onDeleteClick,
}: ListUserActionsProps) => {
  const { t } = useTranslation('settings', { keyPrefix: 'user' });

  return (
    <KebabMenu>
      <KebabMenu.Item
        onClick={(e) => {
          e.stopPropagation();
          onEditClick();
        }}
      >
        {t('list.editButton')}
      </KebabMenu.Item>
      <KebabMenu.Item
        state="error"
        onClick={(e) => {
          e.stopPropagation();
          onDeleteClick();
        }}
      >
        {t('list.deleteButton')}
      </KebabMenu.Item>
    </KebabMenu>
  );
};

ListUserActions.displayName = 'ListUserActions';
