import { addMonths, format, parseISO } from 'date-fns';

export const getRelMonth = (rel: number): string => {
  return format(addMonths(new Date(), rel), 'MMM');
};

// Formats a number to 1.000.000 format
export const formatNumber = (num: number): string => {
  return new Intl.NumberFormat('en-US', { useGrouping: true }).format(num);
};

export const formatDateTime = (date: string): string => {
  const parsedDate = parseISO(date);
  return format(parsedDate, 'P p');
};

const toAutoFixed = (num: number, fixed: number): string => {
  if (num > 100) {
    return num.toFixed(0);
  }

  return num.toFixed(fixed);
};

// Formats a number adding K, M, B suffixes
export const formatNumberSuffix = (
  num: number,
  fixed = 1,
  showSign = true,
): string => {
  const signChar = num < 0 && showSign ? '-' : '';
  num = Math.abs(num);

  if (num >= 1_000_000_000) {
    return signChar + toAutoFixed(num / 1_000_000_000, fixed) + 'B';
  }
  if (num >= 1_000_000) {
    return signChar + toAutoFixed(num / 1_000_000, fixed) + 'M';
  }
  if (num >= 1_000) {
    return signChar + toAutoFixed(num / 1_000, fixed) + 'K';
  }

  return signChar + toAutoFixed(num, 0); // No suffix for numbers less than 1000
};
