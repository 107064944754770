import { useCallback } from 'react';

import { useDropdownContext } from '~/components/Dropdown/hooks/useDropdownContext';

export const useItemEventHandlers = () => {
  const { onSelect, collection } = useDropdownContext();
  const focusNext = useCallback(
    (item: string) => {
      const index = collection.findIndex(({ value }) => value === item);
      const nextIndex = (index + 1) % collection.length;
      collection[nextIndex].ref?.current?.focus();
    },
    [collection],
  );

  const focusPrev = useCallback(
    (item: string) => {
      const index = collection.findIndex(({ value }) => value === item);
      const prevIndex = (index - 1 + collection.length) % collection.length;
      collection[prevIndex].ref?.current?.focus();
    },
    [collection],
  );
  const createOnKeyDown = useCallback(
    (item: string) => (e: React.KeyboardEvent<HTMLDivElement>) => {
      // https://www.w3.org/WAI/ARIA/apg/patterns/combobox/examples/grid-combo/
      switch (e.code) {
        case 'ArrowRight':
        case 'ArrowDown':
          focusNext(item);
          break;
        case 'ArrowLeft':
        case 'ArrowUp':
          focusPrev(item);
          break;
        case 'Home':
          collection[0].ref?.current?.focus();
          break;
        case 'End':
          collection[collection.length - 1].ref?.current?.focus();
          break;
        case 'Enter':
          onSelect(item);
          break;
        case 'Tab':
          e.preventDefault();
          e.stopPropagation();
          break;
        default:
          break;
      }
    },
    [collection, onSelect, focusNext, focusPrev],
  );

  const createOnClick = (item: string) => () => {
    onSelect(item);
  };

  return (item: string) => ({
    onClick: createOnClick(item),
    onKeyDown: createOnKeyDown(item),
  });
};
