import { cn } from '~/lib/cn';

export interface ValueProps {
  className?: string;
  children: React.ReactNode | React.ReactNode[];
}

const Value = ({ className, children }: ValueProps): JSX.Element => {
  return (
    <p className={cn('text-display-lg font-light', className)}>{children}</p>
  );
};

Value.displayName = 'Value';

export { Value };
