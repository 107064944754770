import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VictoryLabel } from 'victory';

import { LineGraph } from '~/components/LineGraph/LineGraph';
import { LineGraphLegend } from '~/components/LineGraph/LineGraphLegend';
import { Toggle } from '~/components/Toggle';

import { getTrackPerformanceOverTime } from '../../lib/tracks';
import { PerformanceModuleHeader } from './PerformanceModuleHeader';
import { formatMillionsNumber, formatUtcDate } from './utils';

const LINE_COLOR = '#3E82F0';

interface TrackStreamsGraphProps {
  familyId: number;
  territory: string;
  startDate: string;
  endDate: string;
}

export function TrackStreamsGraph({
  familyId,
  territory,
  startDate,
  endDate,
}: TrackStreamsGraphProps) {
  const { t } = useTranslation('roster');
  const [isCumulative, setIsCumulative] = useState(false);

  const { data } = useQuery({
    queryKey: ['track-performance-over-time', familyId],
    queryFn: () => {
      return getTrackPerformanceOverTime({ familyId });
    },
  });

  const metrics = useMemo(() => {
    let sum = 0;
    return data?.performance_over_time.metrics.map((value, i) => {
      sum += value.streams;
      return {
        date: value.date,
        x: i,
        y: isCumulative ? sum : value.streams,
      };
    });
  }, [data, isCumulative]);

  if (!metrics) {
    return null;
  }

  const legendItems = [
    {
      lineColor: LINE_COLOR,
      label: t('trackDetails.labels.streamsRTD'),
    },
  ];

  return (
    <div className="w-full rounded-sm border-px border-solid p-md bg-level-01 border-emphasis-subtle">
      <PerformanceModuleHeader
        header={t('trackDetails.streamsOverTime')}
        territory={territory}
        startDate={startDate}
        endDate={endDate}
      />
      <div className="flex items-center justify-between">
        <LineGraphLegend items={legendItems} />
        <div className="flex items-center gap-sm text-body-sm font-semibold">
          {isCumulative
            ? t('trackDetails.views.cumulative')
            : t('trackDetails.views.noncumulative')}
          <Toggle pressed={isCumulative} onPressedChange={setIsCumulative} />
        </div>
      </div>
      <LineGraph
        lineColor={LINE_COLOR}
        xAxisProps={{
          style: {
            tickLabels: {
              textAlign: 'left',
            },
          },
          domain: [0, metrics.length],
          tickLabelComponent: <VictoryLabel textAnchor="start" />,
          tickValues: metrics.map((datum) => datum.x),
          tickFormat: metrics.map((datum) => formatUtcDate(datum.date)),
        }}
        yAxisProps={{
          orientation: 'right',
          label: t('trackDetails.labels.streamsRTD'),
          tickFormat: (num) => formatMillionsNumber(num, true),
        }}
        data={metrics}
        containerClassName="w-full"
      />
    </div>
  );
}

TrackStreamsGraph.displayName = 'TrackStreamsGraph';
