import type React from 'react';

import { IconButton } from '../IconButton';
import { Popover } from '../Popover';
import { KebabMenuItem } from './components/KebabMenuItem';

type KebabMenuProps = {
  children?: React.ReactNode;
};

export const KebabMenu = ({ children }: KebabMenuProps) => {
  return (
    <Popover content={children} stopPropagation triggerAsChild>
      <IconButton
        aria-label="open options menu"
        iconType="ellipsis-vertical"
        size="xlarge"
      />
    </Popover>
  );
};

KebabMenu.displayName = 'KebabMenu';
KebabMenu.Item = KebabMenuItem;
