import { Fragment } from 'react/jsx-runtime';

import type { Brand } from '../types/brand';

export const defaultBrandContext: Brand = {
  name: null,
  product: '',
  title: '',
  titleTemplate: '',
  oktaConfig: {},
  meta: <Fragment />,
};
