import type { Row } from '@tanstack/react-table';
import { flexRender } from '@tanstack/react-table';
import {
  type HTMLProps,
  type KeyboardEventHandler,
  type MouseEventHandler,
  useCallback,
} from 'react';

import { cn } from '~/lib/cn';

type TableRowProps<TData> = Omit<
  HTMLProps<HTMLTableRowElement>,
  'onKeyDown' | 'tabIndex' | 'onClick'
> & {
  row: Row<TData>;
  onClick?: MouseEventHandler & KeyboardEventHandler;
};

export function TableRow<TData extends { id: string }>({
  row,
  className,
  onClick: onInteract,
  ...props
}: TableRowProps<TData>) {
  const onKeyDown = useCallback<KeyboardEventHandler>(
    (e) => {
      if (e.key === 'Enter') {
        onInteract?.(e);
      }
    },
    [onInteract],
  );

  const onClick = useCallback<MouseEventHandler>(
    (e) => {
      onInteract?.(e);
    },
    [onInteract],
  );

  return (
    <tr
      key={row.id}
      className={cn(
        'group',
        'odd:bg-level-02',
        onInteract && 'cursor-pointer',
        className,
      )}
      tabIndex={onInteract ? 0 : undefined}
      onClick={onClick}
      onKeyDown={onKeyDown}
      {...props}
    >
      {row.getVisibleCells().map((cell) => (
        <td
          key={cell.id}
          className={cn(
            'border-y-px',
            'border-transparent',
            'group-hover:border-emphasis-stronger',
            'group-hover:bg-level-04',
            'p-md',
            'first:rounded-s-sm',
            'first:border-r-0',
            'first:border-l-px',
            'last:rounded-r-sm',
            'last:border-l-0',
            'last:border-r-px',
          )}
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      ))}
    </tr>
  );
}

TableRow.displayName = 'TableRow';
