import type { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, Tooltip } from '~/components';
import { Button } from '~/components/Button';
import { Tag } from '~/components/Tag';
import type { ArtistFiltersType } from '~/modules/artist-roster/components/ArtistFilter/ArtistFiltersType.ts';

type ArtistAppliedFilter = {
  category: string;
  value: number;
  displayName: string;
  updatedAt?: string;
  updatedBy?: string;
};

interface ArtistAppliedFilterListProps {
  appliedFilters: ArtistAppliedFilter[];
  onRemoveFilter: (value: number) => void;
  setShowFilterPanel: Dispatch<SetStateAction<boolean>>;
  setFiltersToApply: Dispatch<SetStateAction<ArtistFiltersType>>;
  originalFilters: ArtistFiltersType;
  onApplyFilters: (filters: ArtistFiltersType) => void;
}

const MAX_VISIBLE_FILTERS = 3;

const ArtistAppliedFilterList = ({
  appliedFilters,
  onRemoveFilter,
  setShowFilterPanel,
  setFiltersToApply,
  originalFilters,
  onApplyFilters,
}: ArtistAppliedFilterListProps) => {
  const { t } = useTranslation('roster');

  return (
    <div
      className="flex items-center gap-md py-md"
      aria-label="Applied filters"
    >
      <div className="text-body-sm">{t('Filter(s) applied:')}</div>
      {appliedFilters
        .slice(0, 3)
        .map(({ value, displayName, updatedAt, updatedBy }) => {
          return (
            <Tooltip
              key={value}
              content={
                updatedAt ? (
                  <div className="text-body-xs font-semibold leading-tight">
                    <p>
                      {t('labels.lastUpdated')}:{' '}
                      {new Date(updatedAt).toLocaleDateString()}
                    </p>
                    <p>
                      {t('labels.by')}: {updatedBy}
                    </p>
                  </div>
                ) : null
              }
              enabled={!!updatedAt}
              delayDuration={0}
              side="top"
              sideOffset={6}
              size="sm"
            >
              <Tag
                variant="secondary"
                key={value}
                className="gap-xs pr-xs tracking-wider"
              >
                {displayName}
                <button
                  onClick={() => onRemoveFilter(value)}
                  className="px-2xs transition hover:text-hover"
                  data-testid="remove-filter"
                >
                  <Icon type="xmark" variant="regular" size="md" />
                </button>
              </Tag>
            </Tooltip>
          );
        })}
      {appliedFilters.length > MAX_VISIBLE_FILTERS && (
        <Tag
          onClick={() => {
            setShowFilterPanel((prevState) => !prevState);
          }}
          variant="secondary"
          key="more"
          className="gap-xs pr-xs tracking-wider"
        >
          {t('labels.moreLink', {
            count: appliedFilters.length - MAX_VISIBLE_FILTERS,
          })}
        </Tag>
      )}
      <Button
        variant="text"
        onClick={() => {
          setFiltersToApply(originalFilters);
          onApplyFilters(originalFilters);
        }}
      >
        {t('Clear Filters')}
      </Button>
    </div>
  );
};

ArtistAppliedFilterList.displayName = 'ArtistAppliedFilterList';
export { ArtistAppliedFilterList };
