import { cn } from '~/lib/cn';

import { BarChartBar } from './BarChartBar';
import type { BarChartDirection } from './types';

export type BarChartDatum = {
  percentageValues: number[];
  categoryLabel: string;
  valueLabel: string;
  delta?: number;
};

interface BarChartProps {
  data: BarChartDatum[];
  direction?: BarChartDirection;
}

export const BarChart = ({ data, direction = 'vertical' }: BarChartProps) => {
  return (
    <div
      className={cn(
        'flex',
        direction === 'vertical' ? 'h-full' : 'w-full flex-col',
      )}
    >
      {data.map((datum) => (
        <BarChartBar
          key={datum.categoryLabel}
          percentageValues={datum.percentageValues}
          categoryLabel={datum.categoryLabel}
          valueLabel={datum.valueLabel}
          direction={direction}
          delta={datum.delta}
        />
      ))}
    </div>
  );
};

BarChart.displayName = 'BarChart';
