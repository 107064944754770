interface LineGraphLegendItem {
  lineColor: string;
  label: string;
  dashArray?: string;
}

interface LineGraphLegendProps {
  items: LineGraphLegendItem[];
}

export function LineGraphLegend({ items }: LineGraphLegendProps) {
  return (
    <div className="flex gap-md text-caption-md text-default">
      {items.map((item) => {
        return (
          <div className="flex items-center gap-sm" key={`${item.label}`}>
            <svg height="15" width="15">
              <line
                x1="0%"
                y1="50%"
                x2="100%"
                y2="50%"
                stroke={item.lineColor}
                strokeWidth={2}
                strokeDasharray={item.dashArray}
              />
            </svg>
            {`${item.label}`}
          </div>
        );
      })}
    </div>
  );
}

LineGraphLegend.displayName = 'LineGraphLegend';
