const Spinner = () => {
  return (
    <div
      className="aspect-square w-2xl animate-spin rounded-full border-xs border-primary-disabled border-t-primary-default"
      data-testid="spinner"
    />
  );
};
Spinner.displayName = 'Spinner';

export { Spinner };
