import type { Dispatch, SetStateAction } from 'react';

import { Icon } from '~/components';
import { cn } from '~/lib/cn';

import { ArtistFilterPanel } from './ArtistFilterPanel';
import type { ArtistFiltersType } from './ArtistFiltersType';

interface ArtistFilterButtonProps {
  selectedFilters: ArtistFiltersType;
  onApplyFilters: (filters: ArtistFiltersType) => void;
  onClick?: () => void;
  showFilterPanel: boolean;
  setShowFilterPanel: Dispatch<SetStateAction<boolean>>;
  filtersToApply: ArtistFiltersType;
  setFiltersToApply: Dispatch<SetStateAction<ArtistFiltersType>>;
}

const ArtistFilterButton = ({
  selectedFilters,
  onApplyFilters,
  onClick,
  showFilterPanel,
  setShowFilterPanel,
  filtersToApply,
  setFiltersToApply,
}: ArtistFilterButtonProps) => {
  const areFiltersApplied =
    selectedFilters.labels.length > 0 || selectedFilters.isCrossover;

  return (
    <>
      {showFilterPanel ? (
        <ArtistFilterPanel
          originalFilters={selectedFilters}
          onClose={() => setShowFilterPanel(false)}
          onApplyFilters={onApplyFilters}
          filtersToApply={filtersToApply}
          setFiltersToApply={setFiltersToApply}
        />
      ) : null}
      <button
        className={cn(
          'bg-emphasis-default',
          'outline',
          'outline-1',
          'outline-emphasis-subtle',
          'rounded-sm',
          'leading-none',
          'px-md',
          'hover:bg-emphasis-strong',
          'hover:outline-emphasis-stronger',
          'relative',
        )}
        onClick={() => {
          setShowFilterPanel(true);
          onClick && onClick();
        }}
        data-testid="artist-filter-button"
      >
        <Icon
          type="sliders-simple"
          variant={areFiltersApplied ? 'solid' : 'light'}
          size="md"
        />
        {selectedFilters.labels.length > 0 && (
          <div
            data-testid="artist-filter-button-badge"
            className="absolute -right-1/4 -top-1/4 w-fit rounded-full p-xs text-caption-md bg-primary-default"
          >
            {selectedFilters.labels.length}
          </div>
        )}
      </button>
    </>
  );
};

ArtistFilterButton.displayName = 'ArtistFilterButton';
export { ArtistFilterButton };
