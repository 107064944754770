import type { PerformanceMetrics } from '../../lib/types';
import { DemographicsTable } from './DemographicsTable';
import type { MetricType } from './PerformanceView';
import { TrackStreamsGraph } from './TrackStreamsGraph';

interface PerformanceModulesProps {
  familyId: number;
  metricType: MetricType;
  performance?: PerformanceMetrics;
}

// Display different performance modules based on the metric type
export function PerformanceModules({
  familyId,
  metricType,
}: PerformanceModulesProps) {
  /* @TODO WMG1-268 - Pass in real values for props from the call to the streaming API. */
  const territory = 'Global';
  const startDate = 'Oct 28, 2024';
  const endDate = 'Feb 27, 2025';

  switch (metricType) {
    case 'streamsRTD':
      return (
        <div className="flex w-full flex-col gap-lg">
          <TrackStreamsGraph
            familyId={familyId}
            territory={territory}
            startDate={startDate}
            endDate={endDate}
          />
          <div className="w-1/2">
            <DemographicsTable
              territory={territory}
              startDate={startDate}
              endDate={endDate}
            />
          </div>
        </div>
      );
    case 'periodStreams':
      return (
        <div className="flex w-full flex-col gap-lg">
          <div className="w-1/2">
            <DemographicsTable
              territory={territory}
              startDate={startDate}
              endDate={endDate}
            />
          </div>
        </div>
      );
    case 'organicStreams':
      return (
        <div className="flex w-full flex-col gap-lg">
          <div className="w-1/2">
            <DemographicsTable
              territory={territory}
              startDate={startDate}
              endDate={endDate}
            />
          </div>
        </div>
      );
    case 'socialCreations':
      return (
        <div className="flex w-full flex-col gap-lg">
          <div className="w-1/2">
            <DemographicsTable
              territory={territory}
              startDate={startDate}
              endDate={endDate}
            />
          </div>
        </div>
      );
    default:
      return null;
  }
}

PerformanceModules.displayName = 'PerformanceModules';
