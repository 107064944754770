import ky from 'ky';

export const BFFClient = ky.create({
  prefixUrl: '/api/coop2',
  credentials: 'include',
  // 90 seconds
  timeout: 1000 * 30 * 3,
  // Retries are also handled by the React Query Client
  retry: 0,
});
