import { Route, Routes } from 'react-router-dom';

import { TabsPagesLayout } from '../../components';
import { CreateUserPage } from './pages/CreateUserPage';
import { DetailUserPage } from './pages/DetailUserPage';
import { EditUserPage } from './pages/EditUserPage';
import { ListUserPage } from './pages/ListUserPage';

export const UserRouter = () => {
  return (
    <Routes>
      <Route path="create" element={<CreateUserPage />} />
      <Route path=":userId/edit" element={<EditUserPage />} />
      <Route path=":userId" element={<DetailUserPage />} />
      <Route element={<TabsPagesLayout />}>
        <Route path="*" element={<ListUserPage />} />
      </Route>
    </Routes>
  );
};

UserRouter.displayName = 'UserRouter';
