import type { LabelIconMapping } from './types';

export const LABEL_ICON_MAPPINGS: LabelIconMapping = {
  'Warner Music': {
    icon: 'WARNER_BLUE',
  },
  'Warner Music Nashville': {
    icon: 'WARNER_BLUE',
  },
  Atlantic: {
    icon: 'ATLANTIC',
  },
};
