import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import { LabelPerfPageLayout } from '~/modules/label-perf/components/LabelPerfPageLayout';

import { Dashboard } from './containers/Dashboard';
import { Derivative } from './containers/Derivative';
import { LabelPerf } from './containers/LabelPerf/LabelPerf';

/**
 * Top-level router combining module-level routers
 */
const Router = () => {
  return (
    <Suspense>
      <Routes>
        <Route element={<LabelPerfPageLayout />}>
          <Route path="*" element={<LabelPerf />} />
          <Route path="debug-dashboard" element={<Dashboard />} />
          <Route path="debug-derivative" element={<Derivative />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

Router.displayName = 'LabelPerfRouter';

export { Router };
