import type { PropsWithChildren } from 'react';

import { useKeyboardNavigation } from '../hooks/useKeyboardNavigation';
import { KeyboardNavigationContext } from './KeyboardNavigationContext';

interface KeyboardNavigationProviderProps {
  searchWrapperRef: React.RefObject<HTMLDivElement>;
}

export function KeyboardNavigationProvider({
  searchWrapperRef,
  children,
}: PropsWithChildren<KeyboardNavigationProviderProps>): React.ReactNode {
  const { registerElements, elementRefs } = useKeyboardNavigation({
    searchWrapperRef,
  });

  return (
    <KeyboardNavigationContext.Provider
      value={{
        registerElements,
        elementRefs,
      }}
    >
      {children}
    </KeyboardNavigationContext.Provider>
  );
}

KeyboardNavigationProvider.displayName = 'KeyboardNavigationProvider';
