import { useTranslation } from 'react-i18next';

export const EmptyResult = () => {
  const { t } = useTranslation('wmgone');

  return (
    <div className="flex flex-1 flex-col items-center gap-xs py-3xl">
      <p className="text-headline-md font-semibold text-white">
        {t('globalSearch.result.noResult.title')}
      </p>
      <p className="text-subtle">
        {t('globalSearch.result.noResult.description')}
      </p>
    </div>
  );
};

EmptyResult.displayName = 'EmptyResult';
