import type React from 'react';
import { forwardRef } from 'react';
import { Link } from 'react-router-dom';

import type { IconName, IconSize, IconVariant } from '~/components/Icon';
import { Icon } from '~/components/Icon';
import { cn } from '~/lib/cn';
import { variants } from '~/lib/variants';

type Size = 'xlarge' | 'large' | 'medium' | 'small';

const getButtonVariants = variants({
  base: [
    'inline-block',
    'leading-none',
    'text-icon-strong',
    'flex items-center justify-center',
    'rounded-full',
    'bg-transparent',
    'hover:bg-emphasis-strong',
    'active:bg-primary-default',
    'disabled:text-icon-disabled',
    'disabled:bg-transparent',
    'disabled:pointer-events-none',
    'font-medium',
  ],
  variants: {
    size: {
      xlarge: ['py-md', 'px-md', 'w-[3rem]', 'h-[3rem]'],
      large: ['py-sm', 'px-sm', 'w-2xl', 'h-2xl'],
      medium: ['py-sm', 'px-sm', 'w-xl', 'h-xl'],
      small: ['py-xs', 'px-xs', 'w-[1.75rem]', 'h-[1.75rem]'],
    },
  },
});

interface BaseProps {
  iconType: IconName;
  iconVariant?: IconVariant;
  size?: Size;
  className?: string;
}

type ButtonProps = BaseProps &
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    asLink?: false;
    linkTo?: undefined;
  };

type LinkProps = BaseProps &
  React.AnchorHTMLAttributes<HTMLAnchorElement> & {
    asLink: true;
    linkTo: string;
  };

type Props = ButtonProps | LinkProps;

const ICON_SIZE: Record<Size, IconSize> = {
  xlarge: 'lg',
  large: 'lg',
  medium: 'md',
  small: 'sm',
};

const IconButton = forwardRef(
  (
    {
      iconType,
      size = 'medium',
      iconVariant = 'solid',
      className = '',
      asLink,
      linkTo,
      ...props
    }: Props,
    ref: React.Ref<HTMLButtonElement | HTMLAnchorElement>,
  ) => {
    const styles = getButtonVariants({ size });
    const iconParams = {
      type: iconType,
      variant: iconVariant,
      size: ICON_SIZE[size],
    };

    if (asLink && linkTo?.trim().length) {
      return (
        <Link
          {...(props as LinkProps)}
          className={cn(styles, className)}
          to={linkTo}
          ref={ref as React.Ref<HTMLAnchorElement>}
        >
          <Icon {...iconParams} />
        </Link>
      );
    }

    return (
      <button
        {...(props as ButtonProps)}
        className={cn(styles, className)}
        ref={ref as React.Ref<HTMLButtonElement>}
      >
        <Icon {...iconParams} />
      </button>
    );
  },
);

IconButton.displayName = 'IconButton';
export { IconButton };
