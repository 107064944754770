import type { FC } from 'react';

import {
  LinkItem,
  type LinkItemProps,
} from '~/modules/link-farm/components/LinkList/LinkItem.tsx';

type LinkListProps = {
  title?: string;
  data: LinkItemProps[];
};

export const LinkList: FC<LinkListProps> = ({ title, data }) => {
  return (
    <div className="flex w-full flex-col overflow-auto">
      {title ? (
        <p className="py-lg text-headline-sm font-bold">{title}</p>
      ) : null}
      <div className="grid w-full auto-rows-min grid-cols-auto-fill-19 gap-md overflow-auto overscroll-contain">
        {data.map((item) => (
          <LinkItem key={item.actionText + item.url} {...item} />
        ))}
      </div>
    </div>
  );
};

LinkList.displayName = 'LinkList';
