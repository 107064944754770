import { SettingsPageHeader } from '~/modules/settings/components/SettingsPageHeader';
import { SETTINGS_ROUTES } from '~/modules/settings/config/routes';
import type { LabelFormSchema } from '~/modules/settings/modules/label/components/LabelForm';
import { LabelForm } from '~/modules/settings/modules/label/components/LabelForm';

export function CreateLabelPage() {
  const onSubmit = (data: LabelFormSchema) => {
    console.log(data);
  };

  return (
    <article className="relative h-full">
      <SettingsPageHeader
        breadcrumbItems={[
          { to: SETTINGS_ROUTES.ROOT, label: 'title' },
          { to: SETTINGS_ROUTES.USER_LIST, label: 'label.title' },
          { to: '', label: 'label.createLabelTitle' },
        ]}
        title="label.createLabel"
      />
      <LabelForm onSubmit={onSubmit} />
    </article>
  );
}

CreateLabelPage.displayName = 'CreateLabelPage';
