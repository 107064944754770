import { Slot } from '@radix-ui/react-slot';
import type React from 'react';

import { cn } from '~/lib/cn';

export type MenuItemState = 'error' | 'warning' | 'success';

type KebabMenuItemProps = {
  children?: React.ReactNode;
  asChild?: boolean;
  state?: MenuItemState;
} & React.ComponentProps<'button'>;

export const KebabMenuItem = ({
  children,
  asChild,
  state,
  ...props
}: KebabMenuItemProps) => {
  const Component = asChild ? Slot : 'button';
  return (
    <Component
      className={cn(
        'flex',
        'px-md',
        'py-sm',
        'text-white',
        'gap-sm',
        'bg-level-02',
        'cursor-pointer',
        'w-full',
        'transition-colors',
        'duration-200',
        'hover:bg-level-03',
        'focus:bg-level-03',
        'focus:outline-none',
        'border-emphasis-default',
        'border-y-0',
        'border-x-px',
        'first-of-type:border-t-px',
        'first-of-type:rounded-t-xs',
        'last-of-type:rounded-b-xs',
        'last-of-type:border-b-px',
        state === 'error' && 'text-error-default',
        state === 'warning' && 'text-warning-default',
        state === 'success' && 'text-success-default',
      )}
      {...props}
    >
      {children}
    </Component>
  );
};

KebabMenuItem.displayName = 'KebabMenuItem';
