import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { BarChartDatum } from '~/components/BarChart/BarChart';
import { BarChart } from '~/components/BarChart/BarChart';
import type { DropdownOption } from '~/components/Dropdown';
import { Dropdown } from '~/components/Dropdown/Dropdown';
import type { AgeRangeData } from '~/modules/artist-roster/components/TrackDetails/mockData/mockData';
import { mockStreamingData } from '~/modules/artist-roster/components/TrackDetails/mockData/mockData';
import { formatMillionsNumber } from '~/modules/artist-roster/components/TrackDetails/utils';

import { PerformanceModuleHeader } from './PerformanceModuleHeader';

export interface DemographicsTableProps {
  territory: string;
  startDate: string;
  endDate: string;
}

export function DemographicsTable({
  territory,
  startDate,
  endDate,
}: DemographicsTableProps) {
  const { t } = useTranslation('roster');
  const [genderIndex, setGenderIndex] = useState(0);

  const genderNames: string[] = [
    'All genders',
    'Female',
    'Male',
    'Non-binary',
    'Unknown',
  ];

  // @TODO WMG1-268 Retrieve real data from streaming API call
  const genderKeys = Object.keys(
    mockStreamingData.streams_by_demographics,
  ) as Array<keyof typeof mockStreamingData.streams_by_demographics>;
  const ageRangeKeys = Object.keys(
    mockStreamingData.streams_by_demographics.all,
  ) as Array<keyof typeof mockStreamingData.streams_by_demographics.all>;

  ageRangeKeys.splice(ageRangeKeys.indexOf('total_streams'), 1);

  const options: DropdownOption[] = genderNames.map((gender, index) => ({
    label: gender,
    value: index.toString(),
  }));

  const demographicData =
    mockStreamingData.streams_by_demographics[genderKeys[genderIndex]];
  const totalStreams = demographicData.total_streams;
  const data: BarChartDatum[] = ageRangeKeys.map((ageRange) => {
    const ageRangeData = demographicData[ageRange] as AgeRangeData;

    return {
      percentageValues: [Math.round((ageRangeData.total / totalStreams) * 100)],
      categoryLabel: ageRange,
      valueLabel: formatMillionsNumber(ageRangeData.total),
      delta: ageRangeData.change
        ? Math.round((ageRangeData.change / totalStreams) * 100)
        : undefined,
    };
  });

  return (
    <>
      <div className="rounded-xs border-px border-solid p-md border-emphasis-subtle">
        <div className="flex items-start justify-between">
          <PerformanceModuleHeader
            header={t('trackDetails.demographic')}
            territory={territory}
            startDate={startDate}
            endDate={endDate}
          />
          <div className="w-1/6 text-caption-sm font-semibold">
            {/* @TODO WMG1-269 Replace dropdown with new variant that matches Marketing Figma */}
            <Dropdown
              onChange={(option) => {
                setGenderIndex(Number(option));
              }}
              options={options}
              placeholder="Select an option"
              value={genderIndex.toString()}
            />
          </div>
        </div>
        <BarChart data={data} direction="horizontal" />
      </div>
    </>
  );
}

DemographicsTable.displayName = 'DemographicsTable';
