import type { FC, PropsWithChildren } from 'react';

type ConditionProps = {
  visible: boolean;
};

export const Condition: FC<PropsWithChildren<ConditionProps>> = ({
  visible,
  children,
}) => {
  return visible ? children : null;
};

Condition.displayName = 'Condition';
