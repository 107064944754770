import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { idToName } from '~settings/utils/idToName';

import { useLabelGroupList } from '../api/queries/labelGroupList';
import { useLabelList } from '../api/queries/labelList';
import {
  EXTERNAL_ROLES,
  INTERNAL_ROLES,
  LABEL_TYPES,
  USER_TYPES,
} from '../constants/Filter';

const mapOptions = (
  options: { value: string; label: string }[],
  t: (key: string) => string,
) =>
  options.map((option) => ({
    id: option.value,
    name: t(option.label),
  }));

export interface FilterData {
  id: string;
  name: string;
}

interface FilterChips {
  title: { id: string; label: string };
  value: FilterData[];
}

export type FilterEntity = 'users' | 'labels';

export const useFilterChips = (entity: FilterEntity = 'users') => {
  const { labelList } = useLabelList(
    { skip: 0, take: 50 },
    {
      select: (data) =>
        data.data.map((label) => ({ id: label.id, name: label.name })),
    },
  );
  const { labelGroupList } = useLabelGroupList(
    { skip: 0, take: 50 },
    {
      select: (data) =>
        data.data.map((label) => ({ id: label.id, name: label.name })),
    },
  );
  const [searchParams] = useSearchParams();
  const { t } = useTranslation('settings');

  const ROLE_OPTIONS = mapOptions(INTERNAL_ROLES.concat(EXTERNAL_ROLES), t);
  const USER_OPTIONS = mapOptions(USER_TYPES, t);
  const LABEL_OPTIONS = mapOptions(LABEL_TYPES, t);

  const filters: FilterChips[] = [];

  const filterOptions: Record<
    FilterEntity,
    {
      key: string;
      title: string;
      options: FilterData[] | undefined;
    }[]
  > = {
    users: [
      { key: 'roles', title: 'Role', options: ROLE_OPTIONS },
      { key: 'isInternal', title: 'User Type', options: USER_OPTIONS },
      { key: 'labelIds', title: 'Label', options: labelList },
      { key: 'labelGroupIds', title: 'Label Group', options: labelGroupList },
    ],
    labels: [
      { key: 'labelType', title: 'Label Type', options: LABEL_OPTIONS },
      { key: 'labelGroupIds', title: 'Label Group', options: labelGroupList },
    ],
  };

  for (const { key, title, options } of filterOptions[entity]) {
    const ids = searchParams.get(key)?.split(',').filter(Boolean);
    if (!ids) {
      continue;
    }

    filters.push({
      title: { id: key, label: title },
      value: options ? idToName({ ids, data: options }) : [],
    });
  }

  return filters;
};
