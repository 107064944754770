import { useTranslation } from 'react-i18next';

import { Icon, Tag, Tooltip } from '~/components';
import { useIsExternalUser } from '~/hooks/useIsExternalUser';
import { env } from '~/lib/env';

export function TabsHeader() {
  const isExternal = useIsExternalUser();
  const { t } = useTranslation('settings');
  const readOnlyIsNotEnabled =
    env('VITE_CONTAINER_SETTINGS_READ_ONLY_ENABLED', false) === 'false';

  const content = (
    <>
      <div className="p-sm text-headline-md leading-tight">
        {t('betaInfo.title')}
      </div>
      <div className="p-sm">{t('betaInfo.description')}</div>
    </>
  );

  return (
    <header className="flex items-center gap-md">
      <Icon type="gear" size="lg" />
      <h1 className="py-sm text-display-sm font-semibold">{t('title')}</h1>
      {readOnlyIsNotEnabled && !isExternal ? (
        <Tag variant="secondary" size="lg" data-testid="tag-component">
          <Tooltip content={content} side="top">
            {t('betaInfo.tooltipTrigger')}
            <Icon
              type="circle-info"
              variant="light"
              className="ml-xs text-subtlest"
              size="md"
            />
          </Tooltip>
        </Tag>
      ) : null}
    </header>
  );
}

TabsHeader.displayName = 'TabsHeader';
