export interface LabelResponse {
  data: string[];
  total: number;
}
export interface LabelPerfResponse {
  tracks: number;
  tracks_need_review: number;

  opportunity_revenue_from_fixes: number;

  monthly_revenue: number;
  monthly_revenue_trend: number;
  monthly_streams: number;
  monthly_streams_trend: number;

  monthly_revenue_from_fixes: number;
  monthly_revenue_trend_from_fixes: number;
  monthly_streams_from_fixes: number;
  monthly_streams_trend_from_fixes: number;
}

export interface LabelPerfModel {
  totalAssets: number;
  totalGaps: number;
  overallMonthlyRevenue: number;
  overallMonthlyRevenueChange: number;
  overallMonthlyStreams: number;
  overallMonthlyStreamsChange: number;
  opportunityRevenueFromFixes: number;
  revenueFromFixes: number;
  revenueFromFixesChange: number;
  streamsFromFixes: number;
  streamsFromFixesChange: number;
}

export interface TrackResponse {
  isrc: string;
  artist: string;
  track_title: string;

  revenue_value: number;
  revenue_trend: number;

  streams_value: number;
  streams_trend: number;
}

export interface TrackItem {
  trackName: string;
  artistName: string;
  isrc: string;
  revenue: number;
  revenueChange: number;
  streams: number;
  streamsChange: number;
}

export interface FixesHistResponseValue {
  date: string;
  tracks_to_investigate: number;
  fixed_tracks: number;
  active_track_locks: number;
}

export interface FixesHistValue {
  name: string;
  inReview: number;
  fixed: number;
  needFixes: number;
}

export type HistRange = 'week' | 'month' | 'year';

export type TopTenType = 'revenue' | 'streams';
export type TopTenOrder = 'top' | 'bottom';

export enum TopTenCategory {
  RevenueUp = 'revenueUp',
  StreamsUp = 'streamsUp',
  RevenueDown = 'revenueDown',
  StreamsDown = 'streamsDown',
}

export type TopTenVariant =
  | 'revenueUp'
  | 'streamsUp'
  | 'revenueDown'
  | 'streamsDown';

// TREND
export type Direction = 'up' | 'down' | 'none';

// LEGEND
export enum LegendColor {
  NeedsReview = '#F9365D',
  InReview = '#FF6E37',
  FixedTracks = '#FFA602',
}

export enum LegendName {
  NeedsReview = 'Needs Review',
  InReview = 'In Review',
  FixedTracks = 'Fixed Tracks',
}
