import { cn } from '~/lib/cn';
import type { CellRendererParams } from '~/modules/label-perf/components/DataGrid/types';

import type { DataRowKey } from '../../../types/dashoard';

const OptTerrRenderer = (
  deliveredCountField: DataRowKey,
  missedCountField: DataRowKey,
) => {
  const Renderer = (
    p: CellRendererParams,
  ): string | number | JSX.Element | null => {
    if (!p?.data) {
      return null;
    }
    const deliveryTerritoryCount = (p.data[deliveredCountField] as number) ?? 0;
    const missedTerritoriesCount = (p.data[missedCountField] as number) ?? 0;
    const rightsTerritories = deliveryTerritoryCount + missedTerritoriesCount;
    return (
      <>
        <span
          data-testid="delivered-count"
          className={cn(
            deliveryTerritoryCount === rightsTerritories ? 'text-subtle' : '',
            deliveryTerritoryCount < rightsTerritories
              ? 'text-error-alert'
              : '',
            missedTerritoriesCount >= 25 ? 'font-bold text-error-alert' : '',
          )}
        >
          {deliveryTerritoryCount}
        </span>
        <span className="mx-sm font-light">/</span>
        <span data-testid="rights-count">{rightsTerritories}</span>
      </>
    );
  };

  Renderer.displayName = `OptTerrRenderer(${deliveredCountField}, ${missedCountField})`;
  return Renderer;
};

OptTerrRenderer.displayName = 'OptTerrRenderer';

export { OptTerrRenderer };
