import { useTranslation } from 'react-i18next';

import { useKeyboardCategoryHelper } from '../../hooks/useKeyboardCategoryHelper';
import type { LabelLinkItem } from '../../lib/types';
import { LabelItem } from '../LabelItem';
import { ResultSection } from '../ResultSection';
import { ShowAllCategoryResultsButton } from '../ShowAllCategoryResultsButton/ShowAllCategoryResultsButton';

export type LabelsListProps = {
  items: LabelLinkItem[];
};

const CATEGORY_KEY = 'labels';

export function LabelsList({ items }: LabelsListProps) {
  const { t } = useTranslation('wmgone');
  const { registerElement } = useKeyboardCategoryHelper({
    category: CATEGORY_KEY,
    items,
  });

  if (!items.length) {
    return null;
  }

  return (
    <ResultSection
      iconType="record-vinyl"
      iconVariant="regular"
      title={t('globalSearch.result.labels.title')}
    >
      {items.map((item, index) => (
        <LabelItem
          ref={(element) => registerElement({ element, index })}
          key={item.id}
          item={item}
        />
      ))}
      <ShowAllCategoryResultsButton
        ref={(element) => registerElement({ element, index: items.length })}
        category={CATEGORY_KEY}
      />
    </ResultSection>
  );
}

LabelsList.displayName = 'LabelsList';
