import type { CheckboxTreeItem } from '~/modules/artist-roster/components/CheckboxTree/types.ts';

export type LabelHierarchy = {
  label_id: number;
  name: string;
  has_rosters: boolean;
  parent_label_id: number | null;
  child_labels: LabelHierarchy[];
};

export function mapLabelHierarchyToCheckboxTreeItem(
  items: LabelHierarchy[],
  labelIdSet: Set<number>,
): CheckboxTreeItem[] {
  if (items.length === 0) {
    return [];
  }
  return items.map((item) => {
    const mappedChildren = mapLabelHierarchyToCheckboxTreeItem(
      item.child_labels,
      labelIdSet,
    );
    let children: CheckboxTreeItem[] | undefined;

    if (item.child_labels.length > 0) {
      children = mappedChildren;
    }

    return {
      id: item.label_id.toString(),
      label: item.name,
      checked: labelIdSet.has(item.label_id),
      isVisible: true,
      isExpanded: false,
      children,
    };
  });
}
