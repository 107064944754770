import { useTranslation } from 'react-i18next';

import { Separator, Tag } from '~/components';
import type { LabelGroup } from '~settings/api/lib/schemas';
import { HeaderSmall } from '~settings/components/HeaderSmall';

interface LabelGroupsProps {
  labelGroups: LabelGroup[];
}

export const LabelGroups = ({ labelGroups }: LabelGroupsProps) => {
  const { t } = useTranslation('settings');

  if (!labelGroups?.length) {
    return null;
  }

  return (
    <div>
      <Separator className="my-lg" />
      <HeaderSmall>{t('labelGroup.list')}</HeaderSmall>
      <ul className="flex flex-wrap gap-x-xs gap-y-sm">
        {labelGroups.map((group) => (
          <Tag key={group.id} variant="secondary" size="lg" asChild>
            <li>
              <span>{group.name}</span>
            </li>
          </Tag>
        ))}
      </ul>
    </div>
  );
};
LabelGroups.displayName = 'LabelGroups';
