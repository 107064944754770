import { type ChangeEvent, forwardRef, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '~/components';
import { IconButton } from '~/components/IconButton';
import { Input } from '~/components/Input';
import { cn } from '~/lib/cn';
import { useKeyboardNavigationContext } from '~/modules/global-search/hooks/useKeyboardNavigationContext';

import { useGlobalSearchContext } from '../../hooks/useGlobalSearchContext';

type SearchFieldProps = {
  className?: string;
  placeholder?: string;
};

export const SearchField = forwardRef<HTMLFieldSetElement, SearchFieldProps>(
  ({ className, placeholder, ...props }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const { t } = useTranslation('wmgone');
    const { searchQuery, setSearchQuery, resetSearch } =
      useGlobalSearchContext();
    const { registerElements } = useKeyboardNavigationContext();

    const handleSearchQueryInputChange = (
      event: ChangeEvent<HTMLInputElement>,
    ) => {
      const { value } = event.target;
      setSearchQuery(value);
    };

    const handleClearSearchButtonClick = () => {
      resetSearch();
      inputRef.current?.focus();
    };

    useEffect(() => {
      if (!inputRef.current) {
        return;
      }

      registerElements({ type: 'input', payload: inputRef.current });
    }, [registerElements]);

    return (
      <div
        className={cn(
          'group overflow-hidden rounded-xs p-px transition-colors duration-150',
          'bg-emphasis-subtle hover:bg-emphasis-strong',
          'focus-within:bg-[linear-gradient(90deg,oklch(var(--bg-primary-default))62.87%,oklch(var(--bg-tertiary-default))105.06%)]',
        )}
      >
        <fieldset
          {...props}
          className={cn(
            'flex w-full items-center rounded-xs bg-default',
            className,
          )}
          ref={ref}
        >
          <Icon
            className="pointer-events-none absolute px-sm pl-md"
            type="search"
            variant="light"
            size="sm"
            aria-label="search-icon"
            aria-hidden
          />
          <Input
            ref={inputRef}
            onChange={handleSearchQueryInputChange}
            value={searchQuery}
            className={cn(
              'py-sm pl-2xl text-body-sm italic outline-none outline-0 duration-150 bg-emphasis-default placeholder:text-subtle',
              'hover:outline-none hover:outline-0 focus:outline-none focus:outline-0 focus:bg-emphasis-subtle focus:placeholder:text-default group-hover:bg-emphasis-subtle',
            )}
            placeholder={placeholder || t('globalSearch.inputPlaceholder')}
          />
          {searchQuery ? (
            <IconButton
              type="button"
              iconType="circle-xmark"
              size="small"
              className="absolute right-sm duration-150 text-subtle hover:text-icon-strong"
              onClick={handleClearSearchButtonClick}
              aria-label={t('globalSearch.clearInput')}
            />
          ) : null}
        </fieldset>
      </div>
    );
  },
);

SearchField.displayName = 'SearchField';
