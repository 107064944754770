import { Route, Routes } from 'react-router-dom';

import { ErrorPage } from '~/components/ErrorPage';
import { env } from '~/lib/env';

import { DdexConsole } from './components/DdexConsole';

const Router = () => {
  const isDdexConsoleEnabled =
    env('VITE_CONTAINER_DDEX_CONSOLE_ENABLED', false) === 'true';

  return (
    <Routes>
      {isDdexConsoleEnabled ? <Route index element={<DdexConsole />} /> : null}

      <Route path="*" element={<ErrorPage type="notFoundPage" />} />
    </Routes>
  );
};
Router.displayName = 'DdexConsoleRouter';

export { Router };
