import {
  DATE_RANGE_OPERANDS,
  DROPDOWN_OPERANDS,
  INPUT_NUMBER_OPERANDS,
  INPUT_TEXT_OPERANDS,
} from '../../components/DataGrid/constants/filters';
import type {
  ColumnFilter,
  DropdownOption,
} from '../../components/DataGrid/types';
import { ColumnFilterType } from '../../components/DataGrid/types';
import { COUNTRIES } from '../../constants/countries';
import { columnDefs } from './columns';

const setInputTextFilter = (
  column: ColumnFilter,
  opts?: Partial<ColumnFilter>,
): void => {
  column.type = ColumnFilterType.inputText;
  if (opts?.arrayField) {
    column.arrayField = opts.arrayField;
  }
};

const setInputNumberFilter = (column: ColumnFilter): void => {
  column.type = ColumnFilterType.inputNumber;
};

const setDropdownFilter = (
  column: ColumnFilter,
  opts?: Partial<ColumnFilter>,
): void => {
  column.type = ColumnFilterType.dropdown;
  if (opts?.arrayField) {
    column.arrayField = opts.arrayField;
  }
  if (opts?.multiValue) {
    column.multiValue = opts.multiValue;
  }
  if (opts?.customOptions) {
    column.customOptions = opts.customOptions;
  }
};

const setDateFilter = (column: ColumnFilter): void => {
  column.type = ColumnFilterType.dateRange;
};

const countryFieldOptions: DropdownOption[] = COUNTRIES.map((c) => ({
  value: c.code,
  label: c.name,
}));

// ToDo: Remove switch, refactor to model approach
export const filterColumns: ColumnFilter[] = columnDefs
  .filter((c) => {
    if (c.field?.startsWith('_')) {
      return false; // Exclude ephemeral columns
    }

    // Add here non-filterable columns
    return true;
  })
  .map((c) => {
    const filterColumn: ColumnFilter = {
      ...c,
      operands: [],
      field: c.field as string,
      name: (c.filterName ?? c.headerName) as string,
      type: ColumnFilterType.inputText,
      arrayField: false,
      multiValue: false,
    };

    switch (c.field) {
      case 'artist':
      case 'cleanTitle':
      case 'trackTitleCleaned':
        setInputTextFilter(filterColumn);
        break;
      case 'isrcList':
      case 'gpidList':
      case 'rollUpTitleList':
        setInputTextFilter(filterColumn, { arrayField: true });
        break;
      case 'majorLabelList':
      case 'presentationLabelList':
      case 'repertoireLabelList':
      case 'shortTitleList':
      case 'majorGenreDescList':
        setDropdownFilter(filterColumn, { arrayField: true, multiValue: true });
        break;
      case 'deliveryTerritoryList':
      case 'trackRightsTerritoryList':
        setDropdownFilter(filterColumn, {
          arrayField: true,
          multiValue: true,
          customOptions: countryFieldOptions,
        });
        break;
      case 'mediaCode':
        setDropdownFilter(filterColumn, { multiValue: true });
        break;
      case 'wtdStreams':
      case 'lastCompleteWeekStreams':
      case 'mtdStreams':
      case 'ytdStreams':
      case 'immersiveCnt':
      case 'spedUpCnt':
      case 'slowedDownCnt':
      case 'acousticCnt':
      case 'instrumentalCnt':
      case 'remixCnt':
      case 'remasterCnt':
      case 'liveCnt':
      case 'exclusiveCnt':
      case 'amendedCnt':
      case 'pianoCnt':
      case 'stringsCnt':
      case 'demoCnt':
      case 'featuredCnt':
        setInputNumberFilter(filterColumn);
        break;
      case 'latestProductTs':
        setDateFilter(filterColumn);
        break;
    }

    switch (filterColumn.type) {
      case ColumnFilterType.inputText:
        filterColumn.operands = INPUT_TEXT_OPERANDS;
        break;
      case ColumnFilterType.inputNumber:
        filterColumn.operands = INPUT_NUMBER_OPERANDS;
        break;
      case ColumnFilterType.dropdown:
        filterColumn.operands = DROPDOWN_OPERANDS;
        break;
      case ColumnFilterType.dateRange:
        filterColumn.operands = DATE_RANGE_OPERANDS;
        break;
      default:
        filterColumn.operands = INPUT_TEXT_OPERANDS;
        break;
    }

    return filterColumn;
  });
