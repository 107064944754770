import type { DialogProps } from '@radix-ui/react-dialog';
import { Overlay, Portal, Root } from '@radix-ui/react-dialog';

// TODO: Enter/Leave animation (slide in from right); use Framer Motion?
// TODO: Overlay backdrop blur effect or darkened overlay?
export function DrawerRoot({ children, ...delegatedProps }: DialogProps) {
  return (
    <Root {...delegatedProps}>
      <Portal>
        <Overlay className="fixed inset-0 backdrop-blur-sm" />
        {children}
      </Portal>
    </Root>
  );
}
DrawerRoot.displayName = 'DrawerRoot';
