import { type FieldValues, useController } from 'react-hook-form';

import { Input, type InputProps } from '~/components/Input';

import { FormField, type FormFieldProps } from '../../FormField';

type FormFieldInputProps<TFormFields extends FieldValues> = Omit<
  FormFieldProps<TFormFields>,
  'children'
> &
  Omit<InputProps, 'name' | 'className'>;

export function FormFieldInput<TFormFields extends FieldValues>({
  name,
  label,
  className,
  ...inputProps
}: FormFieldInputProps<TFormFields>) {
  const {
    field,
    fieldState: { invalid },
  } = useController({ name, disabled: inputProps.disabled });

  return (
    <FormField name={name} label={label} className={className}>
      <Input {...field} state={invalid ? 'error' : undefined} {...inputProps} />
    </FormField>
  );
}

FormFieldInput.displayName = 'FormFieldInput';
