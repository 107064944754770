import { createContext } from 'react';

import type {
  GlobalSearchContextValue,
  SearchResultCategoryKeysWithAllResults,
} from '../lib/types';

export const CATEGORIES: SearchResultCategoryKeysWithAllResults[] = [
  'allResults',
  'artists',
  'tracks',
  'labels',
  'tools',
];

export const defaultGlobalSearchContextValue: GlobalSearchContextValue = {
  searchQuery: '',
  setSearchQuery: () => undefined,
  resetSearch: () => undefined,
  searchResults: {
    artists: [],
    tracks: [],
    labels: [],
    tools: [],
  },
  isLoading: true,
  selectedCategory: 'allResults',
  setSelectedCategory: () => undefined,
};

export const GlobalSearchContext =
  createContext<GlobalSearchContextValue | null>(null);

GlobalSearchContext.displayName = 'GlobalSearchContext';
