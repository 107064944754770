import { URL_PREFIX } from '../constants/api';
import { HIST_RANGE } from '../constants/label-perf';
import type {
  FixesHistResponseValue,
  FixesHistValue,
  HistRange,
  LabelPerfModel,
  LabelPerfResponse,
  LabelResponse,
  TopTenOrder,
  TopTenType,
  TrackItem,
  TrackResponse,
} from '../types/label-perf';
import { reduceHistRange } from '../utils/hist';
import { mapTrackResponse } from '../utils/track';
import { APIError } from './errors';

export const getLabelPerfReport = async (
  majorLabels: string[],
): Promise<LabelPerfModel> => {
  try {
    const url = `${URL_PREFIX}/api/v1/exec-view/kpi-report`;
    const body = JSON.stringify({
      major_labels: majorLabels,
    });

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch report: ${response.statusText}`);
    }

    const r = (await response.json()) as LabelPerfResponse;

    const labelPerf: LabelPerfModel = {
      totalAssets: r.tracks,
      totalGaps: r.tracks_need_review,
      overallMonthlyRevenue: r.monthly_revenue,
      overallMonthlyRevenueChange: r.monthly_revenue_trend,
      overallMonthlyStreams: r.monthly_streams,
      overallMonthlyStreamsChange: r.monthly_streams_trend,
      opportunityRevenueFromFixes: r.opportunity_revenue_from_fixes ?? 0,
      revenueFromFixes: r.monthly_revenue_from_fixes,
      revenueFromFixesChange: r.monthly_revenue_trend_from_fixes,
      streamsFromFixes: r.monthly_streams_from_fixes,
      streamsFromFixesChange: r.monthly_streams_trend_from_fixes,
    };

    return labelPerf;
  } catch (error) {
    console.warn('Failed to get report:', error);
    throw error;
  }
};

export const getFixesHistory = async (
  majorLabels: string[],
  range: HistRange,
): Promise<FixesHistValue[]> => {
  try {
    const url = `${URL_PREFIX}/api/v1/exec-view/fixes-history`;

    const body = JSON.stringify({
      major_labels: majorLabels,
      days: HIST_RANGE[range],
    });

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    });

    const r = (await response.json()) as FixesHistResponseValue[];

    const fixesHistValues: FixesHistValue[] = r.map((value) => ({
      name: value.date,
      inReview: value.active_track_locks,
      fixed: value.fixed_tracks,
      needFixes: value.tracks_to_investigate,
    }));

    return reduceHistRange(fixesHistValues, range);
  } catch (error) {
    console.warn('Failed to get fixes history:', error);
    throw error;
  }
};

export const getTopTenTracks = async (
  metric: TopTenType,
  order: TopTenOrder,
  majorLabels: string[],
): Promise<TrackItem[]> => {
  try {
    const url = `${URL_PREFIX}/api/v1/exec-view/top-ten`;

    const body = JSON.stringify({
      metric: metric.toUpperCase(),
      order: order.toUpperCase(),
      major_labels: majorLabels,
    });

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    });

    const r = (await response.json()) as TrackResponse[];

    return r.map(mapTrackResponse);
  } catch (error) {
    console.warn('Failed to get top 10 tracks:', error);
    throw error;
  }
};

export const getLables = async (
  column: string,
  refinementFilter?: string,
): Promise<string[]> => {
  const url = `${URL_PREFIX}/api/v1/dashboard/refiners`;

  const body = JSON.stringify({
    column,
    ...(refinementFilter && {
      refinement_filter: `ILIKE '%${refinementFilter}%'`,
    }),
  });

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  });

  if (!response.ok) {
    const errorBody = await response.json();
    throw new APIError(
      errorBody.detail || 'Failed to fetch labels',
      response.status,
    );
  }

  const r = (await response.json()) as LabelResponse;

  return r.data;
};
