import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '~/components/Icon';
import { IconButton } from '~/components/IconButton';
import { cn } from '~/lib/cn';

import { SelectedLabelsCounter } from './SelectedLabelsCounter';

export interface SearchBarProps {
  inputValue?: string;
  placeholder: string;
  data: string[];
  totalLabelsCount: number;
  selectedValues?: string[];
  isLoading: boolean;
  onSubmit: (value: string[]) => void;
  onChange: (value: string) => void;
  onClear: () => void;
  className?: string;
}

const SearchBar = ({
  inputValue = '',
  placeholder,
  data,
  totalLabelsCount,
  selectedValues,
  isLoading,
  onSubmit,
  onChange,
  className = '',
}: SearchBarProps) => {
  const { t } = useTranslation('label-perf');

  const [filteredData, setFilteredData] = useState<string[]>([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedItems, setSelectedItems] = useState<string[]>(
    selectedValues || [],
  );

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputValue) {
      const filtered = data.filter(
        (item) =>
          item.toLowerCase().includes(inputValue.toLowerCase()) &&
          !selectedItems.includes(item),
      );
      setFilteredData(filtered);
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
      setFilteredData(data.filter((item) => !selectedItems.includes(item)));
    }
  }, [inputValue, data, selectedItems]);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      onChange(value);
    },
    [onChange],
  );

  const handleSelect = (value: string) => {
    if (!selectedItems.includes(value)) {
      setSelectedItems((prev) => [...prev, value]);
    }
    onChange('');
    setShowDropdown(false);
  };

  const handleApply = useCallback(() => {
    if (onSubmit) {
      onSubmit(selectedItems);
    }
  }, [selectedItems, onSubmit]);

  const removeChip = (value: string) => {
    setSelectedItems((prev) => prev.filter((item) => item !== value));
  };

  const clearSelectedItems = () => {
    setSelectedItems([]);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setShowDropdown(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div ref={containerRef} className="relative mb-2xl flex w-full gap-sm">
      <form
        className={cn(
          'flex items-center',
          'bg-emphasis-default',
          'outline',
          'outline-1',
          'outline-emphasis-subtle',
          'w-full',
          'py-sm',
          'px-sm',
          'gap-sm',
          'rounded-sm',
          'focus-within:bg-emphasis-strong',
          'focus-within:outline-emphasis-stronger',
          'hover:bg-emphasis-strong',
          'hover:outline-emphasis-strong',
          'hover:focus-within:outline-emphasis-stronger',
          className,
        )}
        role="search"
        aria-label="Search input"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(selectedItems);
          setShowDropdown(false);
        }}
      >
        <Icon
          className={cn(
            inputValue ? 'text-default' : 'text-subtle',
            'py-md',
            'px-sm',
          )}
          type="search"
          variant="light"
          size="sm"
          aria-hidden
        />

        {/* Container for chips and input */}
        <div className="flex grow flex-wrap items-center gap-sm">
          {selectedItems.map((item) => (
            <div
              key={item}
              data-testid={`selected-item-${item}`}
              className="flex items-center rounded-xs px-sm py-px bg-emphasis-strong outline-emphasis-stronger"
            >
              <span className="ml-xs text-body-sm">{item}</span>
              <IconButton
                type="button"
                iconType="remove"
                size="small"
                onClick={() => removeChip(item)}
                aria-label={`Remove ${item}`}
              />
            </div>
          ))}

          <input
            ref={inputRef}
            className={cn(
              'bg-transparent',
              'focus:outline-none',
              'grow',
              'text-default',
              'text-body-sm',
            )}
            placeholder={placeholder}
            value={inputValue}
            onChange={handleInputChange}
            onFocus={() => setShowDropdown(true)}
            aria-label="Search input"
            data-testid="search-input"
          />
        </div>

        {/* Clear All Selected Items button */}
        {selectedItems.length > 0 && (
          <IconButton
            type="button"
            iconType="circle-x"
            size="small"
            onClick={clearSelectedItems}
            aria-label="Clear search input"
          />
        )}
      </form>

      <button
        onClick={handleApply}
        className={cn(
          'bg-emphasis-default',
          'outline',
          'outline-1',
          'outline-emphasis-subtle',
          'rounded-sm',
          'leading-none',
          'px-md',
          'hover:bg-emphasis-strong',
          'hover:outline-emphasis-stronger',
        )}
      >
        {t('actions.apply')}
      </button>

      <div className="absolute -top-xl right-0">
        <SelectedLabelsCounter
          selectedCount={selectedItems.length}
          totalCount={totalLabelsCount}
          label={t('body.selected')}
        />
      </div>

      {/* Autocomplete Dropdown */}
      {showDropdown ? (
        <div
          data-testid="search-dropdown"
          className="absolute left-0 top-full z-40 mt-sm max-h-[18em] min-h-[5em] w-1/2 overflow-y-auto rounded-sm p-sm shadow-lg shadow-black bg-level-05"
        >
          {isLoading ? (
            <div
              data-testid="loading"
              className="flex items-center px-md py-sm text-subtle"
            >
              <Icon type="loader" className="mr-sm animate-spin" size="sm" />
              {t('body.loading')}
            </div>
          ) : filteredData.length > 0 ? (
            filteredData.map((item) => (
              <button
                key={item}
                data-testid={`search-option-${item}`}
                type="button"
                className="w-full rounded-sm px-md py-sm text-left text-default hover:bg-emphasis-strong"
                onClick={() => handleSelect(item)}
              >
                {item}
              </button>
            ))
          ) : (
            <div data-testid="no-results" className="px-md py-sm text-subtle">
              {t('noData.empty')}
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

SearchBar.displayName = 'SearchBar';

export { SearchBar };
