import { Icon } from '~/components';

export interface LegendItem {
  name: string;
  color: string;
  value: number;
}

export interface LegendProps {
  className?: string;
  items: LegendItem[];
}

const toAutoFixed = (num: number): string => {
  if (num < 1) {
    return num.toFixed(2);
  }
  return num.toFixed(0);
};

const LegendList = ({ className, items }: LegendProps): JSX.Element => {
  const total = items.reduce((acc, item) => acc + item.value, 0);

  return (
    <div className={className} data-testid="legend-list">
      <ul className="my-xs text-body-xs">
        {items.map((item, index) => {
          const percentage = total
            ? `${toAutoFixed((item.value / total) * 100)}%`
            : 'N/A';
          return (
            <li key={index}>
              <Icon
                type="circle"
                variant="solid"
                size="sm"
                style={{ color: item.color }}
              />
              <span className="ml-sm text-body-sm font-normal">
                {percentage} {item.name}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

LegendList.displayName = 'LegendList';

export { LegendList };
