import { zodResolver } from '@hookform/resolvers/zod';
import type { HTMLProps } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from '~/components/Button';
import { FormFieldInput } from '~/components/FormField';
import { FormFieldToggle } from '~/components/FormField/components/FormFieldToggle';
import { getI18nErrorMap } from '~/lib/getI18nErrorMap';
import { AccordionContentBox } from '~/modules/settings/components/AccordionContentBox';
import { CTABlock } from '~/modules/settings/components/CTABlock';
import { SETTINGS_ROUTES } from '~/modules/settings/config/routes';

import type { LabelFormSchema } from './schema';
import { labelFormSchema } from './schema';

type LabelFormProps = {
  values?: LabelFormSchema;
  onSubmit: (data: LabelFormSchema) => void;
} & Omit<HTMLProps<HTMLFormElement>, 'method' | 'onSubmit'>;

export function LabelForm({
  values,
  onSubmit,
  className,
  ...formProps
}: LabelFormProps) {
  const { t } = useTranslation('settings', { keyPrefix: 'label' });
  const form = useForm<LabelFormSchema>({
    defaultValues: values,
    resolver: zodResolver(labelFormSchema, {
      errorMap: getI18nErrorMap((s) => t(`required.${s}`)),
    }),
  });

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        {...formProps}
        className={className}
        data-testid="label-form"
      >
        <div className="flex flex-col gap-md">
          <AccordionContentBox title={t('basicInformation')}>
            <div className="flex flex-wrap justify-between gap-md rounded-sm">
              <FormFieldInput
                name="labelName"
                className="min-w-xs flex-1"
                label={t('labelName')}
                placeholder={t('placeholder.labelName')}
              />
              <FormFieldInput
                name="parentLabel"
                className="min-w-xs flex-1"
                label={t('parentLabel')}
                placeholder={t('placeholder.parentLabel')}
              />
              <FormFieldInput
                name="labelGroups"
                className="min-w-xs flex-1"
                label={t('labelGroups')}
                placeholder={t('placeholder.labelGroups')}
              />
            </div>
          </AccordionContentBox>

          <AccordionContentBox title={t('coreLabelTitle')}>
            <div className="flex flex-col gap-md">
              <div className="flex flex-wrap justify-between gap-md rounded-sm">
                <FormFieldInput
                  className="min-w-xs flex-1"
                  name="weaLabelCode"
                  label={t('weaLabelCode')}
                  placeholder={t('placeholder.weaLabelCode')}
                />
                <FormFieldInput
                  className="min-w-xs flex-1"
                  name="usLabelCode"
                  label={t('usLabelCode')}
                  placeholder={t('placeholder.usLabelCode')}
                />
                <FormFieldInput
                  className="min-w-xs flex-1"
                  name="dealOwner"
                  label={t('dealOwner')}
                  placeholder={t('placeholder.dealOwner')}
                />
              </div>
              <div className="flex flex-wrap justify-between gap-md rounded-sm">
                <FormFieldInput
                  className="min-w-xs flex-1"
                  name="dealRegionScope"
                  label={t('dealRegionScope')}
                  placeholder={t('placeholder.dealRegionScope')}
                />
                <FormFieldInput
                  className="min-w-xs flex-1"
                  name="wmiLabelCode"
                  label={t('wmiLabelCode')}
                  placeholder={t('placeholder.wmiLabelCode')}
                />
                <FormFieldInput
                  className="min-w-xs flex-1"
                  name="marketingCompany"
                  label={t('marketingCompany')}
                  placeholder={t('placeholder.marketingCompany')}
                />
              </div>
              <div className="flex flex-wrap justify-between gap-md rounded-sm">
                <FormFieldInput
                  className="min-w-xs flex-1"
                  name="repertoireOwner"
                  label={t('repertoireOwner')}
                  placeholder={t('placeholder.repertoireOwner')}
                />
                <FormFieldInput
                  className="min-w-xs flex-1"
                  name="wbsElementNumber"
                  label={t('wbsElementNumber')}
                  placeholder={t('placeholder.wbsElementNumber')}
                />
                <FormFieldInput
                  className="min-w-xs flex-1"
                  name="dealID"
                  label={t('dealID')}
                  placeholder={t('placeholder.dealID')}
                />
              </div>
              <div className="flex flex-wrap justify-between gap-md rounded-sm">
                <FormFieldInput
                  className="min-w-xs flex-1"
                  name="scopeID"
                  label={t('scopeID')}
                  placeholder={t('placeholder.scopeID')}
                />
                <FormFieldInput
                  className="min-w-xs flex-1"
                  name="dealScope"
                  label={t('dealScope')}
                  placeholder={t('placeholder.dealScope')}
                />
                <FormFieldInput
                  className="min-w-xs flex-1"
                  name="gpiNumber"
                  label={t('gpiNumber')}
                  placeholder={t('placeholder.gpiNumber')}
                />
              </div>
              <div className="flex flex-wrap justify-between gap-md rounded-sm">
                <FormFieldInput
                  className="min-w-xs flex-1"
                  name="profitCenter"
                  label={t('profitCenter')}
                  placeholder={t('placeholder.profitCenter')}
                />
                <FormFieldInput
                  className="min-w-xs flex-1"
                  name="pmo"
                  label={t('pmo')}
                  placeholder={t('placeholder.pmo')}
                />
                <FormFieldInput
                  className="min-w-xs flex-1"
                  name="gvlCode"
                  label={t('gvlCode')}
                  placeholder={t('placeholder.gvlCode')}
                />
              </div>
              <div className="flex flex-wrap justify-between gap-md rounded-sm">
                <FormFieldInput
                  className="min-w-xs flex-1"
                  name="isrcPrefix"
                  label={t('isrcPrefix')}
                  placeholder={t('placeholder.isrcPrefix')}
                />
                <FormFieldInput
                  className="min-w-xs flex-1"
                  name="presentationLabel"
                  label={t('presentationLabel')}
                  placeholder={t('placeholder.presentationLabel')}
                />
                <FormFieldInput
                  className="min-w-xs flex-1"
                  name="distributors"
                  label={t('distributors')}
                  placeholder={t('placeholder.distributors')}
                />
              </div>
            </div>
          </AccordionContentBox>

          <AccordionContentBox title={t('settings')}>
            <div className="grid grid-cols-3 gap-md rounded-sm">
              <div className="flex flex-col gap-md">
                <div className="flex gap-md">
                  <FormFieldToggle
                    label={t('directPass')}
                    name="directPass"
                    aria-label={t('directPass')}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-md">
                <div className="flex gap-md">
                  <FormFieldToggle
                    label={t('allowTimedReleases')}
                    name="allowTimedReleases"
                    aria-label={t('allowTimedReleases')}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-md">
                <div className="flex gap-md">
                  <FormFieldToggle
                    label={t('asylum')}
                    name="asylum"
                    aria-label={t('asylum')}
                  />
                </div>
              </div>
            </div>
          </AccordionContentBox>

          <AccordionContentBox title={t('distributionGroups')}>
            <div className="flex w-full flex-col items-center gap-lg rounded-sm px-md py-2xl bg-level-03">
              <span className="text-body-md font-semibold leading-tight">
                {t('addDistributionGroup')}
              </span>
              <Button
                variant="secondary"
                size="small"
                className="font-semibold"
              >
                {t('createGroup')}
              </Button>
            </div>
          </AccordionContentBox>

          <AccordionContentBox title={t('social')} className="mb-xl">
            <div className="flex flex-col gap-md">
              <div className="flex flex-wrap justify-between gap-md rounded-sm">
                <FormFieldInput
                  className="min-w-xs flex-1"
                  name="facebook"
                  label={t('facebook')}
                  placeholder={t('placeholder.facebook')}
                />
                <FormFieldInput
                  className="min-w-xs flex-1"
                  name="instagram"
                  label={t('instagram')}
                  placeholder={t('placeholder.instagram')}
                />
                <FormFieldInput
                  className="min-w-xs flex-1"
                  name="youtube"
                  label={t('youtube')}
                  placeholder={t('placeholder.youtube')}
                />
              </div>
              <div className="flex flex-wrap gap-md rounded-sm">
                <FormFieldInput
                  className="min-w-xs flex-1"
                  name="x"
                  label={t('x')}
                  placeholder={t('placeholder.x')}
                />
                <FormFieldInput
                  className="min-w-xs flex-1"
                  name="websiteURL"
                  label={t('websiteURL')}
                  placeholder={t('placeholder.websiteURL')}
                />
              </div>
            </div>
          </AccordionContentBox>
        </div>
        <CTABlock>
          <Button asChild variant="secondary" size="large">
            <Link to={SETTINGS_ROUTES.LABEL_LIST}>{t('cancelButton')}</Link>
          </Button>
          <Button variant="primary" type="submit" size="large">
            {t('addLink')}
          </Button>
        </CTABlock>
      </form>
    </FormProvider>
  );
}

LabelForm.displayName = 'LabelForm';
