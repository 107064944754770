import type { PropsWithChildren } from 'react';
import { useRef } from 'react';

import { SearchWrapper } from '~/modules/global-search/components/SearchWrapper';
import { GlobalSearchProvider } from '~/modules/global-search/contexts/GlobalSearchProvider';
import { KeyboardNavigationProvider } from '~/modules/global-search/contexts/KeyboardNavigationProvider';

export function GlobalSearch({ children }: PropsWithChildren): React.ReactNode {
  const searchWrapperRef = useRef(null);

  return (
    <GlobalSearchProvider>
      <KeyboardNavigationProvider searchWrapperRef={searchWrapperRef}>
        <SearchWrapper ref={searchWrapperRef}>{children}</SearchWrapper>
      </KeyboardNavigationProvider>
    </GlobalSearchProvider>
  );
}
GlobalSearch.displayName = 'GlobalSearch';
