import type { ReactNode } from 'react';

import { Separator } from '~/components';
import { cn } from '~/lib/cn';

import { PerformanceDeltaLabel } from '../TrackList/PerformanceDeltaLabel';

interface PerformanceSelectorCardProps {
  title: string;
  value: ReactNode;
  delta?: number;
  className?: string;
  isActive: boolean;
  onClick: () => void;
}

export function PerformanceSelectorCard({
  title,
  value,
  delta,
  isActive,
  onClick,
  className = '',
}: PerformanceSelectorCardProps) {
  return (
    <button
      onClick={onClick}
      className={cn(
        'flex flex-col items-start gap-sm rounded-md border p-md transition-all bg-level-01 border-emphasis-subtle text-subtle',
        'hover:drop-shadow-xl hover:bg-level-03 hover:border-emphasis-strong hover:text-white',
        isActive &&
          'drop-shadow-xl bg-level-03 border-emphasis-strong text-white',
        className,
      )}
    >
      <Separator
        className={cn(
          'mb-sm h-[0.2rem] w-[10rem] rounded-full border-none duration-150 bg-emphasis-subtle',
          isActive && 'bg-default-inverse',
        )}
      />
      <h3 className="text-body-sm font-semibold leading-none">{title}</h3>
      <div className="text-display-md font-bold leading-none">{value}</div>
      {delta ? <PerformanceDeltaLabel delta={delta} dateRange="WoW" /> : null}
    </button>
  );
}

PerformanceSelectorCard.displayName = 'TrackPerformanceCard';
