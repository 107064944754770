import type { DialogProps } from '@radix-ui/react-dialog';

import { Drawer } from '../Drawer';

interface FilterDrawerProps extends DialogProps {}

export const FilterDrawer = ({
  open,
  onOpenChange,
  children,
}: FilterDrawerProps) => {
  return (
    <Drawer.Root open={open} onOpenChange={onOpenChange}>
      <Drawer.Content>{children}</Drawer.Content>
    </Drawer.Root>
  );
};

FilterDrawer.displayName = 'FilterDrawer';
