import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Tag } from '~/components';
import type { UserListItem } from '~settings/api/lib/schemas';
import { HeaderSmall } from '~settings/components/HeaderSmall';
import { SETTINGS_ROUTES } from '~settings/config/routes';

interface LabelAdminsProps {
  adminUsers: UserListItem[];
}

export const LabelAdmins = ({ adminUsers }: LabelAdminsProps) => {
  const { t } = useTranslation('settings');

  if (!adminUsers?.length) {
    return null;
  }

  return (
    <div>
      <HeaderSmall>{t('labelAdmins')}</HeaderSmall>
      <ul className="flex flex-wrap gap-x-xs gap-y-sm">
        {adminUsers.map((user) => (
          <Link
            key={user.id}
            to={SETTINGS_ROUTES.USER_DETAIL(user.id)}
            className="group"
          >
            <Tag variant="secondary" size="lg" asChild className="gap-xs">
              <li>
                <span className="size-md rounded-full text-center text-caption-sm leading-4 bg-emphasis-strong group-hover:bg-emphasis-default">{`${user.firstName[0]}${user.lastName[0]}`}</span>
                <span>{user.fullName}</span>
              </li>
            </Tag>
          </Link>
        ))}
      </ul>
    </div>
  );
};

LabelAdmins.displayName = 'LabelAdmins';
