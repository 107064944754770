import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { cn } from '~/lib/cn';
import globalProtectIcon from '~/modules/link-farm/components/LinkList/LinkItemIcons/globalProtectIcon.png?url';

type RequiredVpnProps = {
  visible: boolean;
};

export const RequiredVpn: FC<RequiredVpnProps> = ({ visible }) => {
  const { t } = useTranslation('wmgone');

  return (
    <div
      className={cn(
        'flex flex-row items-center gap-xs opacity-none transition-opacity group-hover:opacity-full group-focus:opacity-full',
        !visible && 'hidden',
      )}
    >
      <img
        src={globalProtectIcon}
        className="size-lg rounded-full"
        alt="global-protect-icon"
      />
      <p className="text-body-xs">{t('links.item.vpnRequired')}</p>
    </div>
  );
};

RequiredVpn.displayName = 'RequiredVpn';
