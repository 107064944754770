import {
  CONTAINS,
  EQUALS,
  EQUALS_ALL,
  GREATER_OR_EQUAL_TO,
  GREATER_OR_EQUAL_TO_RELATIVE,
  GREATER_THAN,
  GREATER_THAN_RELATIVE,
  LESS_OR_EQUAL_TO,
  LESS_OR_EQUAL_TO_RELATIVE,
  LESS_THAN,
  LESS_THAN_RELATIVE,
  NOT_EQUALS,
  STARTS_WITH,
} from '../constants/filters';
import type { FilterModel } from '../types/filters';
import { ColumnFilterType } from '../types/filters';
import { camelToSnake } from './case';
import { filterIsValid } from './checks';

const basicOperands = {
  [EQUALS.id]: '=',
  [NOT_EQUALS.id]: '<>',
  [GREATER_THAN.id]: '>',
  [GREATER_THAN_RELATIVE.id]: '>',
  [GREATER_OR_EQUAL_TO.id]: '>=',
  [GREATER_OR_EQUAL_TO_RELATIVE.id]: '>=',
  [LESS_THAN.id]: '<',
  [LESS_THAN_RELATIVE.id]: '<',
  [LESS_OR_EQUAL_TO.id]: '<=',
  [LESS_OR_EQUAL_TO_RELATIVE.id]: '<=',
};

export const serialize = (data: FilterModel[]): string => {
  return data
    .filter(filterIsValid)
    .map((d) => {
      const fieldName = camelToSnake(d.field);
      const operand = d.operand!.value;
      const value = d.value as string | number | string[];

      if (d.type === ColumnFilterType.dropdown && d.arrayField) {
        if (operand === EQUALS.id || operand === EQUALS_ALL.id) {
          const logicalOperand = operand === EQUALS.id ? 'or' : 'and';

          if (d.multiValue) {
            const val = value as string[] | number[];
            const conds = val
              .filter(Boolean)
              .map((v) => `array_contains('${v}'::variant, ${fieldName})`);
            const joinedCond = conds.join(` ${logicalOperand} `);
            return conds.length > 1 ? `(${joinedCond})` : joinedCond; // Multiple ORs must be wrapped in parentheses
          }

          const val = value as string | number;
          return `array_contains('${val}'::variant, ${fieldName})`;
        }

        if (operand === NOT_EQUALS.id) {
          if (d.multiValue) {
            const val = value as string[] | number[];
            const conds = val
              .filter(Boolean)
              .map((v) => `not array_contains('${v}'::variant, ${fieldName})`);
            const joinedCond = conds.join(' and ');
            return conds.length > 1 ? `(${joinedCond})` : joinedCond; // For consistency, ANDs are in parentheses
          }

          const val = value as string | number;
          return `not array_contains('${val}'::variant, ${fieldName})`;
        }
      }

      if (d.type === ColumnFilterType.dropdown) {
        if (operand === EQUALS.id) {
          if (d.multiValue) {
            const val = value as string[] | number[];
            return `${fieldName} in (${val.map((v) => `'${v}'`).join(', ')})`;
          }

          const val = value as string | number;
          return `${fieldName} = '${val}'`;
        }

        if (operand === NOT_EQUALS.id) {
          if (d.multiValue) {
            const val = value as string[] | number[];
            return `${fieldName} not in (${val.map((v) => `'${v}'`).join(', ')})`;
          }

          const val = value as string | number;
          return `${fieldName} <> '${val}'`;
        }
      }

      if (d.type === ColumnFilterType.inputText && d.arrayField) {
        const val = (value as string).trim();
        return {
          [EQUALS.id]: `array_contains('${val}'::variant, ${fieldName})`,
          [NOT_EQUALS.id]: `not array_contains('${val}'::variant, ${fieldName})`,
          [CONTAINS.id]: `array_to_string(${fieldName}, '|') ilike '%${val}%'`,
          [STARTS_WITH.id]: `concat('|', array_to_string(${fieldName}, '|')) ilike '%|${val}%'`,
        }[operand];
      }

      if (d.type === ColumnFilterType.inputText) {
        const val = (value as string).trim();
        return {
          [EQUALS.id]: `${fieldName} = '${val}'`,
          [NOT_EQUALS.id]: `${fieldName} <> '${val}'`,
          [CONTAINS.id]: `${fieldName} ilike '%${val}%'`,
          [STARTS_WITH.id]: `${fieldName} ilike '${val}%'`,
        }[operand];
      }

      if (d.type === ColumnFilterType.inputNumber) {
        const val = value as number;
        return `${fieldName} ${basicOperands[operand]} ${val}`;
      }

      if (d.type === ColumnFilterType.dateRange) {
        const val = value as string;
        return d.operand?.value.includes('Relative')
          ? `${fieldName} ${basicOperands[operand]} dateadd('day', -${val}, current_date)`
          : `${fieldName} ${basicOperands[operand]} to_date('${val}')`;
      }

      return null;
    })
    .filter(Boolean)
    .join(' and ');
};
