import type { FC } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Skeleton } from '~/components/Skeleton';
import { cn } from '~/lib/cn';
import type {
  TopTenVariant,
  TrackItem,
} from '~/modules/label-perf/types/label-perf';
import { formatNumberSuffix } from '~/modules/label-perf/utils/format';
import { getTrendDirection } from '~/modules/label-perf/utils/trend';

import { ErrorDisplay } from './ErrorDisplay';
import { Trend } from './Trend';

export interface TableProps {
  className?: string;
  type: TopTenVariant;
  data: TrackItem[] | undefined;
  isLoading: boolean;
  isError: boolean;
}

interface SkeletonTableProps {
  size: number;
  headers: { id: string }[];
}

const TableTh: FC<{ className?: string; children?: React.ReactNode }> = ({
  className,
  children,
}) => (
  <th
    className={cn(
      'whitespace-nowrap px-xl py-lg text-left text-body-xs font-normal bg-level-02',
      className,
    )}
  >
    {children}
  </th>
);

TableTh.displayName = 'TableTh';

const TableTd: FC<{ className?: string; children?: React.ReactNode }> = ({
  className,
  children,
}) => <td className={cn('px-xl py-lg text-body-sm', className)}>{children}</td>;

TableTd.displayName = 'TableTd';

const SkeletonTable: FC<SkeletonTableProps> = ({ size, headers }) => {
  const skeletonRows = useMemo(() => Array.from({ length: size }), [size]);

  return (
    <>
      {skeletonRows.map((_, i) => (
        <tr key={i}>
          {headers.map(({ id }) => (
            <TableTd
              key={id}
              className={cn(
                'py-md first:rounded-l-xs first:pl-lg last:rounded-r-xs',
                i % 2 === 0 && 'bg-level-05',
              )}
            >
              <div className="space-y-sm">
                <Skeleton className="h-md w-[12em] rounded-sm bg-level-02" />
                <Skeleton className="h-md w-[10em] rounded-sm bg-level-04" />
              </div>
            </TableTd>
          ))}
        </tr>
      ))}
    </>
  );
};

SkeletonTable.displayName = 'SkeletonTable';

const TopTen = ({
  className,
  type,
  data,
  isLoading,
  isError,
}: TableProps): JSX.Element => {
  const { t } = useTranslation('label-perf');

  const headers = useMemo(() => {
    const baseHeaders = [
      { id: 'track-name', label: t('table.trackName'), className: 'w-[30%]' },
      { id: 'artist-name', label: t('table.artistName'), className: 'w-[25%]' },
      { id: 'isrc', label: t('table.ISRC'), className: 'w-[15%]' },
    ];

    if (type.includes('revenue')) {
      baseHeaders.push(
        { id: 'revenue', label: t('table.revenue'), className: 'w-[15%]' },
        {
          id: 'revenue-change',
          label: t('table.revenueChange'),
          className: 'w-[15%]',
        },
      );
    }

    if (type.includes('streams')) {
      baseHeaders.push(
        { id: 'streams', label: t('table.streams'), className: 'w-[15%]' },
        {
          id: 'streams-change',
          label: t('table.streamsChange'),
          className: 'w-[15%]',
        },
      );
    }

    return baseHeaders;
  }, [type, t]);

  if (isError) {
    return (
      <ErrorDisplay
        className="min-h-[30em]"
        title={t('table.failedToFetch', {
          type: t(`table.${type}`),
        })}
        description={t('error.refresh')}
        iconType="info-circle"
      />
    );
  }

  if (data?.length === 0) {
    return (
      <div className="flex min-h-4xl items-center justify-center border px-md pt-sm bg-level-02 border-emphasis-subtle">
        <p>{t('table.noData')}</p>
      </div>
    );
  }

  return (
    <div className="overflow-hidden border px-md bg-level-02 border-emphasis-subtle">
      <table
        data-testid="top-ten-table"
        className={cn('relative z-20 w-full table-fixed', className)}
      >
        <thead>
          <tr>
            {headers.map((header) => (
              <TableTh key={header.id} className={header.className}>
                {header.label}
              </TableTh>
            ))}
          </tr>
        </thead>
        <tbody>
          {data
            ? data.map((track, i) => {
                return (
                  <tr
                    className={cn(
                      '',
                      i % 2 === 0 && 'bg-level-04',
                      i % 2 === 1 && 'bg-level-02',
                    )}
                    key={track.isrc}
                  >
                    <TableTd className="rounded-l-xs font-bold">
                      {track.trackName}
                    </TableTd>
                    <TableTd>{track.artistName}</TableTd>
                    <TableTd>{track.isrc}</TableTd>
                    {type.includes('revenue') && (
                      <>
                        <TableTd>${formatNumberSuffix(track.revenue)}</TableTd>
                        <TableTd className="rounded-r-xs">
                          <Trend
                            variant="table"
                            direction={getTrendDirection(track.revenueChange)}
                          >
                            {'$' +
                              formatNumberSuffix(track.revenueChange, 1, false)}
                          </Trend>
                        </TableTd>
                      </>
                    )}
                    {type.includes('streams') && (
                      <>
                        <TableTd>{formatNumberSuffix(track.streams)}</TableTd>
                        <TableTd className="rounded-r-xs">
                          <Trend
                            variant="table"
                            direction={getTrendDirection(track.streamsChange)}
                          >
                            {formatNumberSuffix(track.streamsChange, 1, false)}
                          </Trend>
                        </TableTd>
                      </>
                    )}
                  </tr>
                );
              })
            : null}
          {isLoading ? <SkeletonTable size={10} headers={headers} /> : null}
        </tbody>
      </table>
    </div>
  );
};

TopTen.displayName = 'TopTen';

export { TopTen };
