import type { RefObject } from 'react';
import { useCallback, useRef } from 'react';
import { useKeyPressEvent } from 'react-use';

import { useGlobalSearchContext } from '~/modules/global-search/hooks/useGlobalSearchContext';

import { SHOW_ALL_CATEGORY_RESULTS_BUTTON } from '../components/ShowAllCategoryResultsButton/ShowAllCategoryResultsButton';
import { MAX_ITEMS_UNDER_ALL_RESULTS } from '../lib/constants';
import type {
  ElementRefs,
  KeyboardNavigationContextValue,
  RegisterElementsProps,
  SearchResultCategoryKeys,
  SearchResultCategoryKeysWithAllResults,
} from '../lib/types';
import { hasFocusDeep } from '../lib/utils';

/**
 * Hook to handle keyboard navigation within the Global Search component.
 */
export const useKeyboardNavigation = ({
  searchWrapperRef,
}: {
  searchWrapperRef: RefObject<HTMLDivElement>;
}): KeyboardNavigationContextValue => {
  const { resetSearch, selectedCategory, setSelectedCategory } =
    useGlobalSearchContext();

  const categoryOrderRef = useRef<SearchResultCategoryKeys[]>([]);
  const elementRefs = useRef<ElementRefs>({
    input: null,
    categories: [],
    results: [],
  });

  const registerElements = useCallback(
    ({ type, payload }: RegisterElementsProps) => {
      switch (type) {
        // Reference to the Search Input element.
        case 'input':
          elementRefs.current.input = payload;
          break;
        // Reference to the Category button elements as an array.
        case 'categories':
          elementRefs.current.categories = payload;
          break;
        // Store the order of the categories to match the order in which they're displayed in the UI.
        // If we ever change the order of the components in the JSX, this should automatically pick that up.
        case 'categoryIndex':
          if (categoryOrderRef.current.indexOf(payload) === -1) {
            categoryOrderRef.current.push(payload);
            const categoryIndex = categoryOrderRef.current.indexOf(payload);
            elementRefs.current.results[categoryIndex] = [];
          }
          break;
        // Reference to the Search Result elements as a subarray under each categoryIndex.
        case 'categoryResults':
          {
            const categoryIndex = categoryOrderRef.current.indexOf(
              payload.category,
            );

            elementRefs.current.results[categoryIndex] = payload.results;
          }
          break;
      }
    },
    [],
  );

  const resetRefs = useCallback(() => {
    elementRefs.current.categories = [];
    elementRefs.current.results = [];
    categoryOrderRef.current = [];
  }, []);

  const handleEscapePress = useCallback(() => {
    /**
     * Current Focus: Anywhere inside of Global Search.
     * Action: Reset and close Global Search.
     */
    if (hasFocusDeep(searchWrapperRef.current)) {
      elementRefs.current.input?.focus();
      resetRefs();
      resetSearch();
    }
  }, [resetRefs, resetSearch, searchWrapperRef]);

  const handleArrowDownPress = useCallback(
    (event: KeyboardEvent) => {
      if (!hasFocusDeep(searchWrapperRef.current)) {
        return;
      }

      const target = event.target as HTMLButtonElement;

      /**
       * Current Focus: Search Input
       */
      if (event.target === elementRefs.current.input) {
        /**
         * Current Focus: Search Input
         * Action: Focus on the active Category button.
         */
        if (elementRefs.current.categories.length) {
          elementRefs.current.categories.some((categoryButton) => {
            if (categoryButton.dataset.category === selectedCategory) {
              categoryButton.focus();

              return true;
            }

            return false;
          });

          event.preventDefault();
        }

        return;
      }

      /**
       * Current Focus: Category button
       */
      if (elementRefs.current.categories.includes(target)) {
        // Find the first category with results.
        const categoryIndex = categoryOrderRef.current.findIndex(
          (_category, index) => !!elementRefs.current.results[index]?.length,
        );

        /**
         * Current Focus: Category button
         * Action: Focus on the first Search Result.
         */
        if (categoryIndex !== -1) {
          elementRefs.current.results[categoryIndex][0]?.focus();
          event.preventDefault();
        }

        return;
      }

      // Find the category index of the current target.
      const categoryIndex = elementRefs.current.results.findIndex(
        (_category, index) =>
          elementRefs.current.results[index]?.includes(target),
      );

      if (categoryIndex === -1) {
        return;
      }

      // Find the current index of the target within its category.
      const currentIndex =
        elementRefs.current.results[categoryIndex].indexOf(target);
      const nextIndex = currentIndex + 1;

      /**
       * Current Focus: Search Result
       * Action: Focus on the next Search Result.
       */
      if (nextIndex < elementRefs.current.results[categoryIndex].length) {
        elementRefs.current.results[categoryIndex][nextIndex]?.focus();
        event.preventDefault();

        return;
      }

      const nextCategoryIndex = categoryOrderRef.current
        .slice(categoryIndex + 1)
        .findIndex(
          (_category, index) =>
            !!elementRefs.current.results[categoryIndex + 1 + index].length,
        );

      /**
       * Current Focus: Last Search Result of a category
       * Action: Focus on the first Search Result of the next category.
       */
      if (nextCategoryIndex !== -1) {
        elementRefs.current.results[
          categoryIndex + 1 + nextCategoryIndex
        ][0]?.focus();
        event.preventDefault();
      }
    },
    [searchWrapperRef, selectedCategory],
  );

  const handleArrowUpPress = useCallback(
    (event: KeyboardEvent) => {
      if (!hasFocusDeep(searchWrapperRef.current)) {
        return;
      }

      const target = event.target as HTMLButtonElement;

      /**
       * Current Focus: Category button
       * Action: Focus on the Search Input.
       */
      if (elementRefs.current.categories.includes(target)) {
        elementRefs.current.input?.focus();
        event.preventDefault();

        return;
      }

      const categoryIndex = elementRefs.current.results.findIndex(
        (_category, index) =>
          elementRefs.current.results[index]?.includes(target),
      );

      if (categoryIndex === -1) {
        return;
      }

      const currentIndex =
        elementRefs.current.results[categoryIndex].indexOf(target);

      /**
       * Current Focus: Search Result
       * Action: Focus on the previous Search Result.
       */
      if (currentIndex !== 0) {
        elementRefs.current.results[categoryIndex][currentIndex - 1]?.focus();
        event.preventDefault();

        return;
      }

      /**
       * Current Focus: First Search Result of the first category.
       * Action: Focus on the active Category button.
       */
      if (categoryIndex === 0) {
        elementRefs.current.categories.some((categoryButton) => {
          if (categoryButton.dataset.category === selectedCategory) {
            categoryButton.focus();

            return true;
          }

          return false;
        });

        event.preventDefault();

        return;
      }

      const previousCategoryIndex = categoryOrderRef.current
        .slice(0, categoryIndex)
        .reverse()
        .findIndex(
          (_category, index) =>
            !!elementRefs.current.results[categoryIndex - 1 - index].length,
        );

      /**
       * Current Focus: First Search Result of a category that is not the first category.
       * AND previous category contains search results.
       * Action: Focus on the last Search Result of the previous category.
       */
      if (previousCategoryIndex !== -1) {
        const actualIndex = categoryIndex - 1 - previousCategoryIndex;
        const lastResultIndex =
          elementRefs.current.results[actualIndex].length - 1;
        elementRefs.current.results[actualIndex][lastResultIndex]?.focus();
      }

      /**
       * Current Focus: First Search Result of a category that is not the first category.
       * AND previous category does not contain search results.
       * Action: Focus on the active Category button.
       */
      if (previousCategoryIndex === -1) {
        elementRefs.current.categories.some((categoryButton) => {
          if (categoryButton.dataset.category === selectedCategory) {
            categoryButton.focus();

            return true;
          }

          return false;
        });
      }

      event.preventDefault();
    },
    [searchWrapperRef, selectedCategory],
  );

  const handleSlashPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.target === elementRefs.current.input) {
        return;
      }

      /**
       * Current Focus: Anywhere inside of Global Search.
       * Action: Focus on the Search Input and select all text.
       */
      if (hasFocusDeep(searchWrapperRef.current)) {
        elementRefs.current.input?.focus();
        elementRefs.current.input?.select();
        event.preventDefault();
      }
    },
    [searchWrapperRef],
  );

  const handleArrowRightPress = useCallback(
    (event: KeyboardEvent) => {
      if (!hasFocusDeep(searchWrapperRef.current)) {
        return;
      }

      const target = event.target as HTMLButtonElement;

      /**
       * Current Focus: Not one of the category buttons.
       * Action: Do nothing.
       */
      if (!elementRefs.current.categories.includes(target)) {
        return;
      }

      const currentIndex = elementRefs.current.categories.indexOf(target);
      const nextIndex =
        elementRefs.current.categories
          .slice(currentIndex + 1)
          .findIndex((category) => !category?.disabled) +
        currentIndex +
        1;

      /**
       * Current Focus: Category button
       * Action: Focus on the next non-disabled Category button
       * AND set it as the active Category.
       */
      if (nextIndex <= elementRefs.current.categories.length) {
        event.preventDefault();

        const newCategoryButton = elementRefs.current.categories[nextIndex];
        newCategoryButton.focus();
        const { category } = newCategoryButton.dataset;
        setSelectedCategory(category as SearchResultCategoryKeysWithAllResults);
      }
    },
    [searchWrapperRef, setSelectedCategory],
  );

  const handleArrowLeftPress = useCallback(
    (event: KeyboardEvent) => {
      if (!hasFocusDeep(searchWrapperRef.current)) {
        return;
      }

      const target = event.target as HTMLButtonElement;

      /**
       * Current Focus: Not one of the category buttons.
       * Action: Do nothing.
       */
      if (!elementRefs.current.categories.includes(target)) {
        return;
      }

      const currentIndex = elementRefs.current.categories.indexOf(target);
      let previousIndex = elementRefs.current.categories
        .slice(0, currentIndex)
        .reverse()
        .findIndex((category) => !category?.disabled);

      if (previousIndex !== -1) {
        previousIndex = currentIndex - 1 - previousIndex;
      }

      /**
       * Current Focus: Category button
       * Action: Focus on the previous non-disabled Category button
       * AND set it as the active Category.
       */
      if (previousIndex >= 0) {
        event.preventDefault();

        const newCategoryButton = elementRefs.current.categories[previousIndex];
        newCategoryButton.focus();
        const { category } = newCategoryButton.dataset;
        setSelectedCategory(category as SearchResultCategoryKeysWithAllResults);
      }
    },
    [searchWrapperRef, setSelectedCategory],
  );

  const handleEnterPress = (event: KeyboardEvent) => {
    if (!hasFocusDeep(searchWrapperRef.current)) {
      return;
    }

    const target = event.target as HTMLButtonElement;

    /**
     * Current Focus: "Show all <category> results" button.
     * Action: Focus on the 5th search result in this category.
     */
    if (target.dataset.type === SHOW_ALL_CATEGORY_RESULTS_BUTTON) {
      elementRefs.current.results.some((categoryResults, index) => {
        if (categoryResults.includes(target)) {
          /**
           * Without this setTimeout wrapper, Jest will press the "Enter" key on this Search Result,
           * which will in turn invoke the Search Result's onClick handler, which we don't want.
           */
          setTimeout(() => {
            categoryResults[MAX_ITEMS_UNDER_ALL_RESULTS - 1]?.focus();
          });

          setSelectedCategory(
            categoryOrderRef.current[index] as SearchResultCategoryKeys,
          );

          event.preventDefault();

          return true;
        }

        return false;
      });
    }
  };

  useKeyPressEvent('ArrowUp', handleArrowUpPress);
  useKeyPressEvent('ArrowRight', handleArrowRightPress);
  useKeyPressEvent('ArrowDown', handleArrowDownPress);
  useKeyPressEvent('ArrowLeft', handleArrowLeftPress);
  useKeyPressEvent('Escape', handleEscapePress);
  useKeyPressEvent('Enter', handleEnterPress);
  useKeyPressEvent('/', handleSlashPress);

  return {
    elementRefs,
    registerElements,
  };
};

useKeyboardNavigation.displayName = 'useKeyboardNavigation';
