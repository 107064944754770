import { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { Separator } from '~/components';
import { Button } from '~/components/Button';
import { FormFieldCombobox } from '~/components/FormField';
import { FormFieldRadioGroup } from '~/components/FormField/components/FormFieldRadioGroup';
import { useIsExternalUser } from '~/hooks/useIsExternalUser';
import { useLabelGroupList } from '~/modules/settings/api/queries/labelGroupList';
import type { LabelListQueryVariables } from '~/modules/settings/api/queries/labelList';
import { useLabelList } from '~/modules/settings/api/queries/labelList';
import { Drawer } from '~/modules/settings/components/Drawer';
import {
  EXTERNAL_ROLES,
  INTERNAL_ROLES,
  USER_TYPES,
} from '~/modules/settings/constants/Filter';
import type { UsersFilter } from '~/modules/settings/types/filter';

const TEMP_HARDCODED_VARIABLES: LabelListQueryVariables = {
  skip: 0,
  take: 50,
};

interface UsersFilterFormProps {
  open: boolean;
  onApply: () => void;
}

export const UsersFilterForm = ({ open, onApply }: UsersFilterFormProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('settings');
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const { labelList } = useLabelList(TEMP_HARDCODED_VARIABLES);
  const { labelGroupList } = useLabelGroupList(TEMP_HARDCODED_VARIABLES);

  const isCurrentUserInternal = !useIsExternalUser();

  const LABEL_GROUP_OPTIONS = useMemo(
    () =>
      labelGroupList
        ? labelGroupList.data.map((labelGroup) => ({
            value: labelGroup.id,
            label: labelGroup.name,
          }))
        : [],
    [labelGroupList],
  );
  const LABEL_OPTIONS = useMemo(
    () =>
      labelList
        ? labelList.data.map((label) => ({
            value: label.id,
            label: label.name,
          }))
        : [],
    [labelList],
  );

  const defaultValues = useMemo<UsersFilter>(
    () => ({
      roles: [],
      labelIds: [],
      isInternal: 'all',
      labelGroupIds: [],
    }),
    [],
  );

  const filterForm = useForm<UsersFilter>({
    defaultValues,
  });

  const isFilterKey = useCallback(
    (key: string): key is keyof UsersFilter => key in defaultValues,
    [defaultValues],
  );

  useEffect(() => {
    if (open) {
      for (const [key, value] of searchParams) {
        if (isFilterKey(key)) {
          if (Array.isArray(filterForm.getValues(key))) {
            filterForm.setValue(
              key,
              value.split(',').map((val) => ({ value: val })),
            );
          } else {
            filterForm.setValue(key, value);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterForm, open, searchParams]);

  const handleClear = () => {
    filterForm.reset(defaultValues);
  };

  const handleApply = (filterData: UsersFilter) => {
    const params = new URLSearchParams();
    for (const [key, filter] of Object.entries(filterData)) {
      if (Array.isArray(filter) && filter.length) {
        params.set(key, filter.map((item) => item.value).join(','));
      } else if (filter && !Array.isArray(filter)) {
        switch (key) {
          case 'isInternal':
            if (filter === 'all') {
              if (params.has(key)) {
                params.delete(key);
              }
              break;
            }
            params.set(key, filter);
            break;
        }
      }
    }

    navigate(
      { pathname: location.pathname, search: params.toString() },
      { replace: true },
    );
    onApply();
  };

  const internalRoles = useMemo(
    () =>
      INTERNAL_ROLES.map((role) => ({
        value: role.value,
        label: t(role.label),
      })),
    [t],
  );
  const externalRoles = useMemo(
    () =>
      EXTERNAL_ROLES.map((role) => ({
        value: role.value,
        label: t(role.label),
      })),
    [t],
  );
  const userTypes = useMemo(
    () =>
      USER_TYPES.map((userType) => ({
        value: userType.value,
        label: t(userType.label),
      })),
    [t],
  );

  return (
    <FormProvider {...filterForm}>
      <Drawer.Header>
        <Drawer.Title>{t('filters')}</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <FormFieldCombobox
          name="roles"
          label={t('role')}
          multiselect={true}
          displaySelectedOptions={true}
          options={isCurrentUserInternal ? internalRoles : externalRoles}
        />
        {isCurrentUserInternal ? (
          <>
            <Separator className="my-md" />
            <FormFieldRadioGroup
              name="isInternal"
              label={t('userType')}
              options={userTypes}
            />
          </>
        ) : null}
        <Separator className="my-md" />
        <FormFieldCombobox
          name="labelIds"
          label={t('labels')}
          multiselect={true}
          displaySelectedOptions={true}
          options={LABEL_OPTIONS}
        />
        {isCurrentUserInternal ? (
          <>
            <Separator className="my-md" />
            <FormFieldCombobox
              name="labelGroupIds"
              label={t('labelGroup.list')}
              multiselect={true}
              displaySelectedOptions={true}
              options={LABEL_GROUP_OPTIONS}
            />
          </>
        ) : null}
      </Drawer.Body>
      <Drawer.Footer>
        <Button
          size="large"
          variant="tertiary"
          onClick={handleClear}
          className="font-normal"
          type="button"
        >
          {t('clear')}
        </Button>
        <Button
          size="large"
          type="button"
          onClick={filterForm.handleSubmit(handleApply)}
        >
          {t('applyButton')}
        </Button>
      </Drawer.Footer>
    </FormProvider>
  );
};

UsersFilterForm.displayName = 'UsersFilterForm';
