import { cn } from '~/lib/cn';

export interface TitleProps {
  className?: string;
  children: React.ReactNode | React.ReactNode[];
}

const Title = ({ className, children }: TitleProps): JSX.Element => {
  return <p className={cn('text-body-sm font-bold', className)}>{children}</p>;
};

Title.displayName = 'Title';

export { Title };
