export const searchFilter = (search: string): string => {
  if (search.startsWith('"') && search.endsWith('"')) {
    const s = search.slice(1, -1);
    return `clean_title = '${s}' or artist = '${s}'`;
  }
  return `clean_title ilike '${search}%' or artist ilike '${search}%'`;
};

export const combineFilters = (
  search: string | undefined,
  filter: string | undefined,
): string => {
  const searchCriteria = search ? searchFilter(search) : '';
  const serializedFilter = filter || '';

  if (searchCriteria && serializedFilter) {
    return `${serializedFilter} AND (${searchCriteria})`;
  }

  return searchCriteria || serializedFilter.trim();
};
