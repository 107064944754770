import { showSaveFilePicker } from 'file-system-access';

import { DEFAULT_PAGE_SIZE } from '../components/constants';
import {
  DATA_TOOLS_ORDER,
  IDENTIFIER_TYPES,
  SOCIALS_ORDER,
} from '../lib/constants';
import type {
  ArtistDetails,
  ArtistDetailsDataResponse,
  ArtistSearchDataResponse,
  ArtistType,
  Identifier,
  IdentifierLink,
} from '../lib/types';

export type ArtistSearchResponse = {
  items: ArtistType[];
  pagination: { offset: number; total: number };
};

const REPORTS_IDENTIFIERS: Record<
  string,
  { displayName: string; url: (value: string) => string }
> = {
  [IDENTIFIER_TYPES.SODATONE]: {
    displayName: 'Sodatone',
    url: (value) => `https://app.sodatone.com/artists/${value}`,
  },
};

const SOCIAL_IDENTIFIERS: Record<
  string,
  { displayName: string; url: (value: string) => string }
> = {
  [IDENTIFIER_TYPES.SPOTIFY]: {
    displayName: 'Spotify',
    url: (value) => `https://open.spotify.com/artist/${value}`,
  },

  [IDENTIFIER_TYPES.INSTAGRAM]: {
    displayName: 'Instagram',
    url: (value) => `https://www.instagram.com/${value}`,
  },

  [IDENTIFIER_TYPES.YOUTUBE]: {
    displayName: 'YouTube',
    url: (value) => `https://www.youtube.com/channel/${value}`,
  },

  [IDENTIFIER_TYPES.TIKTOK]: {
    displayName: 'TikTok',
    url: (value) => `https://www.tiktok.com/@${value}`,
  },
};

const formatIdentifier = (
  identifier: Identifier,
  filter: Record<
    string,
    { displayName: string; url: (value: string) => string }
  >,
) => {
  const formatUrl = filter[identifier.identifier_type.toLowerCase()];

  return formatUrl
    ? {
        name: identifier.identifier_type.toLowerCase(),
        url: identifier.url ? identifier.url : formatUrl.url(identifier.value),
        displayName: formatUrl.displayName,
        followerCount: identifier.follower_count,
      }
    : null;
};

const formatArtistData = (artist: ArtistDetailsDataResponse): ArtistType => {
  return {
    id: artist.party_id,
    uaid: artist.uaid,
    name: artist.name.default_name,
    labels: artist.labels
      .map((label) => ({
        labelId: label.label_id,
        name: label.name,
      }))
      .sort((a, b) => a.name.localeCompare(b.name)),
    image: artist.image?.sodatone,
    isCrossOver: artist.name.is_crossover,
    reports: [
      ...(artist.name.identifiers
        .map((identifier) => formatIdentifier(identifier, REPORTS_IDENTIFIERS))
        .filter(Boolean) as IdentifierLink[]),
      {
        name: IDENTIFIER_TYPES.ARTIST_PACK,
        url: `https://audience-insights.onrender.com/decks/d-0/slides/deckTitle?a=${artist.uaid}`,
        displayName: 'Artist Pack',
      },
      {
        name: IDENTIFIER_TYPES.OPUS,
        url: `https://opus.wmg.com/entity/artist/GRP-${artist.uaid}`,
        displayName: 'Opus',
      },
    ].sort(
      (a, b) =>
        DATA_TOOLS_ORDER.indexOf(a.displayName ?? a.name) -
        DATA_TOOLS_ORDER.indexOf(b.displayName ?? b.name),
    ),
    socials: artist.name.identifiers
      .map((identifier) => formatIdentifier(identifier, SOCIAL_IDENTIFIERS))
      .filter((identifier) => !!identifier)
      .sort(
        (a, b) => SOCIALS_ORDER.indexOf(a.name) - SOCIALS_ORDER.indexOf(b.name),
      ) as IdentifierLink[],
  };
};

const formatArtistDetails = (
  artist: ArtistDetailsDataResponse,
): ArtistDetails => {
  return {
    ...formatArtistData(artist),
    countryOfOriginISO: artist.country_of_origin_iso_code ?? undefined,
  };
};

const formatArtistSearchData = (
  data: ArtistSearchDataResponse,
): ArtistSearchResponse => {
  return {
    items: data.items.map(formatArtistData),
    pagination: {
      offset: data.pagination_offset.offset,
      total: data.pagination_offset.total,
    },
  };
};

export const getArtists = async ({
  search = '',
  limit = DEFAULT_PAGE_SIZE,
  offset = 0,
  labels = [],
  sortBy = '+NAME',
  isCrossover = false,
}: {
  search?: string;
  limit?: number;
  offset?: number;
  labels?: number[];
  isCrossover?: boolean;
  sortBy?: string;
}) => {
  try {
    // TODO: Hookup isCrossover (https://wmggt.atlassian.net/browse/WMG1-183)
    const response = await fetch(
      `/api/roster/artists?search=${encodeURIComponent(search)}&limit=${limit}&offset=${offset}&labelIds=${labels.join(',')}&sort_by=${encodeURIComponent(sortBy)}&crossover=${isCrossover}`,
    );

    if (!response.ok) {
      throw new Error(`Failed to fetch artists: ${response.statusText}`);
    }

    const data: ArtistSearchDataResponse = await response.json();
    return formatArtistSearchData(data);
  } catch (error) {
    console.error('Error fetching artists:', error);
    throw error;
  }
};

export const exportArtistsCSV = async ({
  search = '',
  labels = [],
  isCrossOver = false,
  sortBy = '+NAME',
}: {
  search?: string;
  labels?: number[];
  isCrossOver?: boolean;
  sortBy?: string;
}): Promise<boolean> => {
  let fileHandle;

  try {
    fileHandle = await showSaveFilePicker({
      _preferPolyfill: false,
      suggestedName: `artists-${new Date().toISOString()}${search ? '-' + search.replace(/[^a-z0-9]/gi, '_') : ''}.csv`,
      types: [{ description: 'CSV Files', accept: { 'text/csv': ['.csv'] } }],
    });
  } catch {
    // If the user cancels the modal, then an exception is thrown so just return
    // here because there's nothing to do.
    return false;
  }

  let stream: FileSystemWritableFileStream | null = null;

  try {
    const searchParams = new URLSearchParams({
      sort_by: sortBy,
      search,
      crossover: `${isCrossOver}`,
    });

    if (labels.length > 0) {
      searchParams.append('labelIds', labels.join(','));
    }

    const response = await fetch(`/api/roster/artists/export?${searchParams}`);
    if (!response.ok || !response.body) {
      throw new Error(`Failed to export artists: ${response.statusText}`);
    }

    stream = await fileHandle.createWritable();
    const reader = response.body.getReader();

    let done = false;
    while (!done) {
      const { value, done: readDone } = await reader.read();
      done = readDone;
      if (value) {
        await stream.write(value);
      }
    }

    return true;
  } catch (error) {
    if (error instanceof DOMException && error.name === 'AbortError') {
      if (stream) {
        await stream.abort();
      }

      return false;
    }

    console.error('Error exporting artists:', error);
    throw error;
  } finally {
    if (stream) {
      await stream.close();
    }
  }
};

export const getArtistDetail = async ({ uaid }: { uaid?: string }) => {
  if (!uaid) {
    throw new Error('UAID is required');
  }

  try {
    const response = await fetch(`/api/roster/artists/${uaid}`);
    if (!response.ok) {
      throw new Error(`Failed to fetch artists: ${response.statusText}`);
    }

    const data: ArtistDetailsDataResponse = await response.json();
    return formatArtistDetails(data);
  } catch (error) {
    console.error('Error fetching artists:', error);
    throw error;
  }
};

export const getCountryName = (countryCode: string, lang = 'en') => {
  const regionNames = new Intl.DisplayNames([lang], { type: 'region' });
  return regionNames.of(countryCode);
};
