import type { ReactNode } from 'react';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { cn } from '~/lib/cn';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  children: ReactNode;
}

export const Modal = ({ isOpen, onClose, title, children }: ModalProps) => {
  const overlayRef = useRef<HTMLDivElement | null>(null);
  const [isVisible, setIsVisible] = useState(isOpen);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      setIsVisible(true);
      setTimeout(() => setIsAnimating(true), 10);
      document.body.style.overflow = 'hidden';
    } else {
      setIsAnimating(false);
      const timer = setTimeout(() => {
        setIsVisible(false);
        document.body.style.overflow = '';
      }, 150);
      return () => clearTimeout(timer);
    }

    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
      document.body.style.overflow = '';
    };
  }, [isOpen, onClose]);

  if (!isVisible) {
    return null;
  }

  return createPortal(
    <div
      ref={overlayRef}
      role="button"
      tabIndex={0}
      className="fixed inset-0 z-50 flex cursor-default items-center justify-center"
    >
      <div
        className={cn(
          'absolute inset-0 duration-150 bg-overlay bg-transparent',
          { 'backdrop-blur': isAnimating },
        )}
        aria-hidden="true"
      />
      <div
        className={`absolute inset-0 transition-opacity duration-150 bg-overlay ${
          isAnimating ? 'opacity-stronger' : 'opacity-none'
        }`}
        onClick={onClose}
        aria-hidden="true"
        data-testid="modal-overlay"
      />
      <div
        role="dialog"
        aria-labelledby="modal-title"
        aria-modal="true"
        className={`relative z-10 flex flex-col items-center justify-center rounded-lg p-lg shadow-lg transition-opacity duration-150 bg-transparent ${
          isAnimating ? 'opacity-full' : 'opacity-none'
        }`}
      >
        <header className="mb-md flex items-center justify-between bg-transparent">
          {title ? (
            <h2
              id="modal-title"
              className="text-body-lg font-semibold text-default"
            >
              {title}
            </h2>
          ) : null}
        </header>

        {children}
      </div>
    </div>,
    document.body,
  );
};
Modal.displayName = 'Modal';
