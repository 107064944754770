import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Separator } from '~/components';
import { useIsExternalUser } from '~/hooks/useIsExternalUser';
import { DetailPageError } from '~/modules/errors/components/DetailPageError';
import type { UserListItem } from '~/modules/settings/api/lib/schemas';
import { useUserList } from '~/modules/settings/api/queries/userList';
import { useLabel } from '~settings/api/queries/label';
import { ContentBox } from '~settings/components/ContentBox';
import { Tabs } from '~settings/components/Tabs';
import { SETTINGS_ROUTES } from '~settings/config/routes';
import { transformLabelDetails } from '~settings/utils/transformers';

import { DistributionTerritories } from './components/DistributionTerritories';
import { LabelAdmins } from './components/LabelAdmins';
import { LabelDetailsHeader } from './components/LabelDetailsHeader';
import { LabelGroups } from './components/LabelGroups';
import { LabelMetadata } from './components/LabelMetadata';
import { Socials } from './components/Socials';
import { Sublabels } from './components/Sublabels';
import { Users } from './components/Users';

type DetailLabelPageRouteParams = {
  labelId: string;
};

const userEmptyArray: UserListItem[] = [];

export const DetailLabelPage = () => {
  const { labelId } = useParams<DetailLabelPageRouteParams>();
  const navigate = useNavigate();
  const { t } = useTranslation('settings');
  const { isLoading, error, label } = useLabel(labelId || '', {
    select: transformLabelDetails,
  });
  const { userList: adminUserList } = useUserList({
    roles: 'LABEL_ADMIN',
    labelIds: labelId,
  });

  const isCurrentUserExternal = useIsExternalUser();

  useEffect(() => {
    if (!labelId) {
      navigate(SETTINGS_ROUTES.LABEL_LIST);
    }
  }, [labelId, navigate]);

  const tabs = useMemo(() => {
    if (!label) {
      return [];
    }

    return [
      {
        title: t('labelDetails.sublabelsCount', {
          sublabelsCount: label.sublabelsCount,
        }),
        component: <Sublabels parentLabelId={labelId} />,
      },
      {
        title: t('labelDetails.usersCount', {
          usersCount: label.usersCount,
        }),
        component: (
          <Users
            labelId={labelId}
            isSublabel={label.isSublabel}
            total={label.usersCount}
          />
        ),
      },
    ];
  }, [label, labelId, t]);

  if (isLoading) {
    return <section className="py-md">{t('loading')}</section>;
  }

  if (error || !label) {
    return (
      <DetailPageError
        errorCode={error?.response?.status}
        fallbackRoute={{
          href: SETTINGS_ROUTES.LABEL_LIST,
          label: t('backToUserManagement'),
        }}
      />
    );
  }

  return (
    <>
      <LabelDetailsHeader data={label} />
      <article className="flex gap-md py-md">
        <section className="inline-flex min-w-[19rem] flex-none basis-1/5 flex-col gap-md text-body-sm">
          <ContentBox>
            <LabelMetadata key="label-metadata" data={label} />

            {!isCurrentUserExternal || label.parentId ? (
              <Separator className="my-lg" />
            ) : null}
            {adminUserList?.data !== null && adminUserList?.data ? (
              <LabelAdmins
                key="label-admins-parent"
                adminUsers={adminUserList?.data ?? userEmptyArray}
              />
            ) : null}

            {!isCurrentUserExternal && label.distributionGroups !== null && (
              <DistributionTerritories
                key="distribution-territories"
                distributionGroups={label.distributionGroups}
              />
            )}
            {!isCurrentUserExternal && label.labelGroups.length !== 0 && (
              <LabelGroups key="label-groups" labelGroups={label.labelGroups} />
            )}

            {label.socials.length !== 0 && (
              <Socials key="socials-parent" socials={label.socials} />
            )}
          </ContentBox>
        </section>
        <ContentBox className="flex-1 rounded-sm py-lg">
          {label.parentId ? (
            <Users
              labelId={labelId}
              isSublabel={label.isSublabel}
              total={label.usersCount}
            />
          ) : (
            <Tabs tabs={tabs} />
          )}
        </ContentBox>
      </article>
    </>
  );
};
DetailLabelPage.displayName = 'DetailLabelPage';
