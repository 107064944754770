import type {
  ColumnDef,
  OnChangeFn,
  SortingState,
} from '@tanstack/react-table';
import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Skeleton } from '~/components/Skeleton';

import { DEFAULT_PAGE_SIZE } from '../../constants';
import { TableHead } from './components/TableHead';
import { TableRow } from './components/TableRow';
export interface TableProps<TData> {
  data: TData[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<TData, any>[];

  // Row specific props
  rowClassName?: string;
  onRowClick?: (row: TData) => void;

  // Sorting specific props
  sort?: {
    onSortingChange: OnChangeFn<SortingState>;
    sorting: SortingState;
  };
  loading?: boolean;
}

export const Table = <TData extends { id: string }>({
  data,
  columns,
  rowClassName,
  onRowClick,
  sort,
  loading,
}: TableProps<TData>) => {
  const { t } = useTranslation('settings');
  const { onSortingChange, sorting } = sort ?? {};

  const tableData = useMemo(
    () => (loading ? Array(DEFAULT_PAGE_SIZE).fill({}) : data),
    [loading, data],
  );

  const columnsMemo = useMemo(
    () =>
      loading
        ? columns.map((column) => ({ ...column, cell: () => <Skeleton /> }))
        : columns,
    [loading, columns],
  );

  const table = useReactTable({
    data: tableData,
    columns: columnsMemo,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualSorting: true,
    onSortingChange,
    state: {
      sorting,
    },
    // Enable sorting only if onSortingChange is provided
    enableSorting: !!onSortingChange,
  });

  return (
    <table className="w-full border-separate border-spacing-0 pt-lg">
      <TableHead headerGroups={table.getHeaderGroups()} />
      <tbody className="text-body-md">
        {data.length || loading ? (
          table
            .getRowModel()
            .rows.map((row) => (
              <TableRow
                key={row.id}
                row={row}
                className={rowClassName}
                onClick={() => onRowClick?.(row.original)}
              />
            ))
        ) : (
          <tr>
            <td colSpan={columns.length} className="p-md">
              {t('noData')}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

Table.displayName = 'Table';
