import { DEFAULT_PAGE_SIZE } from '../components/constants';
import type {
  AssetFamily,
  AssetFamilyData,
  AssetFamilyDataResponse,
  AssetPerformanceResponse,
  DspType,
  EngagementMetrics,
  PerformanceMetrics,
  PeriodComparison,
  StreamingMetrics,
} from './types';
import { calculateDeltaRatio } from './utils';

const formatArtistTracksData = (
  data: AssetFamilyDataResponse,
): {
  items: AssetFamily[];
  pagination: {
    offset: number;
    total: number;
  };
} => {
  return {
    items: data.items.map((item) => ({
      id: item.family_id,
      title: item.grouping_title,
      description: item.description,
      initialReleaseDate: item.earliest_release_date,
      image: item.image,
      links: item.links
        ? {
            airtable: item.links.airtable,
            opus: item.links.opus,
            tableauBenchmark: item.links.tableau_benchmark,
          }
        : {
            // mock data
            airtable: 'https://www.warnermusic.com',
            opus: 'https://www.warnermusic.com',
            tableauBenchmark: 'https://www.warnermusic.com',
          },
    })),
    pagination: {
      offset: data.pagination_offset.offset,
      total: data.pagination_offset.total,
    },
  };
};

const formatAssetFamilyData = (data: AssetFamilyData): AssetFamily => {
  return {
    id: data.family_id,
    title: data.grouping_title,
    description: data.description,
    initialReleaseDate: data.earliest_release_date,
    image: data.image,
  };
};

export const getArtistTracks = async ({
  uaid = '',
  limit = DEFAULT_PAGE_SIZE,
  offset = 0,
  sortBy = '-EARLIEST_RELEASE_DATE',
  partialTitle = '',
}: {
  uaid?: string;
  limit?: number;
  offset?: number;
  sortBy?: string;
  partialTitle?: string;
}) => {
  if (!uaid) {
    throw new Error('UAID is required');
  }

  try {
    const response = await fetch(
      `/api/roster/asset-families?uaid=${encodeURIComponent(uaid)}&limit=${limit}&offset=${offset}&sort_by=${encodeURIComponent(sortBy)}&partial_title=${encodeURIComponent(partialTitle)}`,
    );

    if (!response.ok) {
      throw new Error(`Failed to fetch artist tracks: ${response.statusText}`);
    }

    const data: AssetFamilyDataResponse = await response.json();
    return formatArtistTracksData(data);
  } catch (error) {
    console.error('Error fetching artists:', error);
    throw error;
  }
};

export const getAssetByFamilyId = async ({
  familyId,
}: {
  familyId: string;
}) => {
  if (!familyId) {
    throw new Error('familyId is required');
  }

  try {
    const response = await fetch(`/api/roster/asset-families/${familyId}`);

    if (!response.ok) {
      throw new Error(
        `Failed to fetch asset by familyId: ${response.statusText}`,
      );
    }

    const data: AssetFamilyData = await response.json();
    return formatAssetFamilyData(data);
  } catch (error) {
    console.error('Error fetching asset by familyId:', error);
    throw error;
  }
};

const formatPeriodComparison = (
  current: number,
  previous: number,
): PeriodComparison => ({
  current,
  previous,
  deltaRatio: calculateDeltaRatio({ current, previous }),
});

const formatStreamingMetric = (
  item: AssetPerformanceResponse['tracks'][number]['streaming_by_dsp'][number],
): StreamingMetrics => ({
  totalStreams: item.total_streams,
  periodStreams: formatPeriodComparison(
    item.this_period_streams,
    item.previous_period_streams,
  ),
  organicStreams: formatPeriodComparison(
    item.organic_streams,
    item.previous_period_organic_streams,
  ),
  // @TODO WMG1-273 Waiting for programmed streams data
});

const formatEngagementMetric = (
  item: AssetPerformanceResponse['tracks'][number]['engagement_by_dsp'][number],
): EngagementMetrics => ({
  socialCreations: formatPeriodComparison(
    item.this_period_social_creations,
    item.previous_period_social_creations,
  ),
});

const formatTrackPerformanceData = (data: AssetPerformanceResponse) => {
  const formatted = data.tracks.map((track) => {
    const streamingDspMap: Record<DspType, StreamingMetrics> = {};
    const engagementDspMap: Record<DspType, EngagementMetrics> = {};

    let aggregatedStreams: StreamingMetrics | undefined;
    let aggregatedEngagement: EngagementMetrics | undefined;

    track.streaming_by_dsp.forEach((item) => {
      const metric = formatStreamingMetric(item);
      if (item.dsp === 'all') {
        aggregatedStreams = metric;
      } else {
        streamingDspMap[item.dsp] = metric;
      }
    });
    track.engagement_by_dsp.forEach((item) => {
      const metric = formatEngagementMetric(item);
      engagementDspMap[item.dsp] = metric;
      if (item.dsp === 'all') {
        aggregatedEngagement = metric;
      }
    });

    return {
      familyId: Number(track.family_id),
      performance: {
        streaming: {
          aggregated: aggregatedStreams!,
          dspMap: streamingDspMap,
        },
        engagement: {
          aggregated: aggregatedEngagement!,
          dspMap: engagementDspMap,
        },
      },
    };
  });

  return new Map<number, PerformanceMetrics>(
    formatted.map((item) => [item.familyId, item.performance]),
  );
};

export const getArtistTracksPerformance = async ({
  familyIds = [],
}: {
  familyIds: number[];
}) => {
  try {
    const response = await fetch(
      `/api/roster/tracks-performance?family_ids=${familyIds.join(',')}&page_size=${DEFAULT_PAGE_SIZE}`,
    );

    if (!response.ok) {
      throw new Error(
        `Failed to fetch artist tracks performance: ${response.statusText}`,
      );
    }

    const data: AssetPerformanceResponse = await response.json();
    return formatTrackPerformanceData(data);
  } catch (error) {
    console.error('Error fetching artists:', error);
    throw error;
  }
};

export const getTrackPerformanceOverTime = async ({
  familyId,
}: {
  familyId: number;
}) => {
  // @TODO WMG1-287 Waiting for performance over time data
  return Promise.resolve({
    familyId,
    performance_over_time: {
      release_age_day: 12,
      metrics: [
        {
          date: '2024-09-01',
          streams: 120000,
          organic_streams: 70000,
          programmed_streams: 50000,
        },
        {
          date: '2024-09-02',
          streams: 130000,
          organic_streams: 75000,
          programmed_streams: 55000,
        },
        {
          date: '2024-09-03',
          streams: 100000,
          organic_streams: 45000,
          programmed_streams: 55000,
        },
        {
          date: '2024-09-04',
          streams: 150000,
          organic_streams: 75000,
          programmed_streams: 75000,
        },
        {
          date: '2024-09-05',
          streams: 120000,
          organic_streams: 70000,
          programmed_streams: 50000,
        },
      ],
    },
  });
};
