import OktaAuth, { type AccessToken, type Token } from '@okta/okta-auth-js';

import { bffClient } from '~/modules/auth/lib/bff-client';
import type { Brand } from '~/modules/branding/types';

import { getOktaConfig } from './okta-config';

const onTokenAdded = async (key: string, token: Token) => {
  if (key === 'accessToken' && !token.pendingRemove) {
    await bffClient.authenticate((token as AccessToken).accessToken);
  }
};

const getOktaAuth = (brand: Brand) => {
  const oktaConfig = getOktaConfig(brand);

  const oktaAuth = new OktaAuth({
    // WidgetOptions defines `authParams` as optional, but it won't be based on the
    // default setup.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    issuer: oktaConfig.authParams.issuer,
    clientId: oktaConfig.clientId,
    redirectUri: window.location.origin + '/login/callback',
    scopes: oktaConfig.authParams?.scopes,
    services: {
      autoRenew: true,
      autoRemove: true,
      syncStorage: true,
      renewOnTabActivation: true,
      // renew after 5 minutes of inactivity
      tabInactivityDuration: 60,
    },
    tokenManager: {
      autoRenew: true,
      // Note: This only works in DEV, so it will not have any effect outside
      // the local environment.
      expireEarlySeconds: 5 * 60,
      secure: true,
      storage: window.localStorage,
    },
  });
  oktaAuth.tokenManager.on('added', onTokenAdded);

  return oktaAuth;
};

export { getOktaAuth };
