import { useTranslation } from 'react-i18next';

import { Icon } from '~/components';
import { Breadcrumbs } from '~/modules/navigation/components/Breadcrumbs';
import { Header } from '~/modules/settings/components/Header';

interface SettingsPageHeader {
  breadcrumbItems: { to: string; label: string }[];
  title: string;
  iconType?: string;
}

export function SettingsPageHeader({
  breadcrumbItems,
  title,
  iconType = 'gear',
}: SettingsPageHeader) {
  const { t } = useTranslation('settings');

  return (
    <Header
      breadcrumbs={
        <Breadcrumbs.Root>
          {breadcrumbItems.map((item, index) => (
            <Breadcrumbs.Item key={index} to={item.to}>
              {index === 0 && (
                <Icon
                  type={iconType}
                  size="sm"
                  variant="regular"
                  className="mr-sm"
                />
              )}
              {t(item.label)}
            </Breadcrumbs.Item>
          ))}
        </Breadcrumbs.Root>
      }
    >
      <h1 className="pb-xl text-display-sm font-bold">{t(title)}</h1>
    </Header>
  );
}
SettingsPageHeader.displayName = 'SettingsPageHeader';
