import type { VictoryThemeDefinition } from 'victory';

export const VictoryWmgTheme: VictoryThemeDefinition = {
  axis: {
    style: {
      axis: {
        stroke: '#FFFFFF12',
      },
      axisLabel: {
        fontFamily: 'SF Pro, sans-serif',
        fontSize: 6,
        fontWeight: 400,
        fill: '#878787',
        angle: 90,
        padding: 32,
      },
      grid: {
        stroke: '#FFFFFF12',
      },
      tickLabels: {
        fontFamily: 'SF Pro, sans-serif',
        fontSize: 6,
        fontWeight: 400,
        fill: '#878787',
        padding: 6,
      },
    },
  },
  area: {
    style: {
      labels: {
        fontFamily: 'SF Pro, sans-serif',
        fontSize: 8,
      },
    },
  },
};
