import { lazy, Suspense, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  exportDerivativeCSV,
  getDerivativePageData,
} from '../../api/derivative';
import type {
  ColDef,
  DataGridState,
  DataLoader,
  DataQuery,
  ExportCSVPayload,
  ExportCSVResponse,
} from '../../components/DataGrid/types';
import {
  arrayUnique,
  camelToSnake,
  serialize,
} from '../../components/DataGrid/utils';
import type { DerivativeRow, DerivativeRowKeyWithPrefix } from '../../types';
import { columnDefs } from './columns';
import { filterColumns } from './filter';
import { combineFilters } from './utils/drv';

const LazyDerivativeGrid = lazy(() =>
  import('../../components/DataGrid').then((module) => ({
    default: module.DataGrid,
  })),
);

const defaultColDef: ColDef = {
  sortable: false,
  resizable: true,
};

const Derivative = (): JSX.Element => {
  const navigate = useNavigate();
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  const downloadCSVFile = (url: string): void => {
    try {
      const downloadAnchor = document.createElement('a');
      downloadAnchor.style.display = 'none';
      downloadAnchor.href = url;
      downloadAnchor.download = 'CSV Export';
      downloadAnchor.click();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error downloading CSV file:', error);
    }
  };

  const exportCSVFile = async (context: DataGridState): Promise<void> => {
    const { select, order, filter } = context;
    const serializedFilter = serialize(filter);
    const combinedFilter = combineFilters(search, serializedFilter);

    const gridData: ExportCSVPayload = {
      select: arrayUnique(select.map((s) => camelToSnake(s))),
      order: order.map(
        (o) => `${o.direction === 'desc' ? '-' : ''}${camelToSnake(o.field)}`,
      ),
      filter: combinedFilter,
    };

    setExportLoading(true);
    const res = await exportDerivativeCSV(gridData).catch((error) => {
      // eslint-disable-next-line no-console
      console.log('error', error);
      setExportLoading(false);
    });
    const url = (res as ExportCSVResponse)?.url;

    if (url) {
      downloadCSVFile(url);
      setExportLoading(false);
    }
  };

  const onExport = (context: DataGridState): void => {
    exportCSVFile(context).catch((error) => {
      // eslint-disable-next-line no-console
      console.log('error', error);
    });
  };

  const dataLoader: DataLoader<Partial<DerivativeRow>> = useCallback(
    (query: DataQuery<Partial<DerivativeRow>>) => {
      const combinedFilter = combineFilters(search, query.filter);

      return getDerivativePageData({
        ...query,
        offset: query.nextPageToken
          ? Number(query.nextPageToken.split('=')[1])
          : 0,
        select: query.select,
        order: query.order?.map((s) =>
          s.direction === 'asc' ? s.field : `-${s.field}`,
        ) as DerivativeRowKeyWithPrefix[],
        filter: combinedFilter,
      });
    },
    [search],
  );

  return (
    <>
      <div className="flex h-[82vh] flex-wrap">
        <Suspense fallback={<div>Loading Data Grid...</div>}>
          <LazyDerivativeGrid
            columnDefs={columnDefs}
            dataLoader={dataLoader}
            defaultColDef={defaultColDef}
            enableConfig={true}
            enableExpand={true}
            enableExport={true}
            enableFilters={true}
            exportLoading={exportLoading}
            filterColumns={filterColumns}
            id="derivative"
            limit={100}
            onExport={onExport}
            onSearch={setSearch}
            onViewChanged={(view) => {
              if (view === 'Derivative') {
                return;
              }
              if (view === 'Territories') {
                navigate('../debug-dashboard');
              }
              if (view === 'TrackRollup') {
                navigate('../track-rollup');
              }
            }}
            viewIcon="microscope"
            views={['Derivative', 'Territories', 'TrackRollup']}
          />
        </Suspense>
      </div>
    </>
  );
};

Derivative.displayName = 'Derivative';

export { Derivative };
